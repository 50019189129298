// import '@capacitor-community/http';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { appsOutline, arrowBack, calendarOutline, clipboardOutline, desktopOutline, flagOutline, logoUsd, peopleOutline, trendingUpOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './MenuRelatorios.css';

type Props = {
    abrirMenu: boolean;
}

const MenuRelatorios: React.FC<Props> = (props: Props) => {

    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [textToast, setTextToast] = useState("");
    const [showToast2, setShowToast2] = useState(false);

    const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
    const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
    const { nomeMenu, setNomeMenu } = useContext(MessageContext);

    useIonViewDidEnter(() => {
        setEsconderMenu(true);
        setNomeMenu("");
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton icon={arrowBack} text=''></IonBackButton>
                    </IonButtons>
                    {/* <IonButtons slot="start">
                        <IonButton>
                            <IonIcon slot="icon-only" icon={list} />
                        </IonButton>
                    </IonButtons> */}
                    <IonTitle slot="start">Relatórios</IonTitle>

                </IonToolbar>
            </IonHeader>

            <IonContent className="conta">

                <div hidden={acessos?.relatorios != 'leitura' && acessos?.relatorios != 'edicao'}>
                    {/* <IonListHeader lines="full" >Relatórios</IonListHeader> */}

                    <IonItem hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'} lines="none" button routerLink="/empresa/relatoriousuarios" routerDirection="forward" detail>
                        <IonIcon color='medium' slot="start" icon={peopleOutline} />
                        <IonLabel>Usuários</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.vendas != 'leitura' && acessos?.vendas != 'edicao'} lines="none" button routerLink="/empresa/relatoriovendas" routerDirection="forward" detail>
                        <IonIcon color='medium' slot="start" icon={trendingUpOutline} />
                        <IonLabel>Vendas</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.balcao != 'leitura' && acessos?.balcao != 'edicao'} lines="none" button routerLink="/empresa/relatoriocaixa" routerDirection="forward" detail>
                        <IonIcon color='medium' slot="start" icon={desktopOutline} />
                        <IonLabel>{window.localStorage.getItem("nomeInternonomeInternoBalcaoOuCaixa") || "Balcão"}</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.comandas != 'leitura' && acessos?.comandas != 'edicao'} lines="none" button routerLink="/empresa/relatoriocomandas" routerDirection="forward" detail>
                        <IonIcon color='medium' slot="start" icon={clipboardOutline} />
                        <IonLabel>{window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas"}</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.projetos != 'leitura' && acessos?.projetos != 'edicao'} lines="none" button routerLink="/empresa/relatorioprojetos" routerDirection="forward" detail>
                        <IonIcon color='medium' slot="start" icon={appsOutline} />
                        <IonLabel>{window.localStorage.getItem("servicoTipoPlural") || "Projetos"}</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.agenda != 'leitura' && acessos?.agenda != 'edicao'} lines="none" button routerLink="/empresa/relatorioagendamentos" routerDirection="forward" detail>
                        <IonIcon color='medium' slot="start" icon={calendarOutline} />
                        <IonLabel>Agenda</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.operacoes != 'leitura' && acessos?.operacoes != 'edicao'} lines="none" button routerLink="/empresa/relatoriooperacoes" routerDirection="forward" detail>
                        <IonIcon color='medium' slot="start" icon={flagOutline} />
                        <IonLabel>Operações</IonLabel>
                    </IonItem>
                    <IonItem hidden={acessos?.financeiro != 'leitura' && acessos?.financeiro != 'edicao'} lines="none" button routerLink="/empresa/relatoriofinanceiro" routerDirection="forward" detail>
                        <IonIcon color='medium' slot="start" icon={logoUsd} />
                        <IonLabel>Financeiro</IonLabel>
                    </IonItem>
                    {/* {(window.localStorage.getItem("servicoPrincipal") == "Obras") && (
                        <IonItem hidden={(acessos?.projetos != 'leitura' && acessos?.projetos != 'edicao')} lines="none" button routerLink="/empresa/relatorioprojetosarquiteto" routerDirection="forward" detail>
                            <IonIcon color='medium' slot="start" icon={briefcaseOutline} />
                            <IonLabel>Arquiteto - {window.localStorage.getItem("servicoTipoPlural") || "Projetos"}</IonLabel>
                        </IonItem>
                    )} */}
                </div>

                <IonToast
                    isOpen={showToast}
                    position="middle" //top, bottom, middle
                    onDidDismiss={() => setShowToast(false)}
                    message={textToast}
                    duration={3000}
                />
            </IonContent>

        </IonPage>
    );
};

export default MenuRelatorios;
