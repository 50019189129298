import { IonButton, IonButtons, IonContent, IonDatetime, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPopover, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar, useIonAlert } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import "./AgendamentosNovo.css";

import { Toast } from '@capacitor/toast';
import { Buffer } from 'buffer';
import { addCircle, arrowBack } from 'ionicons/icons';
import 'moment/locale/pt-br';
import { empresaCarregar, incluirAgendamento, listaEquipePorPerfil, projetoLista, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Endereco from '../Endereco/Endereco';
import EquipeCad from '../EquipeCad/EquipeCad';
import PrestadoresCad from '../PrestadoresCad/PrestadoresCad';

type Props = {
  projetoid: any;
  inputRef: any;
  closeAction: Function;
  closeActionSimple: Function;
};

const AgendamentosNovo: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [tipoAgendamento, setTipoAgendamento] = useState("entrega");
  const [descricaoAgendamento, setDescricaoAgendamento] = useState<any>();
  const [atividadesPrestador, setAtividadesPrestador] = useState<any>();
  const [dataAgendamento, setDataAgendamento] = useState<any>();
  const [dataAgendamentoBr, setDataAgendamentoBr] = useState<any>();
  const [dataAgendamentoPeriodo1, setDataAgendamentoPeriodo1] = useState<any>();
  const [dataAgendamentoPeriodo1Br, setDataAgendamentoPeriodo1Br] = useState<any>();
  const [dataAgendamentoPeriodo2, setDataAgendamentoPeriodo2] = useState<any>();
  const [dataAgendamentoPeriodo2Br, setDataAgendamentoPeriodo2Br] = useState<any>();
  const [horaAgendamento, setHoraAgendamento] = useState<any>();
  const [horaAgendamento2, setHoraAgendamento2] = useState<any>();
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [prestador, setPrestador] = useState<any>();

  // const [showModalConvidados, setShowModalConvidados] = useState(false);
  // const [convidados, setConvidados] = useState<any>([]);
  const [selectedConvidados, setSelectedConvidados] = useState<any>([]);
  // const [selectedConvidadosText, setSelectedConvidadosText] = useState<string>('0 Convidados');

  const [enderecoPrincipalEmpresa, setEnderecoPrincipalEmpresa] = useState(false);
  const [servicoPeriodoHoraEmpresa, setServicoPeriodoHoraEmpresa] = useState(false);
  const [empresaLatitude, setEmpresaLatitude] = useState<any>();
  const [empresaLongitude, setEmpresaLongitude] = useState<any>();
  const [empresaEndereco, setEmpresaEndereco] = useState<any>();
  const [listaPrestador, setListaPrestador] = useState<any>([]);
  const [listaUsuario, setListaUsuario] = useState<any>([]);
  const [checked, setChecked] = useState(false);
  const [checkedPeriodo, setCheckedPeriodo] = useState(false);
  const [projeto, setProjeto] = useState("");
  const [listaProjeto, setListaProjeto] = useState<any>([]);
  const [diasSemana, setDiasSemana] = useState<string[]>();
  const [showModalDataCad, setShowModalDataCad] = useState(false);
  const [showModalDataInicio, setShowModalDataInicio] = useState(false);
  const [showModalDataFim, setShowModalDataFim] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover2, setShowPopover2] = useState(false);
  const [semDescricaoAgendamento, setSemDescricaoAgendamento] = useState(true);
  const [local, setLocal] = useState<any>();
  const [localEndereco, setLocalEndereco] = useState<any>();

  const [complemento, setComplemento] = useState('');

  const [mostartAlerta] = useIonAlert();

  const [showDetalhesEndereco, setShowDetalhesEndereco] = useState(false);
  const inputRefEndereco = useRef<any>(null);


  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');

  const popover1 = useRef<HTMLIonPopoverElement>(null);
  const popover2 = useRef<HTMLIonPopoverElement>(null);
  const popover3 = useRef<HTMLIonPopoverElement>(null);
  const popover4 = useRef<HTMLIonPopoverElement>(null);
  const popover5 = useRef<HTMLIonPopoverElement>(null);

  const [showDetalhesPrestador, setShowDetalhesPrestador] = useState(false);
  const [showDetalhesEquipe, setShowDetalhesEquipe] = useState(false);


  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { loja, setLoja } = useContext(MessageContext);

  const [checkedInterno, setCheckedInterno] = useState(false);

  const inputEquipeCadRef = useRef<any>(null);
  const inputPrestadoresCadRef = useRef<any>(null);

  let params: any = {
    projetoid: ""
  }

  params = useParams();

  if (location && location.state && location.state.projetoid) {
    params.projetoid = location.state.projetoid;
  }

  if (props.projetoid) {
    params = {
      projetoid: props.projetoid
    }
  }

  useEffect(() => {
    // Define o foco no input quando o componente é montado
    if (props.inputRef.current) {
      props.inputRef.current.focus();
    }
  }, [props.inputRef]);

  useEffect(() => {

    if (props.projetoid != null) {
      let id = atob(props.projetoid);
      console.log("props.projetoid", props.projetoid);
      setProjeto(id);
      avancarAgendamento(id);

      console.log("entrou no useEffect projetoid");
    }
    else {
      avancarAgendamento();
    }
  }, [props.projetoid]);

  // const formatData = (data: string[]) => {
  //   if (data.length === 1) {
  //     const convidado = convidados.find((convidado: any) => convidado.pk === data[0])!;
  //     return convidado.nome;
  //   }

  //   return `${data.length} Convidados`;
  // };

  // const convidadoSelectionChanged = (convidados: any[]) => {

  //   if (convidados.length > 0) {
  //     setSelectedConvidados(convidados);
  //     setSelectedConvidadosText(formatData(convidados));
  //   }
  //   else {
  //     setSelectedConvidados([]);
  //     setSelectedConvidadosText("0 Convidados");
  //   }
  //   setShowModalConvidados(false);
  // };



  const consultarUsuario = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo").concat("&lojas=" + Buffer.from(loja).toString('base64'));

    const resp = await fetch(montarUrl.replace("{perfil}", "usuario"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        return equipe.Items;
      }
    }
  }

  const consultarPrestadorUsuarios = async () => {

    let ret1 = await consultarPrestador();

    let lista = (ret1.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
    setListaPrestador(lista);
    
    // let ret2 = await consultarUsuario();

    // if (ret1 && ret2) {
    //   let merged = [...ret1, ...ret2]

    //   console.log("merged", merged);

    //   let lista = (merged.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
    //   // setConvidados(lista);
    //   setListaPrestador(lista);
    // }
  }

  const consultarPrestador = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo").concat("&lojas=" + Buffer.from(loja).toString('base64'));

    const resp = await fetch(montarUrl.replace("{perfil}", "prestador"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        return equipe.Items;
      }
    }
  }

  async function avancarAgendamento(projetoid?: any) {

    setDescricaoAgendamento("");
    setAtividadesPrestador("");



    let data = new Date().toString();
    const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'America/Sao_Paulo' }).format(
      Date.parse(data)
    );
    setDataAgendamentoBr(dataFormatada);
    setDataAgendamento(data);


    setDataAgendamentoPeriodo1Br(dataFormatada);
    setDataAgendamentoPeriodo1(data);


    setDataAgendamentoPeriodo2Br(dataFormatada);
    setDataAgendamentoPeriodo2(data);



    const horaAtualFormatada = new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).format(new Date());

    console.log(horaAtualFormatada);

    setHoraAgendamento(horaAtualFormatada);
    setHoraAgendamento2(horaAtualFormatada);

    setPrestador("");
    setChecked(false);
    setLocal("");
    setLatitude('');
    setLongitude('');
    //setProjeto("");

    setCheckedPeriodo(false);
    //setCheckedPeriodoHora(false);
    let arrayDias = ["1", "2", "3", "4", "5"];
    setDiasSemana(arrayDias);

    setCheckedInterno(false);

    const consultarEmpresa = async () => {
      requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {

        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log("response_empresa", response);
          setEnderecoPrincipalEmpresa(response.Item.enderecoPrincipalEmpresa || '');
          setServicoPeriodoHoraEmpresa(response.Item.servicoPeriodoHoraEmpresa ? true : false);
          setSemDescricaoAgendamento(response.Item.semDescricaoAgendamento ? true : false);
          if (response.Item.tipoAgendamentoPadrao) {
            setTipoAgendamento(response.Item.tipoAgendamentoPadrao);
          }
          //if (response.Item.enderecoPrincipalEmpresa) {
          setEmpresaEndereco(response.Item.empresaEndereco || '');
          setEmpresaLatitude(response.Item.empresaLatitude || '');
          setEmpresaLongitude(response.Item.empresaLongitude || '');
          //setLocal(response.Item.empresaEndereco);
          //}          
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });
    }

    const consultarProjeto = async (projetoid?: any) => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let montarUrl = projetoLista.url.replace("{loja}", loja.replaceAll(/#/g, "%23"));

      const resp = await fetch(montarUrl,
        {
          method: "GET",
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      let projetos = await resp.json();
      console.log("projetos", projetos);
      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }
      else {
        if (projetos.Items) {
          // let lista = projetos.Items.sort((a: any, b: any) => {
          //   const nomeProjetoA = typeof a.nomeProjeto === 'string' ? a.nomeProjeto.toLowerCase() : '';
          //   const nomeProjetoB = typeof b.nomeProjeto === 'string' ? b.nomeProjeto.toLowerCase() : '';

          //   if (nomeProjetoA > nomeProjetoB) return 1;
          //   if (nomeProjetoA < nomeProjetoB) return -1;
          //   return 0;
          // });

          setListaProjeto(projetos.Items);

          if (projetoid) {
            let found = projetos.Items.find((obj: any) => {
              return obj.pk === projetoid;
            });

            if (found) {
              setLocal(found.endereco);
              setLatitude(found.latitude);
              setLongitude(found.longitude);
              setComplemento(found.complemento);
            }

          }


        }
      }
    }

    await consultarProjeto(projetoid);
    await consultarEmpresa();
    await consultarPrestadorUsuarios();


  }

  function validarCamposAgendamento() {


    if (!semDescricaoAgendamento) {
      if (descricaoAgendamento.length === 0) {
        setTextToast("Campo Descrição é obrigatório.");
        setShowToast(true);
        return false;
      }
    }

    if (tipoAgendamento.length === 0) {
      setTextToast("Campo Tipo é obrigatório.");
      setShowToast(true);
      return false;
    }


    if (projeto.length === 0) {
      setTextToast("Campo " + (window.localStorage.getItem("servicoTipo") || "Projeto") + " é obrigatório.");
      setShowToast(true);
      return false;
    }


    if (horaAgendamento.length === 0) {
      setTextToast("Campo " + (servicoPeriodoHoraEmpresa ? "Começo é obrigatório." : "Hora é obrigatório."));
      setShowToast(true);
      return false;
    }

    if (servicoPeriodoHoraEmpresa && horaAgendamento2.length === 0) {
      setTextToast("Campo Termina é obrigatório.");
      setShowToast(true);
      return false;
    }

    if (servicoPeriodoHoraEmpresa) {
      let h1 = parseInt(horaAgendamento.replace(":", ""));
      let h2 = parseInt(horaAgendamento2.replace(":", ""));
      if (h1 > h2) {
        setTextToast("Hora termina não pode menor que hora começa.");
        setShowToast(true);
        return false;
      }
    }


    if (prestador.length === 0) {
      setTextToast("Campo Entregador é obrigatório.");
      setShowToast(true);
      return false;
    }

    if (local === '') {
      setTextToast("Campo Endereço é obrigatório.");
      setShowToast(true);
      return false;
    }

    return true;
  }

  const formatDateToSaoPaulo = (data: string) => {
    const date = new Date(data);
    const offset = date.getTimezoneOffset() / 60; // UTC offset in hours
    const saoPauloOffset = -3; // São Paulo is UTC-3
    return new Date(date.setHours(date.getHours() - offset + saoPauloOffset)).toISOString().substring(0, 10);
  };

  // const formatDateToSaoPaulo = (data: string) => {
  //   const date = new Date(data);

  //   return new Intl.DateTimeFormat('pt-BR', {
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     second: '2-digit',
  //     timeZone: 'America/Sao_Paulo',
  //   }).format(date);
  // };

  function formatDateConcatenando(dateString: any) {
    const date = new Date(dateString);

    console.log("***************", date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa de 0, então adicionamos 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  function iniciarAgendamento(pularEndereco: boolean = false) {


    if (!validarCamposAgendamento())
      return;




    if (botaoAtivo) {
      setShowLoading(true);
      setBotaoAtivo(false);


      let enderecoAgendamento = local;

      if (localEndereco == "online") {
        enderecoAgendamento = "online";
      }
      let enderecoAgendamentoLat = "";
      let enderecoAgendamentoLng = "";

      let found;
      found = listaProjeto.find((obj: any) => {
        return obj.pk === projeto;
      });

      if (found) {


        if (local == found.endereco) {
          enderecoAgendamentoLat = found.enderecoLat;
          enderecoAgendamentoLng = found.enderecoLng;
        }
        else if (local == empresaEndereco) {
          enderecoAgendamentoLat = empresaLatitude;
          enderecoAgendamentoLng = empresaLongitude;
        }

        console.log("found", found);


        // console.log("********dataFormatada", new Date(dataAgendamentoPeriodo1).toLocaleString('sv-SE'));        
        // console.log("********dataFormatada", formatDateConcatenando(new Date(dataAgendamentoPeriodo1)));
        // console.log("********dataFormatada", new Date(dataAgendamentoPeriodo1).toLocaleString());
        // console.log("********dataFormatada", formatDateToSaoPaulo(dataAgendamentoPeriodo1));

        let objRequest = {
          "descricaoAgendamento": descricaoAgendamento || found.nomeProjeto,
          "atividadesPrestador": atividadesPrestador,
          "tipoAgendamento": tipoAgendamento,
          "dataAgendamento": formatDateConcatenando(new Date(dataAgendamento)),
          "horaAgendamento": horaAgendamento,
          "horaAgendamento2": horaAgendamento2,
          "agendamentoPorPeriodo": checkedPeriodo,
          "agendamentoPeriodoHora": servicoPeriodoHoraEmpresa,
          "dataAgendamentoPeriodo1": formatDateConcatenando(new Date(dataAgendamentoPeriodo1)),
          "dataAgendamentoPeriodo2": formatDateConcatenando(new Date(dataAgendamentoPeriodo2)),
          "diasSemana": Buffer.from(JSON.stringify(diasSemana)).toString('base64'),
          "endereco": enderecoAgendamento || "",
          "complemento": complemento || "", //"localEndereco = online"
          "enderecoLat": latitude || enderecoAgendamentoLat,
          "enderecoLng": longitude || enderecoAgendamentoLng,
          "prestadorid": prestador,
          "convidados": selectedConvidados,
          "projetoid": projeto,
          "empresaid": loja || "",
          "interno": checkedInterno,
          "pularEndereco": pularEndereco
        };



        console.log("objRequest", objRequest);

        requestService(incluirAgendamento.url, {
          method: incluirAgendamento.method,
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          body: JSON.stringify(objRequest),
          credentials: 'include'
        },
          history,
          (response: any) => {
            if (response.message) {
              setBotaoAtivo(true);
              setShowLoading(false);

              props.closeAction();
              Toast.show({
                text: response.message,
                position: "center",
                duration: "long"
              });
            }
          },
          (error: any) => {
            console.log(error);
            setBotaoAtivo(true);
            setShowLoading(false);
            // Toast.show({
            //   text: error.message,
            //   position: "center",
            //   duration: "long"
            // });

            mostartAlerta({
              backdropDismiss: false,
              header: 'Endereço com problema',
              message: error.message,

              buttons: [{
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Confirmar', handler: () => {
                  iniciarAgendamento(true);
                }
              }],
              onDidDismiss: () => { console.log('did dismiss'); }
            })

          })

      }
      else {
        setBotaoAtivo(true);
        setShowLoading(false);
        console.log('Endereço não cadastrado no cliente!');
        setTextToast("Endereço não cadastrado no cliente!");
        setShowToast(true);
      }

    }
  }

  function readProjeto() {
    return listaProjeto.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={value.pk} value={value.pk}>{value.nomeProjeto} - {value.clienteNome}- {value.statusWorkflow}</IonSelectOption>
    )
    )
  };

  function readPrestador() {
    return listaPrestador.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={value.pk} value={value.pk}>{value.nome}</IonSelectOption>
    )
    )
  };

  function readUsuario() {
    return listaUsuario.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={value.pk} value={value.pk}>{value.nome}</IonSelectOption>
    )
    )
  };

  const openPopoverCad = (e: any) => {
    popover1.current!.event = e;
    setShowModalDataCad(true);
  };

  const openPopoverInicio = (e: any) => {
    popover2.current!.event = e;
    setShowModalDataInicio(true);
  };

  const openPopoverFim = (e: any) => {
    popover3.current!.event = e;
    setShowModalDataFim(true);
  };

  const openPopoverHora = (e: any) => {
    popover4.current!.event = e;
    setShowPopover(true);
  };

  const openPopoverHora2 = (e: any) => {
    popover5.current!.event = e;
    setShowPopover2(true);
  };

  async function closeModalPrestador(pk: any) {
    setPrestador(pk);
    setShowDetalhesPrestador(false);
    await consultarPrestadorUsuarios();
  }

  async function closeModalSimplePrestador() {
    setShowDetalhesPrestador(false);
  }



  async function closeModalEquipe(pk: any) {
    setPrestador(pk);
    setShowDetalhesEquipe(false);
    await consultarPrestadorUsuarios();
  }

  async function closeModalSimpleEquipe() {
    setShowDetalhesEquipe(false);
  }


  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    modal.current?.dismiss();
  }

  async function closeModalEndereco(enderecoSelecionado: any, latitudeSelecionado: any, longitudeSelecionado: any) {
    console.log("enderecoSelecionado", enderecoSelecionado);
    console.log("latitudeSelecionado", latitudeSelecionado);
    console.log("longitudeSelecionado", longitudeSelecionado);

    setLocal(enderecoSelecionado);
    setLatitude(latitudeSelecionado);
    setLongitude(longitudeSelecionado);

    setShowDetalhesEndereco(false);
  }

  async function closeModalSimpleEndereco() {
    setShowDetalhesEndereco(false);
  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { props.closeActionSimple() }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Nova agenda</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>



        <IonList lines="full" class="ion-no-margin">
          <IonItem lines="none" hidden={semDescricaoAgendamento}>
            <IonLabel>Descrição*</IonLabel>
            <IonInput placeholder="Descrição da agenda" value={descricaoAgendamento}
              ref={(ref) => props.inputRef.current = ref}
              autoCorrect='true' autoCapitalize='true'
              required onIonInput={(e: any) => setDescricaoAgendamento(e.target.value)}></IonInput>
          </IonItem>

          {/* <IonItem lines="none">
            <IonLabel>Tipo*</IonLabel>
            <IonSelect placeholder="Selecione" slot="end" value={tipoAgendamento} onIonChange={(e) => { setTipoAgendamento(e.detail.value || "") }}>
              <IonSelectOption value="entrega">entrega</IonSelectOption>
              <IonSelectOption value="serviço">serviço</IonSelectOption>
              <IonSelectOption value="reunião">reunião</IonSelectOption>
              <IonSelectOption value="consulta">consulta</IonSelectOption>
              <IonSelectOption value="visita técnica">visita técnica</IonSelectOption>
              <IonSelectOption value="reserva">reserva</IonSelectOption>
              <IonSelectOption value="check in">check in</IonSelectOption>
              <IonSelectOption value="check out">check out</IonSelectOption>
            </IonSelect>
          </IonItem> */}

          <IonItem lines={"none"} hidden={params.projetoid != undefined}>
            <IonLabel>{window.localStorage.getItem("servicoTipo") || "Projeto"}*</IonLabel>
            <IonSelect slot="end" value={projeto} placeholder="Selecione" onIonChange={e => {
              {
                console.log(e.detail.value);
                setProjeto(e.detail.value);

                let found = listaProjeto.find((obj: any) => {
                  return obj.pk === e.detail.value;
                });



                if (found) {
                  if (!enderecoPrincipalEmpresa) {
                    setLocal(found.endereco);
                    setLatitude(found.latitude);
                    setLongitude(found.longitude);
                    setComplemento(found.complemento);
                  }
                }
              }
            }}>
              {readProjeto()}
            </IonSelect>
          </IonItem>

          <IonItem lines={"none"}>
            <IonSelect value={localEndereco} placeholder="Endereço" onIonChange={e => {
              {
                console.log(e.detail.value);
                setLocalEndereco(e.detail.value);

                if (e.detail.value == "projeto") {

                  let found = listaProjeto.find((obj: any) => {
                    return obj.pk === projeto;
                  });

                  if (found) {
                    setLocal(found.endereco);
                    setLatitude(found.latitude);
                    setLongitude(found.longitude);
                    setComplemento(found.complemento);
                  }
                }
                else if (e.detail.value == "empresa") {
                  setLocal(empresaEndereco);
                  // setLatitude(found.latitude);
                  // setLongitude(found.longitude);
                  setComplemento("");
                }
                // else if (e.detail.value == "online") {
                //   setLocal("online");
                //   setLatitude("");
                //   setLongitude("");

                //   setTextToast("Utilize o link da reunião na descrição da agenda.");
                //   setShowToast(true);


                // }
                else {
                  setLocal("");
                  setLatitude("");
                  setLongitude("");
                }
              }
            }}>
              <IonSelectOption value="projeto">Projeto</IonSelectOption>
              <IonSelectOption value="empresa">Empresa</IonSelectOption>
              {/* <IonSelectOption value="online">Online</IonSelectOption> */}
              <IonSelectOption value="outro">Outro</IonSelectOption>
            </IonSelect>
            <IonInput placeholder="Ex: Avenida Paulista, 111, São Paulo, SP" value={local}
              autoCorrect='true' autoCapitalize='true'
              required onIonInput={(e: any) => setLocal(e.target.value)}></IonInput>
            <IonButton slot="end" style={{ textAlign: 'right' }} onClick={() => { setShowDetalhesEndereco(true); }}>Mapa</IonButton>
          </IonItem>

          {/* <IonItem lines="none">
            <IonLabel>Latitude*</IonLabel>
            <IonInput placeholder="Selecione a Latitude" value={latitude}
              autoCorrect='true' autoCapitalize='true'
              required onIonInput={(e: any) => setLatitude(e.target.value)}></IonInput>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Longitude*</IonLabel>
            <IonSelect value={longitude} placeholder="Selecione o Longitude" onIonChange={(e) => setLongitude(e.detail.value)}>
              {longitudes.map((longitude) => (
                <IonSelectOption key={longitude.sigla} value={longitude.sigla}>
                  {longitude.sigla}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem> */}
          {/* <IonItem lines="none">
            <IonButton slot="end" style={{ textAlign: 'right' }} onClick={() => { setShowDetalhesEndereco(true); }}>Mapa</IonButton>
          </IonItem> */}
          <IonItem lines="full">
            <IonLabel>Complemento</IonLabel>
            <IonInput placeholder="Digite o Complemento" value={complemento}
              autoCorrect='true' autoCapitalize='true'
              required onIonInput={(e: any) => setComplemento(e.target.value)}></IonInput>
          </IonItem>

          {/* <IonItem lines="full" >
            <IonLabel>Bloqueado</IonLabel>
            <IonToggle checked={checkedInterno} onIonChange={e => {
              setCheckedInterno(e.detail.checked);
            }} />

          </IonItem> */}

          <IonItem lines="none" hidden={tipoAgendamento == "entrega"}>
            <IonLabel>Agendamento período?</IonLabel>
            <IonToggle checked={checkedPeriodo} onIonChange={e => {
              setCheckedPeriodo(e.detail.checked);


            }} />
          </IonItem>




          <IonItem lines="none" hidden={checkedPeriodo}>
            <IonLabel>Data*</IonLabel>

            <IonLabel style={{ "color": "#BDBDBD" }} slot="end" onClick={e => { openPopoverCad(e); }}>{dataAgendamentoBr}</IonLabel>

          </IonItem>

          <IonItem lines="none" hidden={!checkedPeriodo}>
            <IonLabel>Período*</IonLabel>

            <IonLabel style={{ "color": "#BDBDBD" }} slot="end" onClick={e => { openPopoverInicio(e); }}>{dataAgendamentoPeriodo1Br}</IonLabel>
            <IonLabel style={{ "color": "#BDBDBD" }} slot="end" onClick={e => { openPopoverFim(e); }}>{dataAgendamentoPeriodo2Br}</IonLabel>


          </IonItem>

          <IonItem lines="none" hidden={!checkedPeriodo}>
            <IonLabel>Dias da semana*</IonLabel>
            <IonSelect placeholder="Selecione" title='Dias da semana' multiple={true} cancelText="Cancelar" okText="Ok" value={diasSemana} onIonChange={(e) => { setDiasSemana(e.detail.value || "") }}>

              <IonSelectOption value="1">segunda-feira</IonSelectOption>
              <IonSelectOption value="2">terça-feira</IonSelectOption>
              <IonSelectOption value="3">quarta-feira</IonSelectOption>
              <IonSelectOption value="4">quinta-feira</IonSelectOption>
              <IonSelectOption value="5">sexta-feira</IonSelectOption>
              <IonSelectOption value="6">sabádo</IonSelectOption>
              <IonSelectOption value="0">domingo</IonSelectOption>

            </IonSelect>
          </IonItem>





          <IonItem lines={!servicoPeriodoHoraEmpresa ? "full" : "none"}>
            <IonLabel>{servicoPeriodoHoraEmpresa ? "Começa*" : "Hora*"}</IonLabel>

            {/* <IonLabel onClick={e => { openPopoverHora(e); }} style={{ "color": "#BDBDBD" }} slot="end">{horaAgendamento || "00:00"}</IonLabel> */}

            {/* <IonDatetime
                    presentation='time'
                    minuteValues={"0,10,20,30,40,50"}
                    onIonChange={e => {

                      if (e.detail.value) {
                        let data = e.detail.value.toString();

                        const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeStyle: 'short' }).format(
                          Date.parse(data)
                        );
                        setHoraAgendamento(dataFormatada);
                        //console.log('amigosss', dataFormatada);
                      }
                    }}></IonDatetime> */}


            <IonLabel onClick={e => { setShowPopover(true); }} style={{ "color": "#BDBDBD" }} slot="end">{horaAgendamento || "00:00"}</IonLabel>

            {/* <IonItem lines="none" slot="end"> <IonInput type="time" value={horaAgendamento} required onIonInput={(e: any) => setHoraAgendamento(e.target.value)} /></IonItem> */}


          </IonItem>

          <IonItem lines="full" hidden={!servicoPeriodoHoraEmpresa}>
            <IonLabel>Termina*</IonLabel>

            {/* <IonLabel onClick={e => { openPopoverHora2(e); }} style={{ "color": "#BDBDBD" }} slot="end">{horaAgendamento2 || "00:00"}</IonLabel> */}

            {/* <IonDatetime
                    presentation='time'
                    minuteValues={"0,10,20,30,40,50"}
                    onIonChange={e => {

                      if (e.detail.value) {
                        let data = e.detail.value.toString();

                        const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeStyle: 'short' }).format(
                          Date.parse(data)
                        );
                        setHoraAgendamento2(dataFormatada);
                        //console.log('amigosss', dataFormatada);
                      }
                    }}></IonDatetime> */}


            <IonLabel onClick={e => { setShowPopover2(true); }} style={{ "color": "#BDBDBD" }} slot="end">{horaAgendamento2 || "00:00"}</IonLabel>

            {/* <IonItem lines="none" slot="end"> <IonInput type="time" value={horaAgendamento2} required onIonInput={(e: any) => setHoraAgendamento2(e.target.value)} /></IonItem> */}

          </IonItem>



          <IonItem lines="none">
            <IonLabel>Entregador*</IonLabel>

            <IonButtons slot="end" onClick={() => { setShowDetalhesPrestador(true) }}>
              <IonIcon color="tertiary" size='large' slot="icon-only" icon={addCircle}></IonIcon>
            </IonButtons>
            <IonSelect slot="end" value={prestador} placeholder="Selecione" onIonChange={e => { setPrestador(e.detail.value); }}>
              {readPrestador()}
            </IonSelect>
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Atividades</IonLabel>
            <IonTextarea style={{ "--background": "#eeeeee", marginLeft: "10px", marginBottom: "4px" }} rows={6} value={atividadesPrestador} maxlength={250} placeholder="" onIonInput={(e: any) => setAtividadesPrestador(e.target.value)}></IonTextarea>
          </IonItem>

          {/* <IonItem lines="none">
            <IonLabel>Convidados</IonLabel>
          
            <IonItem slot="end" lines="none" button={true} detail={false} onClick={e => { setShowModalConvidados(true); }}>
              {selectedConvidadosText}
            </IonItem>
        
            <IonModal isOpen={showModalConvidados} onDidDismiss={() => setShowModalConvidados(false)} >
              <AppTypeaheadList
                title="Convidados"
                items={convidados}
                selectedItems={selectedConvidados}
                onSelectionCancel={() => setShowModalConvidados(false)}
                onSelectionChange={convidadoSelectionChanged}
              />
            </IonModal>
          </IonItem> */}

        </IonList>



        <IonPopover ref={popover1}
          isOpen={showModalDataCad}
          onDidDismiss={() => setShowModalDataCad(false)} >
          <IonContent>
            <IonDatetime

              presentation='date'

              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataAgendamentoBr(dataFormatada);
                  setDataAgendamento(data);
                }
                //setShowModalDataCad(false);
              }}></IonDatetime>
          </IonContent>
        </IonPopover >

        <IonPopover ref={popover2}
          isOpen={showModalDataInicio}
          onDidDismiss={() => setShowModalDataInicio(false)} >
          <IonContent>
            <IonDatetime
              presentation='date'
              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataAgendamentoPeriodo1Br(dataFormatada);
                  setDataAgendamentoPeriodo1(data);
                }
                //setShowModalDataInicio(false);
              }}></IonDatetime>
          </IonContent>
        </IonPopover >

        <IonPopover ref={popover3}
          isOpen={showModalDataFim}
          onDidDismiss={() => setShowModalDataFim(false)} >
          <IonContent>
            <IonDatetime
              presentation='date'
              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataAgendamentoPeriodo2Br(dataFormatada);
                  setDataAgendamentoPeriodo2(data);
                }
                //setShowModalDataFim(false);
              }}></IonDatetime>
          </IonContent>
        </IonPopover >




        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />


        <IonModal onDidPresent={() => {
          if (inputPrestadoresCadRef.current) {
            inputPrestadoresCadRef.current.setFocus();
          }
        }} isOpen={showDetalhesPrestador} onDidDismiss={() => { setShowDetalhesPrestador(false); }}>
          <PrestadoresCad inputRef={inputPrestadoresCadRef} usuario={null} closeAction={closeModalPrestador} closeActionSimple={closeModalSimplePrestador}></PrestadoresCad>
        </IonModal>


        <IonModal
          onDidPresent={() => {
            if (inputRefEndereco.current) {
              inputRefEndereco.current.setFocus();
            }
          }} isOpen={showDetalhesEndereco} onDidDismiss={() => { setShowDetalhesEndereco(false); }}>
          <Endereco endereco={local} inputRef={inputRefEndereco} closeAction={closeModalEndereco} closeActionSimple={closeModalSimpleEndereco}></Endereco>
        </IonModal>


        <IonModal id="modal1" isOpen={showPopover} onDidDismiss={() => { setShowPopover(false); }}>
          <div>


            {/* <IonPopover ref={popover4}
              isOpen={showPopover}
              onDidDismiss={() => { setShowPopover(false); }} >             */}
            <IonDatetime
              presentation='time'
              minuteValues={"0,10,20,30,40,50"}
              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString();

                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeStyle: 'short' }).format(
                    Date.parse(data)
                  );
                  setHoraAgendamento(dataFormatada);
                  //console.log('amigosss', dataFormatada);
                }
              }}></IonDatetime>
          </div>


          {/* </IonPopover > */}
        </IonModal>

        <IonModal id="modal2" isOpen={showPopover2} onDidDismiss={() => { setShowPopover2(false); }}>
          <div>
            {/* <IonPopover ref={popover5}
              isOpen={showPopover2}
              onDidDismiss={() => setShowPopover2(false)} >            */}
            <IonDatetime
              presentation='time'
              minuteValues={"0,10,20,30,40,50"}
              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString();

                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeStyle: 'short' }).format(
                    Date.parse(data)
                  );
                  setHoraAgendamento2(dataFormatada);
                  //console.log('amigosss', dataFormatada);
                }
              }}></IonDatetime>

          </div>

          {/* </IonPopover > */}
        </IonModal>

        <IonModal onDidPresent={() => {
          if (inputPrestadoresCadRef.current) {
            inputPrestadoresCadRef.current.setFocus();
          }
        }} isOpen={showDetalhesEquipe} onDidDismiss={() => { setShowDetalhesEquipe(false); }}>
          <EquipeCad usuario={null} inputRef={inputPrestadoresCadRef} closeAction={closeModalEquipe} closeActionSimple={closeModalSimpleEquipe}></EquipeCad>
        </IonModal>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
      <IonFooter className="ion-no-border" >
        <IonToolbar>
          <IonButton expand="block" size='large' color="primary" onClick={() => { iniciarAgendamento(); }}>Avançar</IonButton>
        </IonToolbar>
      </IonFooter>

    </>
  );


};

export default ({ projetoid, inputRef, closeAction, closeActionSimple }: { projetoid: any, inputRef: any, closeAction: Function, closeActionSimple: Function }) => (
  <AgendamentosNovo projetoid={projetoid} inputRef={inputRef} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </AgendamentosNovo>
)