// import '@capacitor-community/http';
import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonRow, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { alterarStatusVenda, requestService } from '../../../../../Utils/Services';
import './VendasCad.css';

type MyModalProps = {
  usuario: any;
  inputRef: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const VendasCad: React.FC<MyModalProps> = (props: MyModalProps) => {

  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [primeiroFoco, setPrimeiroFoco] = useState(false);
  const [pk, setPk] = useState("");
  const [cpf, setCPF] = useState("");
  const [nome, setNome] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [prospectNome, setProspectNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const campoTelefone = useRef<any>(null);
  const [selected, setSelected] = useState<any>("prospect");
  const [showIncluirUsuario, setShowIncluirUsuario] = useState(false);
  const [alteracao, setAlteracao] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [lojas, setLojas] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaId || "");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [showLoading, setShowLoading] = useState(false);

  const [chamadaRealizada, setChamadaRealizada] = useState(false);
  if (!chamadaRealizada) {
    console.log("eteeee", props.usuario);
    if (props.usuario) {
      setChamadaRealizada(true);
      setAlteracao(true);
      setPk(props.usuario.pk);
      if (props.usuario.prospectCpf) {
        setCPF(formatCPF(props.usuario.prospectCpf));
      }
      setNome(props.usuario.nomeVenda);
      setFormaPagamento(props.usuario.formaPagamento);
      setProspectNome(props.usuario.prospectNome);
      setEmail(props.usuario.prospectEmail);
      setTelefone(formataTelefone(props.usuario.prospectTelefone));
    }
  }

  useEffect(() => {
    // Define o foco no input quando o componente é montado
    if (props.inputRef.current) {
      props.inputRef.current.focus();
    }
  }, [props.inputRef]);


  function onChangeCPF(e: any) {

    setPrimeiroFoco(true);
    e.persist();
    e.preventDefault();


    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCPF(cpfval);

  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    let val = e.target.value;

    const maskPhone = val
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})(\d+?)$/, "$1");
    setTelefone(maskPhone);
  }

  function formatCPF(e: string) {

    if (e.length == 11) {
      let cpfval = e.replace(/\D/g, "")
      cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
      cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
      cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
      console.log(cpfval);
      return cpfval;
    }
    else {
      return "";
    }
  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  function cadastrar() {

    console.log("aaaa", selected);

    if (cpf.trim().length > 0 && cpf.trim().length < 14) {
      setTextToast("CPF Inválido.");
      setShowErro(true);
    }
    else {

      if (!validarCampos())
        return;

      setShowIncluirUsuario(true);

    }


  }

  function validarCampos() {

    if (prospectNome.length == 0 || telefone.length != 15) {
      setTextToast("Todos os campos com * são obrigatórios.");
      setShowToast(true);
      return false;
    }
    return true;
  }

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} />
          </IonButtons>
          <IonTitle>Atualizar informações</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList>

                <IonItem lines="full">
                  <IonLabel position="stacked">Venda*</IonLabel>
                  <IonInput placeholder="Nome" value={nome} ref={(ref) => props.inputRef.current = ref} autoCorrect='true' autoCapitalize='true' required onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
                </IonItem>
                {/* <IonItem lines="full">
                  <IonLabel position="stacked">Forma de pagamento</IonLabel>
                  <IonInput placeholder="Forma de pagamento" value={formaPagamento}
                    autoCorrect='true' autoCapitalize='true'
                    onIonInput={(e: any) => setFormaPagamento(e.target.value)}></IonInput>
                </IonItem> */}

                <IonItem lines="none">
                  <IonLabel position="stacked">Nome Prospect*</IonLabel>
                  <IonInput placeholder="Nome" value={prospectNome}
                    autoCorrect='true' autoCapitalize='true'
                    required onIonInput={(e: any) => setProspectNome(e.target.value)}></IonInput>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel position="stacked">Telefone*</IonLabel>
                  <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} />
                </IonItem>

                <IonItem lines="none">
                  <IonLabel position="stacked">CPF</IonLabel>
                  <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} />
                </IonItem>

                <IonItem lines="none">
                  <IonLabel position="stacked">E-mail</IonLabel>
                  <IonInput value={email} type='email' autoCorrect='true' placeholder="Digite o e-mail" onIonInput={(e: any) => setEmail(e.target.value)}></IonInput>
                </IonItem>

              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert isOpen={showIncluirUsuario}
          onDidDismiss={() => setShowIncluirUsuario(false)}
          header={'Alterar'}
          message={'Você <strong>confirma</strong> inclusão?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                const objectRequest = {
                  cpf: cpf.replace("-", "").replace(".", "").replace(".", ""),
                  pk: pk,
                  nome: nome,
                  prospectNome: prospectNome,
                  email: email,
                  formaPagamento: formaPagamento,
                  telefone: (telefone ? "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "") : "")
                }


                if (alteracao) {

                  requestService(alterarStatusVenda.url.replaceAll("{id}", pk.replaceAll(/#/g, "%23")) + "?alteracao=true", {
                    method: alterarStatusVenda.method,
                    headers: [["token", localStorage.getItem("token") || ""]],
                    credentials: 'include',
                    body: JSON.stringify(objectRequest)
                  },
                    history,
                    (response: any) => {
                      setFormaPagamento("");
                      setProspectNome("");
                      setNome("");
                      setCPF("");
                      setTelefone("");
                      setEmail("")
                      setTextToast("Alteração realizada com sucesso.");
                      setShowSucesso(true);
                    },
                    (error: any) => {
                      setTextToast(error.message);
                      setShowErro(true);
                    });
                }
              }
            }
          ]}
        />

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />


        <IonAlert isOpen={showSucesso}
          onDidDismiss={() => { setShowSucesso(false); }}
          header={'Sucesso'}
          message={textToast}
          backdropDismiss={false}
          buttons={[
            {
              text: 'OK',
              handler: () => { setShowSucesso(false); props.closeAction(); }
            }
          ]} />

        <IonAlert isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />

        <IonLoading
          cssClass='my-custoDm-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          message={'Incluindo...'}

        />

      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton disabled={!botaoAtivo} onClick={() => cadastrar()} expand="block" type="submit">SALVAR</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default ({ usuario, inputRef, closeAction, closeActionSimple }: { usuario: any, inputRef: any, closeAction: Function, closeActionSimple: Function }) => (
  <VendasCad usuario={usuario} inputRef={inputRef} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </VendasCad>
)