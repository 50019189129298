import { Toast } from '@capacitor/toast';
import { IonActionSheet, IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonPage, IonRow, IonTextarea, IonTitle, IonToast, IonToolbar, useIonAlert, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { addCircle, alertCircle, appsOutline, arrowBack, arrowForwardOutline, calendarOutline, closeOutline, createOutline, documentOutline, flagOutline, funnelOutline, informationCircleOutline, listOutline, mailOutline, personOutline, phonePortraitOutline, powerOutline, trash, trendingUp } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { finalizarVenda, removerAnotacaoVenda, removerArquivoVenda, urlBase, vendaGerarOrcamento, vendaRegistrarAnotacao, vendaRegistrarArquivo } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import VendasCad from '../VendasCad/VendasCad';
import VendasCriarProjeto from '../VendasCriarProjeto/VendasCriarProjeto';
import VendasWorkflowAvancar from '../VendasWorkflowAvancar/VendasWorkflowAvancar';
import "./VendasDetalhes.css";

type Props = {
  abrirMenu: boolean;
  vendaid: string;
  empresaid: string;
  onDismiss: () => void;
};

const VendasDetalhes: React.FC<Props> = (props: Props) => {
  const history = useHistory<any>();
  const location = useLocation<any>();
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [idVenda, setIdVenda] = useState("");
  const [dadosVenda, setDadosVenda] = useState<any>([]);
  const [temHoraFim, setTemHoraFim] = useState(false);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showActionSheetLinhaDoTempo, setShowActionSheetLinhaDoTempo] = useState(false);
  const [alertConfirmar, setAlertConfirmar] = useState(false);
  const [showAtualizarInformacoes, setShowAtualizarInformacoes] = useState(false);
  const [vendaId, setVendaId] = useState("");
  const [indiceWorkflow, setIndiceWorkflow] = useState("");
  const [statusWorkflow, setStatusWorkflow] = useState("");
  const [listaStatus, setListaStatus] = useState<any>([]);
  const [showWorkflowAvancar, setShowWorkflowAvancar] = useState(false);
  const [showCriarProjeto, setShowCriarProjeto] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [mostartAlerta] = useIonAlert();
  const [mostrarToast, fecharToast] = useIonToast();
  const [showModalArquivos, setShowModalArquivos] = useState(false);
  const [fileArquivos, setFileArquivos] = useState<any>();
  const [textoArquivos, setTextoArquivos] = React.useState<string>();
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [caminhoParaExcluir, setCaminhoParaExcluir] = React.useState<string>();
  const [showAlertConfirmarExclusaoArquivo, setShowAlertConfirmarExclusaoArquivo] = useState(false);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { recarregarVenda, setRecarregarVenda } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const inputRef = useRef<any>(null);

  const inputVendasCadRef = useRef<any>(null);
  const inputVendasCriarProjetoRef = useRef<any>(null);

  useEffect(() => {
    console.log("idVenda", idVenda);

    if (idVenda != "") {
      getVenda();
    }
  }, [idVenda]);

  useEffect(() => {
    console.log("recarregarVenda", recarregarVenda);
    if (recarregarVenda) {
      getVenda();
      setRecarregarVenda(false);
    }
  }, [recarregarVenda]
  );

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    if (params.vendaid) {
      setIdVenda(params.vendaid);
    }

  });


  let params: any = {
    vendaid: "",
    empresaid: ""
  }
  params = useParams();

  if (location && location.state && location.state.vendaid) {
    params.vendaid = location.state.vendaid;
    params.empresaid = location.state.empresaid;
  }


  if (props.vendaid) {
    params = {
      vendaid: props.vendaid,
      empresaid: props.empresaid
    }
  }


  const getVenda = async () => {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("vendaid", params.vendaid);

    setShowLoadingPage(true);
    const resp = await fetch(urlBase.url + "/vendas/" + params.vendaid.replaceAll(/#/g, '%23'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const operacao = await resp.json();
    if (operacao.Item !== undefined) {

      setDadosVenda(operacao.Item);
      setTemHoraFim(operacao.Item.dataEncerramento ? true : false);
      console.log("dadosvenda", operacao.Item);
    }

    setShowLoadingPage(false);

  }

  function encerrar2(prospect: string, motivo: string) {

    let objRequest = {
      "motivo": motivo
    };

    console.log("objRequest", objRequest);


    return fetch(finalizarVenda.url.replace("{id}", prospect.replaceAll(/#/g, "%23")),
      {
        method: finalizarVenda.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      });
  }


  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  function formatarDataHoraCompleta(data: Date) {
    return adicionaZeroNaData(data.getDate().toString()) + "/" + (adicionaZeroNaData(data.getMonth() + 1).toString()) + "/" + (data.getFullYear().toString()) + " " + ("0" + data.getHours().toString()).substr(-2) + ":" + ("0" + data.getMinutes().toString()).substr(-2);
  }

  async function funcRemoverLoja(pk: string, data: string) {

    let resp = await fetch(removerAnotacaoVenda.url.replace("{id}", pk.replace(/#/g, "%23")).replace("{anotacao}", data.replace(/#/g, "%23")),
      {
        method: removerAnotacaoVenda.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });

    let retorno = await resp.json();

    console.log("retorno", retorno);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      console.log("dadosVenda.anotacoes", dadosVenda.anotacoes);

      var res = dadosVenda.anotacoes.filter((p: any, i: any) => {
        if (p.data == data) {
          dadosVenda.anotacoes.splice(i, 1); //remove the mached object from the original array
          return p;
        }
      });
      console.log("res", res);
    }
    return resp;
  }

  const loadAnotacoes = () => {

    return dadosVenda.anotacoes?.sort(function (a: any, b: any) {
      if (a.data < b.data) {
        return 1;
      }
      if (a.data > b.data) {
        return -1;
      }
      return 0;
    }).slice(0).map((value: any) => (

      <IonItem lines="none">

        <IonLabel className="ion-text-wrap">
          <h3 style={{ "verticalAlign": "middle" }}><strong>{new Date(value.data).toLocaleDateString() + " " + new Date(value.data).toLocaleTimeString()}</strong></h3>
          <h3>{value.anotacao + (value.pessoa ? (' - ' + value.pessoa) : "")}</h3>
        </IonLabel>
        <IonIcon hidden={acessos?.vendas != 'edicao'} icon={trash} color="danger" style={{ fontSize: "20px" }} onClick={() =>
          mostartAlerta({
            backdropDismiss: false,
            header: 'Remover Anotação',
            message: 'Realizar <strong>remoção</strong>?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Remover',
                cssClass: 'primary',
                handler: () => {
                  setShowLoadingPage(true);
                  let retorno = funcRemoverLoja(dadosVenda.pk, value.data);
                  if (retorno) {
                    retorno?.then(res => {
                      if (res.status === 200) {
                        console.log("res", res)
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Anotação removida com sucesso.'
                        });
                      }
                      else {
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro ao tentar remover.'
                        });
                      }
                    }).catch(() => {
                      mostrarToast({
                        position: 'middle',
                        buttons: [{ text: 'ok', handler: () => fecharToast() }],
                        message: 'Erro ao tentar remover.'
                      });
                    }).finally(() => {
                      setShowLoadingPage(false);
                    });
                  }
                  else {
                    mostrarToast({
                      position: 'middle',
                      buttons: [{ text: 'ok', handler: () => fecharToast() }],
                      message: 'Erro ao tentar remover.'
                    });
                    setShowLoadingPage(false);
                  }
                  return true;
                }
              }
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
          })
        }>
        </IonIcon>
      </IonItem>
    ))
  }

  function cpf_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  async function gerarOrcamento(vendaid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("vendaid", vendaid);

    setShowLoadingPage(true);
    const resp = await fetch(vendaGerarOrcamento.url.replaceAll("{id}", vendaid.replaceAll(/#/g, "%23")),
      {
        method: vendaGerarOrcamento.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar PDF',
        message: '<strong>PDF</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })




    }

    setShowLoadingPage(false);

  }


  function getButtons() {

    let resultado: any = [
      {
        text: 'Cancelar',
        icon: closeOutline,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }
    ];

    if (acessos?.vendas == 'edicao') {

      resultado.push(
        {
          text: 'Editar',
          icon: createOutline,
          handler: () => {
            // console.log("dadosVenda.pk", dadosVenda);
            // setDadosVenda(dadosVenda);
            setShowAtualizarInformacoes(true);
          }
        }
      );

      resultado.push(
        {
          text: 'Avançar etapa',
          icon: arrowForwardOutline,
          handler: () => {
            setVendaId(dadosVenda.pk);
            setIndiceWorkflow(dadosVenda.indiceWorkflow);
            setStatusWorkflow(dadosVenda.statusWorkflow);
            setListaStatus(dadosVenda.configWorkflow);
            setShowWorkflowAvancar(true);
          }
        }
      );

      resultado.push(
        {
          text: 'Gerar PDF',
          icon: documentOutline,
          handler: async () => {
            await gerarOrcamento(dadosVenda.pk);
          }
        }
      );

      resultado.push(
        {
          text: 'Criar ' + (window.localStorage.getItem("servicoTipo") || "Projeto"),
          icon: appsOutline,
          handler: () => {
            console.log("dadosVenda.pk", dadosVenda);

            if (dadosVenda.prospectNome?.trim() == "" || dadosVenda.nomeVenda?.trim() == "" || dadosVenda.prospectTelefone?.trim() == "" || dadosVenda.prospectTelefone == "55") {
              setTextToast("Para avançar cadastre as informações de nome e telefone na venda.");
              setShowToast(true);
            }
            else {
              setDadosVenda(dadosVenda);
              setShowCriarProjeto(true);
            }
          }
        }
      );

      resultado.push(
        {
          text: 'Encerrar venda',
          icon: powerOutline,
          handler: () => {
            mostartAlerta({
              backdropDismiss: false,
              header: 'Encerrar venda',
              inputs: [
                {
                  label: 'Preço',
                  type: 'radio',
                  value: 'preço',
                },
                {
                  label: 'Prazo',
                  type: 'radio',
                  value: 'prazo',
                },
                {
                  label: 'Desistência',
                  type: 'radio',
                  value: 'desistência',
                },
                {
                  label: 'Não especificado',
                  type: 'radio',
                  value: 'não especificado',
                },
                {
                  label: 'Falta de interesse',
                  type: 'radio',
                  value: 'falta de interesse',
                },
              ],
              message: 'Deseja <strong>encerrar</strong>?',
              buttons: [
                {
                  text: 'Cancelar',
                  role: 'cancel',
                },
                {
                  text: 'Confirmar',
                  cssClass: 'primary',
                  handler: (data) => {

                    if (data == undefined) {
                      mostrarToast({
                        duration: 2000,
                        position: 'middle',
                        buttons: [{ text: 'ok', handler: () => fecharToast() }],
                        message: "Selecione um motivo."
                      });
                    }
                    else {
                      setShowLoadingPage(true);
                      let retorno = encerrar2(dadosVenda.pk.replaceAll(/#/g, "%23"), data);
                      if (retorno) {
                        retorno?.then(res => {
                          if (res.status === 200) {
                            console.log(res);
                            setRecarregarVenda(true);
                            history.goBack();
                          }
                          else {
                            mostrarToast({
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Erro ao tentar fechar.'
                            });
                          }
                        }).catch(() => {
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro ao tentar cancelado.'
                          });
                        }).finally(() => {
                          setShowLoadingPage(false);
                        });
                        return true;
                      }
                      else {
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro ao tentar fechar.'
                        });
                        setShowLoadingPage(false);
                        return false;
                      }
                    }

                  }
                }
              ],
              onDidDismiss: (e) => console.log('did dismiss'),
            })
          }
        }
      );
    }
    return resultado;
  }


  function registrarAnotacao(pk: any, mensagem: any) {
    if (!mensagem || mensagem.trim() == "") {
      return null;
    }

    let params = {
      mensagem: mensagem
    }
    return fetch(vendaRegistrarAnotacao.url.replaceAll("{pk}", pk.replaceAll(/#/g, "%23")),
      {
        method: vendaRegistrarAnotacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      });
  }

  function preencheArquivos(value: any) {

    return (
      (value.arquivos) ?
        (<>
          {value.arquivos.map((arq: any, index: any) => (

            <IonCol size="6" key={index} >

              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '10px', position: 'relative' }}>
                <div style={{ maxWidth: '350px', width: '100%' }}>
                  <a target='_blank' href={arq.split(";")[1]}>
                    {arq.split(";")[0]}
                  </a>

                  <IonButton hidden={acessos?.vendas != 'edicao'} style={{ margin: '10px 0 10px 0', fontWeight: 'normal', width: '100%' }} fill='clear' color="tertiary" size="small" expand="block" onClick={() => {
                    setCaminhoParaExcluir(arq); setShowAlertConfirmarExclusaoArquivo(true);
                  }}>Excluir</IonButton>
                </div>
              </div>
            </IonCol>
          ))}

        </>) :
        (
          <>
            {/* {preencheCard(value, index)} */}
          </>
        )
    )
  }

  const handleUpload = async (idVenda: string) => {

    if (!fileArquivos || !textoArquivos || textoArquivos.trim() == "") {
      setAlertaPreenchimento(true);
      return null;
    }

    console.log("fileArquivos", fileArquivos);


    let file = fileArquivos.files[0];
    console.log("file", file);

    if (file.size < 15000000) {
      let fileParts = file.name.split('.');
      let fileNameArquivo = fileParts[0];
      let fileTypeArquivo = fileParts[1];
      console.log("Preparing the upload");
      console.log(fileParts);

      try {
        incluirAquivo(idVenda, file, fileTypeArquivo, textoArquivos || "", "Arquivos");

      } catch (error) {
        console.log("error", error);
        Toast.show({
          text: "Ocorreu um erro ao adicionar um arquivo, tente novamente.",
          position: "center",
          duration: "long"
        });
      }

    }
    else {
      Toast.show({
        text: "Somente arquivos até 15 MB.",
        position: "center",
        duration: "long"
      });
    }
  }

  async function incluirAquivo(vendaId: string, file: any, fileTypeArquivo: any, nomeArquivo: string, tipoArquivo: string) {


    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let params = {
        nome: nomeArquivo,
        campo: tipoArquivo,
        mensagem: fileTypeArquivo
      }

      try {
        setShowLoadingPage(true);

        const resp = await fetch(vendaRegistrarArquivo.url.replaceAll("{id}", vendaId.replaceAll(/#/g, "%23")),
          {
            method: vendaRegistrarArquivo.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();

        console.log("response", response);
        if (response.Item) {



          console.log("file.type", file.type);
          const uploadToS3 = await fetch(response.Item, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });

          if (uploadToS3.status == 200) {

            Toast.show({
              text: "Arquivo foi adicionado com sucesso.",
              position: "center",
              duration: "long"
            });

            setTextoArquivos("");
            setFileArquivos("");
          }
          else {
            Toast.show({
              text: "Ocorreu um erro ao fazer o upload do arquivo.",
              position: "center",
              duration: "long"
            });

          }
        }
        setShowLoadingPage(false);

      } catch (error) {
        console.log("erro", error);
        setShowLoadingPage(false);
      }
    }

    atualizar();

  };

  function deletarArquivo(url: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    setShowLoadingPage(true);
    const delArquivo = async () => {
      const resp = await fetch(removerArquivoVenda.url.replace("{id}", params.vendaid.replace(/#/g, "%23")),
        {
          method: removerArquivoVenda.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'arquivo': url }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      //if (resp.status === 400 || resp.status === 401) history.replace("/login/");

      console.log("retorno", retorno);


      if (retorno.Item && retorno.Item.Attributes) {
        setDadosVenda(retorno.Item.Attributes);
        setTemHoraFim(retorno.Item.Attributes.dataEncerramento ? true : false);
      }
      setShowLoadingPage(false);
    }

    delArquivo();


  }


  async function closeModalAtualizarInformacoes() {
    setShowAtualizarInformacoes(false);
  }

  async function closeModalAtualizarInformacoesSucess() {
    getVenda();
    setShowAtualizarInformacoes(false);
    setRecarregarVenda(true);
  }


  async function closeModalWorkflowAvancar() {
    setShowWorkflowAvancar(false);
  }

  async function closeModalWorkflowAvancarSucess(dadosOperacao: any) {
    setTextToast("Etapa alterada com sucesso!");
    setShowToast(true);
    getVenda();
    setShowWorkflowAvancar(false);
    setRecarregarVenda(true);
  }

  async function closeModalCriarProjeto() {
    setShowCriarProjeto(false);
  }

  async function closeModalCriarProjetoSucess(dadosOperacao: any) {
    setTextToast((window.localStorage.getItem("servicoTipo") || "Projeto") + " criado com sucesso!");
    setShowToast(true);
    getVenda();
    setShowCriarProjeto(false);
    setRecarregarVenda(true);
  }

  function carregarServicos() {
    history.push("../vendas/vendasservicos", { id: dadosVenda.pk.replaceAll(/#/g, "%23") });

  }

  function carregarProdutos() {
    history.push("../vendas/vendasprodutos", { id: dadosVenda.pk.replaceAll(/#/g, "%23") });

  }

  const getColor = (percentual: any) => {
    const percent = parseFloat(percentual?.toString().replace(/\./g, '').replace(/\,/g, '.') || 0);
    if (percent <= 33) {
      return "rgba(212, 37, 28, 0.5)"; // Vermelho com 70% de opacidade
    } else if (percent <= 66) {
      return "rgba(29, 52, 96, 0.5)"; // Azul com 70% de opacidade
    } else {
      return "rgba(51, 172, 27, 0.5)"; // Verde com 70% de opacidade
    }
  };

  return (

    <IonPage className="VendasDetalhes">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => {
              history.goBack();
              /*.replace("/empresa/operacoes", { recarregar: false }); */
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false} scrollEvents={true}>
        <IonList>

          <IonListHeader lines="full">
            <IonLabel>Venda {dadosVenda && dadosVenda.numero ? dadosVenda.numero : ""}</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon icon={trendingUp} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosVenda && dadosVenda.nomeVenda ? dadosVenda.nomeVenda : ""}</h2>
            </div>
          </IonItem>
          {/* <IonItem lines="none">
            <IonIcon icon={logoUsd} slot="start" />
            <div className="contentList">
              <h1>Forma de pagamento</h1>
              <h2>{dadosVenda && dadosVenda.formaPagamento ? dadosVenda.formaPagamento : ""}</h2>
            </div>
          </IonItem> */}

          <IonListHeader lines="full">
            <IonLabel>Prospect</IonLabel>
          </IonListHeader>

          <IonItem lines="none">
            <IonIcon icon={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosVenda && dadosVenda.prospectNome ? dadosVenda.prospectNome : ""}</h2>
            </div>
          </IonItem>


          {/* <IonItem lines="none">
            <IonIcon src={card} slot="start" />
            <div className="contentList">
              <h1>CPF</h1>
              <h2>{dadosVenda && dadosVenda.prospectCpf ? cpf_mask(dadosVenda.prospectCpf) : ""}</h2>
            </div>
          </IonItem> */}

          <IonItem lines="none">
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://api.whatsapp.com/send?phone=" + (dadosVenda ? dadosVenda.prospectTelefone : "")}><h2>{dadosVenda && dadosVenda.prospectTelefone ? formataTelefone(dadosVenda.prospectTelefone) : ""}</h2></a>
              </div>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon src={mailOutline} slot="start" />
            <div className="contentList">
              <h1>Email</h1>
              <h2>{dadosVenda && dadosVenda.prospectEmail ? dadosVenda.prospectEmail : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              <h1>Status</h1>
              <h2>{dadosVenda ? dadosVenda.status === "Inativo" ? "Encerrado" : dadosVenda.status : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={flagOutline} slot="start" />
            <div className="contentList">
              <h1>Canal</h1>
              <h2>{dadosVenda && dadosVenda.prospectCanal ? dadosVenda.prospectCanal : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={funnelOutline} slot="start" />
            <div className="contentList">
              <h1>Funil</h1>
              <h2>{dadosVenda ? dadosVenda.statusWorkflow : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={calendarOutline} slot="start" />
            <div className="contentList">
              <h1>Data Cadastro</h1>
              <h2>{dadosVenda ? formatarDataHoraCompleta(new Date(dadosVenda.dataCadastro)) : ""}</h2>
            </div>
          </IonItem>

          <IonItem lines="none">
            <IonIcon icon={personOutline} slot="start" />
            <div className="contentList">
              <h1>Responsável</h1>
              <h2>{dadosVenda ? dadosVenda.cadastradoPor : ""}</h2>
            </div>
          </IonItem>

          <IonListHeader lines="full">
            <IonLabel>Itens</IonLabel>
          </IonListHeader>
          <IonItem
            hidden={!(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")}
            lines="none"
            button
            detail
            onClick={(e) => { carregarProdutos(); }}
            style={{ backgroundColor: '#f7f7f7', padding: '10px 15px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          >
            <IonIcon src={listOutline} slot="start" style={{ color: dadosVenda.dataCupom ? "var(--ion-color-success)" : "var(--ion-color-primary)", fontSize: '28px' }} />
            <div className="contentList" style={{ fontWeight: 'bold', fontSize: "24px", color: dadosVenda.dataCupom ? "var(--ion-color-success)" : "var(--ion-color-primary)" }}>
              <h1>Produtos</h1>
              <h2>R$ {dadosVenda && dadosVenda.valorTotalProdutos ? dadosVenda.valorTotalProdutos : "0,00"}</h2>
            </div>
          </IonItem>


          <IonItem
            hidden={(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")}
            lines="none"
            button
            detail
            onClick={(e) => { carregarServicos(); }}
            style={{ backgroundColor: '#f7f7f7', padding: '10px 15px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          >
            <IonIcon src={listOutline} slot="start" style={{ color: dadosVenda.dataCupom ? "var(--ion-color-success)" : "var(--ion-color-primary)", fontSize: '28px' }} />
            <div className="contentList" style={{ fontWeight: 'bold', fontSize: "24px", color: dadosVenda.dataCupom ? "var(--ion-color-success)" : "var(--ion-color-primary)" }}>
              <h1>Serviços</h1>
              <h2>R$ {dadosVenda && dadosVenda.valorTotalServicos ? dadosVenda.valorTotalServicos : "0,00"}</h2>
            </div>
          </IonItem>


          <IonListHeader lines="full">
            <IonLabel>Empresa</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosVenda && dadosVenda.empresaNome ? dadosVenda.empresaNome : ""}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon src={phonePortraitOutline} slot="start" />
            <div className="contentList">
              <h1>Telefone</h1>
              <div>
                <a target='_blank' href={'https://api.whatsapp.com/send?phone=' + dadosVenda.empresaTelefone}>
                  <h2>{dadosVenda && dadosVenda.empresaTelefone ? formataTelefone(dadosVenda.empresaTelefone) : ""}</h2>
                </a>
              </div>
            </div>
          </IonItem>

          <IonListHeader lines="full">
            <IonLabel>Arquivos</IonLabel>
            <IonItem lines="none" hidden={acessos?.vendas != 'edicao'}>
              <IonIcon src={addCircle} color="primary" onClick={async () => { setShowModalArquivos(true) }}></IonIcon>
            </IonItem>
          </IonListHeader>




          <IonList lines="full" class="ion-no-margin">
            <IonGrid>
              <IonRow>

                {preencheArquivos(dadosVenda)}
              </IonRow>
            </IonGrid>
          </IonList>


          <IonListHeader lines="full">
            <IonLabel>Anotações</IonLabel>
            <IonItem hidden={acessos?.vendas != 'edicao'} lines="none" >
              <IonIcon src={addCircle} color="primary" onClick={async () => {

                mostartAlerta({
                  backdropDismiss: false,
                  header: 'Registrar anotação na venda',
                  message: 'Digite a anotação abaixo',
                  inputs: [
                    {
                      name: 'mensagem',
                      type: 'textarea',
                      id: 'name2-id',
                      placeholder: 'Digite aqui a anotação',
                    }],
                  buttons: ['Cancel', {
                    text: 'Incluir', handler: (d) => {
                      setShowLoadingPage(true);
                      let retorno = registrarAnotacao(dadosVenda.pk.replace(/#/g, '%23'), d.mensagem);
                      if (retorno) {
                        retorno?.then(async e => {
                          let body = await e.json();
                          if (e.status === 200) {
                            if (dadosVenda.anotacoes) {
                              dadosVenda.anotacoes?.push({ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa });
                            }
                            else {
                              dadosVenda.anotacoes = [{ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa }];
                            }

                            mostrarToast({
                              duration: 2000,
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Sucesso na inclusão!'
                            });
                          }
                          else {
                            mostrarToast({
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Erro na inclusão!'
                            });
                          }
                        }).catch((e) => {
                          console.log(e);
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro na inclusão!'
                          });
                        }).finally(() => {
                          setShowLoadingPage(false);
                        });
                        return true;
                      }
                      else {
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Digite uma anotação para incluir.'
                        });
                        setShowLoadingPage(false);
                        return false;
                      }
                    }
                  }],
                  onDidDismiss: (e) => { console.log('did dismiss'); }
                })

              }}></IonIcon>
            </IonItem>
          </IonListHeader>

          {loadAnotacoes()}



        </IonList>








        <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          cssClass='my-custom-class'
          buttons={getButtons()}
        />

        <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheetLinhaDoTempo}
          onDidDismiss={() => setShowActionSheetLinhaDoTempo(false)}
          cssClass='my-custom-class'
          buttons=

          {[
            {
              text: 'Cancelar',
              icon: closeOutline,
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked');
              }
            }]}
        />

        <IonModal onDidPresent={() => {
          if (inputVendasCadRef.current) {
            inputVendasCadRef.current.setFocus();
          }
        }} isOpen={showAtualizarInformacoes} onDidDismiss={() => { setShowAtualizarInformacoes(false); }}>
          <VendasCad usuario={dadosVenda} inputRef={inputVendasCadRef} closeAction={closeModalAtualizarInformacoesSucess} closeActionSimple={closeModalAtualizarInformacoes}></VendasCad>
        </IonModal>

        <IonModal onDidPresent={() => {
          if (inputVendasCriarProjetoRef.current) {
            inputVendasCriarProjetoRef.current.setFocus();
          }
        }} isOpen={showWorkflowAvancar} onDidDismiss={() => { setShowWorkflowAvancar(false); }}>
          <VendasWorkflowAvancar vendaid={vendaId} statusWorkflow={statusWorkflow} indiceWorkflow={indiceWorkflow} listaStatus={listaStatus} closeAction={closeModalWorkflowAvancar} alterarAction={closeModalWorkflowAvancarSucess}></VendasWorkflowAvancar>
        </IonModal>

        <IonModal isOpen={showCriarProjeto} onDidDismiss={() => { setShowCriarProjeto(false); }}>
          <VendasCriarProjeto inputRef={inputVendasCriarProjetoRef} dadosVenda={dadosVenda} closeAction={closeModalCriarProjeto} salvarAction={closeModalCriarProjetoSucess}></VendasCriarProjeto>
        </IonModal>

        <IonModal onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showModalArquivos} onDidDismiss={() => { getVenda(); setShowModalArquivos(false); setTextoArquivos(''); setFileArquivos(''); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Adicionar Arquivos</IonTitle>
              <IonButtons slot="start" onClick={() => { setShowModalArquivos(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <IonItem lines="none">
              <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
              <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
            </IonItem>
            <IonItem lines="none" >
              <IonLabel className="ion-text-wrap" position="stacked">Arquivo:</IonLabel>
              <IonTextarea rows={1} maxlength={20} value={textoArquivos} ref={(ref) => inputRef.current = ref} autoCorrect='true' autoCapitalize='true' placeholder="Nome do arquivo" onIonChange={e => setTextoArquivos(e.detail.value!)}></IonTextarea>
            </IonItem>
            <IonItem lines="none" >
              <input ref={(ref) => { setFileArquivos(ref); }} type="file" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*" />
            </IonItem>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' onClick={() => { handleUpload(params.vendaid).catch((e) => { console.log("cancelado"); }); }}>Adicionar Arquivo</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>

        <IonAlert isOpen={showAlertConfirmarExclusaoArquivo}
          onDidDismiss={() => setShowAlertConfirmarExclusaoArquivo(false)}
          header={'Confirmar Exclusão'}
          message={'Você <strong>confirma</strong> excluir esse arquivo?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                deletarArquivo(caminhoParaExcluir);
                setShowAlertConfirmarExclusaoArquivo(false);
              }

            }
          ]}
        />


        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />

      </IonContent>

      <IonFooter className="ion-no-border" hidden={(temHoraFim)}>
        <IonToolbar>
          <IonButton size='large' expand="block" onClick={() => setShowActionSheet(true)}>Ações</IonButton>
        </IonToolbar>
      </IonFooter>

      <IonFooter className="ion-no-border" hidden={(!temHoraFim)}>
        <IonToolbar>
          <IonButton size='large' expand="block" onClick={() => setShowActionSheetLinhaDoTempo(true)}>Ações</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );


};

export default VendasDetalhes;