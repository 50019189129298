// import '@capacitor-community/http';
import { IonAlert, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonPage, IonTextarea, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { arrowBack } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { empresaAlterar, empresaCarregar, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './OperacoesAlt.css';

type Props = {
  abrirMenu: boolean;
};

const OperacoesAlt: React.FC<Props> = (props: Props) => {

  const history = useHistory();

  const [showToast2, setShowToast2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [instrucoes, setInstrucoes] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [showSucesso, setShowSucesso] = useState(false);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarConta();
  });

  function carregarConta() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(empresaCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setInstrucoes(response.Item.instrucoesMotorista || '');
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  const cadastrar = () => {
    setShowToast2(true);
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">


          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack(); }}><IonIcon slot="icon-only" icon={arrowBack} /></IonButton>
          </IonButtons>


          <IonTitle slot="start">Instruções da operação</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem lines="none">
            <IonLabel position="stacked">Instruções do entregador</IonLabel>
            <IonTextarea style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }} rows={6} value={instrucoes} maxlength={250} placeholder="Instruções para orientar o prestador" onIonInput={(e: any) => setInstrucoes(e.target.value)}></IonTextarea>
          </IonItem>
        </IonList>
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonAlert isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          header={'Alteração de cadastro'}
          message={'Você <strong>confirma</strong> a alteração?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                setShowLoading(true);

                const objectRequest = {
                  instrucoesMotorista: instrucoes
                }
                let id = loja;

                console.log("id", id)

                requestService(
                  empresaAlterar.url.replace("{id}", id.replaceAll("#", "%23")).concat("?tipo=instrucoesmotorista"),
                  {
                    method: empresaAlterar.method,
                    body: JSON.stringify(objectRequest),
                    headers: [
                      ["token", window.localStorage.getItem("token")]
                    ],
                    credentials: 'include'
                  },
                  history,
                  (response: any) => {
                    console.log(response);
                    if (response) {
                      setTextToast("Alteração realizado com sucesso.");
                      setShowSucesso(true);

                    } else if (response.status === 400) {
                      console.log(response);
                      setTextToast("Ocorreu um erro ao efetuar a alteração.");
                      setShowToast(true);

                    }
                    setShowLoading(false);
                  },
                  (error: any) => {
                    console.log(error);
                    setTextToast("Ocorreu um erro ao efetuar a alteração.");
                    setShowToast(true);
                    setShowLoading(false);
                  })
              }
            }
          ]}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e) => { setShowSucesso(false); history.goBack(); }}
          message={'Alterações salvas com sucesso!'}
          duration={3000}
          animated={false}
        />
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit" hidden={acessos?.configuracoes != 'edicao'}>Salvar</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default OperacoesAlt;
