import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonLabel, IonPage, IonTitle, IonToast, IonToolbar, isPlatform, useIonViewDidEnter } from '@ionic/react';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { arrowBack } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { confirmarLogin, enviarEmail, requestService } from '../../../Utils/Services';
import { MessageContext } from '../../../contexts/MessageContext';
import { authentication } from '../../../firebase-config';
import './ValidarOtp.css';

const ValidarOtp: React.FC = () => {

  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [textBotao, setTextBotao] = useState("Validar Número");
  const [codigo, setCodigo] = useState("");
  const [confirmation, setConfirmation] = useState<any>();
  const [desabilitarEnviarEmail, setDesabilitarEnviarEmail] = useState(false);
  const { loja, setLoja } = useContext(MessageContext);

  const inputRef = useRef<any>(null);

  useIonViewDidEnter(() => {
    setDesabilitarEnviarEmail(false);
    setBotaoAtivo(false);


    let fcmAtivo = window.localStorage.getItem("fcmAtivo");
    console.log("fcmAtivo1", fcmAtivo);
    if (!isPlatform('ios') && fcmAtivo == "true") {
      var nome = 'divRecaptcha' + new Date().toLocaleTimeString();
      let elementToAdd = document.createElement("div");
      elementToAdd.setAttribute('id', nome);

      let div = document.getElementById('recaptcha-container');
      if (div) div.append(elementToAdd);

      let recpVerify = new RecaptchaVerifier(nome, {
        'size': 'invisible',
        'callback': (response: any) => {
        }
      }, authentication);

      console.log("aaaa" + window.localStorage.getItem("telefone"))
      signInWithPhoneNumber(authentication, '+' + window.localStorage.getItem("telefone"), recpVerify)
        .then((confirmationResult) => {
          setConfirmation(confirmationResult);
          inputRef.current.setFocus();
          setBotaoAtivo(true);
          console.log(confirmationResult);
        }).catch((error) => {
          console.log(error);
          setTextToast("Não foi possível enviar o SMS.");
          setShowToast(true);
          setBotaoAtivo(true);
          history.goBack();
        });
    }
    else {
      inputRef.current.setFocus();
      setBotaoAtivo(true);
    }

    setCodigo("");
    setTextBotao("Validar Número");
  });

  const validar = () => {
    inputRef.current.setBlur();
    setBotaoAtivo(false);
    setTextBotao("Aguarde...");

    let fcmAtivo = window.localStorage.getItem("fcmAtivo");
    console.log("fcmAtivo2", fcmAtivo);
    if (!isPlatform('ios') && fcmAtivo == "true") {
      console.log("111111111111");
      confirmation.confirm(codigo).then((result: any) => {
        console.log(result);
        const user = result?.user;

        login(user?.accessToken);


      }).catch((error: any) => {
        console.log(error);
        login();
      });
    }
    else {
      console.log("2222222222");
      login();
    }

  }


  function login(accessToken?: any) {

    //alert(window.localStorage.getItem("chaveContato") || "0");

    const objectRequest = {
      otp: codigo.toString(),
      accessToken: accessToken,
      chaveContato: window.localStorage.getItem("chaveContato") || "0",
      telefone: window.localStorage.getItem("telefone")
    }

    requestService(
      confirmarLogin.url.replace("{cpf}", window.localStorage.getItem("cpf") || ""),
      {
        method: confirmarLogin.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objectRequest),
        credentials: 'include'
      },
      history,
      (response: any) => {
        //window.localStorage.clear();
        window.localStorage.setItem("empresa", response.gsi2pk);
        setLoja(response.gsi2pk);
        window.localStorage.setItem("logado", "true");
        if (response.lojas) window.localStorage.setItem("lojas", JSON.stringify(response.lojas));

        if (response.servicoPrincipal && response.servicoTipo && response.servicoTipoPlural && response.configWorkflow) {
          window.localStorage.setItem("configWorkflow", JSON.stringify(response.configWorkflow));
          window.localStorage.setItem("servicoPrincipal", response.servicoPrincipal);
          window.localStorage.setItem("identificacaoPorNumero", response.identificacaoPorNumero);
          window.localStorage.setItem("servicoTipo", response.servicoTipo);
          window.localStorage.setItem("servicoTipoPlural", response.servicoTipoPlural);
        }

        if (response.nomeInternoMesaOuComanda) {
          window.localStorage.setItem("nomeInternoMesaOuComanda", response.nomeInternoMesaOuComanda);
        }

        if (response.nomeInternoMesasOuComandas) {
          window.localStorage.setItem("nomeInternoMesasOuComandas", response.nomeInternoMesasOuComandas);
        }

        if (response.imprimirMesaParcial) {
          window.localStorage.setItem("imprimirMesaParcial", response.imprimirMesaParcial);
        }

        if (response.nomeLinhaDoTempo) {
          window.localStorage.setItem("nomeLinhaDoTempo", response.nomeLinhaDoTempo);
        }

        if (response.polos) {
          window.localStorage.setItem("polos", JSON.stringify(response.polos));
        }

        if (response.naoAgrupar) {
          window.localStorage.setItem("naoAgrupar", response.naoAgrupar);
        }

        console.log("******************", response.esconderValoresProjeto);

        if (response.esconderValoresProjeto) {
          window.localStorage.setItem("esconderValoresProjeto", response.esconderValoresProjeto);
        }

        if (response.nome) {
          window.localStorage.setItem("nome", response.nome);
        }

        if (response.naoOperacao) {
          window.localStorage.setItem("naoOperacao", response.naoOperacao);
        }

        if (response.configWorkflowVenda) {
          window.localStorage.setItem("configWorkflowVenda", JSON.stringify(response.configWorkflowVenda));
        }

        if (response.empresaMarca) {
          window.localStorage.setItem("empresaMarca", response.empresaMarca);
        }

        if (response.parceria) {
          window.localStorage.setItem("parceria", response.parceria);
        }

        if (response.acessos) {
          window.localStorage.setItem("acessos", JSON.stringify(response.acessos));
        }

        setBotaoAtivo(true);
        setCodigo("");
        setTextBotao("Validar Número");
        history.push("/empresa/inicio");
        //history.push(!(!isPlatform('mobileweb') && isPlatform('mobile')) ? "/empresa/painel" : "/empresa/inicio");
      },
      (error: any) => {
        setTextToast("Token invalido");
        setShowToast(true);
        setCodigo("");
        setBotaoAtivo(true);
        setTextBotao("Validar Número");
      }
    );
  }

  const email = () => {

    requestService(
      enviarEmail.url.replace("{cpf}", window.localStorage.getItem("cpf") || ""),
      {
        method: enviarEmail.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        //body: JSON.stringify(objectRequest),
        credentials: 'include'
      },
      history,
      (response: any) => {
        console.log("response", response.message);
        setTextToast(response.message);
        setShowToast(true);
        setDesabilitarEnviarEmail(true);

      },
      (error: any) => {
        setTextToast("Ocorreu um erro ao enviar o e-mail");
        setShowToast(true);
      }
    );
  }

  function onChangeCodigo(e: any) {
    e.persist();
    e.preventDefault();
    setCodigo(e.target.value);

    if (e.keyCode === 13) {
      if (botaoAtivo) validar();
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle>VALIDE SEU CELULAR</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"ValidarLogin"}>
        <IonLabel className="titulo">Insira o <strong>código</strong> que você irá receber por <strong>SMS</strong> no celular informado:</IonLabel>
        <div className="input">
          <span>Token</span>
          <IonInput ref={(ref) => inputRef.current = ref} type="number" maxlength={6} required value={codigo} onKeyUp={onChangeCodigo} onIonInput={(e: any) => setCodigo(e.target.value)} />
        </div>
        <IonButton disabled={!botaoAtivo} onClick={() => { validar(); }}>{textBotao}</IonButton>
        <div id="recaptcha-container"></div>
        <IonButton fill='clear' color="tertiary"
          disabled={desabilitarEnviarEmail}
          onClick={() => { email(); }}>Enviar código por email</IonButton>
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default ValidarOtp;
