import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonTitle, IonToast, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { alertCircle, arrowBack } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { apiProjetosCaixasPOST, requestService } from '../../../../../Utils/Services';
import './CaixaGeralAbrir.css';

type Props = {
  closeAction: Function;
  closeActionSimple: Function;
}

const CaixaGeralAbrir: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [valor, setValor] = useState<any>("");
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const { loja, setLoja } = useContext(MessageContext);
  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);

  const [mostartAlerta] = useIonAlert();
  const [textToast, setTextToast] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));

  useIonViewDidEnter(() => {

  })

  const handleAbrir = async () => {

    if (!valor) {
      setAlertaPreenchimento(true);
      return null;
    }

    mensagemAbrirCaixa();

  }

  const mensagemAbrirCaixa = () => {
    mostartAlerta({
      backdropDismiss: false,
      header: "Atenção!",
      message: "Você tem certeza que deseja abrir o caixa com o valor informado?",
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {

          }
        },
        {
          text: 'Confirmar',
          handler: async () => {


            abrirCaixa();

          }
        }
      ],
      onDidDismiss: () => { console.log('did dismiss'); }
    });
  };

  const abrirCaixa = () => {
    setShowLoading(true);

    let objRequest = {
      loja: loja,
      valor: valor
    };

    requestService(
      apiProjetosCaixasPOST.url.replace("{id}", loja.replaceAll("#", "%23")),
      {
        method: apiProjetosCaixasPOST.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
      history,
      (response: any) => {
        if (response) {
          props.closeAction();
        }
      },
      (error: any) => {
        console.log(error);
        setTextToast(error.message);
        setShowToast(true);
      }
    ).finally(() => {
      setShowLoading(false);
    });
  };

  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    setValor(v);
  }

  return (
    <>

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} />
          </IonButtons>
          <IonTitle>Abertura de caixa</IonTitle>

        </IonToolbar>

      </IonHeader>

      <IonContent class="ion-padding">

        <IonItem lines="none">
          Insira abaixo o valor de abertura do caixa:
        </IonItem>


        <IonItem lines="none">
          <IonLabel position="stacked">Valor de abertura do caixa*</IonLabel>
          <IonInput type="tel" maxlength={14} placeholder="Digite o valor" value={valor} onKeyUp={onChangeValor} />
        </IonItem>

        <IonItem lines="none">
          <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
          <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
        </IonItem>


        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />


        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
      <IonFooter className="ion-no-border" >
        <IonToolbar>
          <IonButton expand="block" size='large' disabled={acessos?.caixa != 'edicao'} onClick={() => { handleAbrir().catch((e) => { console.log("cancelado"); }); }}>Abrir Caixa</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default ({ closeAction, closeActionSimple }: { closeAction: Function, closeActionSimple: Function }) => (
  <CaixaGeralAbrir closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </CaixaGeralAbrir>
)
