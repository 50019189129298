// import '@capacitor-community/http';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonList, IonPage, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useContext } from 'react';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './TermosPolitica.css';


type Props = {
  abrirMenu: boolean;
}

const TermosPolitica: React.FC<Props> = (props: Props) => {

  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle slot="start">Privacidade e Termos de Uso</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem lines='none'>
            <a target='_blank' href={'https://xeguei-plt-welcome.s3.sa-east-1.amazonaws.com/termos/empresa/Termos_de_uso.pdf'}>Ler os termos de uso</a>
          </IonItem>
          <IonItem lines='none'>
            <a target='_blank' href={'https://xeguei-plt-welcome.s3.sa-east-1.amazonaws.com/termos/empresa/politica_de_privacidade.pdf'}>Ler a política de privacidade</a>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default TermosPolitica;
