import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonList,
    IonSearchbar,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import * as icons from 'ionicons/icons';
import { arrowBack } from 'ionicons/icons';
import { useState } from 'react';

interface TypeaheadProps {
    items: any[];
    selectedItem: string;
    title?: string;
    onSelectionCancel?: () => void;
    onSelectionChange?: (item: string) => void;
}


type IconType = typeof import('ionicons/icons/index');

function AppTypeahead(props: TypeaheadProps) {
    const [filteredItems, setFilteredItems] = useState<any[]>([...props.items]);
    const [workingSelectedValue, setWorkingSelectedValue] = useState<string>(props.selectedItem);

    const [selectedIcon, setSelectedIcon] = useState<string | null>(null); // Estado para controlar o ícone selecionado

    // Função para lidar com o clique no ícone
    const handleIconClick = (value: string) => {
        // Verifica se o ícone clicado já está selecionado
        if (selectedIcon === value) {
            setSelectedIcon(null); // Desmarca o ícone se já estiver selecionado
        } else {
            setSelectedIcon(value); // Marca o ícone se ainda não estiver selecionado
        }
    };

    const cancelChanges = () => {
        const { onSelectionCancel } = props;
        if (onSelectionCancel !== undefined) {
            onSelectionCancel();
        }
    };

    const confirmChanges = () => {
        const { onSelectionChange } = props;
        if (onSelectionChange !== undefined) {
            onSelectionChange(workingSelectedValue || "");
        }
    };

    const searchbarInput = (ev: any) => {
        filterList(ev.target.value);
    };

    /**
     * Update the rendered view with
     * the provided search query. If no
     * query is provided, all data
     * will be rendered.
     */
    const filterList = (searchQuery: string | null | undefined) => {
        /**
         * If no search query is defined,
         * return all options.
         */
        if (searchQuery === undefined || searchQuery === null) {
            setFilteredItems([...props.items]);
        } else {
            /**
             * Otherwise, normalize the search
             * query and check to see which items
             * contain the search query as a substring.
             */
            const normalizedQuery = searchQuery.toLowerCase();
            setFilteredItems(
                props.items.filter((item) => {
                    return item.toLowerCase().includes(normalizedQuery);
                })
            );
        }
    };

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonIcon slot="icon-only" icon={arrowBack} onClick={cancelChanges} />
                    </IonButtons>
                    <IonTitle>{props.title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton fill='solid' color="tertiary" onClick={confirmChanges}>OK</IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar onIonInput={searchbarInput}></IonSearchbar>
                </IonToolbar>
            </IonHeader>

            <IonContent class="ion-padding">
                <IonList>

                    {filteredItems.map((value: any) => (

                        <IonIcon
                            style={{
                                margin: "0px",
                                padding: "0px",
                                fontSize: "30px",
                                color: selectedIcon === value ? "#FF0000" : "#333333" // Altera a cor se o ícone estiver selecionado
                            }}
                            icon={icons[value as keyof IconType]}
                            onClick={() => {
                                handleIconClick(value);
                                setWorkingSelectedValue(value);
                            }}

                        ></IonIcon>


                    ))}



                </IonList>
            </IonContent>
        </>
    );
}
export default AppTypeahead;