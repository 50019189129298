import { isPlatform } from '@ionic/react';
import { useState } from 'react';

import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
  Photo
} from "@capacitor/camera";

import { Directory, Filesystem } from "@capacitor/filesystem";

import { Toast } from '@capacitor/toast';
import imageCompression from 'browser-image-compression';
import { incluirFotoOperacao } from '../Utils/Services';


//const { Filesystem, FilesystemDirectory } = Plugins;

export function usePhotoGallery(idOperacao: string) {

  const [photos, setPhotos] = useState<UserPhoto[]>([]);

  const takePhoto = async (idOperacao: string) => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      allowEditing: false,
      direction: CameraDirection.Rear,
      source: CameraSource.Prompt, //CameraSource.Camera || CameraSource.Photos,//.Prompt,
      promptLabelHeader: 'Fotos',
      promptLabelPhoto: 'Biblioteca de fotos',
      promptLabelPicture: 'Câmera',
      promptLabelCancel: 'Cancelar'
      //quality: 80,
      //preserveAspectRatio: true,

      //saveToGallery: true,
      //width: 600,
      //height: 600
    });

    var binary_string = window.atob(cameraPhoto.base64String || '');
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(bytes.buffer)], {
      type: `image/${cameraPhoto.format}`,
    });

    const file = new File([blob], "Name", {
      lastModified: new Date().getTime(),
      type: blob.type,
    });

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1102,
      useWebWorker: true,
      initialQuality: 0.5
    }

    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      let base64 = await getBase64(compressedFile);
      Toast.show({
        text: "Aguarde o upload da foto.",
        position: "center",
        duration: "long"
      });
      incluirFoto(idOperacao, base64);

    } catch (error) {
      console.log(error);
    }

  };

  async function getBase64(file: any) {


    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(file)
    })
  }


  const loadPhotos = async (_photos: string[]) => {
    let newPhotos: UserPhoto[] = [];
    _photos.forEach(path => {
      console.log(path.toString());
      newPhotos.push({ filepath: path, webviewpath: path });
    });

    setPhotos(newPhotos);
  };

  function incluirFoto(operacaoId: string, base64: any) {

    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }

      const resp = await fetch(incluirFotoOperacao.url,
        {
          method: incluirFotoOperacao.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({
            "id": operacaoId,
            "base64": base64
          }),
          credentials: 'include'
        });

      const res = await resp.json();
      if (res) {
        console.log(res);

        const newPhotos = [{
          filepath: res.Item,
          webviewpath: res.Item
        }, ...photos];
        setPhotos(newPhotos);
      }
    }

    atualizar();

  };


  const savePicture = async (photo: Photo, fileName: string): Promise<UserPhoto> => {

    let base64Data: string;

    if (isPlatform('hybrid')) {
      const file = await Filesystem.readFile({
        path: photo.path!
      });

      if (typeof file.data === 'string') {
        base64Data = file.data;
      } else {
        base64Data = await new Response(file.data).text();
      }
    } else {
      base64Data = await base64FromPath(photo.webPath!);
    }
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Documents
    });
    console.log("arquivo gravado");
    console.log(fileName);
    console.log(base64Data);
    console.log(Directory.Documents);
    console.log(savedFile);

    const readedfile = await Filesystem.readFile({ path: savedFile.uri });
    console.log(savedFile);

    if (!isPlatform('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      //return {
      //  filepath: savedFile.uri,
      //  webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      //};
    }
    else {
      // Use webPath to display the new image instead of base64 since it's
      // already loaded into memory
      return {
        filepath: fileName,
        webviewpath: photo.webPath
      };
    }

    return {
      filepath: fileName,
      webviewpath: photo.webPath
    };
  };

  return {
    photos,
    takePhoto,
    loadPhotos
  };

}

export interface UserPhoto {
  filepath: string;
  webviewpath?: string;
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject('method did not return a string')
      }
    };
    reader.readAsDataURL(blob);
  });
}