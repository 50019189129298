import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonSelect, IonSelectOption, IonTitle, IonToast, IonToggle, IonToolbar, useIonAlert } from '@ionic/react';
import { addCircle, arrowBack } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
//import { assinarArquivoOperacao, carregaAssinatura, requestService, urlBase } from '../../../../../Utils/Services';
import "./VendasCriarProjeto.css";

import { useHistory } from 'react-router';
import { cadastraEquipe, incluirProjeto, listaEquipePorPerfil, requestService } from '../../../../../Utils/Services';
import AppTypeaheadList from '../../../../../components/AppTypeaheadList';
import { MessageContext } from '../../../../../contexts/MessageContext';
import ArquitetosCad from '../ArquitetosCad/ArquitetosCad';

type Props = {
  dadosVenda: any;
  inputRef: any;
  closeAction: Function;
  salvarAction: Function;
};

const VendasCriarProjeto: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showModalResponsaveis, setShowModalResponsaveis] = useState(false);
  const [responsaveis, setResponsaveis] = useState<any>([]);
  const [selectedResponsaveis, setSelectedResponsaveis] = useState<any>([]);
  const [selectedResponsaveisText, setSelectedResponsaveisText] = useState<string>('0 Responsáveis');
  const [lojas, setLojas] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaId || "");
  const [nomeProjeto, setNomeProjeto] = useState<any>();
  const [descricaoProjeto, setDescricaoProjeto] = useState<any>();
  const [checked, setChecked] = useState(false);
  const [checkedInterno, setCheckedInterno] = useState(false);
  const [esconderBloqueado, setEsconderBloqueado] = useState(false);

  const [local, setLocal] = useState<any>("");
  const [listaArquiteto, setListaArquiteto] = useState<any>([]);
  const [showDetalhesArquiteto, setShowDetalhesArquiteto] = useState(false);
  const [arquiteto, setArquiteto] = useState<any>();
  const { loja, setLoja } = useContext(MessageContext);
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);

  const inputArquitetosCadRef = useRef<any>(null);

  const { pkUsuario, setPkUsuario } = useContext(MessageContext);

  const [mostartAlerta] = useIonAlert();
  const [localEnderecoSelecionado, setLocalEnderecoSelecionado] = useState<any>("");
  const [listaEnderecos, setListaEnderecos] = useState<any>([]);


  useEffect(() => {

    if (props.dadosVenda) {
      console.log("entrou no useEffect");
      avancarProjeto();
    }
  }, [props.dadosVenda]);

  useEffect(() => {
    // Define o foco no input quando o componente é montado
    if (props.inputRef.current) {
      props.inputRef.current.focus();
    }
  }, [props.inputRef]);

  function readArquiteto() {
    return listaArquiteto.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"arquiteto" + value.pk} value={value.pk}>{value.nome}</IonSelectOption>
    )
    )
  };

  const consultarArquiteto = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", props.dadosVenda.gsi3pk.replaceAll(/#/g, "%23"));

    const resp = await fetch(montarUrl.replace("{perfil}", "arquiteto"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
        setListaArquiteto(lista);
      }
    }
  }

  function validarCampos() {

    if (nomeProjeto.length === 0 || local.length === 0 || (checked && arquiteto.length === 0)) {
      console.log("************ n validou");
      setTextToast("Todos os campos com * são obrigatórios.");
      setShowToast(true);
      return false;
    }
    return true;
  }

  function avancarProjeto() {

    console.log("props.dadosVenda", props.dadosVenda);
    setDescricaoProjeto("");
    setNomeProjeto(props.dadosVenda.nomeVenda);
    if (props.dadosVenda.enderecoVenda) {
      setLocal(props.dadosVenda.enderecoVenda);
      setEsconderBloqueado(true);
    }
    else {
      setLocal("");
      setEsconderBloqueado(false);
    }
    setChecked(false);
    setCheckedInterno(false);
    setArquiteto("");
    // setSelectedResponsaveis([]);
    // setSelectedResponsaveisText("0 Responsáveis");

    setBotaoAtivo(true);

    consultarArquiteto();
    consultarUsuarios();
  }

  async function CriarCliente(): Promise<any> {
    return new Promise((resolve, reject) => {
      const objectRequest = {
        cpf: props.dadosVenda?.prospectCpf?.replace("-", "").replace(".", "").replace(".", ""),
        nome: props.dadosVenda.prospectNome,
        telefone: props.dadosVenda?.prospectTelefone,
        email: props.dadosVenda?.prospectEmail,
        dataAniversario: props.dadosVenda.prospectDataAniversario,
        endereco: local || "",
        color: "",
        perfil: "cliente",
        empresaMarca: localStorage.getItem("empresaMarca") || "",
        lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
          return lojas.includes(value.empresaLojaId);
        })
      }

      console.log("objectRequest", objectRequest);

      requestService(cadastraEquipe.url, {
        method: cadastraEquipe.method,
        headers: [["token", localStorage.getItem("token") || ""]],
        credentials: 'include',
        body: JSON.stringify(objectRequest)
      },
        history,
        (response: any) => {
          resolve(response); // Resolvendo a promessa com a resposta
        },
        (error: any) => {
          reject(error); // Rejeitando a promessa com o erro
        });
    });
  }


  async function inserirProjeto(response: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let objRequest = {
        "nomeProjeto": nomeProjeto,
        "descricaoProjeto": descricaoProjeto,
        "endereco": response.usuario.endereco,
        "enderecoLat": response.usuario.enderecoLat,
        "enderecoLng": response.usuario.enderecoLng,
        "vendaid": props.dadosVenda.pk,
        "clienteid": response.usuario.pk,
        "responsavelid": (checked && arquiteto.length > 0 ? arquiteto : ""),
        "empresaid": props.dadosVenda.gsi3pk,
        "responsaveis": selectedResponsaveis,
        "interno": checkedInterno,
      };

      console.log("objRequest", objRequest);

      requestService(incluirProjeto.url, {
        method: incluirProjeto.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
        history,
        (response: any) => {
          resolve(response); // Resolvendo a promessa com a resposta        
        },
        (error: any) => {
          reject(error); // Rejeitando a promessa com o erro
        });
    });
  }



  const formatData = (data: string[]) => {
    if (data.length === 1) {
      const responsavel = responsaveis.find((convidado: any) => convidado.pk === data[0])!;
      return responsavel.nome;
    }

    return `${data.length} Responsáveis`;
  };


  const responsavelSelectionChanged = (responsaveis: any[]) => {

    if (responsaveis.length > 0) {
      setSelectedResponsaveis(responsaveis);
      setSelectedResponsaveisText(formatData(responsaveis));
    }
    else {
      setSelectedResponsaveis([]);
      setSelectedResponsaveisText("0 Responsáveis");
    }
    setShowModalResponsaveis(false);
  };


  const consultarUsuario = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo");

    const resp = await fetch(montarUrl.replace("{perfil}", "usuario"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        return equipe.Items;
      }
    }
  }

  const consultarUsuarios = async () => {

    let ret2 = await consultarUsuario();
    console.log("ret2", ret2);

    let lista = (ret2.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
    setResponsaveis(lista);

    const responsavelSelecionado = lista.find((convidado: any) => convidado.pk === pkUsuario);

    console.log("responsavelSelecionado", responsavelSelecionado);

    if (responsavelSelecionado) {
      setSelectedResponsaveis([responsavelSelecionado.pk]);
      setSelectedResponsaveisText(responsavelSelecionado.nome);
    }
  }

  async function closeModalArquiteto(pk: any) {
    setArquiteto(pk);
    setShowDetalhesArquiteto(false);
    consultarArquiteto();
  }

  async function closeModalSimpleArquiteto() {
    setShowDetalhesArquiteto(false);
  }

  function readListaEnderecos() {
    return listaEnderecos.map((value: any, index: any) => (
      <IonItem
        key={"endereco" + value.place_id}
        button
        onClick={() => setLocalEnderecoSelecionado(value.formatted_address)}
        color={localEnderecoSelecionado === value.formatted_address ? 'tertiary' : ''}
      >
        <IonLabel>{value.formatted_address}</IonLabel>
      </IonItem>
    ));
  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonTitle>Criar {window.localStorage.getItem("servicoTipo") || "Projeto"}</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => {
              props.closeAction();
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>
        <IonList lines="full" class="ion-no-margin">
          <IonListHeader lines="full">
            <IonLabel>
              Dados {window.localStorage.getItem("servicoTipo") || "Projeto"}
            </IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonLabel>Nome*</IonLabel>
            <IonInput placeholder="Nome" value={nomeProjeto} ref={(ref) => props.inputRef.current = ref}
              autoCorrect='true' autoCapitalize='true'
              required onIonInput={(e: any) => setNomeProjeto(e.target.value)}></IonInput>
          </IonItem>

          <IonItem lines="none" hidden={esconderBloqueado}>
            <IonLabel>Endereço*</IonLabel>
            <IonInput placeholder="Endereço" value={local}
              autoCorrect='true' autoCapitalize='true'
              required onIonInput={(e: any) => setLocal(e.target.value)}></IonInput>
          </IonItem>


          {/* <IonItem lines="none">
            <IonLabel>Descrição</IonLabel>
            <IonInput placeholder="Descrição" value={descricaoProjeto}
              autoCorrect='true' autoCapitalize='true'
              required onIonInput={(e: any) => setDescricaoProjeto(e.target.value)}></IonInput>
          </IonItem> */}

          <IonItem lines="none">
            <IonLabel>Responsáveis</IonLabel>
            <IonItem slot="end" lines="none" button={true} detail={false} onClick={e => { setShowModalResponsaveis(true); }}>
              {selectedResponsaveisText}
            </IonItem>
            <IonModal isOpen={showModalResponsaveis} onDidDismiss={() => setShowModalResponsaveis(false)} >
              <AppTypeaheadList
                title="Responsáveis"
                items={responsaveis}
                selectedItems={selectedResponsaveis}
                onSelectionCancel={() => setShowModalResponsaveis(false)}
                onSelectionChange={responsavelSelectionChanged}
              />
            </IonModal>
          </IonItem>

          {/* <IonItem lines="none" hidden={esconderBloqueado}>
            <IonLabel>Bloqueado</IonLabel>
            <IonToggle slot='end' checked={checkedInterno} onIonChange={e => {
              setCheckedInterno(e.detail.checked);
            }} />

          </IonItem> */}

          <IonItem lines="none" hidden={listaArquiteto.length === 0}>
            <IonLabel>Arquiteto</IonLabel>
            <IonButtons slot="end" onClick={() => { setShowDetalhesArquiteto(true) }}>
              <IonIcon color="tertiary" size='large' slot="icon-only" icon={addCircle}></IonIcon>
            </IonButtons>
            <IonToggle checked={checked} onIonChange={e => {
              setChecked(e.detail.checked);
            }} />

          </IonItem>

          <IonItem lines="none" hidden={!checked}>
            <IonSelect slot="end" value={arquiteto} placeholder="Selecione" onIonChange={e => { setArquiteto(e.detail.value); }}>
              {readArquiteto()}
            </IonSelect>
          </IonItem>




        </IonList>

        <IonModal isOpen={(Array.isArray(listaEnderecos) && listaEnderecos.length > 0)} onDidDismiss={() => { setListaEnderecos([]); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Selecione um endereço</IonTitle>
              <IonButtons slot="start" onClick={() => setListaEnderecos([])}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {readListaEnderecos()}
            </IonList>
          </IonContent>
          <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {

              mostartAlerta({
                backdropDismiss: false,
                header: 'Número',
                message: 'Adicione Número',
                inputs: [
                  {
                    name: 'mensagem',
                    type: 'textarea',
                    id: 'name2-id',
                    placeholder: 'Digite aqui o número',
                  }],
                buttons: ['Cancel', {
                  text: 'OK', handler: (d) => {
                    setLocal(localEnderecoSelecionado + ", " + d.mensagem);
                  }
                }],
                onDidDismiss: (e) => { console.log('did dismiss'); }
              })

              setListaEnderecos([]);
            }}>Avançar</IonButton>
          </IonFooter>
        </IonModal>


        <IonModal onDidPresent={() => {
          if (inputArquitetosCadRef.current) {
            inputArquitetosCadRef.current.setFocus();
          }
        }} isOpen={showDetalhesArquiteto} onDidDismiss={() => { setShowDetalhesArquiteto(false); }}>
          <ArquitetosCad inputRef={inputArquitetosCadRef} usuario={null} closeAction={closeModalArquiteto} closeActionSimple={closeModalSimpleArquiteto}></ArquitetosCad>
        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
      <IonFooter className="ion-no-border" >
        <IonToolbar>
          <IonButton disabled={!botaoAtivo} expand="block" size='large' color="primary" onClick={async () => {

            if (!validarCampos())
              return;

            if (botaoAtivo) {
              setShowLoading(true);
              setBotaoAtivo(false);
              let response = await CriarCliente().catch((error) => {
                console.log("error.message", error.message);
                setTextToast(error.message);
                setShowToast(true);
                if (error.data) {
                  console.log("entrou varios endereços");
                  if (Array.isArray(error.data) && error.data.length > 0) {
                    setListaEnderecos(error.data);
                  }
                }
              });
              console.log("********** CriarCliente", response);
              if (response && response.usuario) {
                let responseProjeto = await inserirProjeto(response).catch((error) => {
                  console.log("error.message", error.message);
                  setTextToast(error.message);
                  setShowToast(true);
                });
                if (responseProjeto && responseProjeto.message) {
                  props.salvarAction();
                  setRecarregarProjeto(true);
                }
              }
              setShowLoading(false);
              setBotaoAtivo(true);


            }

          }}>Registrar</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );

};

export default ({ dadosVenda, inputRef, closeAction, salvarAction }: { dadosVenda: any, inputRef: any, closeAction: Function, salvarAction: Function }) => (
  <VendasCriarProjeto dadosVenda={dadosVenda} inputRef={inputRef} closeAction={closeAction} salvarAction={salvarAction}>
  </VendasCriarProjeto>
)