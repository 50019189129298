import {
  IonActionSheet, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
  IonChip, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent,
  IonItem, IonLabel, IonLoading, IonModal, IonPage, IonPopover, IonRefresher, IonRefresherContent, IonRow, IonSelect,
  IonSelectOption, IonTitle, IonToast, IonToolbar, useIonAlert, useIonViewDidEnter
} from '@ionic/react';
import { Buffer } from 'buffer';
import { arrowBack, closeOutline, documentOutline, logoUsd, printOutline, search } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  listaEquipePorPerfil,
  mesaGerarCupom,
  projetoGerarCupomPdf,
  urlBase
} from '../../../../../Utils/Services';
import AppTypeaheadItem from '../../../../../components/AppTypeaheadItem';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './ComandasHistorico.css';

const ComandasHistorico: React.FC = () => {
  const history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [arrayComandas, setArrayComandas] = useState<any>([]);
  const [comandaId, setComandaId] = useState("");
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [comandapk, setComandapk] = useState("");
  const [comandask, setComandask] = useState("");
  const [comandagsi2sk, setComandagsi2sk] = useState("");
  const [showCopiado, setShowCopiado] = useState(false);
  const [listaClientesFiltro, setListaClientesFiltro] = useState<any>([]);
  const [pesquisaData, setPesquisaData] = useState<any>("Pesquisar");
  const [pesquisaNome, setPesquisaNome] = useState<any>("Pesquisar");
  const [showLoadingPage, setShowLoadingPage] = useState(true);
  const [showModalData, setShowModalData] = useState(false);
  const popover = useRef<HTMLIonPopoverElement>(null);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [mostartAlerta] = useIonAlert();
  const { loja, setLoja } = useContext(MessageContext);
  const [showModalFiltro, setShowModalFiltro] = useState(false);

  const [filtroCampoSelecionadoComanda, setFiltroCampoSelecionadoComanda] = useState<any>();
  const [filtroMostrarDataComanda, setFiltroMostrarDataComanda] = useState<any>();
  const [filtroPesquisaComanda, setFiltroPesquisaComanda] = useState<any>();

  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const [showActionSheet, setShowActionSheet] = useState(false);

  useEffect(() => {
    // primeira vez q entra e quando troca de loja
    console.log("entrou useEffect mudou loja - comandas", loja);
    setFiltroCampoSelecionadoComanda("data");
    setFiltroMostrarDataComanda(true);
    carregarComandasSegmentadaData("");
    setPesquisaData('Pesquisar');
  }, []);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
  });

  const filtroSelectionChanged = (filtroClientes: string) => {
    if (filtroClientes !== "") {
      setFiltroPesquisaComanda(filtroClientes);
      const cliente = listaClientesFiltro.find((prospect: any) => prospect.pk === filtroClientes);
      setPesquisaNome(cliente.nome);
    } else {
      setFiltroPesquisaComanda("");
      setPesquisaNome("Pesquisar");
    }
    carregarComandasSegmentadaData(filtroClientes);
    setShowModalFiltro(false);
  };

  async function lerCLientesFiltro() {
    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof tkn === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23"));

    console.log("lojas", loja);
    setShowLoadingPage(true);
    const resp = await fetch(montarUrl.replace("{perfil}", "cliente"), {
      method: "GET",
      headers: [
        ["token", token]
      ],
      credentials: 'include'
    });

    let equipe = await resp.json();
    setShowLoadingPage(false);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    } else {
      let lista = equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1);
      setListaClientesFiltro(lista);
    }
  }

  function trocarCampoFiltro(e: any) {
    setFiltroCampoSelecionadoComanda(e.detail.value);

    if (e.detail.value === 'data') {
      console.log('entrou data');
      setFiltroMostrarDataComanda(true);
    } else {
      console.log('entrou nome');
      lerCLientesFiltro();
      setFiltroMostrarDataComanda(false);
    }
    setPesquisaNome("Pesquisar");
    setPesquisaData("Pesquisar");
    setFiltroPesquisaComanda("");
    setArrayComandas([]);
  }

  function doRefresh(event: any) {
    carregarComandasSegmentadaData(filtroPesquisaComanda);
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  function carregarServicos(comandaid: any) {
    history.push("../projetos/projetosservicos", { id: comandaid.replaceAll(/#/g, "%23") });
  }

  function carregarProdutos(comandaid: any) {
    history.push("../projetos/projetosprodutos", { id: comandaid.replaceAll(/#/g, "%23") });
  }

  async function gerarCupom(comandaid: any) {
    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof tkn === "string") { token = tkn; }
    console.log("comandaid", comandaid);

    setShowLoadingPage(true);
    const resp = await fetch(mesaGerarCupom.url.replaceAll("{id}", comandaid.replaceAll(/#/g, "%23")), {
      method: mesaGerarCupom.method,
      headers: [
        ["token", token]
      ],
      credentials: 'include'
    });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom',
        message: '<strong>Cupom</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      });
    }
    setShowLoadingPage(false);
  }

  async function gerarCupomPdf(comandaid: any) {
    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof tkn === "string") { token = tkn; }
    console.log("comandaid", comandaid);

    setShowLoadingPage(true);
    const resp = await fetch(projetoGerarCupomPdf.url.replaceAll("{id}", comandaid.replaceAll(/#/g, "%23")), {
      method: projetoGerarCupomPdf.method,
      headers: [
        ["token", token]
      ],
      credentials: 'include'
    });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom PDF',
        message: '<strong>Cupom PDF</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      });
    }
    setShowLoadingPage(false);
  }

  function preencheCard(value: any, indice: any) {
    return (
      <IonCard className={"cardComanda"} key={"comanda" + indice + value.pk}>
        <IonCardHeader id={value.pk} style={{ margin: 0 }}>

          <IonItem style={{ width: "100%", borderRadius: "10px 10px 0 0" }} lines="none">
            {/* <IonLabel style={{ margin: 0 }} slot="start">
              {value.nomeComanda}
            </IonLabel> */}

          </IonItem>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow key={"comandarow1" + indice + value.pk}>
              <IonCol>
                <div className="comanda">
                  <IonLabel className="operacaoTexto">Cliente</IonLabel>
                  <IonLabel className="comandaTexto">{value.clienteNome}</IonLabel>
                </div>
              </IonCol>
            </IonRow>



            {/* <IonRow hidden={window.localStorage.getItem("esconderValoresComanda") == "true" || !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")}>
              <IonCol key={"comandarow5" + indice + value.pk}>
                <div className="operacao">
                  <IonLabel position="fixed">Itens</IonLabel>


                  <IonChip slot="end" color='primary' style={{ padding: "7px", margin: "0px", fontWeight: 'bold', fontSize: "24px" }} onClick={(e) => {
                    carregarProdutos(value.pk);
                  }}>
                    <IonIcon icon={logoUsd} style={{ height: "20px" }}></IonIcon>
                    <IonLabel style={{ width: "auto" }}
                      className="ion-text-wrap">{value.valorTotalProdutos || "0,00"}</IonLabel>
                  </IonChip>

                </div>
              </IonCol>
            </IonRow> */}


            <IonRow hidden={window.localStorage.getItem("esconderValoresComanda") == "true" || (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")}>
              <IonCol key={"comandarow6" + indice + value.pk}>
                <div className="operacao">
                  <IonLabel position="fixed">Itens</IonLabel>


                  <IonChip slot="end" color='primary' style={{ padding: "7px", margin: "0px", fontWeight: 'bold', fontSize: "24px" }} onClick={(e) => {
                    carregarServicos(value.pk);
                  }}>
                    <IonIcon icon={logoUsd} style={{ height: "20px" }}></IonIcon>
                    <IonLabel style={{ width: "auto" }}
                      className="ion-text-wrap">{value.valorTotalServicos || "0,00"}</IonLabel>
                  </IonChip>

                </div>
              </IonCol>
            </IonRow>

            <IonRow hidden={window.localStorage.getItem("esconderValoresComanda") == "true"}>
              <IonCol key={"comandarow5" + indice + value.pk}>
                <div className="operacao" style={{ alignItems: 'center' }}>
                  <IonLabel position="fixed" style={{ fontSize: "24px", fontWeight: 'bold' }}>TOTAL</IonLabel>
                  <div style={{ display: 'flex', alignItems: 'center', padding: "7px", margin: "0px", fontWeight: 'bold', fontSize: "24px" }}>
                    <IonIcon icon={logoUsd} style={{ height: "20px" }}></IonIcon>
                    <IonLabel style={{ width: "auto" }}
                      className="ion-text-wrap">{value.valorTotal || "0,00"}</IonLabel>
                  </div>
                </div>
              </IonCol>
            </IonRow>

          </IonGrid>

          <IonItem lines="none">

            <IonChip style={{ fontSize: "18px", borderRadius: '8px' }} slot="end" color='primary' onClick={() => { setComandaId(value.pk); gerarCupom(value.pk);}}>
              <IonIcon icon={printOutline}></IonIcon>
              <IonLabel>Cupom</IonLabel>
            </IonChip>
          </IonItem>

        </IonCardContent>
      </IonCard >
    )
  }

  const loadItens = () => {

    if (arrayComandas === undefined || arrayComandas.length === 0) {
      return (
        <IonCard key="primeira">
          <IonCardHeader key="primeiraheader" >
            <IonCardTitle>Neste momento não existe histórico.</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      )
    }

    return arrayComandas.map((value: any, index: any, arr: any) =>

      (!arr[index - 1]) ?
        (<div key={"divcomandapai" + index}>
          <IonCard className={"card"} key={"comanda" + index}>
            <IonCardHeader key={"headercomanda" + index}>
              <IonCardTitle key={"titulocomanda" + index}>
                <div key={"divcomanda" + index} className={"cabecalho"} >{new Date(value.dataCadastro).toLocaleString('pt-BR').substring(0, 10)}</div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
          {preencheCard(value, index)}
        </div>)
        :
        (((new Date(value.dataCadastro).toLocaleString('pt-BR').substring(0, 10) !== new Date(arr[index - 1].dataCadastro).toLocaleString('pt-BR').substring(0, 10)))) ?
          (<div key={"divcomandapai" + index}>
            <IonCard className={"card"} key={"comanda" + index}>
              <IonCardHeader key={"headercomanda" + index}>
                <IonCardTitle key={"titulocomanda" + index}>
                  <div key={"divcomanda" + index} className={"cabecalho"} >{new Date(value.dataCadastro).toLocaleString('pt-BR').substring(0, 10)}</div>
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
            {preencheCard(value, index)}
          </div>
          ) :
          (
            <div key={"divcomandapai" + index}>
              {preencheCard(value, index)}
            </div>
          )
    );
  }


  async function carregarComandasSegmentadaData(data: any, lojasParam?: any) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      console.log("valor", data);
      console.log("filtroCampoSelecionadoComanda", filtroCampoSelecionadoComanda);
      let valor = data;
      if (data) {
        valor = data.replaceAll(/#/g, "%23");
      }
      const resp: Response = await fetch(urlBase.url + "/projetos/historico?campo=" + (filtroCampoSelecionadoComanda || "data") +
        "&valor=" + valor +
        "&filtro=comandas" +
        "&lojas=" + Buffer.from(lojasParam || loja).toString('base64'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          setShowLoadingPage(false);
          history.replace("/login/");
        }

        if (res && res.LastEvaluatedKey) {
          setComandapk(res.LastEvaluatedKey.pk);
          setComandask(res.LastEvaluatedKey.sk);
          setComandagsi2sk(res.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setDesabilitarInfiniteScroll(true);
        }

        setArrayComandas(res.Items);
        setShowLoadingPage(false);
      });
    }
    consultar();
  }

  async function carregarMaisComandas($event: CustomEvent<void>) {

    await carregarMaisComandasChamada().then(() => {
    });

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function carregarMaisComandasChamada() {
    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let valor = filtroPesquisaComanda;
    if (filtroPesquisaComanda) {
      valor = filtroPesquisaComanda.replaceAll(/#/g, "%23");
    }


    console.log(valor);

    const res: Response = await fetch(urlBase.url + "/projetos/historico?campo=" + (filtroCampoSelecionadoComanda || "data") +
      "&filtro=comandas" +
      "&valor=" + valor +
      "&isk=" + comandagsi2sk +
      "&pk=" + comandapk +
      "&sk=" + comandask +
      "&lojas=" + Buffer.from(loja).toString('base64'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (comandas) => {

        if (comandas.Items) {
          for (let i = 0; i < comandas.Items.length; i++) {
            arrayComandas.push(comandas.Items[i]);
          }

          if (comandas.LastEvaluatedKey) {
            setComandapk(comandas.LastEvaluatedKey.pk);
            setComandask(comandas.LastEvaluatedKey.sk);
            setComandagsi2sk(comandas.LastEvaluatedKey.gsi2sk);
            setDesabilitarInfiniteScroll(false);
          }
          else {
            setComandapk("");
            setComandask("");
            setComandagsi2sk("");
            setDesabilitarInfiniteScroll(true);
          }
        }

      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");

  }

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setShowModalData(true);
  };

  function getButtons() {
    let resultado: any = [
      {
        text: 'Cancelar',
        icon: closeOutline,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      },

    ];

    if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {

      resultado.push(
        {
          text: 'Imprimir TXT',
          icon: printOutline,
          handler: async () => {
            setShowActionSheet(false);
            await gerarCupom(comandaId);
          }
        }
      );

      resultado.push(
        {
          text: 'Imprimir PDF',
          icon: documentOutline,
          handler: async () => {
            setShowActionSheet(false);
            await gerarCupomPdf(comandaId);
          }
        }
      );
    }


    return resultado;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => {
              history.goBack();
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>

          <IonTitle>Histórico {(window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas")}</IonTitle>


          <IonSelect interface='popover' style={{ fontSize: "14px" }} name='Filtros' title='Filtros' value={filtroCampoSelecionadoComanda} multiple={false} cancelText="Cancelar" okText="Ok" onIonChange={e => { trocarCampoFiltro(e); }} slot="end">
            <IonSelectOption value="data">Data</IonSelectOption>
            {/* <IonSelectOption value="nome">Clientes</IonSelectOption> */}
          </IonSelect>

          <IonButton style={{ width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={filtroMostrarDataComanda} onClick={e => { setShowModalFiltro(true); }}>
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={filtroPesquisaComanda != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaNome} </IonLabel>
          </IonButton>

          <IonButton style={{ zIndex: '0', width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={!filtroMostrarDataComanda} onClick={e => { openPopover(e); }} >
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={filtroPesquisaComanda != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaData} </IonLabel>

          </IonButton>

          <IonModal isOpen={showModalFiltro} onDidDismiss={() => setShowModalFiltro(false)} >
            <AppTypeaheadItem
              title="Clientes"
              camposFiltro="nome,telefone"
              campo="nome"
              items={listaClientesFiltro}
              selectedItem={filtroPesquisaComanda}
              onSelectionCancel={() => setShowModalFiltro(false)}
              onSelectionChange={filtroSelectionChanged}
            />
          </IonModal>
        </IonToolbar>
      </IonHeader>


      <IonContent fullscreen scrollEvents={true} ref={contentRef} color='warning'>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className='ComandasSlides'>
          {loadItens()}
        </div>

        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisComandas(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais comandas..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonToast
          isOpen={showCopiado}
          onDidDismiss={() => setShowCopiado(false)}
          message="Campo copiado."
          duration={3000}
        />

        <div className="content">

        </div>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />
      </IonContent>

      <IonPopover ref={popover}
        isOpen={showModalData}
        onDidDismiss={() => setShowModalData(false)} >
        <IonDatetime
          presentation='date'
          onIonChange={e => {
            if (e.detail.value) {
              let data = e.detail.value.toString().substring(0, 10);
              const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                Date.parse(data)
              );
              setPesquisaData(dataFormatada);
              setFiltroPesquisaComanda(data);
              carregarComandasSegmentadaData(data);
            }
          }}></IonDatetime>

      </IonPopover >

      <IonActionSheet
        backdropDismiss={true}
        translucent={true}
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        // cssClass='my-custom-class'        
        buttons={getButtons()}
      />

    </IonPage>
  );
};

export default ComandasHistorico;
