// import '@capacitor-community/http';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { arrowBack, clipboardOutline, desktopOutline, peopleOutline, personOutline, searchOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './MenuCadastros.css';
import iconMoto from "./imgs/moto.svg";

type Props = {
    abrirMenu: boolean;
}

const MenuCadastros: React.FC<Props> = (props: Props) => {

    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [textToast, setTextToast] = useState("");
    const [showToast2, setShowToast2] = useState(false);
    const [showPerfil, setShowPerfil] = useState(false);

    const [perfil, setPerfil] = useState<any>("clientes");
    const { loja, setLoja } = useContext(MessageContext);

    const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
    const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
    const { nomeMenu, setNomeMenu } = useContext(MessageContext);

    useIonViewDidEnter(() => {
        setEsconderMenu(true);
        setNomeMenu("");

    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton icon={arrowBack} text=''></IonBackButton>
                    </IonButtons>
                    {/* <IonButtons slot="start">
                        <IonButton>
                            <IonIcon slot="icon-only" icon={create} />
                        </IonButton>
                    </IonButtons> */}
                    <IonTitle slot="start">Cadastros</IonTitle>

                </IonToolbar>
            </IonHeader>

            <IonContent className="conta">
                {/* <IonListHeader lines="full" hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'}>Cadastros</IonListHeader> */}
                <IonItem lines="none" button routerLink="/empresa/equipe" routerDirection="forward" detail hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'}>
                    <IonIcon color='medium' slot="start" icon={peopleOutline} />
                    <IonLabel>Usuários</IonLabel>
                </IonItem>
                {(acessos?.vendas == 'leitura' || acessos?.vendas == 'edicao') && (
                    <IonItem lines="none" button routerLink="/empresa/prospects" routerDirection="forward" detail hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'}>
                        <IonIcon color='medium' slot="start" icon={searchOutline} />
                        <IonLabel>Prospects</IonLabel>
                    </IonItem>
                )}
                {(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") && (
                    <IonItem lines="none" button routerLink="/empresa/usuarioscaixa" routerDirection="forward" detail hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'}>
                        <IonIcon color='medium' slot="start" icon={desktopOutline} />
                        <IonLabel>{window.localStorage.getItem("nomeInternonomeInternoBalcaoOuCaixa") || "Balcão"}</IonLabel>
                    </IonItem>
                )}
                {(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") && (
                    <IonItem lines="none" button routerLink="/empresa/usuarioscomandas" routerDirection="forward" detail hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'}>
                        <IonIcon color='medium' slot="start" icon={clipboardOutline} />
                        <IonLabel>{window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas"}</IonLabel>
                    </IonItem>
                )}
                <IonItem lines="none" button routerLink="/empresa/clientes" routerDirection="forward" detail hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'}>
                    <IonIcon color='medium' slot="start" icon={personOutline} />
                    <IonLabel>Clientes</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/empresa/prestadores" routerDirection="forward" detail hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'}>
                    <IonIcon color='medium' slot="start" icon={iconMoto} />
                    <IonLabel>Entregadores</IonLabel>
                </IonItem>
                {/* {(window.localStorage.getItem("servicoPrincipal") == "Obras") && (
                    <IonItem lines="none" button routerLink="/empresa/arquitetos" routerDirection="forward" detail hidden={(acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao')}>
                        <IonIcon color='medium' slot="start" icon={briefcaseOutline} />
                        <IonLabel>Arquitetos</IonLabel>
                    </IonItem>
                )} */}
                <IonToast
                    isOpen={showToast}
                    position="middle" //top, bottom, middle
                    onDidDismiss={() => setShowToast(false)}
                    message={textToast}
                    duration={3000}
                />
            </IonContent>

        </IonPage>
    );
};

export default MenuCadastros;
