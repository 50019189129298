import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './TipoConta.css';

const TipoConta: React.FC = () => {
  const history = useHistory();
  const [accountType, setAccountType] = useState<'cpf' | 'cnpj'>('cpf');
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text='' />
          </IonButtons>
          <IonTitle>Criar sua conta</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="TipoConta">
        <div className="content-wrapper">
          <IonLabel className="titulo">Qual <b>tipo de conta</b> deseja criar?</IonLabel>
          <div className="radio-container">
            <IonRadioGroup value={accountType} onIonChange={e => setAccountType(e.detail.value)}>
              <IonItem lines="none" className="center-item">
                <IonLabel>Autônomo (CPF)</IonLabel>
                <IonRadio slot="start" value="cpf" />
              </IonItem>
              <IonItem lines="none" className="center-item">
                <IonLabel>Empresa (CNPJ)</IonLabel>
                <IonRadio slot="start" value="cnpj" />
              </IonItem>

            </IonRadioGroup>
          </div>
          <IonButton onClick={() => {
            if (accountType === 'cpf') {
              history.replace("/cadastro");
            } else {
              history.replace("/cadastro-cnpj");
            }
          }}>Próximo</IonButton>
        </div>
        <IonToast
          isOpen={showToast}
          position="middle"
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default TipoConta;
