import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
//import { assinarArquivoOperacao, carregaAssinatura, requestService, urlBase } from '../../../../../Utils/Services';
import "./VendasWorkflowAvancar.css";

import { Toast } from '@capacitor/toast';
import * as icons from 'ionicons/icons';
import { alterarStatusVenda, requestService } from '../../../../../Utils/Services';

type Props = {
  vendaid: string;
  statusWorkflow: string;
  indiceWorkflow: string;
  listaStatus: string[];
  closeAction: Function;
  alterarAction: Function;
};

const VendasWorkflowAvancar: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [novoStatus, setNovoStatus] = React.useState<string>();

  function alterarStatus(pk: any, indice: any, status: any) {

    console.log(pk.replaceAll(/#/g, "%23"));
    console.log(status);
    if (!status || status.trim() == "") {
      setAlertaPreenchimento(true);
      return null;
    }

    let params = {
      indice: indice,
      status: status,
      indiceCard: 0
    };
    console.log("params", params);
    setShowLoading(true);
    requestService(alterarStatusVenda.url.replaceAll("{id}", pk.replaceAll(/#/g, "%23")), {
      method: alterarStatusVenda.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(params),
      credentials: 'include'
    },
      history,
      (response: any) => {
        console.log(response);
        props.alterarAction(response.message.Attributes);
      },
      (error: any) => {
        console.log(error);
        Toast.show({
          text: error.message,
          position: "center",
          duration: "long"
        });
      }).finally(() => {
        setShowLoading(false);
      });



  }


  function readStatus() {

    return props.listaStatus.map((valor: any, index: any) =>


      (valor.status !== props.statusWorkflow) ?
        (<>
          <IonSelectOption key={valor.indice + ":" + valor.status} value={valor.indice + ":" + valor.status}>{Number(index) + 1} - {valor.status}</IonSelectOption>

        </>)
        :
        (
          <>
            <IonSelectOption disabled={true} key={valor.indice + ":" + valor.status} value={valor.indice + ":" + valor.status}>{Number(index) + 1} - {valor.status}</IonSelectOption>

          </>
        )

    )

  };



  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => {
              props.closeAction();
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonItem lines="none" color="primary" >
            <IonTitle className="ion-text-wrap" style={{ "padding-left": "20px", "padding-top": "0px", "padding-bottom": "0px", "text-align": "left" }}>Avançar etapa</IonTitle>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>
        <IonItem lines="none">
          <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={icons.alertCircle} color="danger" />
          <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
        </IonItem>
        <IonItem lines="none" >
          <IonLabel className="ion-text-wrap">Etapa atual <b>{Number(props.indiceWorkflow) + 1} - {props.statusWorkflow}</b></IonLabel>
        </IonItem>
        <IonItem lines="none" >
          <IonLabel className="ion-text-wrap">Escolha para qual etapa deseja alterar:</IonLabel>
          <IonSelect slot="end" value={novoStatus} placeholder="Selecione" onIonChange={e => { console.log("xxxx", e.detail.value); setNovoStatus(e.detail.value); }}>
            {readStatus()}
          </IonSelect>
        </IonItem>


        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton expand="block" size='large' onClick={() => { alterarStatus(props.vendaid, novoStatus?.split(":")[0], novoStatus?.split(":")[1]) }}>Avançar</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );

};

export default ({ vendaid, statusWorkflow, indiceWorkflow, listaStatus, closeAction, alterarAction }: { vendaid: string, statusWorkflow: string, indiceWorkflow: string, listaStatus: string[], closeAction: Function, alterarAction: Function }) => (
  <VendasWorkflowAvancar vendaid={vendaid} statusWorkflow={statusWorkflow} indiceWorkflow={indiceWorkflow} listaStatus={listaStatus} closeAction={closeAction} alterarAction={alterarAction}>
  </VendasWorkflowAvancar>
)