// import '@capacitor-community/http';
import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonDatetime, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPopover, IonRow, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { alteraEquipe, cadastraEquipe, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './UsuariosComandasCad.css';

type MyModalProps = {
  usuario: any;
  inputRef: any;
  closeAction: Function;
  closeActionSimple: Function;
  setCliente: Function;
}

const UsuariosComandasCad: React.FC<MyModalProps> = (props: MyModalProps) => {

  const history = useHistory();

  const { loja } = useContext(MessageContext);

  const [textToast, setTextToast] = useState("");
  const [primeiroFoco, setPrimeiroFoco] = useState(false);
  const [pk, setPk] = useState("");
  const [retornoPk, setRetornoPk] = useState("");
  const [retornoEndereco, setRetornoEndereco] = useState("");
  const [retornoNome, setRetornoNome] = useState("");
  const [cpf, setCPF] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [endereco, setEndereco] = useState("");
  const [dataAniversario, setDataAniversario] = useState("");
  const [dataAniversarioBr, setDataAniversarioBr] = useState("");
  const campoTelefone = useRef<any>(null);
  const [selected, setSelected] = useState<any>("comanda");
  const [selectedColor, setSelectedColor] = useState<any>();
  const [showIncluirUsuario, setShowIncluirUsuario] = useState(false);
  const [alteracao, setAlteracao] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [lojas, setLojas] = useState<string>(loja);
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [checkedAtivo, setCheckedAtivo] = useState(true);
  const [showModalDataCad, setShowModalDataCad] = useState(false);
  const popover1 = useRef<HTMLIonPopoverElement>(null);

  useEffect(() => {
    if (props.usuario) {
      console.log("props.usuario", props.usuario);
      setAlteracao(true);
      setPk(props.usuario.pk);
      setCPF(formatCPF(props.usuario.cpf));
      setNome(props.usuario.nome);
      setSelectedColor(props.usuario.color);
      setEndereco(props.usuario.endereco);
      setCheckedAtivo(props.usuario.statusUsuario === "ativo");
      setEmail(props.usuario.email);
      setTelefone(formataTelefone(props.usuario.telefone));

      if (props.usuario.dataAniversario) {
        setDataAniversario(props.usuario.dataAniversario);
        setDataAniversarioBr(new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
          Date.parse(props.usuario.dataAniversario)
        ));
      }
      setSelected(props.usuario.perfil);
      setLojas(loja);
    }
  }, [props.usuario]);

  useEffect(() => {
    // Define o foco no input quando o componente é montado
    if (props.inputRef.current) {
      props.inputRef.current.focus();
    }
  }, [props.inputRef]);

  function onChangeCPF(e: any) {

    setPrimeiroFoco(true);
    e.persist();
    e.preventDefault();

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCPF(cpfval);

  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    let val = e.target.value;

    const maskPhone = val
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})(\d+?)$/, "$1");
    setTelefone(maskPhone);

  }

  function formatCPF(e: string) {
    let cpfval = e.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    console.log(cpfval);
    return cpfval;
  }

  const openPopoverCad = (e: any) => {
    popover1.current!.event = e;
    setShowModalDataCad(true);
  };

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  function cadastrar() {

    console.log("aaaa", selected);

    if (nome.length == 0) {
      setTextToast("Campo Nome é obrigatório.");
      setShowErro(true);
    }
    else {
      setShowIncluirUsuario(true);

    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} />
          </IonButtons>
          <IonTitle>Cadastro de {window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas"}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList>

                <IonItem lines="none">
                  <IonLabel position="stacked">Nome*</IonLabel>
                  <IonInput placeholder="Digite o nome" value={nome} ref={(ref) => props.inputRef.current = ref} autoCorrect='true' autoCapitalize='true' required onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
                </IonItem>




                <IonItem lines="none" hidden={!alteracao} >
                  <IonLabel>Ativo</IonLabel>
                  <IonToggle slot='end' checked={checkedAtivo} onIonChange={e => {
                    setCheckedAtivo(e.detail.checked);
                  }} />
                </IonItem>

                <IonItem disabled={alteracao} lines="none" hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 1) ? false : true}>
                  <IonLabel position='stacked'>Lojas</IonLabel>
                </IonItem>
                <IonItem disabled={alteracao} lines="none" hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 1) ? false : true}>
                  <IonLabel position='stacked'></IonLabel>
                  <IonSelect name='Lojas' placeholder='Selecione' title='Lojas' value={lojas} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojas(e.detail.value); }} slot="end">
                    {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                      <IonSelectOption value={valor.empresaLojaId}>{valor.empresaLojaNome}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>

              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert isOpen={showIncluirUsuario}
          onDidDismiss={() => setShowIncluirUsuario(false)}
          header={'Cadastro de ' +
            (window.localStorage.getItem("nomeInternoMesaOuComanda") || "Mesa")}
          message={'Você <strong>confirma</strong> inclusão?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                let dataNiver = "";

                console.log("dataAniversario", dataAniversario);
                if (dataAniversario) {
                  dataNiver = dataAniversario;
                }
                const objectRequest = {
                  cpf: cpf.replace("-", "").replace(".", "").replace(".", ""),
                  pk: pk,
                  nome: nome,
                  email: email,
                  endereco: endereco,
                  color: selectedColor,
                  telefone: "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
                  perfil: selected,
                  interno: true,
                  statusUsuario: checkedAtivo ? "ativo" : "inativo",
                  empresaMarca: localStorage.getItem("empresaMarca") || "",
                  dataAniversario: dataNiver,
                  lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
                    return lojas.includes(value.empresaLojaId);
                  })
                }


                if (!alteracao) {

                  requestService(cadastraEquipe.url, {
                    method: cadastraEquipe.method,
                    headers: [["token", localStorage.getItem("token") || ""]],
                    credentials: 'include',
                    body: JSON.stringify(objectRequest)
                  },
                    history,
                    (response: any) => {
                      setRetornoPk(response.usuario.pk);
                      setRetornoEndereco(response.usuario.endereco);
                      setRetornoNome(response.usuario.nome);

                      setNome("");
                      setCPF("");
                      setSelectedColor("");
                      setEndereco("");
                      setTelefone("");
                      setCheckedAtivo(true);
                      setEmail("");
                      setDataAniversario("");
                      setSelected("usuario");
                      setTextToast("Cadastro realizado com sucesso.");
                      setShowSucesso(true);

                      if (props.setCliente) props.setCliente(response.usuario.pk);
                    },
                    (error: any) => {
                      setTextToast(error.message);
                      setShowErro(true);
                    });
                }
                else {
                  requestService(alteraEquipe.url, {
                    method: alteraEquipe.method,
                    headers: [["token", localStorage.getItem("token") || ""]],
                    credentials: 'include',
                    body: JSON.stringify(objectRequest)
                  },
                    history,
                    (response: any) => {
                      setNome("");
                      setCPF("");
                      setSelectedColor("");
                      setEndereco("");
                      setTelefone("");
                      setCheckedAtivo(true);
                      setEmail("");
                      setDataAniversario("");
                      setSelected("usuario");
                      setTextToast("Alteração realizada com sucesso.");
                      setShowSucesso(true);
                    },
                    (error: any) => {
                      setTextToast(error.message);
                      setShowErro(true);
                    });
                }
              }
            }
          ]}
        />



        <IonPopover ref={popover1}
          isOpen={showModalDataCad}
          onDidDismiss={() => setShowModalDataCad(false)} >
          <IonContent>
            <IonDatetime

              presentation='date'

              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataAniversarioBr(dataFormatada);
                  setDataAniversario(data);
                }
              }}></IonDatetime>
          </IonContent>
        </IonPopover >


        <IonAlert isOpen={showSucesso}
          onDidDismiss={() => { setShowSucesso(false); }}
          header={'Sucesso'}
          message={textToast}
          backdropDismiss={false}
          buttons={[
            {
              text: 'OK',
              handler: () => { setShowSucesso(false); props.closeAction(retornoPk, retornoEndereco, retornoNome); }
            }
          ]} />

        <IonAlert isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />

        <IonLoading
          cssClass='my-custoDm-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          message={'Incluindo...'}

        />

      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton disabled={!botaoAtivo} onClick={() => cadastrar()} expand="block" type="submit">SALVAR</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default ({ usuario, inputRef, closeAction, closeActionSimple, setCliente }: { usuario: any, inputRef: any, closeAction: Function, closeActionSimple: Function, setCliente: Function }) => (
  <UsuariosComandasCad usuario={usuario} inputRef={inputRef} closeAction={closeAction} closeActionSimple={closeActionSimple} setCliente={setCliente} >
  </UsuariosComandasCad>
)