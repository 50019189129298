import { IonAlert, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonRadio, IonRadioGroup, IonRow, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBack, openOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
//import { assinarArquivoOperacao, carregaAssinatura, requestService, urlBase } from '../../../../../Utils/Services';
import { UserPhoto } from '../../../../../components/usePhotoGallery';
import "./Formulario.css";

import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { Toast } from '@capacitor/toast';
import imageCompression from 'browser-image-compression';
import * as icons from 'ionicons/icons';
import Signature from "signature_pad";
import { carregaFormulario, registrarFoto, removerFotoFormulario, requestService, salvarFormulario } from '../../../../../Utils/Services';

type Props = {
  operacaoid: string;
  closeAction: Function;
  encerrarAction: Function;
};

const Formulario: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [dadosOperacao, setDadosOperacao] = useState<any>([]);
  const [operacaoCarregada, setOperacaoCarregada] = useState(false);
  const [showModalConfirmarAssinatura, setShowModalConfirmarAssinatura] = useState(false);
  const [showAssinatura, setShowAssinatura] = useState(false);
  const [arquivoIndiceParaAssinar, setArquivoIndiceParaAssinar] = React.useState<string>();
  const [showAlertConfirmarAssinatura, setShowAlertConfirmarAssinatura] = useState(false);
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [categorias, setCategorias] = useState<any>([]);
  const [fotos, setFotos] = useState<any>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [checkedTermo, setCheckedTermo] = useState(false);
  const [signaturePad, setSignaturePad] = useState<any>(null);
  const [savedSignature, setSavedSignature] = useState<any>(null);
  const [caminhoParaExcluir, setCaminhoParaExcluir] = React.useState<string>();
  const [caminhoIndiceParaExcluir, setCaminhoIndiceParaExcluir] = React.useState<string>();
  const [showAlertConfirmarExclusaoFoto, setShowAlertConfirmarExclusaoFoto] = useState(false);


  const readyPad = () => {
    let wrapper = document.getElementById("signature-pad");
    let canvas = wrapper?.querySelector("canvas");
    if (canvas) {

      let tempSignaturePad = new Signature(canvas, {
        backgroundColor: "rgb(255, 255, 255)"
      });
      setSignaturePad(tempSignaturePad);
    }
  };

  const handleClear = () => {
    signaturePad.clear();
  };

  function carregarOperacao() {
    if (props.operacaoid != "" && props.operacaoid != undefined) {
      if (!operacaoCarregada) {
        setOperacaoCarregada(true);
        var token: string = "";
        var tkn = localStorage.getItem("token");
        if (typeof (tkn) === "string") { token = tkn; }
        const getoperacao = async () => {




          setShowLoading(true);



          requestService(carregaFormulario.url.replace("{id}", props.operacaoid), {
            method: carregaFormulario.method,
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            credentials: 'include'
          },
            history,
            (response: any) => {

              console.log(response);
              if (response.Item) {
                console.log(response);
                preencherDadosOperacao(response.Item);

              }
              setShowLoading(false);
            },
            (error: any) => {
              console.log(error);
              setShowLoading(false);
              if (error.status === 426) { // atualizar versao
                history.replace("/atualizar");
              }
              else {
                history.replace("/login/");
              }
            });

        }

        getoperacao();
      }
    }
    return "";
  }

  function preencherDadosCampoFotos(operacao: any) {




    let preencherFotos: any[] = [];
    let newPhotosOperacao: UserPhoto[] = [];

    operacao.formulario?.map((value: any, index: any) => {

      if (value.campoFotos && Array.isArray(value.campoFotos) && value.campoFotos.length > 0) {
        let _photos: string[] = value.campoFotos;
        _photos.map(async (valorPath: any, indexPath: any) => {
          newPhotosOperacao.push({ filepath: valorPath, webviewpath: valorPath });
        })
        preencherFotos.push(newPhotosOperacao);
        newPhotosOperacao = [];

      }
      else {
        preencherFotos.push("");
      }

      dadosOperacao.formulario[index].campoFotos = value.campoFotos;

    });

    setFotos(preencherFotos);
  }

  function preencherDadosOperacao(operacao: any) {

    setDadosOperacao(operacao);

    let preencherCategorias: any[] = [];

    let preencherFotos: any[] = [];
    let newPhotosOperacao: UserPhoto[] = [];

    operacao.formulario?.map((value: any, index: any) => {
      preencherCategorias.push(value.categoria);


      console.log("value.campoFotos ", value.campoFotos)
      if (value.campoFotos && Array.isArray(value.campoFotos) && value.campoFotos.length > 0) {
        let _photos: string[] = value.campoFotos;
        _photos.map(async (valorPath: any, indexPath: any) => {
          newPhotosOperacao.push({ filepath: valorPath, webviewpath: valorPath });
        })
        preencherFotos.push(newPhotosOperacao);
        newPhotosOperacao = [];

      }
      else {
        preencherFotos.push("");
      }

    });

    setCategorias(preencherCategorias);
    setFotos(preencherFotos);
  }

  function preencheFotos(value: any, indice: any) {

    return (
      (fotos[indice]) ?
        (<>

          {fotos[indice].map((photo: any, index: any) => (
            <IonCol size="6" key={index} >
              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '10px', position: 'relative' }}>
                <div style={{ maxWidth: '350px', width: '100%', minHeight: '100px' }}>
                  <a target='_blank' href={photo.webviewpath?.replace("small", "big")}>
                    <img style={{ width: '100%', borderRadius: '5px' }} src={photo.webviewpath?.replace("small", "big")}></img>
                  </a>

                  <IonButton fill='clear' color="tertiary" size="small" expand="block" onClick={() => {
                    setCaminhoParaExcluir(photo.webviewpath); setCaminhoIndiceParaExcluir(indice.toString()); setShowAlertConfirmarExclusaoFoto(true);
                  }}>Excluir</IonButton>


                </div>
              </div>
            </IonCol>
          ))}

        </>) :
        (
          <>
            {/* {preencheCard(value, index)} */}
          </>
        )
    )
  }

  function preencheAssinatura(value: any, indice: any) {

    return (
      (fotos[indice]) ?
        (<>

          {fotos[indice].map((arq: any, index: any) => (
            <IonCol size="6" key={index} >
              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '10px', position: 'relative' }}>
                <div style={{ maxWidth: '350px', width: '100%' }}>
                  <a target='_blank' href={arq.webviewpath?.replace("small", "big")}>
                    <img style={{ width: '100%', borderRadius: '5px' }} src={arq.webviewpath?.replace("small", "big")}></img>
                  </a>
                  <IonButton fill='clear' color="tertiary" size="small" expand="block" onClick={() => {
                    setCaminhoParaExcluir(arq.webviewpath); setCaminhoIndiceParaExcluir(indice.toString()); setShowAlertConfirmarExclusaoFoto(true);
                  }}>Excluir</IonButton>

                </div>
              </div>
            </IonCol>
          ))}

        </>) :
        (
          <>
            {/* {preencheCard(value, index)} */}
          </>
        )
    )
  }

  const takePhoto = async (indice: string) => {

    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      allowEditing: false,
      direction: CameraDirection.Rear,
      source: CameraSource.Prompt,
      promptLabelHeader: 'Fotos',
      promptLabelPhoto: 'Biblioteca de fotos',
      promptLabelPicture: 'Câmera',
      promptLabelCancel: 'Cancelar'
    });

    var binary_string = window.atob(cameraPhoto.base64String || '');
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(bytes.buffer)], {
      type: `image/${cameraPhoto.format}`,
    });

    const file = new File([blob], "Name", {
      lastModified: new Date().getTime(),
      type: blob.type,
    });

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1102,
      useWebWorker: true,
      initialQuality: 0.5
    }
    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB


      let base64 = await getBase64(compressedFile);
      await incluirFoto(indice, base64);

    } catch (error) {
      console.log(error);
    }

  };

  async function getBase64(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(file)
    })
  }

  async function incluirFoto(indice: string, base64: any) {


    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let parametros = {
        mensagem: base64,
        indice: indice
      }

      try {
        setShowLoading(true);

        const resp = await fetch(registrarFoto.url.replaceAll("{id}", props.operacaoid.replace(/#/g, "%23")),
          {
            method: registrarFoto.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(parametros),
            credentials: 'include'
          });

        const response = await resp.json();

        console.log("response", response);
        if (response.Attributes) {
          preencherDadosCampoFotos(response.Attributes);
          //preencherDadosOperacao(response.Attributes);
          console.log("retornoobjetocheio", response);
        }
        setShowLoading(false);


      } catch (error) {
        console.log("erro", error);
        setShowLoading(false);
      }
    }

    atualizar();

  };

  function preencheCard(value: any, index: any) {

    return (
      (<>
        <IonList>
          <IonItem lines="none" hidden={!(value.tipo === "text")} style={{ "width": "100%", "padding": "0px", "margin": "0px" }}>
            <div style={{ "width": "100%", "paddingLeft": "4px", "paddingRight": "8px" }}>
              <IonTextarea style={{ "--background": "#eeeeee" }} rows={4}
                onIonChange={e => {
                  console.log("entrou 1", e.detail.value);
                  value.resposta = e.detail.value;
                }}


                value={value.resposta}
              ></IonTextarea>

            </div>
          </IonItem>

          {/* <IonItem lines="none" hidden={!(value.tipo === "checkbox")} style={{ "width": "100%", "padding": "0px", "margin": "0px" }}>
            <div style={{ "width": "100%" }}>
              <div style={{ "padding": "4px" }}>



      {
        "M": {
          "categoria": {
            "S": "Condensadora"
          },
          "indice": {
            "S": "0"
          },
          "obrigatorio": {
            "S": "sim"
          },
          "opcoes": {
            "L": [
              {
                "M": {
                  "descricao": {
                    "S": "Quartos"
                  },
                  "indice": {
                    "S": "0"
                  },
                  "selecionado": {
                    "S": "sim"
                  }
                }
              },
              {
                "M": {
                  "descricao": {
                    "S": "Sala"
                  },
                  "indice": {
                    "S": "1"
                  },
                  "selecionado": {
                    "S": "sim"
                  }
                }
              }
            ]
          },
          "pergunta": {
            "S": "Quais ambientes tem maquina?"
          },
          "tipo": {
            "S": "checkbox"
          }
        }
      },

                
                {value?.opcoes?.map((item: any, index: any) => (
                  <IonItem key={index}>
                    <IonLabel>{item.descricao}</IonLabel>
                    <IonCheckbox
                      // checked={item && item.selecionado === 'sim'}
                      onIonChange={e => {

                        try {


                          const checked = e.detail.checked;
                          if (value.opcoes && value.opcoes[index]) {
                            console.log("Novas opcoes antes da atualizacao:", value.opcoes);
                            const novasOpcoes = [...value.opcoes];
                            console.log("Item selecionado antes da atualizacao:", novasOpcoes[index].selecionado);
                            novasOpcoes[index].selecionado = checked ? 'sim' : 'nao';
                            console.log("Novas opcoes após a atualizacao:", novasOpcoes);
                            // Atualizar o estado com as novas opções, se necessário
                          }
                        } catch (error) {

                        }
                      }}
                    />
                  </IonItem>
                ))}









                
                <IonItem lines="none" >

                  <IonSelect
                    value={
                      value.opcoes?.filter((valor: any, ind: any) => {
                        if (valor.selecionado == "sim") {
                          return true;
                        }
                      })?.map((a: { indice: any; }) => a.indice)
                    }
                    onIonChange={e => {

                      // console.log("mudou checkbox e.detail.value", e.detail.value);



                      if (Array.isArray(e.detail.value)
                        && Array.isArray(value.opcoes) && value.opcoes.length > 0) {

                        console.log("11111mudou checkbox e.detail.value");
                        value.opcoes.map((valor: any, ind: any) => {
                          value.opcoes[ind].selecionado = "nao";
                        });
                      }

                      if (Array.isArray(e.detail.value)
                        && Array.isArray(value.opcoes) && value.opcoes.length > 0) {
                        console.log("22222mudou checkbox e.detail.value");
                        e.detail.value.map((indSelecionado: any, ind: any) => {
                          value.opcoes[indSelecionado].selecionado = "sim";
                        });
                      }

                      console.log(" value?.opcoes", value?.opcoes);
                    }}
                    placeholder="Selecione" multiple={true}>
                    {value?.opcoes?.map((item: any, index: number) => (

                      <IonSelectOption value={item.indice}>{item.descricao}</IonSelectOption>

                    ))}
                  </IonSelect>
                </IonItem>
              </div>
            </div>

          </IonItem> */}

          <IonItem lines="none" hidden={!(value.tipo === "select")} style={{ "width": "100%", "padding": "0px", "margin": "0px" }}>

            {/* <IonSelect
                value={
                  value.opcoes?.find((valor: any, ind: any) => {
                    if (valor.selecionado == "sim") {
                      return true;
                    }
                  })?.indice
                }
                onIonChange={e => {
                  if (Array.isArray(value.opcoes) && value.opcoes.length > 0) {
                    value.opcoes.map((valor: any, ind: any) => {
                      value.opcoes[ind].selecionado = "nao";
                    });

                    value.opcoes[e.detail.value].selecionado = "sim";
                  }
                }
                } placeholder="Selecione" multiple={false}>
                {value?.opcoes?.map((item: any, index: number) => (

                  <IonSelectOption value={item.indice}>{item.descricao}</IonSelectOption>

                ))}
              </IonSelect> */}

            <IonRadioGroup
              value={
                value.opcoes?.find((valor: any) => valor.selecionado === "sim")?.indice
              }
              onIonChange={(e: any) => {
                if (Array.isArray(value.opcoes) && value.opcoes.length > 0) {
                  value.opcoes.forEach((valor: any) => {
                    valor.selecionado = "nao";
                  });

                  value.opcoes[e.detail.value].selecionado = "sim";
                }
              }}
            >
              {value?.opcoes?.map((item: any, index: number) => (
                <IonItem lines="none" key={index} style={{ "padding": "0px", "margin": "0px" }}>
                  <IonLabel className="ion-text-wrap" >{item.descricao}</IonLabel>
                  <IonRadio slot="start" value={item.indice} />
                </IonItem>
              ))}
            </IonRadioGroup>

          </IonItem>

          <IonItem lines="none" hidden={!(value.tipo === "image")} style={{ "width": "100%", "padding": "0px", "margin": "0px" }}>



            <IonList lines="full" class="ion-no-margin">
              <IonGrid>
                <IonRow>
                  {preencheFotos(value, index)}
                </IonRow>
              </IonGrid>
            </IonList>

          </IonItem>

          <IonItem lines="none" hidden={!(value.tipo === "signature")} style={{ "width": "100%", "padding": "0px", "margin": "0px" }}>
            <IonList lines="full" class="ion-no-margin">
              <IonGrid>
                <IonRow>

                  {preencheAssinatura(value, index)}
                </IonRow>
              </IonGrid>
            </IonList>

          </IonItem>
        </IonList >


      </>)
    )
  }

  const assinarDocumento = async () => {

    if (!checkedTermo) {
      Toast.show({
        text: "Você precisa aceitar os Termos de uso para assinar o documento.",
        position: "center", duration: "long"
      });
    }
    else if (signaturePad.toData().length == 0) {
      Toast.show({
        text: "Você precisa assinar o documento.",
        position: "center", duration: "long"
      });
    }
    else {
      setShowAlertConfirmarAssinatura(true);
    }

  }

  function salvar() {

    console.log("encerrou", dadosOperacao);


    let validado = true;


    let respostas: string[] = [];

    dadosOperacao?.formulario?.forEach(function (item: any, index: number) {
      console.log("item.tipo", item.tipo);

      if (item.obrigatorio == "sim") {
        if (item.tipo == "text") {
          if (!item.resposta || item.resposta.trim() == "") {
            validado = false;
            respostas.push((index + 1).toString());
            return;
          }
        } else if (item.tipo == "select") {
          let selecionado = item.opcoes?.find((valor: any, ind: any) => {
            if (valor.selecionado == "sim") {
              return true;
            }
          });

          if (selecionado == undefined) {
            console.log("obrigatorio2");
            validado = false;
            respostas.push((index + 1).toString());
            return;
          }
        } else if (item.tipo == "image" || item.tipo == "signature") {
          console.log("****************item.campoFotos", item.campoFotos);
          let selecionadoFoto = (Array.isArray(item.campoFotos) && item.campoFotos.length > 0);
          if (!selecionadoFoto) {
            console.log("obrigatorio3");
            validado = false;
            respostas.push((index + 1).toString());
            return;
          }
        }
      }
    });

    if (!validado) {
      salvarFormularioAPI("nao");
      Toast.show({
        text: respostas.join(', ') + " não preenchida(s). Não pode encerrar a operação.",
        position: "center", duration: "long"
      });
    }
    else {
      salvarFormularioAPI("sim");
      props.encerrarAction();
    }
  }

  function salvarFormularioAPI(formularioRespondido: any) {
    let parametros = {
      formulario: dadosOperacao.formulario,
      formularioRespondido: formularioRespondido      
    };
    console.log("params", parametros);
    requestService(salvarFormulario.url.replaceAll("{id}", props.operacaoid.replace(/#/g, "%23")),
      {
        method: salvarFormulario.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(parametros),
        credentials: 'include'
      },
      history,
      (response: any) => {

        if (response) {

          console.log("****************response.Attributes salvarFormulario", response.Attributes);

          //preencherDadosOperacao(response.Attributes);
        }

      },
      (error: any) => {
        console.log(error);
      });

  }

  function deletarFoto(url: any, indice: any) {




    const delFoto = async () => {
      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }

      setShowLoading(true);
      const resp = await fetch(removerFotoFormulario.url.replace("{id}", props.operacaoid.replace(/#/g, "%23")),
        {
          method: removerFotoFormulario.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'photo': url, 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      //if (resp.status === 400 || resp.status === 401) history.replace("/login/");

      console.log("retorno", retorno);


      if (retorno.Attributes) {


        preencherDadosCampoFotos(retorno.Attributes);
        //preencherDadosOperacao(retorno.Attributes);


      }
      setShowLoading(false);
    }

    let validado = true
    if (dadosOperacao.formulario[indice] && dadosOperacao.formulario[indice].obrigatorio == "sim") {

      if (dadosOperacao.formulario[indice]?.campoFotos && Array.isArray(dadosOperacao.formulario[indice]?.campoFotos) && dadosOperacao.formulario[indice]?.campoFotos.length == 1) {
        validado = false;
      }
    }

    if (!validado) {

      Toast.show({
        text: "Não é possivel excluir, esse item é obrigatório.",
        position: "center", duration: "long"
      });
    }
    else {
      delFoto();
    }


  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <div>
            {carregarOperacao()}
          </div>
          <IonButtons slot="start">
            <IonButton onClick={() => {
              //salvarFormularioAPI();
              props.closeAction();
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonItem lines="none" color="primary" >
            <IonTitle className="ion-text-wrap" style={{ "padding-left": "20px", "padding-top": "0px", "padding-bottom": "0px", "text-align": "left" }}>{dadosOperacao ? dadosOperacao.nomeFormulario : ""}</IonTitle>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>
        <div>
          {
            (dadosOperacao?.formulario == undefined) ?
              (
                <div style={{ textAlign: 'center' }}>
                  <IonLabel>Não foi criado o relatório pela empresa</IonLabel>
                </div>
              )
              : (<></>)

          }
          {

            dadosOperacao?.formulario?.map((value: any, index: any) => (
              (index > 0 && (categorias[index] == categorias[index - 1])) ?
                (
                  <IonList>
                    <IonItem style={{ marginLeft: '0px' }}>
                      <IonLabel>{index + 1} - {value.pergunta}{value.obrigatorio == "sim" ? "*" : ""}</IonLabel>

                      <IonIcon hidden={value.tipo != "image"} src={icons.addCircle} color="primary" onClick={async () => { await takePhoto(index.toString()).catch((e) => { console.log("cancelado"); }); }}></IonIcon>
                      <IonIcon hidden={value.tipo != "signature"} src={icons.addCircle} color="primary" onClick={async () => {
                        salvarFormularioAPI("nao");
                        setArquivoIndiceParaAssinar(index.toString()); setShowModalConfirmarAssinatura(true);
                      }}>
                      </IonIcon>

                    </IonItem>
                    {preencheCard(value, index)}
                  </IonList>
                ) :
                (


                  <IonList>
                    <IonListHeader lines="full">
                      <IonLabel><b>{value.categoria}</b></IonLabel>
                    </IonListHeader>
                    <IonItem style={{ marginLeft: '0px' }}>
                      <IonLabel>{index + 1} - {value.pergunta}{value.obrigatorio == "sim" ? "*" : ""}</IonLabel>

                      <IonIcon hidden={value.tipo != "image"} src={icons.addCircle} color="primary" onClick={async () => { await takePhoto(index.toString()).catch((e) => { console.log("cancelado"); }); }}></IonIcon>
                      <IonIcon hidden={value.tipo != "signature"} src={icons.addCircle} color="primary" onClick={async () => {
                        salvarFormularioAPI("nao");
                        setArquivoIndiceParaAssinar(index.toString()); setShowModalConfirmarAssinatura(true);
                      }}>
                      </IonIcon>

                    </IonItem>
                    {preencheCard(value, index)}


                  </IonList>

                )))
          }

        </div>

        <IonModal isOpen={showModalConfirmarAssinatura} onDidDismiss={() => { setShowModalConfirmarAssinatura(false); setShowAssinatura(false); setCheckedTermo(false); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="light">
              <div className="input" style={{ display: 'block', alignItems: 'center', paddingLeft: '10px', fontSize: "10px" }}>
                <IonCheckbox style={{ 'margin-right': '8px', "color": "white" }} checked={checkedTermo} onIonChange={e => {

                  setCheckedTermo(e.detail.checked);
                  if (e.detail.checked) {
                    setShowAssinatura(true);
                    setBotaoAtivo(true);
                  }
                  else {
                    setShowAssinatura(false);
                    setBotaoAtivo(false);
                  }
                  readyPad();
                }}
                />
                Eu li e aceito os&nbsp;<a style={{ "color": "red" }} target='_blank' href={'https://xeguei-plt-welcome.s3.sa-east-1.amazonaws.com/termos/cliente' + (window.localStorage.getItem("marca") || '') + '/Termos_de_uso_assinatura_eletronica.pdf'}>Termos de Uso <IonIcon src={openOutline} /></a>&nbsp;para&nbsp;assinaturas&nbsp;eletrônicas
              </div>
              <IonButtons slot="start" onClick={() => { setShowModalConfirmarAssinatura(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <div hidden={!showAssinatura}>
              <div id="signature-pad" style={{ "textAlign": "center" }}>

                <canvas className="signature-canvas" height="580" ></canvas>
              </div>
            </div>

          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton fill='clear' size="small" disabled={!botaoAtivo} onClick={() => handleClear()} type="submit">Limpar</IonButton>
              <IonButton slot="end" size="small" disabled={!botaoAtivo} onClick={() => { assinarDocumento() }}>Assinar</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>


        <IonAlert isOpen={showAlertConfirmarAssinatura}
          onDidDismiss={() => setShowAlertConfirmarAssinatura(false)}
          header={'Confirmar assinatura'}
          message={'Você <strong>confirma</strong> a assinatura desse documento?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {


                setSavedSignature(signaturePad.toDataURL());
                setBotaoAtivo(false);
                const assinarDoc = async () => {



                  Toast.show({
                    text: "Aguarde a geração da assinatura.",
                    position: "center", duration: "long"
                  });

                  const resp = await fetch(registrarFoto.url.replace("{id}", props.operacaoid.replace(/#/g, "%23")),
                    {
                      method: registrarFoto.method,
                      headers: [
                        ["token", localStorage.getItem("token") || ""]
                      ],
                      body: JSON.stringify({
                        'indice': arquivoIndiceParaAssinar,
                        'mensagem': signaturePad.toDataURL()
                      }),
                      credentials: 'include'
                    });
                  const retorno = await resp.json();

                  console.log("retorno assinatura", retorno);


                  if (retorno.Attributes) {
                    preencherDadosCampoFotos(retorno.Attributes);
                    //preencherDadosOperacao(retorno.Attributes);
                    Toast.show({
                      text: "Documento assinado com sucesso.",
                      position: "center", duration: "long"
                    });

                  }
                  else {
                    Toast.show({
                      text: "Ocorreu um erro ao assinar o documento, tente novamente mais tarde.",
                      position: "center", duration: "long"
                    });
                  }

                  setShowModalConfirmarAssinatura(false);
                }


                assinarDoc();

              }

            }
          ]}
        />


        <IonAlert isOpen={showAlertConfirmarExclusaoFoto}
          onDidDismiss={() => setShowAlertConfirmarExclusaoFoto(false)}
          header={'Confirmar Exclusão'}
          message={'Você <strong>confirma</strong> excluir essa imagem?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                deletarFoto(caminhoParaExcluir, caminhoIndiceParaExcluir);
                setShowAlertConfirmarExclusaoFoto(false);
              }

            }
          ]}
        />


        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton hidden={dadosOperacao?.formulario == undefined}
            onClick={() => salvar()}
            expand="block" type="submit">SALVAR</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );

};

export default ({ operacaoid, closeAction, encerrarAction }: { operacaoid: string, closeAction: Function, encerrarAction: Function }) => (
  <Formulario operacaoid={operacaoid} closeAction={closeAction} encerrarAction={encerrarAction}>
  </Formulario>
)