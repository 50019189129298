import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { Buffer } from 'buffer';
import { arrowBack } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { alterarProjetosItemProdutosEmpresa, empresaCarregar, inserirProjetosItemProdutosEmpresa, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import "./ProjetosProdutosAdicionar.css";


type MyModalProps = {
  idprojeto: any;
  value: any;
  inputRefSearchAdicionar: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const ProjetosProdutosAdicionar: React.FC<MyModalProps> = (props: MyModalProps) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [descricao, setDescricao] = useState("");
  const [categoria, setCategoria] = useState("");
  const [quantidade, setQuantidade] = useState<any>();
  const [largura, setLargura] = useState<any>();
  const [comprimento, setComprimento] = useState<any>();
  const [unidadeMedida, setUnidadeMedida] = useState("");
  const [valor, setValor] = useState<any>();
  const [desconto, setDesconto] = useState<any>();
  const [subtotal, setSubtotal] = useState<any>();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));

  const [buscarAdicionar, setBuscarAdicionar] = useState("");
  const [listaItemCatalogoProdutosAdicionar, setListaItemCatalogoProdutosAdicionar] = useState<any>([]);
  const [filteredItemsAdicionar, setFilteredItemsAdicionar] = useState<any[]>([]);

  const [listaCategoria, setListaCategoria] = useState<any>([]);

  useEffect(() => {
    console.log("entrou no useEffect");
    carregarEmpresa();
  }, []);


  useEffect(() => {
    // Define o foco no input quando o componente é montado
    if (props.inputRefSearchAdicionar.current) {
      props.inputRefSearchAdicionar.current.focus();
    }
  }, [props.inputRefSearchAdicionar]);


  useEffect(() => {
    // Define o foco no input quando o componente é montado

    console.log("*********props.value", props.value);
    if (props.value) {
      setDescricao(props.value.descricao);
      setCategoria(props.value.categoria);


      setQuantidade(props.value.quantidade.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

      if (props.value.unidadeMedida == "m2") {
        setLargura(props.value.largura.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));


        setComprimento(props.value.comprimento.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      }

      setUnidadeMedida(props.value.unidadeMedida);

      setValor(props.value.valor.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

      setDesconto(props.value.desconto.toString());
      setSubtotal(props.value.subtotal);
    }

  }, [props.value]);



  useEffect(() => {

    try {
      calcularSubtotal(valor, quantidade, unidadeMedida, largura, comprimento, desconto);
    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [largura, comprimento, quantidade, valor, desconto]);


  const calcularSubtotal = (
    valor: any,
    quantidade: any,
    unidadeMedida: any,
    largura?: any,
    comprimento?: any,
    desconto?: any
  ) => {
    try {
      const valorNumerico = parseFloat(valor?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o valor para número
      let quantidadeNumerica = parseFloat(quantidade?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a quantidade para número
      if (unidadeMedida === "m2") {
        let larg = parseFloat(largura?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a largura para número
        let comp = parseFloat(comprimento?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o comprimento para número
        quantidadeNumerica = larg * comp;

        if (largura && comprimento) {
          setQuantidade((larg * comp).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
      }
      let descontoNumerico = 0;
      if (desconto) {
        descontoNumerico = parseFloat(desconto.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o desconto para número
      }

      console.log("valorNumerico", valorNumerico);
      console.log("quantidadeNumerica", quantidadeNumerica);
      console.log("descontoNumerico", descontoNumerico);

      if (!valorNumerico || !quantidadeNumerica) {
        setSubtotal("0,00");
        return;
      }

      // Calcula o valor total sem desconto
      let valorTotal = valorNumerico * quantidadeNumerica;
      // Aplica o desconto
      valorTotal -= (valorTotal * descontoNumerico / 100);

      let valorTotalFormatado = (valorTotal.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

      setSubtotal(valorTotalFormatado);

      return valorTotal;
    } catch (error) {
      console.log("erro ao calcular", error);
    }
  };

  function carregarEmpresa() {

    const consultar = async () => {

      requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          // console.log(response);
          //setArea(response.Item.area);

          setListaItemCatalogoProdutosAdicionar(response.Item.catalogoProdutos || []);



          setListaCategoria(response.Item.categoriaProdutos || []);

          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function validarCampo(valor: any, mensagem: any) {
    if (!valor || valor === 0 || valor.trim() === "") {
      setTextToast(mensagem);
      setShowToast(true);
      return false;
    }
    return true;
  }

  const resetFields = () => {
    setBuscarAdicionar("");
    setDescricao("");
    setCategoria("");
    setQuantidade("");
    setLargura("");
    setComprimento("");
    setUnidadeMedida("");
    setValor("");
    setDesconto("");
    setSubtotal("0,00");
  };

  function validarCampos(
    descricao: any,
    categoria: any,
    unidadeMedida: any,
    valor: any,
    desconto: any,
    subtotal: any,
    largura?: any,
    comprimento?: any,
    quantidade?: any
  ): boolean {
    if (
      !validarCampo(descricao, "O campo descrição é obrigatório.") ||
      !validarCampo(categoria, "O campo categoria é obrigatório.") ||
      !validarCampo(unidadeMedida, "O campo unidade de medida é obrigatório.") ||
      !validarCampo(valor, "O campo valor é obrigatório.") ||
      !validarCampo(subtotal, "O campo subtotal é obrigatório.")
    ) {
      return false;
    }

    if (unidadeMedida === "m2") {
      if (
        !validarCampo(largura, "O campo largura é obrigatório.") ||
        !validarCampo(comprimento, "O campo comprimento é obrigatório.")
      ) {
        return false;
      }
    } else {
      if (!validarCampo(quantidade, "O campo quantidade é obrigatório.")) {
        return false;
      }
    }

    return true;
  }

  function cadastrarAdicionarProduto() {

    if (!validarCampos(descricao, categoria, unidadeMedida, valor, desconto, subtotal, largura, comprimento, quantidade)) {
      return false;
    }

    cadastrarDireto(descricao, categoria, quantidade, unidadeMedida, valor, desconto, subtotal);
  }

  function cadastrarDireto(descricaoValue: any, categoriaValue: any, quantidadeValue: any, unidadeMedidaValue: any, valorValue: any, descontoValue: any, subtotalValue: any) {

    console.log("indice", props.value.indice);
    if (props.value.indice) {
      const objectRequest = {
        descricao: descricaoValue,
        categoria: categoriaValue,
        quantidade: quantidadeValue,
        largura: largura,
        comprimento: comprimento,
        unidadeMedida: unidadeMedidaValue,
        valor: valorValue,
        desconto: descontoValue || 0,
        subtotal: subtotalValue,
        indice: props.value.indice
      }
      // console.log("objectRequest", objectRequest);

      setShowLoading(true);
      requestService(
        alterarProjetosItemProdutosEmpresa.url.replace("{id}", props.idprojeto.replaceAll("#", "%23")),
        {
          method: alterarProjetosItemProdutosEmpresa.method,
          body: JSON.stringify(objectRequest),
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
        history,
        (response: any) => {
          // console.log(response);
          if (response) {
            props.closeAction(response.message, true);

            setTextToast("Alteração realizada com sucesso.");
            setShowToast(true);

          } else if (response.status === 400) {
            console.log(response);
            setTextToast("Ocorreu um erro ao efetuar a alteração.");
            setShowToast(true);

          }
        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao efetuar a alteração.");
          setShowToast(true);
        })
        .finally(() => {
          setShowLoading(false);
        });
    } else {
      const objectRequest = {
        descricao: descricaoValue,
        categoria: categoriaValue,
        quantidade: quantidadeValue,
        largura: largura,
        comprimento: comprimento,
        unidadeMedida: unidadeMedidaValue,
        valor: valorValue,
        desconto: descontoValue || 0,
        subtotal: subtotalValue,
      }
      // console.log("objectRequest", objectRequest);

      setShowLoading(true);
      requestService(
        inserirProjetosItemProdutosEmpresa.url.replace("{id}", props.idprojeto.replaceAll("#", "%23")),
        {
          method: inserirProjetosItemProdutosEmpresa.method,
          body: JSON.stringify(objectRequest),
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
        history,
        (response: any) => {
          // console.log(response);

          // console.log("response", response);
          if (response) {
            resetFields();
            props.closeAction(response.message, false);

            setTextToast("Cadastro realizado com sucesso.");
            setShowToast(true);

          } else if (response.status === 400) {
            console.log(response);
            setTextToast("Ocorreu um erro ao efetuar o cadastro.");
            setShowToast(true);

          }
        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);
        })
        .finally(() => {
          setShowLoading(false);
          if (props.inputRefSearchAdicionar.current) {
            props.inputRefSearchAdicionar.current.focus();
          }
        });
    }

  }

  const handleSearchAdicionar = (e: any) => {
    setBuscarAdicionar(e.target.value);
    const searchTerm = e.target.value?.toLowerCase();
    if (searchTerm === "") {
      setFilteredItemsAdicionar([]);
      return;
    }

    const isInteger = /^\d+$/.test(searchTerm);

    const filtered = listaItemCatalogoProdutosAdicionar.filter((item: any) =>
      isInteger ? item.codigo === searchTerm : item.codigo.includes(searchTerm) || item.nome.toLowerCase().includes(searchTerm)
    );
    setFilteredItemsAdicionar(filtered);
  };

  function readCategoria() {
    return listaCategoria.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"listacategoria" + index} value={value.nome}>{value.nome}</IonSelectOption>
    )
    )
  };


  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setValor(v);
  }

  function onChangeQuantidade(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setQuantidade(v);
  }

  function onChangeLargura(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setLargura(v);
  }

  function onChangeComprimento(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setComprimento(v);
  }


  function onChangeDesconto(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    // Remove qualquer coisa que não seja um dígito
    v = v.replace(/\D/g, '');

    // Limita o desconto a 100%
    if (parseInt(v) > 100) {
      v = '100';
    }

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setDesconto(v);
  }


  const handleSelectItem = (item: any) => {
    setDescricao(item.nome);
    setCategoria(item.categoria);
    setQuantidade("1");
    setUnidadeMedida(item.unidadeMedida);
    setValor(item.valor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setDesconto("0");
    setBuscarAdicionar("");
    setFilteredItemsAdicionar([]);
  };


  return (

    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
              props.closeActionSimple();
            }} />
          </IonButtons>
          <IonTitle>Adicionar produto</IonTitle>


        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonSearchbar
          onIonInput={handleSearchAdicionar}
          placeholder="Digite o código ou nome"
          value={buscarAdicionar} ref={props.inputRefSearchAdicionar}
        />

        <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: filteredItemsAdicionar.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
          {filteredItemsAdicionar.map((item: any, index: any) => (
            <IonItem lines="none" key={"filtro" + index} button onClick={() => handleSelectItem(item)}>
              <IonLabel>{item.nome} (R$ {item.valor})</IonLabel>
            </IonItem>
          ))}
        </IonList>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList lines="full">
                <IonItem>
                  <IonLabel position="stacked">Descrição*</IonLabel>
                  <IonInput
                    placeholder="Digite a descrição"
                    autoCorrect='true' autoCapitalize='true'
                    value={descricao}
                    required
                    onIonInput={(e: any) => setDescricao(e.target.value)}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Categoria*</IonLabel>
                  <IonSelect
                    value={categoria}
                    placeholder="Selecione"
                    onIonChange={e => { setCategoria(e.detail.value); }}
                  >
                    {readCategoria()}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Valor*</IonLabel>
                  <IonInput
                    type="tel"
                    maxlength={14}
                    placeholder="Digite o valor"
                    value={valor}
                    onKeyUp={onChangeValor}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Unidade de medida*</IonLabel>
                  <IonSelect
                    value={unidadeMedida}
                    placeholder="Selecione"
                    onIonChange={e => { setUnidadeMedida(e.detail.value); }}
                  >
                    <IonSelectOption key="kg" value="kg">Quilograma (kg)</IonSelectOption>
                    <IonSelectOption key="m" value="m">Metro (m)</IonSelectOption>
                    <IonSelectOption key="m2" value="m2">Metro (m²)</IonSelectOption>
                    <IonSelectOption key="unid" value="unid">Unidade (unid)</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem hidden={unidadeMedida != "m2"}>
                  <IonLabel position="stacked">Largura*</IonLabel>
                  <IonInput
                    type="tel"
                    maxlength={14}
                    placeholder="Digite a largura"
                    value={largura}
                    onKeyUp={onChangeLargura}
                  />
                </IonItem>
                <IonItem hidden={unidadeMedida != "m2"}>
                  <IonLabel position="stacked">Comprimento*</IonLabel>
                  <IonInput
                    type="tel"
                    maxlength={14}
                    placeholder="Digite o comprimento"
                    value={comprimento}
                    onKeyUp={onChangeComprimento}
                  />
                </IonItem>
                <IonItem disabled={unidadeMedida == "m2"}>
                  <IonLabel position="stacked">Quantidade*</IonLabel>
                  <IonInput
                    type="tel"
                    maxlength={14}
                    placeholder="Digite a quantidade"
                    value={quantidade}
                    onKeyUp={onChangeQuantidade}
                  />
                </IonItem>

                {/* <IonItem>
          <IonLabel position="stacked">Desconto(%)*</IonLabel>
          <IonInput
            type="tel"
            maxlength={3}
            placeholder="Digite o desconto"
            value={desconto}
            onKeyUp={onChangeDesconto}
          />
        </IonItem> */}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />


      </IonContent>
      <IonFooter className="ion-no-border" style={{ borderTop: '1px solid #ccc' }}>


        <IonList>
          <IonItem lines="none">
            <IonLabel slot="start" style={{ fontWeight: 'bold' }}>Valor Total:</IonLabel>
            <IonLabel slot="end">{subtotal || "0"}</IonLabel>
          </IonItem>


        </IonList>

        <IonToolbar>
          <IonButton onClick={() => cadastrarAdicionarProduto()} expand="block" type="submit">Cadastrar</IonButton>
        </IonToolbar>
      </IonFooter>

    </>
  );


};

export default ({ idprojeto, value, inputRefSearchAdicionar, closeAction, closeActionSimple }: { idprojeto: any, value: any, inputRefSearchAdicionar: any, closeAction: Function, closeActionSimple: Function }) => (
  <ProjetosProdutosAdicionar idprojeto={idprojeto} value={value} inputRefSearchAdicionar={inputRefSearchAdicionar} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </ProjetosProdutosAdicionar>
)