import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList
} from "@chatscope/chat-ui-kit-react";
import { IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonItem, IonLoading, IonPage, IonToast, IonToolbar, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { arrowBack, refresh } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { enviarMensagemCliente, listarMensagensCliente, requestService } from '../../../../../Utils/Services';
import { MessageContext } from "../../../../../contexts/MessageContext";
import './Mensagem.css';
import './styles.min.css';




type Props = {
  abrirMenu: boolean;
  projetoid: string;
  projetonome: string;
  onDismiss: () => void;
};



const Mensagem: React.FC<Props> = (props: Props) => {

  const location = useLocation<any>();
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const history = useHistory();
  const [showCopiado, setShowCopiado] = useState(false);
  const [listaMensagensCliente, setListaMensagensCliente] = useState<any>([]);
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [messageInputValue, setMessageInputValue] = useState("");
  const inputRef = useRef<any>(null);
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [whatspk, setWhatspk] = useState("");
  const [whatssk, setWhatssk] = useState("");
  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  useEffect(() => {

    if (notificacoes && notificacoes.length > 0) {
      console.log("carregou useEffect por notificacoes");
      if (params.projetoid) {
        console.log(params.projetoid);
        carregaMensagensCliente(params.projetoid);
      }

    }
  }, [notificacoes]);

  useEffect(() => inputRef.current.focus());

  useIonViewDidEnter(() => {
    console.log("entrou did enter");
    setEsconderMenu(true);
    setNomeMenu("");
    carregaMensagensCliente(params.projetoid);
  });

  useIonViewDidLeave(() => {
    console.log("saiu da tela");
  });


  let params: any = {
    projetoid: "",
    projetonome: "",
  }
  params = useParams();

  if (location && location.state && location.state.projetoid) {
    params.projetoid = location.state.projetoid;
    params.projetonome = location.state.projetonome;
  }


  if (props.projetoid) {
    params = {
      projetoid: props.projetoid,
      projetonome: props.projetonome
    }
  }

  async function carregaMensagensCliente(pk: string) {



    if (pk) {

      //setShowLoadingPage(true);

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      const resp = await fetch(listarMensagensCliente.url.replace("{id}", pk.replaceAll(/#/g, "%23")),
        {
          method: "GET",
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      let retorno = await resp.json();
      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }
      else {

        if (retorno && retorno.LastEvaluatedKey) {
          setWhatspk(retorno.LastEvaluatedKey.pk);
          setWhatssk(retorno.LastEvaluatedKey.sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setDesabilitarInfiniteScroll(true);
        }

        if (retorno.Items) {
          setListaMensagensCliente(retorno.Items);


        }
      }

      //setShowLoadingPage(false);
    }



  }

  async function carregarMaisMensagensChamada() {



    //setShowLoadingPage(true);

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }


    let id = params.projetoid;
    console.log("id", id);

    const res = await fetch(listarMensagensCliente.url.replace("{id}", id.replaceAll(/#/g, "%23")) +
      "?pk=" + whatspk +
      "&sk=" + whatssk
      ,
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });


    await res
      .json()
      .then(async (mensagens) => {

        if (mensagens.Items) {

          for (let i = 0; i < mensagens.Items.length; i++) {
            listaMensagensCliente.unshift(mensagens.Items[i]);
          }

          if (mensagens.LastEvaluatedKey) {
            setWhatspk(mensagens.LastEvaluatedKey.pk);
            setWhatssk(mensagens.LastEvaluatedKey.sk);
            setDesabilitarInfiniteScroll(false);
          }
          else {
            setWhatspk("");
            setWhatssk("");
            setDesabilitarInfiniteScroll(true);
          }
        }

      })
      .catch(err => console.error(err));

    //setShowLoadingPage(false);

    if (res.status === 400 || res.status === 401) {
      history.replace("/login/");
    }
  }

  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }



  function formatarDataHora(data: Date) {
    return adicionaZeroNaData(data.getDate().toString()) + "/" + (adicionaZeroNaData(data.getMonth() + 1).toString()) + " " + ("0" + data.getHours().toString()).substr(-2) + ":" + ("0" + data.getMinutes().toString()).substr(-2);
  }

  const loadMensagensWhatsapp = () => {




    return listaMensagensCliente.map((value: any, index: any, arr: any) =>



      (value.perfil == "cliente") ?
        (
          <>
            <Message
              model={{
                message: (!value.urlFile ? value.mensagem + ' - ' :
                  (
                    value.mensagem == "image" || value.mensagem == "sticker" ?
                      "<a target='_blank' href=" + value.urlFile + "><img width='240px' src=" + value.urlFile + "><br></a>"
                      :
                      (
                        value.mensagem == "audio" ?
                          ""
                          : "<a target='_blank' href=" + value.urlFile + ">" + value.mensagem + " </a>"
                      )
                  )
                )

                  + (value.perfil != "cliente" ? value.enviadoPor + ' - ' : ' ')
                  + formatarDataHora(new Date(value.dataCadastro)),
                sentTime: value.dataCadastro,
                sender: value.enviadoPor,
                direction: value.perfil != "cliente" ? "outgoing" : "incoming",
                position: "normal"
              }
              }
            >
            </Message >
          </>
        )
        :
        (
          <>
            <Message
              model={{
                message: (!value.urlFile ? value.mensagem + ' - ' :
                  (
                    value.mensagem == "image" || value.mensagem == "sticker" ?
                      "<a target='_blank' href=" + value.urlFile + "><img width='240px' src=" + value.urlFile + "><br></a>"
                      :
                      (
                        value.mensagem == "audio" ?
                          ""
                          : "<a target='_blank' style='color:#ffffff' href=" + value.urlFile + ">" + value.mensagem + " </a>"
                      )
                  )
                )

                  + (value.perfil != "cliente" ? value.enviadoPor + ' - ' : ' ')
                  + formatarDataHora(new Date(value.dataCadastro)),
                sentTime: value.dataCadastro,
                sender: value.enviadoPor,
                direction: value.perfil != "cliente" ? "outgoing" : "incoming",
                position: "normal"
              }
              }
            >
              {/* <Avatar style={{ backgroundColor: value.setor ? value.setor : "#ffffff" }} src={value.perfil == "robo" ? robotIco : person} name={value.nome} /> */}

            </Message >
          </>
        )
    );


  }

  function enviarMensagem() {

    if (params.projetoid) {

      let objRequest = {
        "mensagem": messageInputValue
      };
      console.log("objRequest", objRequest);

      setTextToast("Aguarde o envio da mensagem.");
      setShowToast(true);

      requestService(enviarMensagemCliente.url.replaceAll("{id}", params.projetoid.replaceAll(/#/g, "%23")), {
        method: enviarMensagemCliente.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
        history,
        (response: any) => {
          if (response.message) {
            carregaMensagensCliente(params.projetoid);
          }
        },
        (error: any) => {
          console.log(error);
          setTextToast(error.message);
          setShowToast(true);
        })
      setMessageInputValue("");

    }
  }

  return (
    <IonPage className="Mensagem">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">

          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack(); }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>


          <IonItem lines="none" color="primary" >
            {params.projetonome}
            {/* {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
              valor.empresaLojaId == loja ?
                valor.empresaLojaNome
                :
                ""
            ))} */}
          </IonItem>
        </IonToolbar>


      </IonHeader>


      <IonContent fullscreen={false}>


        <IonFab vertical="top" horizontal="end" style={{ "top": "30px" }} slot="fixed" edge hidden={true} >
          <IonFabButton color="tertiary" onClick={() => {
            carregaMensagensCliente(params.projetoid);

          }} >
            <IonIcon color="light" icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab>



        <div className='MensagemSlides' style={{ height: "100%" }} >
          <MainContainer>
            <ChatContainer>
              {/* <ConversationHeader>
                <ConversationHeader.Content userName={params.projetonome}
                />
              </ConversationHeader> */}
              <MessageList>

                <IonGrid className="ion-text-center">
                  <IonButton color="tertiary" hidden={desabilitarInfiniteScroll} onClick={() => {
                    console.log("carregou mensagens");
                    carregarMaisMensagensChamada();

                  }} >
                    Carregar mais mensagens
                  </IonButton>
                </IonGrid>


                {loadMensagensWhatsapp()}







              </MessageList>

              <MessageInput hidden={acessos?.projetos != 'edicao'}
                ref={inputRef}
                attachButton={false}
                placeholder="Mensagem"
                autoCorrect='true' autoCapitalize='true'
                value={messageInputValue}
                onChange={(val) => setMessageInputValue(val)}
                onSend={() => enviarMensagem()}
              />
            </ChatContainer>

          </MainContainer>

        </div >

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={6000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />

      </IonContent>
    </IonPage>
  );
};

export default Mensagem;