import type { CheckboxCustomEvent } from '@ionic/react';
import {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonSearchbar,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import type { Item } from './types';

interface TypeaheadProps {
    items: Item[];
    campo?: string;
    selectedItems: string[];
    title?: string;
    autoFocus?: boolean;
    onSelectionCancel?: () => void;
    onSelectionChange?: (items: string[]) => void;
}

function AppTypeahead(props: TypeaheadProps) {
    const [filteredItems, setFilteredItems] = useState<Item[]>([...props.items]);
    const [workingSelectedValues, setWorkingSelectedValues] = useState<string[]>([...props.selectedItems]);
    const searchbarRef = useRef<HTMLIonSearchbarElement>(null);

    useEffect(() => {
        if (props.autoFocus && searchbarRef.current) {
            searchbarRef.current.setFocus().catch((err) => {
                console.log("Erro ao definir foco na barra de busca: ", err);
            });
        }
    }, [props.autoFocus]);

    const isChecked = (value: string) => {
        return workingSelectedValues.find((item) => item === value) !== undefined;
    };

    const cancelChanges = () => {
        const { onSelectionCancel } = props;
        if (onSelectionCancel !== undefined) {
            onSelectionCancel();
        }
    };

    const confirmChanges = () => {
        const { onSelectionChange } = props;
        if (onSelectionChange !== undefined) {
            onSelectionChange(workingSelectedValues);
        }
    };

    const searchbarInput = (ev: any) => {
        filterList(ev.target.value);
    };

    const filterList = (searchQuery: string | null | undefined) => {
        if (searchQuery === undefined || searchQuery === null) {
            setFilteredItems([...props.items]);
        } else {
            const normalizedQuery = searchQuery.toLowerCase();
            setFilteredItems(
                props.items.filter((item: any) => {
                    return Object.values(item).some(value => {
                        return typeof item[props.campo || "nome"] === 'string' && item[props.campo || "nome"].toLowerCase().includes(normalizedQuery);
                    });
                })
            );
        }
    };

    const checkboxChange = (ev: CheckboxCustomEvent) => {
        const { checked, value } = ev.detail;
        if (checked) {
            setWorkingSelectedValues([...workingSelectedValues, value]);
        } else {
            setWorkingSelectedValues(workingSelectedValues.filter((item) => item !== value));
        }
    };

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonIcon slot="icon-only" icon={arrowBack} onClick={cancelChanges} />
                    </IonButtons>
                    <IonTitle>{props.title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton fill='solid' color="tertiary" onClick={confirmChanges}>OK</IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar ref={searchbarRef} onIonInput={searchbarInput}></IonSearchbar>
                </IonToolbar>
            </IonHeader>

            <IonContent color="light" class="ion-padding">
                <IonList id="modal-list" inset={true}>
                    {filteredItems.map((item: any) => (
                        <IonItem lines="none" key={item.pk}>
                            <IonCheckbox slot="start" value={item.pk} checked={isChecked(item.pk)} onIonChange={checkboxChange}></IonCheckbox>
                            {`${item[props.campo || "nome"]}`}
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </>
    );
}

export default AppTypeahead;
