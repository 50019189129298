import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonPopover, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { int } from '@zxing/library/esm/customTypings';
import dayjs from 'dayjs';
import { arrowBack, filterOutline } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import 'react-funnel-pipeline/dist/index.css';
import { Chart } from "react-google-charts";
import { useHistory } from 'react-router-dom';
import { urlBase } from '../../../../../Utils/Services';

import { Toast } from '@capacitor/toast';
import { Buffer } from 'buffer';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Notificacoes from '../Notificacoes/Notificacoes';
import './Desempenho.css';

type Props = {
  recarregar: boolean;
};

const Desempenho: React.FC<Props> = (props: Props) => {

  const history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [chartValoresProjetos, setChartValoresProjetos] = useState<any>({});
  const [chartFormaPagamento, setChartFormaPagamento] = useState<any>({});
  const [chartQuantidadeProjetos, setChartQuantidadeProjetos] = useState<any>({});

  const { loja, setLoja } = useContext(MessageContext);
  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);

  const { mensagens, setMensagens } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const [showLoading, setShowLoading] = useState(false);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);


  const [valorTotal, setValorTotal] = useState(0);
  const [quantidadePedidos, setQuantidadePedidos] = useState(0);
  const [ticketMedio, setTicketMedio] = useState(0);


  const [opcaoSelecionada, setOpcaoSelecionada] = useState<any>("hoje");
  const [abrirFiltroPeriodo, setAbrirFiltroPeriodo] = useState<any>();



  const popover1 = useRef<HTMLIonPopoverElement>(null);
  const popover2 = useRef<HTMLIonPopoverElement>(null);


  const [showModalDataInicio, setShowModalDataInicio] = useState(false);
  const [showModalDataFim, setShowModalDataFim] = useState(false);


  const [dataInicio, setDataInicio] = useState<any>("Selecione");
  const [dataInicioBr, setDataInicioBr] = useState<any>("Selecione");
  const [dataFim, setDataFim] = useState<any>("Selecione");
  const [dataFimBr, setDataFimBr] = useState<any>("Selecione");


  // useEffect(() => {
  //   carregarTodos();
  // }, [loja]
  // );


  const openPopoverInicio = (e: any) => {
    popover1.current!.event = e;
    setShowModalDataInicio(true);
  };

  const openPopoverFim = (e: any) => {
    popover2.current!.event = e;
    setShowModalDataFim(true);
  };




  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarTodos();
  });




  const optionsQuantidadeProjetos = {
    title: 'Quantidade de Pedidos',
    // chartArea: { width: '50%' },
    isStacked: true,
    legend: { position: 'top', maxLines: 3 },
    colors: ['#1f77b4', '#ff7f0e', '#2ca02c'], // Cor para 'Projetos', 'Caixa', 'Mesas'
    hAxis: {
      title: 'Data',
      minValue: 0,
      textStyle: {
        fontSize: 10           // Tamanho da fonte pequeno
      },
      slantedText: true, // Permite que o texto seja inclinado
      slantedTextAngle: 90, // Ângulo de 90 graus para texto na vertical
    },
    vAxis: {
      title: 'Quantidade de Pedidos',
      textStyle: {
        fontSize: 10           // Tamanho da fonte pequeno
      },
    }
  };

  const optionsValoresProjetos = {
    title: 'Entradas de Pedidos',
    // chartArea: { width: '50%' },
    isStacked: true,
    legend: { position: 'top', maxLines: 3 },
    colors: ['#1f77b4', '#ff7f0e', '#2ca02c'], // Cor para 'Projetos', 'Caixa', 'Mesas'
    hAxis: {
      title: 'Data',
      minValue: 0,
      textStyle: {
        fontSize: 10          // Tamanho da fonte pequeno
      },
      slantedText: true, // Permite que o texto seja inclinado
      slantedTextAngle: 90, // Ângulo de 90 graus para texto na vertical
    },
    vAxis: {
      title: 'R$',
      textStyle: {
        fontSize: 10          // Tamanho da fonte pequeno
      },
    }
  };

  // const optionsValoresProjetos = {
  //   title: 'Entradas de Pedidos do Mês',
  //   // chartArea: { width: '50%' },
  //   legend: { position: 'right', maxLines: 3 },
  //   colors: ['#1f77b4', '#ff7f0e', '#2ca02c'], // Cor para 'Projetos', 'Caixa', 'Mesas'
  //   hAxis: {
  //     title: 'Data',
  //     minValue: 0,
  //     textStyle: {
  //       fontSize: 8           // Tamanho da fonte pequeno
  //     },
  //   },
  //   vAxis: {
  //     title: 'R$',
  //     textStyle: {
  //       fontSize: 8           // Tamanho da fonte pequeno
  //     },
  //   }
  // };


  function carregarTodos() {

    carregarRelatorioProjetosSegmentada();
  }


  function addDays(date: Date, days: int) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }


  async function carregarRelatorioProjetosSegmentada(opcao: any = "") {
    setOpcaoSelecionada(opcao);
    const consultar = async () => {


      console.log("opcao", opcao);

      // hoje
      let dataFormatada = dayjs(new Date()).format('YYYY-MM-DD');
      setDataInicio(dataFormatada);
      let dataFormatada2 = dayjs(new Date()).format('YYYY-MM-DD');
      setDataFim(dataFormatada2);

      if (opcao == "mesatual") {

        dataFormatada = dayjs().startOf('month').format('YYYY-MM-DD');
        setDataInicio(dataFormatada);
        dataFormatada2 = dayjs(new Date()).format('YYYY-MM-DD');
        setDataFim(dataFormatada2);

      }
      else if (opcao == "ontem") {
        dataFormatada = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        setDataInicio(dataFormatada);
        dataFormatada2 = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        setDataFim(dataFormatada2);
      }
      else if (opcao == "ult7") {
        dataFormatada = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
        setDataInicio(dataFormatada);
        dataFormatada2 = dayjs(new Date()).format('YYYY-MM-DD');
        setDataFim(dataFormatada2);
      }
      else if (opcao == "ult30dias") {
        dataFormatada = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
        setDataInicio(dataFormatada);
        dataFormatada2 = dayjs(new Date()).format('YYYY-MM-DD');
        setDataFim(dataFormatada2);
      }
      // else if (opcao === "mesanterior") {
      //   // Primeiro dia do mês anterior
      //   const dtInicio = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      //   setDataInicio(dtInicio);

      //   // Último dia do mês anterior
      //   const dtFim = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      //   setDataFim(dtFim);

      //   console.log("Mês anterior - Início:", dtInicio);
      //   console.log("Mês anterior - Fim:", dtFim);
      //   dataFormatada = dtInicio;
      //   dataFormatada2 = dtFim;
      // }
      else if (opcao === "custom") {





        if (dataInicioBr == "Selecione" || dataFimBr == "Selecione") {
          setTextToast("Todos os campos de data são obrigatórios.");
          setShowToast(true);
          return null;
        }

        let data1 = new Date(dataInicio).getTime();
        let data2 = new Date(dataFim).getTime();

        var msDiff = data2 - data1;
        var msDiffDay = Math.floor(msDiff / (1000 * 60 * 60 * 24));

        if (msDiffDay < 0) {
          setTextToast("Data fim não pode ser menor que Data início.");
          setShowToast(true);
          return null;
        }
        else if (msDiffDay > 31) {
          setTextToast("Intervalo da data não pode ser maior que 31 dias.");
          setShowToast(true);
          return null;
        }

        // Verificar se o intervalo é maior que 30 dias
        const diferencaDias = dayjs(dataFim).diff(dayjs(dataInicio), 'day');
        if (diferencaDias > 30) {
          Toast.show({
            text: "O período não pode ser maior que 30 dias.",
            position: "center",
            duration: "long"
          });
          return;
        }

        // Se tudo estiver válido, formata as datas
        dataFormatada = dayjs(dataInicio).format('YYYY-MM-DD');
        setDataInicio(dataFormatada);

        dataFormatada2 = dayjs(dataFim).format('YYYY-MM-DD');
        setDataFim(dataFormatada2);
      }





      console.log("dataFormatada", dataFormatada);
      console.log("dataFormatada2", dataFormatada2);

      // let dataFormatada = dayjs().startOf('month').format('YYYY-MM-DD');
      // let dataFormatada = dayjs(new Date()).format('YYYY-MM-DD');
      // let dataFormatada2 = dayjs(addDays(new Date(), +1)).format('YYYY-MM-DD');

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      const urls = [
        "/projetos/relatorio?campo=data" +
        "&valor=" + dataFormatada +
        "&valor2=" + dataFormatada2 +
        "&filtro=Encerrado" +
        "&lojas=" + Buffer.from(loja).toString('base64'),

        "/projetos/relatoriocc?campo=data&filtro2=caixa" +
        "&valor=" + dataFormatada +
        "&valor2=" + dataFormatada2 +
        "&filtro=Encerrado" +
        "&lojas=" + Buffer.from(loja).toString('base64'),

        "/projetos/relatoriocc?campo=data&filtro2=comandas" +
        "&valor=" + dataFormatada +
        "&valor2=" + dataFormatada2 +
        "&filtro=Encerrado" +
        "&lojas=" + Buffer.from(loja).toString('base64'),
      ];

      const fetchRelatorio = async (endpoint: string) => {
        let urlFinal = `${urlBase.url}${endpoint}`;
        const resp: Response = await fetch(urlFinal, {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
        const res = await resp.json();
        return res.Items || [];
      };

      const [projetos, projetosCC, projetosCD] = await Promise.all(
        urls.map(url => fetchRelatorio(url))
      );

      const dataPorDia: any = {};
      const quantidadePorDia: any = {};
      const formaPagamentoTotais: any = {};
      let valorTotal: any = 0.0;
      let quantidade: number = 0;

      // Função para preencher os dias com valor 0
      const preencherDiasComZeros = (dataInicio: string, dataFim: string) => {
        const inicio = dayjs(dataInicio);
        const fim = dayjs(dataFim);

        for (let data = inicio; data.isBefore(fim) || data.isSame(fim, 'day'); data = data.add(1, 'day')) {
          const dataFormatada = data.format('DD/MM/YYYY');
          dataPorDia[dataFormatada] = { projetos: 0, projetosCC: 0, projetosCD: 0 };
          quantidadePorDia[dataFormatada] = { projetos: 0, projetosCC: 0, projetosCD: 0 };
        }
      };

      // Preencher os dias com zeros
      preencherDiasComZeros(dataFormatada, dataFormatada2);

      const processarProjetos = (projetos: any[], tipo: string) => {
        projetos.forEach((item: any) => {

          // const dataFormatada = new Date(item.dataCadastro).toISOString().split("T")[0];
          const dataFormatada = dayjs(item.dataCadastro).format('DD/MM/YYYY');
          if (!dataPorDia[dataFormatada]) {
            dataPorDia[dataFormatada] = { projetos: 0, projetosCC: 0, projetosCD: 0 };
            quantidadePorDia[dataFormatada] = { projetos: 0, projetosCC: 0, projetosCD: 0 };
          }

          // Atualizar os valores diários


          let valor = 0;
          if (typeof item.valorTotal === 'string') {
            dataPorDia[dataFormatada][tipo] += parseFloat(item.valorTotal.replace(',', '.')) || 0;
          }
          else {
            dataPorDia[dataFormatada][tipo] += item.valorTotal || 0;
          }

          quantidadePorDia[dataFormatada][tipo] += 1;

          // Processar formas de pagamento
          let formasPagamento = item.formaPagamento;

          // Caso o campo "formaPagamento" não seja um array, tratá-lo como string
          if (!Array.isArray(formasPagamento)) {
            formasPagamento = [{ pagamento: item.formaPagamento, valor: item.valorTotal }];
          }

          // Somar valores por forma de pagamento
          formasPagamento.forEach((pagamentoObj: any) => {
            const forma = pagamentoObj.pagamento;
            let valor = 0;
            if (typeof item.valorTotal === 'string') {
              valor = parseFloat(pagamentoObj.valor.replace(',', '.')) || 0;
            }
            else {
              valor = pagamentoObj.valor || 0;
            }

            // console.log("valor", valor);
            valorTotal += valor;
            quantidade += 1;
            if (!formaPagamentoTotais[forma]) {
              formaPagamentoTotais[forma] = 0;
            }

            formaPagamentoTotais[forma] += valor;
          });
        });
      };

      processarProjetos(projetos, 'projetos');
      processarProjetos(projetosCC, 'projetosCC');
      processarProjetos(projetosCD, 'projetosCD');

      const dataValoresParaGrafico: any = [["Data", (window.localStorage.getItem("servicoTipoPlural") || "Projetos"), "Balcão", "Mesas"]];
      const dataQuantidadeParaGrafico: any = [["Data", (window.localStorage.getItem("servicoTipoPlural") || "Projetos"), "Balcão", "Mesas"]];

      for (const data in dataPorDia) {
        dataValoresParaGrafico.push([
          data.substring(0, 5),
          dataPorDia[data].projetos,
          dataPorDia[data].projetosCC,
          dataPorDia[data].projetosCD
        ]);

        dataQuantidadeParaGrafico.push([
          data.substring(0, 5),
          quantidadePorDia[data].projetos,
          quantidadePorDia[data].projetosCC,
          quantidadePorDia[data].projetosCD
        ]);
      }


      // Preparar os dados para o gráfico de pizza (formas de pagamento)
      const dataFormaPagamentoParaGrafico: any = [["Forma de Pagamento", "Valor Total"]];
      for (const forma in formaPagamentoTotais) {
        dataFormaPagamentoParaGrafico.push([forma, formaPagamentoTotais[forma]]);
      }

      // Atualizar os estados dos gráficos
      setChartValoresProjetos(dataValoresParaGrafico);
      setChartQuantidadeProjetos(dataQuantidadeParaGrafico);
      setChartFormaPagamento(dataFormaPagamentoParaGrafico); // Gráfico de pizza para formas de pagamento

      setValorTotal(valorTotal);
      setQuantidadePedidos(quantidade);
      const ticketMedio = quantidade > 0 ? valorTotal / quantidade : 0;
      setTicketMedio(ticketMedio);
    };

    return consultar();
  }





  function doRefresh(event: any) {
    carregarTodos();
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }


  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }

  return (
    <IonPage class="relatorio">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">

            < IonButton onClick={() => {
              history.goBack();
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>

          </IonButtons>

          <IonTitle>Desempenho</IonTitle>
        </IonToolbar>


        <IonToolbar>
          <IonGrid>

            <IonRow style={{ alignItems: 'center' }}>
              <IonCol size="10" style={{ padding: "0px", margin: "0px" }}>
                <IonGrid style={{ padding: "0px", margin: "0px" }}>
                  <IonRow>
                    <IonCol size="auto" style={{ padding: "0px", margin: "0px" }}>
                      <IonButton
                        style={{ height: "36px", fontSize: "14px", padding: "0 10px" }}
                        shape="round"
                        color={opcaoSelecionada === "hoje" || opcaoSelecionada === "" ? "danger" : "light"}
                        size="small"
                        expand="block"
                        onClick={() => { carregarRelatorioProjetosSegmentada("hoje") }}>
                        Hoje
                      </IonButton>
                    </IonCol>

                    <IonCol size="auto" style={{ padding: "0px", margin: "0px" }}>
                      <IonButton
                        style={{ height: "36px", fontSize: "14px", padding: "0 10px" }}
                        shape="round"
                        color={opcaoSelecionada === "ontem" ? "danger" : "light"}
                        size="small"
                        expand="block"
                        onClick={() => { carregarRelatorioProjetosSegmentada("ontem") }}>
                        Ontem
                      </IonButton>
                    </IonCol>

                    <IonCol size="auto" style={{ padding: "0px", margin: "0px" }}>
                      <IonButton
                        style={{ height: "36px", fontSize: "14px", padding: "0 10px" }}
                        shape="round"
                        color={opcaoSelecionada === "ult7" ? "danger" : "light"}
                        size="small"
                        expand="block"
                        onClick={() => { carregarRelatorioProjetosSegmentada("ult7") }}>
                        7 dias
                      </IonButton>
                    </IonCol>
                    <IonCol size="auto" style={{ padding: "0px", margin: "0px" }}>
                      <IonButton
                        style={{ height: "36px", fontSize: "14px", padding: "0 10px" }}
                        shape="round"
                        color={opcaoSelecionada === "ult30dias" ? "danger" : "light"}
                        size="small"
                        expand="block"
                        onClick={() => { carregarRelatorioProjetosSegmentada("ult30dias") }}>
                        30 dias
                      </IonButton>
                    </IonCol>
                    <IonCol size="auto" style={{ padding: "0px", margin: "0px" }}>
                      <IonButton
                        style={{ height: "36px", fontSize: "14px", padding: "0 10px" }}
                        shape="round"
                        color={opcaoSelecionada === "mesatual" ? "danger" : "light"}
                        size="small"
                        expand="block"
                        onClick={() => { carregarRelatorioProjetosSegmentada("mesatual") }}>
                        Mês atual
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>

              <IonCol size="2" style={{ textAlign: 'right' }}>
                <IonButton
                  size="small"
                  fill="clear"
                  onClick={() => { setAbrirFiltroPeriodo(!abrirFiltroPeriodo) }}>
                  <IonIcon icon={filterOutline} />
                </IonButton>
              </IonCol>
            </IonRow>




            {/* Linhas para entradas de data */}
            <IonRow style={{ borderTop: "#dddddd 1px solid" }} hidden={!abrirFiltroPeriodo}>
              <IonCol size="12" sizeMd='4' style={{ margin: "0px", padding: "0px" }}>

                <IonItem lines='none'>
                  <IonLabel>Data início</IonLabel>
                  <IonLabel style={{ "color": "#BDBDBD" }} slot="end" onClick={e => { openPopoverInicio(e); }}>{dataInicioBr}</IonLabel>
                </IonItem>

                <IonItem lines='none'>
                  <IonLabel>Data fim</IonLabel>
                  <IonLabel style={{ "color": "#BDBDBD" }} slot="end" onClick={e => { openPopoverFim(e); }}>{dataFimBr}</IonLabel>
                </IonItem>
              </IonCol>
              {/* <IonCol size="4">
                <IonInput
                  type="date"
                  value={dataInicio}
                  placeholder="Data de Início"
                  onIonChange={(e) => setDataInicio(e.detail.value!)}
                />
              </IonCol>
              <IonCol size="4">
                <IonInput
                  type="date"
                  value={dataFim}
                  placeholder="Data de Fim"
                  onIonChange={(e) => setDataFim(e.detail.value!)}
                />
              </IonCol> */}
              <IonCol size="0" sizeMd='5'>
              </IonCol>
              <IonCol size="12" sizeMd='3'>
                <IonButton
                  color="tertiary"
                  size="small"
                  expand="block"
                  onClick={() => {
                    carregarRelatorioProjetosSegmentada("custom");
                  }}
                >
                  Filtrar
                </IonButton>
              </IonCol>
            </IonRow>
            {/* 
            <IonRow>
              <IonCol size="12" className="vertical-center">
                {`Total das vendas entre o dia ${dataInicio} até o dia ${dataFim}`}
              </IonCol>
            </IonRow> */}
          </IonGrid>





        </IonToolbar>
      </IonHeader >
      <IonContent className={"Relatorios"}>

        {/* <IonFab vertical="top" horizontal="end" slot="fixed" hidden={isPlatform("android") || isPlatform("ios")}>
          <IonFabButton color="tertiary" onClick={() => { carregarTodos(); }} >
            <IonIcon color="light" icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab> */}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>





        {/* <div style={{ justifyContent: 'center', paddingTop: '0px', paddingBottom: '0px' }}> */}
        <div className="divDesempenho">




          <IonGrid>



            <IonRow hidden={acessos?.desempenho != 'leitura' && acessos?.desempenho != 'edicao'} >
              <IonCardHeader>
                <IonCardTitle>Acompanhe os principais indicadores</IonCardTitle>
              </IonCardHeader>
            </IonRow>




            <IonRow hidden={acessos?.desempenho != 'leitura' && acessos?.desempenho != 'edicao'} >
              <IonCol size="12" sizeLg='12' sizeXl='12'>
                <IonCard style={{ padding: "0px", margin: "0px" }}>
                  <IonCardContent style={{ padding: "0px", margin: "0px" }}>
                    <IonGrid style={{ width: "100%" }}>


                      <IonRow style={{ width: "100%" }}>
                        <IonCol size="12" sizeMd="4">

                          <p><h2>Número de vendas</h2></p>
                          <h1>{quantidadePedidos}</h1>
                        </IonCol>

                        <IonCol size="12" sizeMd="4">


                          <p><h2>Valor total</h2></p>
                          <h1>R$ {valorTotal.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</h1>


                        </IonCol>

                        <IonCol size="12" sizeMd="4">

                          <p><h2>Ticket médio</h2></p>
                          <h1>R$ {ticketMedio.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</h1>


                        </IonCol>


                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>








            <IonRow hidden={acessos?.desempenho != 'leitura' && acessos?.desempenho != 'edicao'} >
              <IonCardHeader>
                <IonCardTitle>Vendas (Quant.)</IonCardTitle>
              </IonCardHeader>
            </IonRow>

            <IonRow hidden={acessos?.desempenho != 'leitura' && acessos?.desempenho != 'edicao'} >
              <IonCol size="12" sizeLg='12' sizeXl='12'>
                <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                  <IonCardContent>
                    <Chart
                      width='100%'
                      height='300px'
                      chartType="ColumnChart"
                      data={chartQuantidadeProjetos}
                      options={optionsQuantidadeProjetos}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>


            <IonRow hidden={acessos?.desempenho != 'leitura' && acessos?.desempenho != 'edicao'}>
              <IonCardHeader>
                <IonCardTitle>Entradas (R$)</IonCardTitle>
              </IonCardHeader>
            </IonRow>
            <IonRow hidden={acessos?.desempenho != 'leitura' && acessos?.desempenho != 'edicao'}>
              <IonCol size="12" sizeLg='12' sizeXl='12'>
                <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                  {/* <IonCardHeader id={'Projetos por Etapas'}>
                      <IonCardTitle>{(window.localStorage.getItem("nomeLinhaDoTempo") || "Etapas")}</IonCardTitle>
                    </IonCardHeader> */}
                  <IonCardContent>
                    <Chart
                      width='100%'
                      height='300px'
                      chartType="LineChart"
                      data={chartValoresProjetos}
                      options={optionsValoresProjetos}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>


            <IonRow hidden={acessos?.desempenho != 'leitura' && acessos?.desempenho != 'edicao'}>
              <IonCardHeader>
                <IonCardTitle>Formas de Pagamento (R$)</IonCardTitle>
              </IonCardHeader>
            </IonRow>
            <IonRow hidden={acessos?.desempenho != 'leitura' && acessos?.desempenho != 'edicao'}>
              <IonCol size="12" sizeLg='12' sizeXl='12'>
                <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                  <IonCardContent>
                    <Chart
                      width='100%'
                      height='300px'
                      chartType="PieChart"
                      data={chartFormaPagamento}
                      options={{
                        title: 'Distribuição por Forma de Pagamento',
                        // is3D: true,
                        pieSliceText: 'value',
                        legend: { position: 'right' },
                      }}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>





          </IonGrid>


        </div>
        {/* </div> */}

        <div className="content">
          <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
            <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
          </IonModal>

        </div>


        <IonPopover ref={popover1}
          isOpen={showModalDataInicio}
          onDidDismiss={() => setShowModalDataInicio(false)} >
          <IonContent>
            <IonDatetime
              presentation='date'
              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataInicioBr(dataFormatada);
                  setDataInicio(data);
                }
                //setShowModalDataInicio(false);
              }}></IonDatetime>
          </IonContent>
        </IonPopover >

        <IonPopover ref={popover2}
          isOpen={showModalDataFim}
          onDidDismiss={() => setShowModalDataFim(false)} >
          <IonContent>
            <IonDatetime
              presentation='date'
              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataFimBr(dataFormatada);
                  setDataFim(data);
                }
                //setShowModalDataFim(false);
              }}></IonDatetime>
          </IonContent>
        </IonPopover >

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'

        />

      </IonContent>

      {/* <IonFooter>

        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol size="12" className="vertical-center">
                {`Total das vendas entre o dia ${dataInicio} até o dia ${dataFim}`}

              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="2">
                <IonButton color={opcaoSelecionada == "hoje" ? "danger" : "light"} size="small" expand="block" onClick={() => { carregarRelatorioProjetosSegmentada("hoje") }}>Hoje</IonButton>
              </IonCol>
              <IonCol size="2">
                <IonButton color={opcaoSelecionada == "ontem" ? "danger" : "light"} size="small" expand="block" onClick={() => { carregarRelatorioProjetosSegmentada("ontem") }}>Ontem</IonButton>
              </IonCol>
              <IonCol size="2">
                <IonButton color={opcaoSelecionada == "ult7" ? "danger" : "light"} size="small" expand="block" onClick={() => { carregarRelatorioProjetosSegmentada("ult7") }}>Últ. 7 dias</IonButton>
              </IonCol>
              <IonCol size="2">
                <IonButton color={opcaoSelecionada == "ult30dias" ? "danger" : "light"} size="small" expand="block" onClick={() => { carregarRelatorioProjetosSegmentada("ult30dias") }}>Últ. 30 dias</IonButton>
              </IonCol>
              <IonCol size="2">
                <IonButton color={opcaoSelecionada == "mesanterior" ? "danger" : "light"} size="small" expand="block" onClick={() => { carregarRelatorioProjetosSegmentada("mesanterior") }}>Mês Anterior</IonButton>
              </IonCol>
              <IonCol size="2">
                <IonButton color={opcaoSelecionada == "mesanterior" ? "danger" : "light"} size="small" expand="block" onClick={() => { carregarRelatorioProjetosSegmentada("mesanterior") }}>Mês Atual</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>




        </IonToolbar>

      </IonFooter> */}


    </IonPage >
  );
};

export default Desempenho;
