import { Toast } from '@capacitor/toast';
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonTextarea, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { alertCircle, arrowBack } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { uploadCatalogoProdutosEmpresaIfood } from '../../../../../Utils/Services';
import './UploadIfood.css';

type Props = {
  closeAction: Function;
  closeActionSimple: Function;
}

const UploadIfood: React.FC<Props> = (props: Props) => {

  const [showLoading, setShowLoading] = useState(false);
  const [urlIfood, setUrlIfood] = useState<any>("");
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const { loja, setLoja } = useContext(MessageContext);
  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);

  useIonViewDidEnter(() => {

  })

  const handleUpload = async () => {

    if (!urlIfood) {
      setAlertaPreenchimento(true);
      return null;
    }


    const upload = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let params = {
        url: urlIfood
      }

      try {

        setShowLoading(true);

        const resp = await fetch(uploadCatalogoProdutosEmpresaIfood.url.replaceAll("{id}", loja.replaceAll(/#/g, "%23")),
          {
            method: uploadCatalogoProdutosEmpresaIfood.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();

        console.log("response", response);
        if (response.message && response.message.Attributes) {

          Toast.show({
            text: "Importado com sucesso.",
            position: "center",
            duration: "long"
          });

          // dadosEmpresa.catalogoProdutos = response.message.Attributes?.catalogoProdutos;
          // setDadosEmpresa(dadosEmpresa);

          props.closeAction(response.message.Attributes);
        }
        else {
          Toast.show({
            text: response.message || "Ocorreu um erro ao realizar a importação.",
            position: "center",
            duration: "long"
          });
        }
        setShowLoading(false);

      } catch (error) {
        console.log("erro", error);
        Toast.show({
          text: "Ocorreu um erro ao realizar a importação.",
          position: "center",
          duration: "long"
        });
        setShowLoading(false);
      }
    }

    upload();






  }

  return (
    <>

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} />
          </IonButtons>
          <IonTitle>Importar Catalogo iFood</IonTitle>

        </IonToolbar>

      </IonHeader>

      <IonContent class="ion-padding">

        <IonItem lines="none">
          Para importar seus produtos, acesse o aplicativo do iFood e copie a URL da sua loja.
        </IonItem>


        <IonItem lines="none" >

          <IonTextarea
            style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
            rows={4}
            placeholder="Digite o URL"
            value={urlIfood}
            onIonChange={e => setUrlIfood(e.target.value)}
          />

        </IonItem>
        <IonItem lines="none">
          <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
          <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
        </IonItem>


        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
      <IonFooter className="ion-no-border" >
        <IonToolbar>
          <IonButton expand="block" size='large' onClick={() => { handleUpload().catch((e) => { console.log("cancelado"); }); }}>Realizar Importação</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default ({ closeAction, closeActionSimple }: { closeAction: Function, closeActionSimple: Function }) => (
  <UploadIfood closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </UploadIfood>
)
