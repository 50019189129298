import { IonActionSheet, IonAlert, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import "./FinanceiroAlterar.css";

import { Toast } from '@capacitor/toast';
import { Buffer } from 'buffer';
import { arrowBack, closeOutline, pencilOutline, trashOutline } from 'ionicons/icons';
import 'moment/locale/pt-br';
import { alterarFinanceiro, empresaCarregar, excluirFinanceiro, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';

type Props = {
  financeiro: any;
  closeAction: Function;
  closeActionSimple: Function;
};

const FinanceiroAlterar: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [showModalTipoLancamento, setShowModalTipoLancamento] = useState(false);
  const [observacoes, setObservacoes] = useState<any>();
  const [cobranca, setCobranca] = useState<any>();
  const [dataLancamento, setDataLancamento] = useState<any>();
  const [dataLancamentoBr, setDataLancamentoBr] = useState<any>();
  const [nomeProjeto, setNomeProjeto] = useState<any>();


  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [categoria, setCategoria] = useState<any>();
  const [listaCategoria, setListaCategoria] = useState<any>([]);
  const [listaUsuario, setListaUsuario] = useState<any>([]);
  const [projeto, setProjeto] = useState("");
  const [listaProjeto, setListaProjeto] = useState<any>([]);
  const [showModalDataCad, setShowModalDataCad] = useState(false);

  const [financeiro, setFinanceiro] = useState<any>();

  const [valor, setValor] = useState<any>();

  const popover1 = useRef<HTMLIonPopoverElement>(null);

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { loja, setLoja } = useContext(MessageContext);

  const [checkedEnviarMensagem, setCheckedEnviarMensagem] = useState(false);
  const [tipo, setTipo] = useState<string>("");


  const [showActionSheet, setShowActionSheet] = useState(false);
  const [alertConfirmar, setAlertConfirmar] = useState(false);

  let params: any = {
    financeiro: ""
  }

  params = useParams();

  if (location && location.state && location.state.financeiro) {
    params.financeiro = location.state.financeiro;
  }

  if (props.financeiro) {
    params = {
      financeiro: props.financeiro
    }
  }


  useEffect(() => {
    if (props.financeiro != undefined) {
      console.log("entrou useEffect", props.financeiro);
      carregarCombos(props.financeiro.tipoLancamento);

      setCategoria(props.financeiro.categoria);
      setCobranca(props.financeiro.cobranca);
      setObservacoes(props.financeiro.observacoes);
      setProjeto(props.financeiro.projetoid);
      setNomeProjeto(props.financeiro.nomeProjeto);
      setTipo(props.financeiro.tipoLancamento);

      if (props.financeiro.tipoLancamento == "receita") {
        setValor(props.financeiro.valor.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      }
      else {
        setValor((props.financeiro.valor * -1).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      }
      setCheckedEnviarMensagem(props.financeiro.enviarMensagem);

      let data = new Date(props.financeiro.data).toString();
      const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
        Date.parse(data)
      );
      setDataLancamentoBr(dataFormatada);
      setDataLancamento(data);

      setFinanceiro(props.financeiro);

    }
  }, [props.financeiro]
  );

  async function carregarCombos(tipoLancamento: any) {

    const consultarEmpresa = async () => {

      setShowLoading(true);

      requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {

        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log("response_empresa", response);
          if (tipoLancamento == "receita") {
            setListaCategoria(response.Item.categoriaReceita || []);
          }
          else {
            setListaCategoria(response.Item.categoriaDespesa || []);
          }
          setShowLoading(false);

        },
        (error: any) => {
          setShowLoading(false);
          console.log(error);
          history.replace("/login/");
        });
    }


    await consultarEmpresa();

  }

  function validarCamposLancamento() {


    if (categoria.length === 0) {
      setTextToast("Campo Categoria é obrigatório.");
      setShowToast(true);
      return false;
    }

    if (tipo.length === 0) {
      setTextToast("Campo Tipo é obrigatório.");
      setShowToast(true);
      return false;
    }

    if (valor.length === 0) {
      setTextToast("Campo Valor é obrigatório.");
      setShowToast(true);
      return false;
    }


    return true;
  }

  function excluirLancamento() {

    if (!validarCamposLancamento())
      return;




    if (botaoAtivo) {
      setShowLoading(true);
      setBotaoAtivo(false);

      let found;
      found = listaProjeto.find((obj: any) => {
        return obj.pk === projeto;
      });


      requestService(excluirFinanceiro.url.replace("{id}", financeiro.pk.replaceAll(/#/g, "%23")), {
        method: excluirFinanceiro.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {

          console.log("response_alterarFinanceiro", response);
          if (response.message) {
            setShowModalTipoLancamento(false);
            props.closeAction();
            Toast.show({
              text: "Lançamento removido com sucesso!",
              position: "center",
              duration: "long"
            });
          }
        },
        (error: any) => {
          console.log(error);
          setShowLoading(false);
          Toast.show({
            text: error.message,
            position: "center",
            duration: "long"
          });
        })
    }

    setShowLoading(false);
    setBotaoAtivo(true);
  }

  function alterarLancamento() {

    if (!validarCamposLancamento())
      return;




    if (botaoAtivo) {
      setShowLoading(true);
      setBotaoAtivo(false);

      let found;
      found = listaProjeto.find((obj: any) => {
        return obj.pk === projeto;
      });

      

      console.log("found", found);

      let objRequest = {
        "categoria": categoria,
        "observacoes": observacoes,
        "cobranca": cobranca,
        "tipo": tipo,
        //"baixado": checkedBaixa,
        // "data": new Date(dataLancamento),
        "valor": valor,
        // "projeto": projeto || "",
        // "nomeProjeto": found?.nomeProjeto || "",
        // "empresaid": loja || "",
        // "clienteid": found?.clienteid || "",
        "enviarMensagem": checkedEnviarMensagem
      };

      console.log("objRequest", objRequest);

      requestService(alterarFinanceiro.url.replace("{id}", financeiro.pk.replaceAll(/#/g, "%23")), {
        method: alterarFinanceiro.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
        history,
        (response: any) => {

          console.log("response_alterarFinanceiro", response);
          if (response.message) {
            setShowModalTipoLancamento(false);
            props.closeAction();
            Toast.show({
              text: "Lançamento alterado com sucesso!",
              position: "center",
              duration: "long"
            });
          }
        },
        (error: any) => {
          console.log(error);
          setShowLoading(false);
          Toast.show({
            text: error.message,
            position: "center",
            duration: "long"
          });
        })
    }

    setShowLoading(false);
    setBotaoAtivo(true);
  }

  function readCategoria() {
    return listaCategoria.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={value} value={value}>{value}</IonSelectOption>
    )
    )
  };

  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    setValor(v);
  }

  function getButtons() {
    let resultado: any = [
      {
        text: 'Cancelar',
        icon: closeOutline,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      },

    ];

    if (acessos?.financeiro == 'edicao') {

      resultado.push(
        {
          text: "Alterar Lançamento",
          icon: pencilOutline,
          handler: () => {
            alterarLancamento();
          }
        }
      )

      resultado.push(
        {
          text: "Excluir Lançamento",
          icon: trashOutline,
          handler: () => {
            setAlertConfirmar(true);            
          }
        }
      )
    }

    return resultado;
  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { props.closeActionSimple() }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Alterar {tipo}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>
        <IonContent class="ion-padding">
          <IonList lines="full" class="ion-no-margin">

            <IonItem lines="none">
              <IonLabel>Categoria*</IonLabel>
              <IonSelect slot="end" value={categoria} placeholder="Selecione" onIonChange={e => { setCategoria(e.detail.value); }}>
                {readCategoria()}
              </IonSelect>
            </IonItem>

            <IonItem lines={"none"} hidden={nomeProjeto == ""}>
              <IonLabel>{window.localStorage.getItem("servicoTipo") || "Projeto"}*</IonLabel>
              <IonLabel style={{ "color": "#BDBDBD" }} slot="end">{nomeProjeto}</IonLabel>
            </IonItem>

            <IonItem lines="none">
              <IonLabel>Data*</IonLabel>
              <IonLabel style={{ "color": "#BDBDBD" }} slot="end">{dataLancamentoBr}</IonLabel>
            </IonItem>

            <IonItem lines="none">
              <IonLabel>Valor*</IonLabel>
              <IonInput type="tel" maxlength={14} placeholder="Digite o valor" value={valor} onKeyUp={onChangeValor} />
            </IonItem>

            <IonItem lines="none">
              <IonLabel>Observações</IonLabel>
              <IonTextarea style={{ "--background": "#eeeeee", marginLeft: "10px" }} rows={6} value={observacoes} maxlength={250} placeholder="" onIonInput={(e: any) => setObservacoes(e.target.value)}></IonTextarea>
            </IonItem>

            <IonItem lines="none" hidden={tipo == "despesa"}>
              <IonLabel>Enviar Cobrança</IonLabel>
              <IonToggle slot='end' checked={checkedEnviarMensagem} onIonChange={e => {
                setCheckedEnviarMensagem(e.detail.checked);
              }} />

            </IonItem>

            <IonItem lines="none" hidden={checkedEnviarMensagem == false}>
              <IonLabel>Cobrança</IonLabel>
              <IonTextarea style={{ "--background": "#eeeeee", marginLeft: "10px" }} rows={6} value={cobranca} maxlength={250} placeholder="" onIonInput={(e: any) => setCobranca(e.target.value)}></IonTextarea>
            </IonItem>


          </IonList>

          <IonActionSheet
            backdropDismiss={true}
            translucent={true}
            isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            cssClass='my-custom-class'
            buttons={getButtons()}

          />


        </IonContent>


        <div className="content">

          <IonAlert isOpen={alertConfirmar}
            onDidDismiss={() => setAlertConfirmar(false)}
            header={'Remover'}
            message={'Deseja <strong>remover</strong> o lançamento? Esta ação não poderá ser desfeita.'}
            buttons={[
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Confirmar',
                cssClass: 'primary',
                handler: async () => {
                  excluirLancamento();
                }
              }
            ]}
          />

          <IonToast
            isOpen={showToast}
            position="middle" //top, bottom, middle
            onDidDismiss={() => setShowToast(false)}
            message={textToast}
            duration={3000}
          />
        </div>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />


      </IonContent>

      <IonFooter className="ion-no-border" >
        <IonToolbar>
          <IonButton size='large' expand="block" onClick={() => setShowActionSheet(true)}>Ações</IonButton>
        </IonToolbar>
      </IonFooter>

    </>
  );


};

export default ({ financeiro, closeAction, closeActionSimple }: { financeiro: any, closeAction: Function, closeActionSimple: Function }) => (
  <FinanceiroAlterar financeiro={financeiro} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </FinanceiroAlterar>
)