import { IonBadge, IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonReorder, IonReorderGroup, IonRow, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, ItemReorderEventDetail, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { add, arrowBack, pencil, reorderTwo, trash } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { alterarItemCatalogoServicosEmpresa, empresaCarregar, inserirItemCatalogoServicosEmpresa, ordernarItemCatalogoServicosEmpresa, removerItemCatalogoServicosEmpresa, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import "./ServicosCatalogo.css";

type Props = {
  recarregar: boolean;
  onDismiss: () => void;
};

const ServicosCatalogo: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();

  const [showLoading, setShowLoading] = useState(false);
  const [listaItemCatalogoServicos, setListaItemCatalogoServicos] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const [indice, setIndice] = useState("");
  const [nome, setNome] = useState("");
  const [codigo, setCodigo] = useState("");
  const [categoria, setCategoria] = useState("");
  const [valor, setValor] = useState<any>();
  const [unidadeMedida, setUnidadeMedida] = useState("");


  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [listaCategoria, setListaCategoria] = useState<any>([]);

  const [isDisabled, setIsDisabled] = useState(true);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const { recarregarCatalogo, setRecarregarCatalogo } = useContext(MessageContext);

  const inputRef = useRef<any>(null);

  const [mostartAlerta] = useIonAlert();

  let params: any = {
    recarregar: ""
  }
  params = useParams();

  if (location && location.state && location.state.recarregar) {
    params.recarregar = location.state.recarregar;
  }


  if (props.recarregar) {
    params = {
      recarregar: props.recarregar
    }
  }

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarConta();
  });


  function carregarConta() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(empresaCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setListaItemCatalogoServicos(response.Item.catalogoServicos || []);
          setListaCategoria(response.Item.categoriaServicos || []);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }



  function deletarItemCatalogoServicos(indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delItemCatalogoServicos = async () => {
      const resp = await fetch(removerItemCatalogoServicosEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerItemCatalogoServicosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Item do catálogo removido com sucesso.");
        setShowToast(true);
        setListaItemCatalogoServicos(retorno.Item?.Attributes?.catalogoServicos || []);
        if (params.recarregar) {
          setRecarregarCatalogo(true);
        }
      }
      else {
        setTextToast("Ocorreu um erro ao efetuar a exclusão.");
        setShowToast(true);
      }
    }

    delItemCatalogoServicos();

  }


  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    // console.log('Before complete', listaItemCatalogoServicos);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    setListaItemCatalogoServicos(event.detail.complete(listaItemCatalogoServicos));

    // After complete is called the items will be in the new order
    // console.log('After complete', listaItemCatalogoServicos);


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const ordItemCatalogoServicos = async () => {
      const resp = await fetch(ordernarItemCatalogoServicosEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: ordernarItemCatalogoServicosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'catalogoServicos': listaItemCatalogoServicos }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno ordenar", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Ordenação alterada com sucesso.");
        setShowToast(true);
        setListaItemCatalogoServicos(retorno.Item?.Attributes?.catalogoServicos || []);
      }
      else {
        setTextToast("Ocorreu um erro ao ordernar.");
        setShowToast(true);
      }
    }

    ordItemCatalogoServicos();

  }


  const loadItemCatalogoServicos = () => {

    if (listaItemCatalogoServicos == undefined) return;

    if (listaItemCatalogoServicos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonReorderGroup disabled={isDisabled} onIonItemReorder={handleReorder}>
          {listaItemCatalogoServicos.map((value: any, index: any) => (
            <IonItem lines="none" key={"servicos" + index}>


              <IonLabel style={{ fontSize: "12px" }}>{value.codigo}</IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>{value.nome}</IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>{value.categoria}</IonLabel>
              <IonLabel style={{ textAlign: "right", fontSize: "12px" }}>{value.valor?.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}</IonLabel>
              <IonLabel style={{ textAlign: "center", fontSize: "12px" }}>{value.unidadeMedida}</IonLabel>
              <IonLabel></IonLabel>
              <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonReorder slot="end"></IonReorder>
              </div>
              <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonButtons >


                  <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                    onClick={() => {
                      setIndice(value.indice);
                      setNome(value.nome);
                      setCodigo(value.codigo);
                      setCategoria(value.categoria);
                      setValor(value.valor);
                      setUnidadeMedida(value.unidadeMedida);
                      setShowDetalhes(true);

                    }}>
                    <IonIcon color="primary" icon={pencil} />
                  </IonButton>

                  <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                    onClick={() => {
                      mostartAlerta({
                        backdropDismiss: false,
                        header: 'Remover item',
                        message: 'Deseja <strong>remover</strong>?',
                        buttons: [
                          {
                            text: 'Cancelar',
                            role: 'cancel',
                          },
                          {
                            text: 'Confirmar',
                            cssClass: 'primary',
                            handler: () => {
                              deletarItemCatalogoServicos(value.indice);
                            }
                          }
                        ],
                        onDidDismiss: (e) => console.log('did dismiss'),
                      })
                    }}>
                    <IonIcon color="primary" icon={trash} />
                  </IonButton>

                </IonButtons>

              </div>
            </IonItem>

          ))}
        </IonReorderGroup>
      </>
    );
  }

  function cadastrar() {
    if (!nome || nome.trim() == ""
      || !codigo || codigo.trim() == ""
      || !categoria || categoria.trim() == ""
      || !valor || valor.trim() == ""
      || !unidadeMedida || unidadeMedida.trim() == "") {
      setTextToast("Todos os campos são obrigatórios.");
      setShowToast(true);
      return false;
    }
    else {



      console.log("indice", indice);
      if (indice != "") {
        const objectRequest = {
          nome: nome,
          codigo: codigo,
          categoria: categoria,
          valor: valor,
          unidadeMedida: unidadeMedida,
          indice: indice
        }
        console.log("objectRequest", objectRequest);

        let id = loja;

        console.log("id", id);

        requestService(
          alterarItemCatalogoServicosEmpresa.url.replace("{id}", id.replaceAll("#", "%23")),
          {
            method: alterarItemCatalogoServicosEmpresa.method,
            body: JSON.stringify(objectRequest),
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            credentials: 'include'
          },
          history,
          (response: any) => {
            console.log(response);
            if (response) {
              if (params.recarregar) {
                setRecarregarCatalogo(true);
              }
              setTextToast("Alteração realizada com sucesso.");
              setShowToast(true);

              setListaItemCatalogoServicos(response.message.Attributes.catalogoServicos || []);
              setShowDetalhes(false);

            } else if (response.status === 400) {
              console.log(response);
              setTextToast("Ocorreu um erro ao efetuar a alteração.");
              setShowToast(true);

            }
            setShowLoading(false);
          },
          (error: any) => {
            console.log(error);
            setTextToast("Ocorreu um erro ao efetuar a alteração.");
            setShowToast(true);
            setShowLoading(false);
          })
      } else {
        const objectRequest = {
          nome: nome,
          codigo: codigo,
          categoria: categoria,
          valor: valor,
          unidadeMedida: unidadeMedida
        }
        console.log("objectRequest", objectRequest);

        let id = loja;

        console.log("id", id);

        requestService(
          inserirItemCatalogoServicosEmpresa.url.replace("{id}", id.replaceAll("#", "%23")),
          {
            method: inserirItemCatalogoServicosEmpresa.method,
            body: JSON.stringify(objectRequest),
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            credentials: 'include'
          },
          history,
          (response: any) => {
            console.log(response);
            if (response) {
              if (params.recarregar) {
                setRecarregarCatalogo(true);
              }
              setTextToast("Cadastro realizado com sucesso.");
              setShowToast(true);

              setListaItemCatalogoServicos(response.message.Attributes.catalogoServicos || []);
              setShowDetalhes(false);

            } else if (response.status === 400) {
              console.log(response);
              setTextToast("Ocorreu um erro ao efetuar o cadastro.");
              setShowToast(true);

            }
            setShowLoading(false);
          },
          (error: any) => {
            console.log(error);
            setTextToast("Ocorreu um erro ao efetuar o cadastro.");
            setShowToast(true);
            setShowLoading(false);
          })
      }
    }

  }

  function readCategoria() {
    return listaCategoria.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"categoria" + index} value={value}>{value}</IonSelectOption>
    )
    )
  };

  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    setValor(v);
  }

  return (

    <IonPage className="ServicosCatalogo">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Catálogo de serviços</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            setIndice("");
            setNome("");
            setCodigo("");
            setCategoria("");
            setValor("");
            setUnidadeMedida("");
            setShowDetalhes(true);
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>



        <IonList>
          <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>
            <IonLabel style={{ fontSize: "12px" }}>Código</IonLabel>
            <IonLabel style={{ fontSize: "12px" }}>Nome</IonLabel>

            <IonLabel style={{ fontSize: "12px" }}>Categoria</IonLabel>
            <IonLabel style={{ textAlign: "right", fontSize: "12px" }}>Valor</IonLabel>
            <IonLabel style={{ textAlign: "center", fontSize: "12px" }}>Unidade de Medida</IonLabel>
            <IonLabel style={{ textAlign: "right" }}>
              <IonBadge hidden={acessos?.configuracoes != 'edicao'}
                onClick={() => setIsDisabled((current) => !current)}>
                <IonIcon color="medium" icon={reorderTwo}></IonIcon>
              </IonBadge>
            </IonLabel>
          </IonItem>
          {loadItemCatalogoServicos()}



          {/* <IonFab horizontal="end" edge style={{ "top": "0px", "right": "-4px" }} hidden={acessos?.configuracoes != 'edicao'}>
            <IonFabButton size="small" color="tertiary" 
              <IonIcon color="light" icon={menu}></IonIcon>
            </IonFabButton>
          </IonFab> */}
        </IonList>


        {/* <IonButton slot="end" >Ordenar</IonButton> */}



        <IonModal onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowDetalhes(false)} />
                </IonButtons>
                <IonTitle>Novo item</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonList lines="full">


                      {/* Codigo */}
                      <IonItem>
                        <IonLabel position="stacked">Código*</IonLabel>

                        <IonInput
                          placeholder="Digite a código"
                          value={codigo}
                          ref={(ref) => inputRef.current = ref}
                          autoCorrect='true' autoCapitalize='true'
                          required
                          onIonInput={(e: any) => setCodigo(e.target.value)}
                        />

                      </IonItem>

                      {/* Nome */}
                      <IonItem>
                        <IonLabel position="stacked">Nome*</IonLabel>
                        <IonInput
                          placeholder="Digite a nome"
                          value={nome}
                          autoCorrect='true' autoCapitalize='true'
                          required
                          onIonInput={(e: any) => setNome(e.target.value)}
                        />
                      </IonItem>

                      {/* Categoria */}
                      <IonItem>
                        <IonLabel position="stacked">Categoria*</IonLabel>
                        <IonSelect

                          value={categoria}
                          placeholder="Selecione"
                          onIonChange={e => { setCategoria(e.detail.value); }}
                        >
                          {readCategoria()}
                        </IonSelect>
                      </IonItem>

                      <IonItem lines="none">
                        <IonLabel position="stacked">Valor*</IonLabel>
                        <IonInput type="tel" maxlength={14} placeholder="Digite o valor" value={valor} onKeyUp={onChangeValor} />
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Unidade de medida*</IonLabel>
                        <IonSelect
                          value={unidadeMedida}
                          placeholder="Selecione"
                          onIonChange={e => { setUnidadeMedida(e.detail.value); }}
                        >
                          <IonSelectOption key="kg" value="kg">Quilograma (kg)</IonSelectOption>
                          <IonSelectOption key="m" value="m">Metro (m)</IonSelectOption>
                          <IonSelectOption key="m2" value="m2">Metro (m²)</IonSelectOption>
                          <IonSelectOption key="unid" value="unid">Unidade (unid)</IonSelectOption>
                        </IonSelect>
                      </IonItem>

                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonContent>
            <IonFooter className="ion-no-border">
              <IonToolbar>
                <IonButton onClick={() => cadastrar()} expand="block" type="submit">Cadastrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default ServicosCatalogo;