import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonDatetime, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonMenuButton, IonModal, IonPage, IonPopover, IonRefresher, IonRefresherContent, IonRow, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, isPlatform, useIonAlert, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { add, addCircle, arrowBack, arrowForward, call, chatbubbles, checkboxOutline, checkmark, create, documentOutline, grid, mail, notifications, pencil, power, refresh, search } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { finalizarVenda, incluirVenda, listaEquipePorPerfil, requestService, urlBase, vendaGerarOrcamento, vendaRegistrarAnotacao } from '../../../../../Utils/Services';
import AppTypeaheadItem from '../../../../../components/AppTypeaheadItem';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Notificacoes from '../Notificacoes/Notificacoes';
import ProspectsCad from '../ProspectsCad/ProspectsCad';
import VendasCad from '../VendasCad/VendasCad';
import VendasCriarProjeto from '../VendasCriarProjeto/VendasCriarProjeto';
import VendasWorkflowAvancar from '../VendasWorkflowAvancar/VendasWorkflowAvancar';
import './Vendas.css';


const Vendas: React.FC = () => {
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const history = useHistory();
  const [arrayVendas, setArrayVendas] = useState<any>([]);
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [vendapk, setVendapk] = useState("");
  const [vendasFunilk, setVendask] = useState("");
  const [vendagsi2sk, setVendagsi2sk] = useState("");
  const [showCopiado, setShowCopiado] = useState(false);
  const [showModalNovaVenda, setShowModalNovaVenda] = useState(false);
  const [nomeVenda, setNomeVenda] = useState<any>();
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [prospect, setProspect] = useState<any>();
  const [listaProspect, setListaProspect] = useState<any>([]);
  const [listaProspectsFiltro, setListaProspectsFiltro] = useState<any>([]);
  const [pesquisaData, setPesquisaData] = useState<any>("Pesquisar");
  const [pesquisaNome, setPesquisaNome] = useState<any>("Pesquisar");
  const [showLoadingPage, setShowLoadingPage] = useState(true);
  const [showModalLoja, setShowModalLoja] = useState(false);
  const [showModalData, setShowModalData] = useState(false);
  const popover = useRef<HTMLIonPopoverElement>(null);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [mostartAlerta] = useIonAlert();
  const [mostrarToast, fecharToast] = useIonToast();
  const [showDetalhesProspect, setShowDetalhesProspect] = useState(false);
  const { loja, setLoja } = useContext(MessageContext);
  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);
  const { mensagens, setMensagens } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const [dadosVenda, setDadosVenda] = useState<any>();
  const [showAtualizarInformacoes, setShowAtualizarInformacoes] = useState(false);
  const [vendaId, setVendaId] = useState("");
  const [indiceWorkflow, setIndiceWorkflow] = useState("");
  const [statusWorkflow, setStatusWorkflow] = useState("");
  const [listaStatus, setListaStatus] = useState<any>([]);
  const [showWorkflowAvancar, setShowWorkflowAvancar] = useState(false);
  const [showCriarProjeto, setShowCriarProjeto] = useState(false);
  const { filtroCampoSelecionadoVenda, setFiltroCampoSelecionadoVenda } = useContext(MessageContext);
  const { filtroMostrarDataVenda, setFiltroMostrarDataVenda } = useContext(MessageContext);
  const { filtroPesquisaVenda, setFiltroPesquisaVenda } = useContext(MessageContext);
  const { filtroStatusVenda, setFiltroStatusVenda } = useContext(MessageContext);
  const { recarregarVenda, setRecarregarVenda } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const inputRef = useRef<any>(null);
  const inputDetalhesProspectRef = useRef<any>(null);
  const inputAtualizarInformacoesRef = useRef<any>(null);
  const inputCriarProjetoRef = useRef<any>(null);

  const [showModalProspect, setShowModalProspect] = useState(false);
  const [prospectText, setProspectText] = useState<any>();

  useEffect(() => {
    // primeira vez q entra e quando troca de loja
    console.log("entrou useEffect mudou loja - vendas", loja);
    console.log("entrou useEffect mudou loja - vendas", filtroStatusVenda);
    setFiltroCampoSelecionadoVenda("data");
    setFiltroMostrarDataVenda(true);
    setFiltroStatusVenda("Ativo");
    // setRecarregarVenda(false);
    carregarVendasSegmentadaData("Ativo", "");
    setPesquisaData('Pesquisar');
  }, [loja]
  );

  useEffect(() => {
    if (recarregarVenda) {
      carregarVendasSegmentadaData(filtroStatusVenda, filtroPesquisaVenda);
      setRecarregarVenda(false);
    }
  }, [recarregarVenda]
  );

  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("Vendas");
  })


  const prospectSelectionChanged = (pkProspectSelecionado: string) => {
    console.log("pkProspectSelecionado", pkProspectSelecionado);

    const selecionado = listaProspect.find((prospect: any) => prospect.pk === pkProspectSelecionado);

    console.log("prospectSelecionado", selecionado);

    if (selecionado) {
      setProspect(selecionado.pk);
      setProspectText(selecionado.nome);
    }
    setShowModalProspect(false);
  };

  const filtroSelectionChanged = (filtroProspects: string) => {

    if (filtroProspects != "") {
      setFiltroPesquisaVenda(filtroProspects);
      const prospect = listaProspectsFiltro.find((prospect: any) => prospect.pk === filtroProspects);
      setPesquisaNome(prospect.nome);
    }
    else {
      setFiltroPesquisaVenda("");
      setPesquisaNome("Pesquisar");
    }
    carregarVendasSegmentadaData(filtroStatusVenda, filtroProspects);
    setShowModalFiltro(false);
  };

  async function lerCLientesFiltro() {


    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23"))//.concat("?status=ativo");

    console.log("lojas", loja);
    setShowLoadingPage(true);
    const resp = await fetch(montarUrl.replace("{perfil}", "prospect"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });

    let equipe = await resp.json();
    setShowLoadingPage(false);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
      setListaProspectsFiltro(lista);
    }

  }

  function trocarCampoFiltro(e: any) {
    setFiltroCampoSelecionadoVenda(e.detail.value);
    if (e.detail.value === 'data') {
      console.log('entrou data');
      setFiltroMostrarDataVenda(true);


    }
    else {
      console.log('entrou nome');
      lerCLientesFiltro();
      setFiltroMostrarDataVenda(false);

    }
    setPesquisaNome("Pesquisar");
    setPesquisaData("Pesquisar");
    setFiltroPesquisaVenda("");
    setArrayVendas([]);
  }

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  function doRefresh(event: any) {
    carregarVendasSegmentadaData(filtroStatusVenda, filtroPesquisaVenda);
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  function registrarAnotacao(pk: any, mensagem: any, indice: any) {
    if (!mensagem || mensagem.trim() == "") {
      return null;
    }

    let params = {
      mensagem: mensagem
    }
    return fetch(vendaRegistrarAnotacao.url.replaceAll("{pk}", pk.replaceAll(/#/g, "%23")),
      {
        method: vendaRegistrarAnotacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      });
  }

  function carregarDetalhes(vendaid: any, empresaid: any) {
    history.push("vendas/vendasdetalhes", { vendaid: vendaid, empresaid: empresaid });
  }

  function carregarServicos(vendaid: any) {
    history.push("vendas/vendasservicos", { id: vendaid.replaceAll(/#/g, "%23") });
  }

  function carregarProdutos(vendaid: any) {
    history.push("vendas/vendasprodutos", { id: vendaid.replaceAll(/#/g, "%23") });
  }

  async function gerarOrcamento(vendaid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("vendaid", vendaid);

    setShowLoadingPage(true);
    const resp = await fetch(vendaGerarOrcamento.url.replaceAll("{id}", vendaid.replaceAll(/#/g, "%23")),
      {
        method: vendaGerarOrcamento.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar PDF',
        message: '<strong>PDF</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })




    }

    setShowLoadingPage(false);

  }



  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  function preencheCard(value: any, indice: any) {
    return (
      <IonCard className={"cardVendas"} key={"vendas" + indice + value.pk}>
        <IonCardHeader id={value.pk} style={{ margin: 0 }}>
          <div className="tempo2">
            <div className="duracao">
              <IonLabel>{(value.statusWorkflow)}</IonLabel>
            </div>
          </div>
          <IonItem style={{ width: "100%", borderRadius: "10px 10px 0 0" }} lines="none" detail={true} onClick={() => {
            carregarDetalhes(value.pk.replaceAll(/#/g, '%23'), value.gsi2pk.replaceAll(/#/g, '%23'));
          }} button>
            <IonLabel style={{ margin: 0 }} slot="start">
              Nº {value.numero} - {value.nomeVenda}


            </IonLabel>
            {/* <IonLabel style={{ margin: 0, paddingRight: "10px" }} slot="end">

              {value.prospectCpf != "" ? <IonIcon color='primary' icon={checkmark} size='small'></IonIcon> : <></>}
              {value.prospectTelefone != "" && value.prospectTelefone != "55" ? <IonIcon color='primary' icon={call} size='small'></IonIcon> : <></>}
              {value.prospectEmail != "" ? <IonIcon color='primary' icon={mail} size='small'></IonIcon> : <></>}

            </IonLabel> */}
            <IonLabel style={{ margin: 0 }} slot='end'>detalhes</IonLabel>




          </IonItem>
        </IonCardHeader>
        <IonCardContent>

          <IonGrid>
            <IonRow key={"vendarow1" + indice + value.pk}>
              <IonCol>

                <div className="vendas" >
                  <IonLabel position="fixed">Prospect</IonLabel>
                  <IonLabel className="vendasTexto" >{value.prospectNome}</IonLabel>
                  <div style={{ position: "absolute", paddingRight: "10px", textAlign: "right", width: "100%" }}>
                    {value.prospectCpf != "" ? <IonIcon color='primary' icon={checkmark} size='small'></IonIcon> : <></>}
                    {value.prospectTelefone != "" && value.prospectTelefone != "55" ? <IonIcon color='primary' icon={call} size='small'></IonIcon> : <></>}
                    {value.prospectEmail != "" ? <IonIcon color='primary' icon={mail} size='small'></IonIcon> : <></>}
                  </div>


                </div>



                <div className="vendas">
                  <IonLabel></IonLabel>
                  <IonLabel className="vendasTexto"> <div>
                    <a target='_blank' href={'https://api.whatsapp.com/send?phone=' + value.prospectTelefone}>
                      <p style={{ color: "black" }}><b>{formataTelefone(value.prospectTelefone)}</b></p>
                    </a>
                  </div></IonLabel>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol key={"projetorow4" + indice + value.pk}>
                <div className="vendas">
                  <IonLabel position="fixed">Funil</IonLabel>
                  <IonLabel className="vendasTexto">{value.statusWorkflow}</IonLabel>
                </div>
              </IonCol>
            </IonRow>

            {(["Pedidos", "Vendas"].includes(window.localStorage.getItem("servicoPrincipal") || "")) ? (
              <IonRow>
                <IonCol key={"projetorow5" + indice + value.pk}>
                  <div className="operacao" style={{ alignItems: 'center' }}>
                    <IonLabel position="fixed">Itens</IonLabel>
                    <IonChip
                      color={value.dataCupom ? 'success' : 'primary'}
                      style={{
                        width: '100%',
                        margin: '0px',
                        height: '40px',
                        padding: '0px',
                        fontWeight: 'bold',
                        fontSize: '26px',
                        borderRadius: '8px'
                      }}
                      onClick={() => carregarProdutos(value.pk)}
                    >

                      <IonLabel style={{ marginLeft: 'auto', textAlign: 'right', width: 'auto' }}
                        className="ion-text-wrap"
                      >
                        R$ {value.valorTotalProdutos || "0,00"}
                      </IonLabel>
                    </IonChip>
                  </div>
                </IonCol>
              </IonRow>



            ) : (
              <IonRow>
                <IonCol key={"projetorow5" + indice + value.pk}>
                  <div className="operacao" style={{ alignItems: 'center' }}>
                    <IonLabel position="fixed">Itens</IonLabel>
                    <IonChip
                      color={value.dataCupom ? 'success' : 'primary'}
                      style={{
                        width: '100%',
                        margin: '0px',
                        height: '40px',
                        padding: '0px',
                        fontWeight: 'bold',
                        fontSize: '26px',
                        borderRadius: '8px'
                      }}
                      onClick={() => carregarServicos(value.pk)}
                    >

                      <IonLabel style={{ marginLeft: 'auto', textAlign: 'right', width: 'auto' }}
                        className="ion-text-wrap"
                      >
                        R$ {value.valorTotalServicos || "0,00"}
                      </IonLabel>
                    </IonChip>
                  </div>
                </IonCol>
              </IonRow>


            )}
            <IonRow key={"vendarow3" + indice + value.pk}>
              <IonCol>
                <div className="operacao">
                  <IonLabel position="fixed">Anotações</IonLabel>
                </div>
                <div className="operacao">
                  <IonLabel key={"vendalabel" + indice + value.pk} className="operacaoTexto" style={{ margin: 0 }}>
                    {
                      value.anotacoes?.sort(function (a: any, b: any) {
                        if (a.data < b.data) {
                          return 1;
                        }
                        if (a.data > b.data) {
                          return -1;
                        }
                        return 0;
                      }).slice(0, 5).map((anotacoesValue: any) => (
                        <p key={"vendap" + indice + value.pk + anotacoesValue.data} style={{ color: "#000000", fontSize: "12px" }}><b>{new Date(anotacoesValue.data).toLocaleDateString() + " " + new Date(anotacoesValue.data).toLocaleTimeString()}</b> - {anotacoesValue.anotacao}</p>
                      ))}
                  </IonLabel>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonItem lines="none">

            <IonChip style={{ fontSize: "18px", borderRadius: '8px' }} slot="end" color='primary' onClick={async (e) => {
              await gerarOrcamento(value.pk);
            }}>
              <IonIcon icon={documentOutline}></IonIcon>
              <IonLabel>Gerar PDF</IonLabel>
            </IonChip>

            <IonChip style={{ fontSize: "18px", borderRadius: '8px' }} slot="end" color='success' onClick={(e) => {
              if (value.prospectNome?.trim() == "" || value.nomeVenda?.trim() == "" || value.prospectTelefone?.trim() == "" || value.prospectTelefone == "55") {
                setTextToast("Para avançar cadastre as informações de nome e telefone na venda.");
                setShowToast(true);
              }
              else {
                setDadosVenda(value);
                setShowCriarProjeto(true);
              }
            }}>
              <IonIcon icon={checkboxOutline}></IonIcon>
              <IonLabel>Criar {window.localStorage.getItem("servicoTipo") || "Projeto"}</IonLabel>
            </IonChip>




          </IonItem>


          <IonItem style={{ padding: 0, margin: 0, "borderRadius": "10px" }} color="grey" lines='none' hidden={acessos?.vendas != 'edicao'}>
            <IonSegmentButton style={{ height: "25px", padding: 0, margin: 0 }} color='tertiary' onClick={(e) => {
              mostartAlerta({
                backdropDismiss: false,
                header: 'Registrar anotação na venda',
                message: 'Digite a anotação abaixo',
                inputs: [
                  {
                    name: 'mensagem',
                    type: 'textarea',
                    id: 'name2-id',
                    placeholder: 'Digite aqui a anotação',
                  }],
                buttons: ['Cancel', {
                  text: 'Incluir', handler: (d) => {
                    setShowLoadingPage(true);
                    let retorno = registrarAnotacao(value.pk.replace(/#/g, '%23'), d.mensagem, indice);
                    if (retorno) {
                      retorno?.then(async e => {
                        let body = await e.json();
                        if (e.status === 200) {
                          if (value.anotacoes) {
                            value.anotacoes?.push({ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa });
                          }
                          else {
                            value.anotacoes = [{ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa }];
                          }

                          mostrarToast({
                            duration: 2000,
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Sucesso na inclusão!'
                          });
                        }
                        else {
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro na inclusão!'
                          });
                        }
                      }).catch((e) => {
                        console.log(e);
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro na inclusão!'
                        });
                      }).finally(() => {
                        setShowLoadingPage(false);
                      });
                      return true;
                    }
                    else {
                      mostrarToast({
                        duration: 2000,
                        position: 'middle',
                        buttons: [{ text: 'ok', handler: () => fecharToast() }],
                        message: 'Digite uma anotação para incluir.'
                      });
                      setShowLoadingPage(false);
                      return false;
                    }
                  }
                }],
                onDidDismiss: (e) => { console.log('did dismiss'); }
              })
            }}>
              <IonIcon icon={pencil} style={{ padding: 0, margin: 0 }}></IonIcon>
              <IonLabel style={{ "fontSize": "8px", padding: 0, margin: 0 }}>Anotação</IonLabel>
            </IonSegmentButton>

            <IonSegmentButton hidden={value.status != "Ativo"} style={{ height: "25px", padding: 0, margin: 0 }} color='primary' onClick={(e) => {
              setDadosVenda(value);
              setShowAtualizarInformacoes(true);
            }}>
              <IonIcon icon={create} style={{ padding: 0, margin: 0 }}></IonIcon>
              <IonLabel style={{ "fontSize": "8px", padding: 0, margin: 0 }}>Editar</IonLabel>
            </IonSegmentButton>


            <IonSegmentButton hidden={value.status != "Ativo"} style={{ height: "25px", padding: 0, margin: 0 }} color='primary' onClick={(e) => {
              setVendaId(value.pk);
              setIndiceWorkflow(value.indiceWorkflow);
              setStatusWorkflow(value.statusWorkflow);
              setListaStatus(value.configWorkflow);
              setShowWorkflowAvancar(true);
            }}>
              <IonIcon icon={arrowForward} style={{ padding: 0, margin: 0 }}></IonIcon>
              <IonLabel style={{ "fontSize": "8px", padding: 0, margin: 0 }}>Avançar</IonLabel>
            </IonSegmentButton>

            {/* <IonSegmentButton hidden={value.status != "Ativo"} style={{ height: "25px", padding: 0, margin: 0 }} color='primary' onClick={(e) => {
              if (value.prospectCpf?.trim() == "" || value.prospectNome?.trim() == "" || value.nomeVenda?.trim() == "" || value.prospectTelefone?.trim() == "" || value.prospectTelefone == "55") {
                setTextToast("Para criar " + (window.localStorage.getItem("servicoTipo") || "Projeto") + " cadastre as informações de nome e telefone na venda.");
                setShowToast(true);
              }
              else {
                setDadosVenda(value);
                setShowCriarProjeto(true);
              }
            }}>
              <IonIcon icon={apps} style={{ padding: 0, margin: 0 }}></IonIcon>
              <IonLabel style={{ "fontSize": "8px", padding: 0, margin: 0 }}>Criar {window.localStorage.getItem("servicoTipo") || "Projeto"}</IonLabel>
            </IonSegmentButton> */}

            <IonSegmentButton hidden={value.status != "Ativo"} style={{ height: "25px", padding: 0, margin: 0 }} color='primary' onClick={(e) => {
              mostartAlerta({
                backdropDismiss: false,
                header: 'Encerrar venda',
                inputs: [
                  {
                    label: 'Preço',
                    type: 'radio',
                    value: 'preço',
                  },
                  {
                    label: 'Prazo',
                    type: 'radio',
                    value: 'prazo',
                  },
                  {
                    label: 'Desistência',
                    type: 'radio',
                    value: 'desistência',
                  },
                  {
                    label: 'Não especificado',
                    type: 'radio',
                    value: 'não especificado',
                  },
                  {
                    label: 'Falta de interesse',
                    type: 'radio',
                    value: 'falta de interesse',
                  },
                ],
                message: 'Deseja <strong>encerrar</strong>?',
                buttons: [
                  {
                    text: 'Cancelar',
                    role: 'cancel',
                  },
                  {
                    text: 'Confirmar',
                    cssClass: 'primary',
                    handler: (data) => {

                      if (data == undefined) {
                        mostrarToast({
                          duration: 2000,
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: "Selecione um motivo."
                        });
                      }
                      else {
                        setShowLoadingPage(true);
                        let retorno = encerrar2(value.pk, data);
                        if (retorno) {
                          retorno?.then(res => {
                            if (res.status === 200) {
                              value.pk = "zero";
                              mostrarToast({
                                duration: 2000,
                                position: 'middle',
                                buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                message: "Venda encerrada com sucesso."
                              });
                            }
                            else {
                              mostrarToast({
                                position: 'middle',
                                buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                message: 'Erro ao tentar fechar.'
                              });
                            }
                          }).catch(() => {
                            mostrarToast({
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Erro ao tentar cancelado.'
                            });
                          }).finally(() => {
                            carregarVendasSegmentadaData(filtroStatusVenda, filtroPesquisaVenda);
                            setShowLoadingPage(false);
                          });
                          return true;
                        }
                        else {
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro ao tentar fechar.'
                          });
                          setShowLoadingPage(false);
                          return false;
                        }
                      }

                    }
                  }
                ],
                onDidDismiss: (e) => console.log('did dismiss'),
              })
            }}>
              <IonIcon icon={power} color={"danger"} style={{ padding: 0, margin: 0 }}></IonIcon>
              <IonLabel style={{ "fontSize": "8px", padding: 0, margin: 0 }}>Encerrar</IonLabel>
            </IonSegmentButton>
          </IonItem>
        </IonCardContent>
      </IonCard >
    )
  }

  function encerrar2(prospect: string, motivo: string) {

    let objRequest = {
      "motivo": motivo
    };

    console.log("objRequest", objRequest);


    return fetch(finalizarVenda.url.replace("{id}", prospect.replaceAll(/#/g, "%23")),
      {
        method: finalizarVenda.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      });
  }

  const loadItens = () => {

    if (arrayVendas === undefined || arrayVendas.length === 0) {
      return (
        <IonCard key="primeira">
          <IonCardHeader key="primeiraheader" >
            <IonCardTitle>Neste momento não existe nenhuma venda.</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      )
    }

    return arrayVendas.map((value: any, index: any, arr: any) =>

      (!arr[index - 1]) ?
        (<div key={"divvendapai" + index}>
          <IonCard className={"card"} key={"prospect" + index}>
            <IonCardHeader key={"headervenda" + index}>
              <IonCardTitle key={"titulovenda" + index}>
                <div key={"divvenda" + index} className={"cabecalho"} >{new Date(value.dataCadastro).toLocaleString('pt-BR').substring(0, 10)}</div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
          {preencheCard(value, index)}
        </div>)
        :
        (((new Date(value.dataCadastro).toLocaleString('pt-BR').substring(0, 10) !== new Date(arr[index - 1].dataCadastro).toLocaleString('pt-BR').substring(0, 10)))) ?
          (<div key={"divvendapai" + index}>
            <IonCard className={"card"} key={"prospect" + index}>
              <IonCardHeader key={"headervenda" + index}>
                <IonCardTitle key={"titulovenda" + index}>
                  <div key={"divvenda" + index} className={"cabecalho"} >{new Date(value.dataCadastro).toLocaleString('pt-BR').substring(0, 10)}</div>
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
            {preencheCard(value, index)}
          </div>
          ) :
          (
            <div key={"divvendapai" + index}>
              {preencheCard(value, index)}
            </div>
          )
    );
  }

  async function carregarVendasSegmentadaData(filtro: any, data: any) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      console.log("valor", data);
      console.log("filtroCampoSelecionadoVenda", filtroCampoSelecionadoVenda);
      let valor = data;
      if (data) {
        valor = data.replaceAll(/#/g, "%23");
      }
      const resp: Response = await fetch(urlBase.url + "/vendas?campo=" + (filtroCampoSelecionadoVenda || "data") +
        "&valor=" + valor +
        "&filtro=" + filtro +
        "&lojas=" + Buffer.from(loja).toString('base64'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          setShowLoadingPage(false);
          history.replace("/login/");
        }

        if (res && res.LastEvaluatedKey) {
          setVendapk(res.LastEvaluatedKey.pk);
          setVendask(res.LastEvaluatedKey.sk);
          setVendagsi2sk(res.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setDesabilitarInfiniteScroll(true);
        }

        setArrayVendas(res.Items);
        setShowLoadingPage(false);
      });
    }
    consultar();
  }

  async function carregarMaisVendas($event: CustomEvent<void>) {
    console.log("carregarMaisVendas",);
    if (vendagsi2sk.includes(filtroStatusVenda)) {
      await carregarMaisVendasChamada().then(() => {
      });
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function carregarMaisVendasChamada() {
    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let valor = filtroPesquisaVenda;
    if (filtroPesquisaVenda) {
      valor = filtroPesquisaVenda.replaceAll(/#/g, "%23");
    }


    console.log(valor);

    const res: Response = await fetch(urlBase.url + "/vendas?campo=" + (filtroCampoSelecionadoVenda || "data") +
      "&valor=" + valor +
      "&filtro=" + filtroStatusVenda +
      "&isk=" + vendagsi2sk +
      "&pk=" + vendapk +
      "&sk=" + vendasFunilk +
      "&lojas=" + Buffer.from(loja).toString('base64'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (vendasFunil) => {

        if (vendasFunil.Items) {
          for (let i = 0; i < vendasFunil.Items.length; i++) {
            arrayVendas.push(vendasFunil.Items[i]);
          }

          if (vendasFunil.LastEvaluatedKey) {
            setVendapk(vendasFunil.LastEvaluatedKey.pk);
            setVendask(vendasFunil.LastEvaluatedKey.sk);
            setVendagsi2sk(vendasFunil.LastEvaluatedKey.gsi2sk);
            setDesabilitarInfiniteScroll(false);
          }
          else {
            setVendapk("");
            setVendask("");
            setVendagsi2sk("");
            setDesabilitarInfiniteScroll(true);
          }
        }

      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");

  }

  // function validarProspect() {

  //   if (prospect.length === 0) {
  //     setTextToast("Campo Prospect é obrigatório.");
  //     setShowToast(true);
  //     return false;
  //   }
  //   return true;
  // }

  function validarCampos() {

    if (prospect.length === 0 || nomeVenda.length === 0) {
      setTextToast("Todos os campos com * são obrigatórios.");
      setShowToast(true);
      return false;
    }
    return true;
  }

  const consultarProspect = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo");

    const resp = await fetch(montarUrl.replace("{perfil}", "prospect"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
        setListaProspect(lista);
      }
    }
  }


  function avancarVenda() {
    setNomeVenda("");
    setProspect("");
    setProspectText("Selecione");
    consultarProspect();
    setShowModalNovaVenda(true);
  }

  function iniciarVenda(pk?: any, nome?: any) {

    let objRequest;
    // if (pk === null) {
    if (!validarCampos())
      return;

    const found = listaProspect.find((obj: any) => {
      return obj.pk === prospect;
    });

    console.log("found", found);

    objRequest = {

      "nomeVenda": nomeVenda,
      "prospectid": prospect.replaceAll(/#/g, '%23'),
      "empresaid": loja || "",
      "tipo": "vendas"
    };

    // }
    // else {


    //   objRequest = {

    //     "nomeVenda": nomeVenda,
    //     "prospectid": pk.replaceAll(/#/g, '%23'),
    //     "empresaid": loja || "",
    //     "tipo": "vendas"
    //   };
    // }

    if (botaoAtivo) {
      setShowLoadingPage(true);
      setBotaoAtivo(false);

      requestService(incluirVenda.url, {
        method: incluirVenda.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
        history,
        (response: any) => {
          if (response.message) {
            setBotaoAtivo(true);
            setShowLoadingPage(false);
            setShowModalNovaVenda(false);
            setShowModalLoja(false);
            carregarVendasSegmentadaData(filtroStatusVenda, filtroPesquisaVenda);
            setTextToast("Venda criada com sucesso!");
            setShowToast(true);
          }
        },
        (error: any) => {
          console.log(error);
          setBotaoAtivo(true);
          setShowLoadingPage(false);
          setTextToast(error.message);
          setShowToast(true);
        })
    }

  }

  async function handleSegmentChange(e: any) {
    setFiltroStatusVenda(e.detail.value);
    await carregarVendasSegmentadaData(e.detail.value, filtroPesquisaVenda);
  }

  function readProspect() {
    return listaProspect.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"prospect" + value.pk} value={value.pk}>{value.nome}</IonSelectOption>
    )
    )
  };


  const openPopover = (e: any) => {
    popover.current!.event = e;
    setShowModalData(true);
  };

  async function closeModalProspect(pk: any, nome: any) {
    setShowDetalhesProspect(false);

    setProspect(pk);
    setProspectText(nome);
    setShowDetalhesProspect(false);
    consultarProspect();

    // iniciarVenda(pk, nome);
  }

  async function closeModalSimpleProspect() {
    setShowDetalhesProspect(false);
  }

  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalAtualizarInformacoes() {
    setShowAtualizarInformacoes(false);
  }

  async function closeModalAtualizarInformacoesSucess() {
    carregarVendasSegmentadaData(filtroStatusVenda, filtroPesquisaVenda);
    setShowAtualizarInformacoes(false);
  }


  async function closeModalWorkflowAvancar() {
    setShowWorkflowAvancar(false);
  }

  async function closeModalWorkflowAvancarSucess(dadosOperacao: any) {
    setTextToast("Etapa alterada com sucesso!");
    setShowToast(true);
    carregarVendasSegmentadaData(filtroStatusVenda, filtroPesquisaVenda);
    setShowWorkflowAvancar(false);
  }

  async function closeModalCriarProjeto() {
    setShowCriarProjeto(false);
  }

  async function closeModalCriarProjetoSucess(dadosOperacao: any) {
    setTextToast((window.localStorage.getItem("servicoTipo") || "Projeto") + " criado com sucesso!");
    setShowToast(true);
    carregarVendasSegmentadaData(filtroStatusVenda, filtroPesquisaVenda);
    setShowCriarProjeto(false);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          {/* <IonButtons slot="start">
           
              {
                ((!isPlatform('mobileweb') && isPlatform('mobile'))) ?
                  (
                    < IonButton onClick={() => {
                      history.goBack();
                    }}>
                      <IonIcon slot="icon-only" icon={arrowBack} />
                    </IonButton>
                  ) :
                  (
                    <IonButtons slot="start">
                      <IonMenuButton></IonMenuButton>
                    </IonButtons>
                  )
              }
            </IonButtons> */}

          {/* <IonTitle hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))}>Vendas</IonTitle> */}

          <IonSelect interface='popover' style={{ fontSize: "14px" }} name='Filtros' title='Filtros' value={filtroCampoSelecionadoVenda} multiple={false} cancelText="Cancelar" okText="Ok" onIonChange={e => { trocarCampoFiltro(e); }} slot="end">
            <IonSelectOption value="data">Data</IonSelectOption>
            <IonSelectOption value="nome">Prospect</IonSelectOption>
          </IonSelect>

          <IonButton style={{ width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={filtroMostrarDataVenda} onClick={e => { setShowModalFiltro(true); }}>
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={filtroPesquisaVenda != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaNome} </IonLabel>
          </IonButton>

          <IonButton style={{ width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={!filtroMostrarDataVenda} onClick={e => { openPopover(e); }} >
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={filtroPesquisaVenda != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaData} </IonLabel>
          </IonButton>

          <IonModal isOpen={showModalFiltro} onDidDismiss={() => setShowModalFiltro(false)} >
            <AppTypeaheadItem
              title="Prospects"
              camposFiltro="nome,telefone"
              campo="nome"
              items={listaProspectsFiltro}
              selectedItem={filtroPesquisaVenda}
              onSelectionCancel={() => setShowModalFiltro(false)}
              onSelectionChange={filtroSelectionChanged}
            />
          </IonModal>

          <IonButtons slot="end">
            <IonButton onClick={() => { setShowNotificacoes(true); }}>
              <IonIcon slot="icon-only" icon={notifications} />
              <IonBadge id="cart-badge" color="danger">{notificacoes?.length}</IonBadge>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
            <IonButton onClick={() => { history.push("./chat"); }}>
              <IonIcon slot="icon-only" icon={chatbubbles} />
              <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar style={{ height: "48px" }}>
          <IonSegment value={filtroStatusVenda} onIonChange={(e) => { scrollToTop(); handleSegmentChange(e); }}>
            <IonSegmentButton style={{ height: "30px" }} value="Ativo" >
              <IonLabel style={{ fontSize: "14px" }}>Ativas</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="Encerrado" >
              <IonLabel style={{ fontSize: "14px" }}>Encerradas</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>

      </IonHeader>


      <IonContent fullscreen scrollEvents={true} ref={contentRef} color='warning'>

        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.vendas != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            avancarVenda();
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">

          <IonFabButton color="tertiary" onClick={() => {
            console.log("lojas.replaceAll(/#/g, '%23')", loja.replaceAll(/#/g, '%23'));
            history.push("vendas/vendaspainel", { empresaid: loja.replaceAll(/#/g, '%23') });
          }} >
            <IonIcon color="light" icon={grid}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonFab vertical="top" horizontal="end" slot="fixed" edge hidden={isPlatform("android") || isPlatform("ios")}>
          <IonFabButton color="tertiary" onClick={() => { carregarVendasSegmentadaData(filtroStatusVenda, filtroPesquisaVenda); }} >
            <IonIcon color="light" icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className='VendasSlides'>
          {loadItens()}
        </div>

        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisVendas(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais vendas..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>

        <IonToast
          isOpen={showCopiado}
          onDidDismiss={() => setShowCopiado(false)}
          message="Campo copiado."
          duration={3000}
        />

        <div className="content">

          <IonModal onDidPresent={() => {
            if (inputRef.current) {
              inputRef.current.setFocus();
            }
          }} isOpen={showModalNovaVenda} onDidDismiss={() => setShowModalNovaVenda(false)} >
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle>Inserir venda</IonTitle>
                <IonButtons slot="start" onClick={() => setShowModalNovaVenda(false)}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
              <IonList lines="full" class="ion-no-margin">
                <IonListHeader lines="full">
                  <IonLabel>
                    Dados da Venda
                  </IonLabel>
                </IonListHeader>
                <IonItem lines="none">
                  <IonLabel>Nome*</IonLabel>
                  <IonInput placeholder="Nome" value={nomeVenda}
                    ref={(ref) => inputRef.current = ref}
                    autoCorrect='true' autoCapitalize='true'
                    required onIonInput={(e: any) => setNomeVenda(e.target.value)}></IonInput>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Prospect*</IonLabel>
                  <IonButtons slot="end" onClick={() => { setShowDetalhesProspect(true) }}>
                    <IonIcon color="tertiary" size='large' slot="icon-only" icon={addCircle}></IonIcon>
                  </IonButtons>

                  <IonItem slot="end" lines="none" button={true} detail={false} onClick={e => { setShowModalProspect(true); }}>
                    {prospectText}
                  </IonItem>
                  <IonModal isOpen={showModalProspect} onDidDismiss={() => setShowModalProspect(false)} >
                    <AppTypeaheadItem
                      title="Prospects"
                      camposFiltro="nome,telefone"
                      campo="nome"
                      items={listaProspect}
                      selectedItem={prospect}
                      onSelectionCancel={() => setShowModalProspect(false)}
                      onSelectionChange={prospectSelectionChanged}
                    />
                  </IonModal>
                  {/* <IonSelect slot="end" value={prospect} placeholder="Selecione" onIonChange={e => {
                      {
                        console.log(e.detail.value);
                        setProspect(e.detail.value);

                        let found = listaProspect.find((obj: any) => {
                          return obj.pk === e.detail.value;
                        });

                      }
                    }}>
                      {readProspect()}
                    </IonSelect> */}
                </IonItem>
              </IonList>

            </IonContent>
            <IonFooter className="ion-no-border" >
              <IonToolbar>
                <IonButton expand="block" size='large' color="primary" onClick={() => { iniciarVenda(null, null); }}>Registrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>

          <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
            <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
          </IonModal>

          <IonModal onDidPresent={() => {
            if (inputDetalhesProspectRef.current) {
              inputDetalhesProspectRef.current.setFocus();
            }
          }} isOpen={showDetalhesProspect} onDidDismiss={() => { setShowDetalhesProspect(false); }}>
            <ProspectsCad usuario={null} inputRef={inputDetalhesProspectRef} closeAction={closeModalProspect} closeActionSimple={closeModalSimpleProspect}></ProspectsCad>
          </IonModal>

          <IonModal onDidPresent={() => {
            if (inputAtualizarInformacoesRef.current) {
              inputAtualizarInformacoesRef.current.setFocus();
            }
          }} isOpen={showAtualizarInformacoes} onDidDismiss={() => { setShowAtualizarInformacoes(false); }}>
            <VendasCad usuario={dadosVenda} inputRef={inputAtualizarInformacoesRef} closeAction={closeModalAtualizarInformacoesSucess} closeActionSimple={closeModalAtualizarInformacoes}></VendasCad>
          </IonModal>

          <IonModal isOpen={showWorkflowAvancar} onDidDismiss={() => { setShowWorkflowAvancar(false); }}>
            <VendasWorkflowAvancar vendaid={vendaId} statusWorkflow={statusWorkflow} indiceWorkflow={indiceWorkflow} listaStatus={listaStatus} closeAction={closeModalWorkflowAvancar} alterarAction={closeModalWorkflowAvancarSucess}></VendasWorkflowAvancar>
          </IonModal>

          <IonModal onDidPresent={() => {
            if (inputCriarProjetoRef.current) {
              inputCriarProjetoRef.current.setFocus();
            }
          }} isOpen={showCriarProjeto} onDidDismiss={() => { setShowCriarProjeto(false); }}>
            <VendasCriarProjeto dadosVenda={dadosVenda} inputRef={inputCriarProjetoRef} closeAction={closeModalCriarProjeto} salvarAction={closeModalCriarProjetoSucess}></VendasCriarProjeto>
          </IonModal>

        </div>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />

      </IonContent>

      <IonPopover ref={popover}
        isOpen={showModalData}
        onDidDismiss={() => setShowModalData(false)} >
        <IonDatetime
          presentation='date'
          onIonChange={e => {
            if (e.detail.value) {
              let data = e.detail.value.toString().substring(0, 10);
              const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                Date.parse(data)
              );
              setPesquisaData(dataFormatada);
              setFiltroPesquisaVenda(data);
              carregarVendasSegmentadaData(filtroStatusVenda, data);
            }
          }}></IonDatetime>

      </IonPopover >
    </IonPage>
  );
};

export default Vendas;
