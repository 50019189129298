import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonDatetime, IonFab, IonFabButton, IonFabList, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonLoading, IonMenuButton, IonModal, IonPage, IonPopover, IonRefresher, IonRefresherContent, IonRow, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonToast, IonToolbar, isPlatform, useIonAlert, useIonModal, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { apps, chatboxOutline, chatbubbles, handRight, notifications, pencil, power, refresh, search } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { encerrarOperacao, listaEquipePorPerfil, operacaoChamarMotorista, operacaoRegistrarAnotacao, urlBase } from '../../../../../Utils/Services';
import Acompanhar from '../Acompanhar/Acompanhar';
import './Operacoes.css';

import { Buffer } from 'buffer';
import validator from 'validator';
import AppTypeaheadItem from '../../../../../components/AppTypeaheadItem';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Notificacoes from '../Notificacoes/Notificacoes';

const Operacoes: React.FC = () => {

  const location = useLocation<any>();
  const inputRef = useRef<any>(null);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const history = useHistory();
  const [arrayOperacoes, setArrayOperacoes] = useState<any>([]);
  const [operacaoId, setOperacaoId] = useState("");
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [operacaopk, setOperacaopk] = useState("");
  const [operacaosk, setOperacaosk] = useState("");
  const [operacaogsi2sk, setOperacaogsi2sk] = useState("");
  const [showCopiado, setShowCopiado] = useState(false);
  const [codigoBarras, setCodigoBarras] = React.useState('0');
  const [showModal, setShowModal] = useState(false);
  const [showModalCodigoBarras, setShowModalCodigoBarras] = useState(false);
  const [showModalCodigoBarrasDigitar, setShowModalCodigoBarrasDigitar] = useState(false);
  const [tipoOperacao, setTipoOperacao] = useState("Entrega");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [codigoLido, setCodigoLido] = useState("");
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [primeiroFoco, setPrimeiroFoco] = useState(false);
  const [cpf, setCPF] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [placa, setPlaca] = useState("");
  const [pesquisaData, setPesquisaData] = useState<any>("Pesquisar");
  const [pesquisaNome, setPesquisaNome] = useState<any>("Pesquisar");
  const [listaClientesFiltro, setListaClientesFiltro] = useState<any>([]);
  const [showLoadingPage, setShowLoadingPage] = useState(true);
  const [showModalData, setShowModalData] = useState(false);
  const popover = useRef<HTMLIonPopoverElement>(null);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [mostartAlerta] = useIonAlert();
  const [mostrarToast, fecharToast] = useIonToast();
  const { loja, setLoja } = useContext(MessageContext);
  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);
  const { mensagens, setMensagens } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const { filtroCampoSelecionadoOperacao, setFiltroCampoSelecionadoOperacao } = useContext(MessageContext);
  const { filtroMostrarDataOperacao, setFiltroMostrarDataOperacao } = useContext(MessageContext);
  const { filtroPesquisaOperacao, setFiltroPesquisaOperacao } = useContext(MessageContext);
  const { filtroStatusOperacao, setFiltroStatusOperacao } = useContext(MessageContext);
  const { recarregarOperacao, setRecarregarOperacao } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  useEffect(() => {
    // primeira vez q entra e quando troca de loja
    console.log("entrou useEffect mudou loja - Operacoes", loja);
    console.log("entrou useEffect mudou loja - Operacoes", filtroStatusOperacao);
    setFiltroCampoSelecionadoOperacao("data");
    setFiltroMostrarDataOperacao(true);
    setFiltroStatusOperacao("Em");
    // setRecarregarOperacao(false);
    carregarOperacoesSegmentada("Em", "");
    setPesquisaData('Pesquisar');
  }, [loja]
  );

  useEffect(() => {
    if (recarregarOperacao) {
      carregarOperacoesSegmentada(filtroStatusOperacao, filtroPesquisaOperacao);
      setRecarregarOperacao(false);
    }
  }, [recarregarOperacao]
  );

  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("Operações");
  })

  const filtroSelectionChanged = (filtroClientes: string) => {

    if (filtroClientes != "") {
      setFiltroPesquisaOperacao(filtroClientes);
      const cliente = listaClientesFiltro.find((prospect: any) => prospect.pk === filtroClientes);
      setPesquisaNome(cliente.nome);
    }
    else {
      setFiltroPesquisaOperacao("");
      setPesquisaNome("Pesquisar");
    }
    carregarOperacoesSegmentada(filtroStatusOperacao, filtroClientes);
    setShowModalFiltro(false);
  };

  async function lerCLientesFiltro() {


    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23"));//.concat("?status=ativo");

    setShowLoadingPage(true);
    const resp = await fetch(montarUrl.replace("{perfil}", "cliente"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });

    let equipe = await resp.json();
    setShowLoadingPage(false);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
      setListaClientesFiltro(lista);
    }

  }

  function trocarCampoFiltro(e: any) {
    setFiltroCampoSelecionadoOperacao(e.detail.value);
    if (e.detail.value === 'data') {
      console.log('entrou data');
      setFiltroMostrarDataOperacao(true);



    }
    else {
      console.log('entrou nome');
      lerCLientesFiltro();
      setFiltroMostrarDataOperacao(false);

    }
    setPesquisaNome("Pesquisar");
    setPesquisaData("Pesquisar");
    setFiltroPesquisaOperacao("");
    setArrayOperacoes([]);
  }

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  // const openScanner = async () => {

  //   const opt: BarcodeScannerOptions = {
  //     formats: 'CODE_128',
  //     prompt: 'Escaneie o codigo de barras'
  //   }

  //   const data: any = await BarcodeScanner.scan(opt);

  //   if (data !== null) {
  //     setShowModalCodigoBarras(false);
  //     setShowModal(false);
  //     setCodigoBarras(data.text.match(/(.{1,4})/g).join(" "));
  //     setShowModalCodigoBarrasDigitar(true);
  //   }

  // };

  function doRefresh(event: any) {
    carregarOperacoesSegmentada(filtroStatusOperacao, filtroPesquisaOperacao);
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  function chamarMotorista(pk: any, mensagem?: any) {
    let params = {
      mensagem: mensagem || ""
    }
    // console.log("entrou aqui");
    return fetch(operacaoChamarMotorista.url.replace("{pk}", pk),
      {
        method: operacaoChamarMotorista.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      });
  }

  function registrarAnotacao(pk: any, mensagem: any, indice: any) {
    if (!mensagem || mensagem.trim() == "") {
      return null;
    }

    let params = {
      mensagem: mensagem
    }
    return fetch(operacaoRegistrarAnotacao.url.replace("{pk}", pk),
      {
        method: operacaoRegistrarAnotacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      });
  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  function carregarDetalhesProjeto(projetoid: any, empresaid: any, abrirInserirAgendamento: boolean) {
    //setChamadaRealizada1(false);
    history.push("projetos/projetosdetalhes", { projetoid: projetoid, empresaid: empresaid, abrirInserirAgendamento: abrirInserirAgendamento, esconderOperacoes: true })
  }

  function preencheCard(value: any, indice: any) {
    return (
      <IonCard className={"cardOperacao"} key={Math.random()}>
        <IonCardHeader id={value.pk} style={{ margin: 0 }}>
          <div className="tempo2" style={{ "background": value.color }}>
            <div className="duracao">
              <IonLabel><span style={{ fontSize: "11px" }}>duração: {tempoCorrido(new Date(value.dataInicio), (value.dataEncerramento ? new Date(value.dataEncerramento) : new Date()))}</span></IonLabel>
            </div>
          </div>

          <IonItem style={{ width: "100%", borderRadius: "10px 10px 0 0" }} lines="none" detail={true} onClick={() => {
            //setChamadaRealizada1(false); 
            history.push("operacoes/acompanhar", { estabelecimento: "A", codigoBarras: "B", operacaoid: value.pk.replace(/#/g, '%23') })
          }} button>
            <IonLabel style={{ margin: 0 }} slot="start">
              {value.descricaoAgendamento} - {value.tipoOperacao}
              <IonChip color="tertiary" hidden={!value.sk.includes("usuarios")}>
                <IonIcon icon={handRight} ></IonIcon>
                <IonLabel>Aberto manualmente</IonLabel>
              </IonChip>
            </IonLabel>
            <IonLabel style={{ margin: 0 }} slot='end'>detalhes</IonLabel>
          </IonItem>
        </IonCardHeader>

        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="operacao">
                  <IonLabel position="fixed">Cliente</IonLabel>
                  <IonLabel className="operacaoTexto">{value.clienteNome}</IonLabel>
                </div>
                <div className="operacao">
                  <IonLabel></IonLabel>
                  <IonLabel className="operacaoTexto"> <div>
                    <a target='_blank' href={'https://api.whatsapp.com/send?phone=' + value.clienteTelefone}>
                      <p style={{ color: "black" }}><b>{formataTelefone(value.clienteTelefone)}</b></p>
                    </a>
                  </div></IonLabel>
                </div>

              </IonCol>

            </IonRow>

            <IonRow>
              <IonCol>
                <div className="operacao">
                  <IonLabel position="fixed">Entregador</IonLabel>
                  <IonLabel className="operacaoTexto">{value.motoristaNome}</IonLabel>
                </div>
                <div className="operacao">
                  <IonLabel></IonLabel>
                  <IonLabel className="operacaoTexto"> <div>
                    <a target='_blank' href={'https://api.whatsapp.com/send?phone=' + value.motoristaTelefone}>
                      <p style={{ color: "black" }}><b>{formataTelefone(value.motoristaTelefone)}</b></p>
                    </a>
                  </div></IonLabel>
                </div>

              </IonCol>

            </IonRow>

            <IonRow hidden={!(value.responsavelid !== undefined && value.responsavelid !== '')}>
              <IonCol>
                <div className="operacao">
                  <IonLabel position="fixed">Arquiteto</IonLabel>
                  <IonLabel className="operacaoTexto">{value.responsavelNome}</IonLabel>
                </div>
                <div className="operacao">
                  <IonLabel></IonLabel>
                  <IonLabel className="operacaoTexto"> <div>
                    <a target='_blank' href={'https://api.whatsapp.com/send?phone=' + value.responsavelTelefone}>
                      <p style={{ color: "black" }}><b>{(value.responsavelid !== undefined && value.responsavelid !== '') ? formataTelefone(value.responsavelTelefone) : ""}</b></p>
                    </a>
                  </div></IonLabel>
                </div>

              </IonCol>

            </IonRow>

            <IonRow>
              <IonCol>
                <div className="operacao">
                  <IonLabel position="fixed">Anotações</IonLabel>
                </div>
                <div className="operacao">
                  <IonLabel className="operacaoTexto" style={{ margin: 0 }}>
                    {
                      value.anotacoes?.sort(function (a: any, b: any) {
                        if (a.data < b.data) {
                          return 1;
                        }
                        if (a.data > b.data) {
                          return -1;
                        }
                        return 0;
                      }).slice(0, 5).map((anotacoesValue: any) => (
                        <p style={{ color: "#000000", fontSize: "12px" }}><b>{new Date(anotacoesValue.data).toLocaleDateString() + " " + new Date(anotacoesValue.data).toLocaleTimeString()}</b> - {anotacoesValue.anotacao}</p>
                      ))}
                  </IonLabel>
                </div>
              </IonCol>
            </IonRow>

          </IonGrid>


          <IonItem lines="none" hidden={!value.projetoid || (acessos?.projetos != 'leitura' && acessos?.projetos != 'edicao')}>

            <IonChip slot="end" color='primary' onClick={(e) => {

              carregarDetalhesProjeto(value.projetoid.replaceAll(/#/g, '%23'), value.gsi2pk.replaceAll(/#/g, '%23'), false);
            }}>
              <IonIcon icon={apps} style={{ height: "20px" }}></IonIcon>
              <IonLabel style={{ height: "20px" }}>{window.localStorage.getItem("servicoTipo") || "Projeto"}</IonLabel>
            </IonChip>
          </IonItem>

          <IonFab horizontal="start" vertical="bottom" style={{ "borderRadius": "50" }} hidden={value.status != "Em andamento" || true}>
            <IonFabButton color="secondary">
              <IonIcon icon={apps} ></IonIcon>
            </IonFabButton>
            <IonFabList side="end">
              <IonFabButton color="light" onClick={() => {
                mostartAlerta({
                  header: 'Enviar mensagem ao cliente',
                  message: 'Digite a mensagem abaixo',
                  inputs: [
                    {
                      name: 'mensagem',
                      type: 'textarea',
                      id: 'name2-id',
                      value: '',
                      placeholder: 'Digite a mensagem',
                    }],
                  buttons: ['Cancel', { text: 'Ok', handler: (d) => chamarMotorista(value.pk.replace(/#/g, '%23'), d.mensagem) }],
                  onDidDismiss: (e) => console.log('did dismiss'),
                })
              }}>
                <IonIcon icon={chatboxOutline}></IonIcon>
              </IonFabButton>
            </IonFabList>
          </IonFab>

          <IonItem lines="none" hidden={value.status != "Em andamento" || true}>
            <IonChip slot="end" color='tertiary' onClick={(e) => mostartAlerta({
              header: 'Enviar mensagem ao cliente',
              message: 'Digite a mensagem abaixo',
              inputs: [
                {
                  name: 'mensagem',
                  type: 'textarea',
                  id: 'name2-id',
                  value: '',
                  placeholder: 'Digite a mensagem',
                }],
              buttons: ['Cancel', { text: 'Ok', handler: (d) => chamarMotorista(value.pk.replace(/#/g, '%23'), d.mensagem) }],
              onDidDismiss: (e) => console.log('did dismiss'),
            })}>
              <IonIcon icon={chatboxOutline} />
              <IonLabel>Chamar motorista</IonLabel>
            </IonChip>
          </IonItem>

          <IonItem style={{ padding: 0, margin: 0, "borderRadius": "10px" }} color="grey" lines='none' hidden={acessos?.operacoes != 'edicao'}>



            <IonSegmentButton style={{ height: "25px", padding: 0, margin: 0 }} color='tertiary' onClick={(e) => {
              mostartAlerta({
                backdropDismiss: false,
                header: 'Registrar anotação na operação',
                message: 'Digite a anotação abaixo',
                inputs: [
                  {
                    name: 'mensagem',
                    type: 'textarea',
                    id: 'name2-id',
                    placeholder: 'Digite aqui a anotação',
                  }],
                buttons: ['Cancel', {
                  text: 'Incluir', handler: (d) => {
                    setShowLoadingPage(true);
                    let retorno = registrarAnotacao(value.pk.replace(/#/g, '%23'), d.mensagem, indice);
                    if (retorno) {
                      retorno?.then(async e => {
                        let body = await e.json();
                        if (e.status === 200) {
                          if (value.anotacoes) {
                            value.anotacoes?.push({ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa });
                          }
                          else {
                            value.anotacoes = [{ data: body.data, anotacao: d.mensagem, pessoa: body.pessoa }];
                          }

                          mostrarToast({
                            duration: 2000,
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Sucesso na inclusão!'
                          });
                        }
                        else {
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro na inclusão!'
                          });
                        }
                      }).catch((e) => {
                        console.log(e);
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro na inclusão!'
                        });
                      }).finally(() => {
                        setShowLoadingPage(false);
                      });
                      return true;
                    }
                    else {
                      mostrarToast({
                        duration: 2000,
                        position: 'middle',
                        buttons: [{ text: 'ok', handler: () => fecharToast() }],
                        message: 'Digite uma anotação para incluir.'
                      });
                      setShowLoadingPage(false);
                      return false;
                    }
                  }
                }],
                onDidDismiss: (e) => { console.log('did dismiss'); }
              })
            }}>
              <IonIcon icon={pencil} style={{ padding: 0, margin: 0 }}></IonIcon>
              <IonLabel style={{ "fontSize": "8px", padding: 0, margin: 0 }}>Anotação</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton hidden={value.status != "Em andamento"} style={{ height: "25px", padding: 0, margin: 0 }} color='primary' onClick={(e) => {
              mostartAlerta({
                backdropDismiss: false,
                header: 'Encerrar operação',
                message: 'Deseja <strong>encerrar</strong>?',
                buttons: [
                  {
                    text: 'Cancelar',
                    role: 'cancel',
                  },
                  {
                    text: 'Confirmar',
                    cssClass: 'primary',
                    handler: () => {
                      setShowLoadingPage(true);
                      let retorno = encerrar2(value.pk);
                      if (retorno) {
                        retorno?.then(res => {
                          if (res.status === 200) {
                            value.pk = "zero";
                            mostrarToast({
                              duration: 2000,
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Operação encerrada com sucesso.'
                            });
                          }
                          else {
                            mostrarToast({
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Erro ao tentar encerrar.'
                            });
                          }
                        }).catch(() => {
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro ao tentar encerrar.'
                          });
                        }).finally(() => {
                          carregarOperacoesSegmentada(filtroStatusOperacao, filtroPesquisaOperacao);
                          setShowLoadingPage(false);
                        });
                        return true;
                      }
                      else {
                        mostrarToast({
                          position: 'middle',
                          buttons: [{ text: 'ok', handler: () => fecharToast() }],
                          message: 'Erro ao tentar encerrar.'
                        });
                        setShowLoadingPage(false);
                        return false;
                      }
                    }
                  }
                ],
                onDidDismiss: (e) => console.log('did dismiss'),
              })
            }}>
              <IonIcon icon={power} color={"danger"} style={{ padding: 0, margin: 0 }}></IonIcon>
              <IonLabel style={{ "fontSize": "8px", padding: 0, margin: 0 }}>Encerrar</IonLabel>
            </IonSegmentButton>

          </IonItem>
        </IonCardContent>
      </IonCard >
    )
  }

  function encerrar2(operacao: string) {

    return fetch(encerrarOperacao.url.replace("{id}", operacao.replace(/#/g, "%23")),
      {
        method: encerrarOperacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });
  }

  const loadItens = () => {
    if (arrayOperacoes === undefined || arrayOperacoes.length === 0) {
      return (
        <IonCard key="primeira">
          <IonCardHeader id="primeira" >
            <IonCardTitle>Neste momento não existe Operação.</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      )
    }

    return arrayOperacoes.map((value: any, index: any, arr: any) =>

      (!arr[index - 1]) ?
        (<>
          <IonCard className={"card"} key={Math.random()}>
            <IonCardHeader id={value.dataInicio}>
              <IonCardTitle>
                <div className={"cabecalho"} >{new Date(value.dataInicio).toLocaleString('pt-BR').substring(0, 10)}</div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
          {preencheCard(value, index)}
        </>)
        :
        (((new Date(value.dataInicio).toLocaleString('pt-BR').substring(0, 10) !== new Date(arr[index - 1].dataInicio).toLocaleString('pt-BR').substring(0, 10)))) ?
          (<>
            <IonCard className={"card"} key={Math.random()}>
              <IonCardHeader id={value.dataInicio}>
                <IonCardTitle>
                  <div className={"cabecalho"} >{new Date(value.dataInicio).toLocaleString('pt-BR').substring(0, 10)}</div>
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
            {preencheCard(value, index)}
          </>
          ) :
          (
            <>
              {preencheCard(value, index)}
            </>
          )
    );

  }

  // function cancelarScan() {
  //   setShowModalCodigoBarrasDigitar(false);
  //   setShowModalCodigoBarras(false);
  // }

  // function modoCodigoBarras() {
  //   if (showModalCodigoBarras) {
  //     //Esta com escaneamento ativo
  //     setShowModalCodigoBarras(false);
  //     setShowModalCodigoBarrasDigitar(true);
  //   } else {
  //     setShowModalCodigoBarras(true);
  //     setShowModalCodigoBarrasDigitar(false);
  //   }
  // }

  async function carregarOperacoesSegmentada(filtro: any, data: any, lojasParam?: any) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      console.log("valor", data);
      console.log("filtroCampoSelecionadoOperacao", filtroCampoSelecionadoOperacao);

      let valor = data;
      if (data) {
        valor = data.replaceAll(/#/g, "%23");
      }

      const resp: Response = await fetch(urlBase.url + "/operacoes?campo=" + (filtroCampoSelecionadoOperacao || "data") +
        "&valor=" + valor +
        "&filtro=" + filtro +
        "&lojas=" + Buffer.from(lojasParam || loja).toString('base64'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {
        if (res.status === 400 || res.status === 401) {
          setShowLoadingPage(false);
          history.replace("/login/");
        }

        if (res && res.LastEvaluatedKey) {
          setOperacaopk(res.LastEvaluatedKey.pk);
          setOperacaosk(res.LastEvaluatedKey.sk);
          setOperacaogsi2sk(res.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setDesabilitarInfiniteScroll(true);
        }

        setArrayOperacoes(res.Items);
        setShowLoadingPage(false);
      });
    }
    consultar();
  }

  async function carregarMaisOperacoes($event: CustomEvent<void>) {
    if (operacaogsi2sk.includes(filtroStatusOperacao)) {
      await carregarMaisOperacoesChamada().then(() => {
      });
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function carregarMaisOperacoesChamada() {
    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let valor = filtroPesquisaOperacao;
    if (filtroPesquisaOperacao) {
      valor = filtroPesquisaOperacao.replaceAll(/#/g, "%23");
    }


    console.log(valor);

    const res: Response = await fetch(urlBase.url + "/operacoes?campo=" + (filtroCampoSelecionadoOperacao || "data") +
      "&valor=" + valor +
      "&filtro=" + filtroStatusOperacao +
      "&isk=" + operacaogsi2sk +
      "&pk=" + operacaopk +
      "&sk=" + operacaosk +
      "&lojas=" + Buffer.from(loja).toString('base64'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (operacoes) => {
        for (let i = 0; i < operacoes.Items.length; i++) {
          arrayOperacoes.push(operacoes.Items[i]);
        }
        if (operacoes.LastEvaluatedKey) {
          setOperacaopk(operacoes.LastEvaluatedKey.pk);
          setOperacaosk(operacoes.LastEvaluatedKey.sk);
          setOperacaogsi2sk(operacoes.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setOperacaopk("");
          setOperacaosk("");
          setOperacaogsi2sk("");
          setDesabilitarInfiniteScroll(true);
        }
      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");

  }

  function tempoCorrido(horario: Date, horarioFim: Date) {
    var g = Math.ceil((horarioFim.getTime() - horario.getTime()) / 1000 / 60);
    if (g > 1440) {
      return Math.floor(g / 60).toString() + 'h';
    }
    else if (g > 60) {
      return Math.floor(g / 60).toString() + 'h' + ('0' + (g % 60).toString()).substr(-2) + '';
    }
    else {
      return g.toString() + 'min';
    }
  }

  function space(str: any, after: any) {
    setAlertaPreenchimento(false);

    if (!str) {
      setCodigoBarras('');
      return false;
    }
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp(".{" + after + "}", "g");
    setCodigoBarras(
      v.replace(reg, function (a: any) {
        return a + ' ';
      })
    );
  };

  function validarCampos() {
    if (nome.length == 0 || cpf.length != 14 || telefone.length != 15 || placa.length === 0) {
      setTextToast("Todos os campos são obrigatórios.");
      setShowToast(true);
      return false;
    }
    else {
      if (!validarCpfNovo(cpf)) {
        setTextToast("CPF Inválido.");
        setShowToast(true);
      }
      else if (!validator.isMobilePhone(telefone, 'pt-BR')) {
        setTextToast("Celular Inválido.");
        setShowToast(true);
      }
      else if (placa.length != 7) {
        setTextToast("Placa Inválida.");
        setShowToast(true);
      }
      else {
        return true;
      }
    }
  }

  function validarCpfNovo(val: any): boolean {
    if (val.length == 14) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    } else { return false; }
  }

  // function iniciarOperacao() {
  //   if (!validarCampos())
  //     return;

  //   if (codigoBarras === undefined || codigoBarras.replace(/\ /, '') === '') {

  //     setAlertaPreenchimento(true);
  //   }
  //   else {

  //     if (botaoAtivo) {
  //       setShowLoadingPage(true);
  //       setBotaoAtivo(false);
  //       setShowModalCodigoBarrasDigitar(false);

  //       let objRequest = {
  //         "motoristaNome": nome,
  //         "motoristaDocumento": cpf || "",
  //         "motoristaTelefone": telefone || "",
  //         "motoristaPlaca": placa || "",
  //         "tipoOperacao": tipoOperacao,
  //         // "tipoCarga": tipoCarga,
  //         "chaveAcesso": codigoBarras.replace(/ /g, ""),
  //         "loja": loja || ""
  //       };

  //       requestService(incluirOperacao.url, {
  //         method: incluirOperacao.method,
  //         headers: [
  //           ["token", window.localStorage.getItem("token")]
  //         ],
  //         body: JSON.stringify(objRequest),
  //         credentials: 'include'
  //       },
  //         history,
  //         (response: any) => {
  //           if (response.message) {
  //             setBotaoAtivo(true);
  //             setShowLoadingPage(false);
  //             setShowModalCodigoBarrasDigitar(false);
  //             carregarOperacoesSegmentada(filtroStatusOperacao, filtroPesquisaOperacao);
  //             setTextToast("Operação criada com sucesso!");
  //             setShowToast(true);
  //           }
  //         },
  //         (error: any) => {
  //           setTextToast("Erro ao tentar inserir! Tente novamente!");
  //           setShowToast(true);
  //           setBotaoAtivo(true);
  //           setShowLoadingPage(false);
  //         })

  //     }
  //   }
  // }

  async function handleSegmentChange(e: any) {
    setFiltroStatusOperacao(e.detail.value);
    await carregarOperacoesSegmentada(e.detail.value, filtroPesquisaOperacao);
  }

  const handleDismiss = () => {
    dismiss();
  };

  const [present, dismiss] = useIonModal(Acompanhar, {
    estabelecimento: "estabelecimento 1",
    codigoBarras: "codigo teste",
    operacaoid: operacaoId,
    onDismiss: handleDismiss
  });

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setShowModalData(true);
  };

  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          {/* <IonButtons slot="start">
           
              {
                ((!isPlatform('mobileweb') && isPlatform('mobile'))) ?
                  (
                    < IonButton onClick={() => {
                      history.goBack();
                    }}>
                      <IonIcon slot="icon-only" icon={arrowBack} />
                    </IonButton>
                  ) :
                  (
                    <IonButtons slot="start">
                      <IonMenuButton></IonMenuButton>
                    </IonButtons>
                  )
              }
            </IonButtons> */}

          {/* <IonTitle hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))}>Operações</IonTitle> */}

          <IonSelect interface='popover' style={{ fontSize: "14px" }} name='Filtros' title='Filtros' value={filtroCampoSelecionadoOperacao} multiple={false} cancelText="Cancelar" okText="Ok" onIonChange={e => { trocarCampoFiltro(e); }} slot="end">
            <IonSelectOption value="data">Data</IonSelectOption>
            <IonSelectOption value="nome">Clientes</IonSelectOption>
          </IonSelect>

          <IonButton style={{ width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={filtroMostrarDataOperacao} onClick={e => { setShowModalFiltro(true); }}>
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={filtroPesquisaOperacao != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaNome} </IonLabel>
          </IonButton>

          <IonButton style={{ width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={!filtroMostrarDataOperacao} onClick={e => { openPopover(e); }} >
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={filtroPesquisaOperacao != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaData} </IonLabel>
          </IonButton>

          <IonModal isOpen={showModalFiltro} onDidDismiss={() => setShowModalFiltro(false)} >
            <AppTypeaheadItem
              title="Clientes"
              camposFiltro="nome,telefone"
              campo="nome"
              items={listaClientesFiltro}
              selectedItem={filtroPesquisaOperacao}
              onSelectionCancel={() => setShowModalFiltro(false)}
              onSelectionChange={filtroSelectionChanged}
            />
          </IonModal>

          <IonButtons slot="end">
            <IonButton onClick={() => { setShowNotificacoes(true); }}>
              <IonIcon slot="icon-only" icon={notifications} />
              <IonBadge id="cart-badge" color="danger">{notificacoes?.length}</IonBadge>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
            <IonButton onClick={() => { history.push("./chat"); }}>
              <IonIcon slot="icon-only" icon={chatbubbles} />
              <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar style={{ height: "48px" }}>
          <IonSegment value={filtroStatusOperacao} onIonChange={(e) => { scrollToTop(); handleSegmentChange(e); }}>
            <IonSegmentButton style={{ height: "30px" }} value="Em" >
              <IonLabel style={{ fontSize: "14px" }}>Andamento</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="Encerrado" >
              <IonLabel style={{ fontSize: "14px" }}>Encerrados</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>

      </IonHeader>
      <IonContent fullscreen scrollEvents={true} ref={contentRef} color='warning'>
        <IonFab vertical="top" horizontal="end" slot="fixed" edge hidden={isPlatform("android") || isPlatform("ios")}>
          <IonFabButton color="tertiary" onClick={() => { carregarOperacoesSegmentada(filtroStatusOperacao, filtroPesquisaOperacao); }} >
            <IonIcon color="light" icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh} >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {/* <IonModal isOpen={showDetalhes} onDidDismiss={() => { setDesabilitarInfiniteScroll(false); setShowDetalhes(false) }}>
          <Acompanhar estabelecimento="estabelecimento 1" codigoBarras="codigo teste" operacaoid={operacaoId} onDismiss={() => { setDesabilitarInfiniteScroll(false); setShowDetalhes(false) }}></Acompanhar>

        </IonModal> */}

        <div className='OperacoesSlides'>
          {loadItens()}
        </div>

        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisOperacoes(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais operações..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonToast
          isOpen={showCopiado}
          onDidDismiss={() => setShowCopiado(false)}
          message="Campo copiado."
          duration={200}
        />

        <div className="content">

          {/* <IonModal isOpen={showModalCodigoBarrasDigitar} onDidPresent={() => { inputRef.current.setFocus(); }} onDidDismiss={() => setShowModalCodigoBarrasDigitar(false)} >
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle>Informe a Nota Fiscal</IonTitle>
                <IonButtons slot="start" onClick={() => { cancelarScan(); setShowModalCodigoBarrasDigitar(false); }}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
              <IonItem lines="none">
                <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
                <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "fontSize": "14px" }} >Campo obrigatório</IonLabel>
              </IonItem>
              <IonItem lines="none">
                <IonLabel className="ion-text-wrap" position="stacked">Informe a <b>chave de acesso</b> (código de barras) na <b>nota</b>:</IonLabel>
                <IonTextarea ref={(ref) => inputRef.current = ref} rows={3} inputMode="numeric" maxlength={54} value={codigoBarras} placeholder="clique aqui para digitar ou utilize o leitor de código de barras" onIonChange={e => space(e.detail.value!, 4)}></IonTextarea>
              </IonItem>
            </IonContent>
            <IonFooter className="ion-no-border" >
              <IonToolbar>
                <IonButton expand="block" size='large' disabled={!botaoAtivo} onClick={() => { iniciarOperacao() }}>Iniciar Operação!</IonButton>
                <IonButton expand="block" fill="clear" color="tertiary" onClick={() => { openScanner() }}>Utilizar câmera do celular</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>
          <IonModal isOpen={showModalCodigoBarras} onDidDismiss={() => setShowModalCodigoBarras(false)} className='my-custom-class camera' >
            <IonLabel>{codigoLido}</IonLabel>
            <IonLabel className="helper">Precisamos que informe o <b>código de barras</b> da nota.</IonLabel>
            <div className="actions" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <IonButton color="primary" onClick={() => { openScanner() }}>Leitor de Código de Barras</IonButton>
              <IonButton color="primary" onClick={modoCodigoBarras}>Digitar o Código de Barras</IonButton>
              <IonButton fill="clear" slot="end" color="tertiary" onClick={cancelarScan}>Cancelar</IonButton>
            </div>
          </IonModal> */}

          <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
            <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
          </IonModal>

          <IonToast
            isOpen={showToast}
            position="middle" //top, bottom, middle
            onDidDismiss={() => setShowToast(false)}
            message={textToast}
            duration={3000}
          />
        </div>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />
      </IonContent>

      <IonPopover ref={popover}
        isOpen={showModalData}
        onDidDismiss={() => setShowModalData(false)} >
        <IonDatetime
          presentation='date'
          onIonChange={e => {
            if (e.detail.value) {
              let data = e.detail.value.toString().substring(0, 10);
              const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                Date.parse(data)
              );
              setPesquisaData(dataFormatada);
              setFiltroPesquisaOperacao(data);
              carregarOperacoesSegmentada(filtroStatusOperacao, data);
            }
            //setShowModalData(false);
          }}></IonDatetime>

      </IonPopover >

    </IonPage>
  );
};

export default Operacoes;
