import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRadio, IonRadioGroup, IonTitle, IonToast, IonToolbar, useIonAlert } from '@ionic/react';
import { arrowBack, openOutline } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { requestService, usuarioCadastrar } from '../../../Utils/Services';
import './Cadastro.css';

const specialties = {
  // 'Assistência Técnica': ['Celular', 'Computador', 'Televisão', 'Ar condicionado', 'Lava roupa', 'Geladeira e freezer', 'Micro-ondas', 'Lava Louças', 'Maquina de costura'],
  // 'Reformas e Reparos': ['Aluguel de Maquinário', 'Arquiteto', 'Automação Residencial', 'Azulejista', 'Banheira', 'Casas e Chalés de Madeira', 'Chaveiro', 'Climatização', 'Coifas e Exaustores', 'Concretagem', 'Decorador', 'Dedetizador', 'Demolição', 'Desentupidor', 'Design de Interiores', 'Desinfecção', 'Diarista', 'Dutos', 'Eletricista', 'Empreiteiro', 'Encanador', 'Energia Solar', 'Engenheiro', 'Escada, Corrimão e Guarda-Corpo', 'Escavação', 'Fossa', 'Galvanização', 'Gesso e DryWall', 'Gás', 'Impermeabilizador', 'Instalador TV Digital', 'Instalação de eletrônicos', 'Instalação de Papel de Parede', 'Isolamentos Térmico e Acústico', 'Jardinagem', 'Lareira', 'Lazer e Recreação', 'Limpeza Pós Obra', 'Marceneiro', 'Marido de aluguel', 'Marmoraria e Granitos', 'Montador de Móveis', 'Mudanças e Carretos', 'Paisagista', 'Pavimentação', 'Pedreiro', 'Pintor', 'Piscina', 'Portão Automático', 'Poço Artesiano', 'Prevenção e Combate a Incêndio', 'Reciclagem', 'Redes de Proteção', 'Remoção de Entulho', 'Restauração e Polimento de Pisos', 'Sauna', 'Segurança Eletrônica', 'Segurança Perimetral', 'Serralheria e Solda', 'Sonorização de Ambientes', 'Tapeceiro', 'Terraplanagem', 'Toldos e Coberturas', 'Topografia', 'Vidraceiro'],
  // 'Serviços Domésticos': ['Diarista', 'Mensalista', 'Passadeira', 'Cozinheira', 'Babá', 'Pet Sitter', 'Motorista'],
  // 'Festas': ['Buffet', 'Salgados', 'Garçom', 'Bartender', 'Confeitaria', 'Música', 'Animação'],
  // 'Saúde': ['Personal Trainer', 'Cuidador de Pessoas', 'Enfermeira', 'Psicólogo', 'Nutricionista', 'Fisioterapeuta'],
  'Restaurantes': ['Pizzaria', 'Hamburgueria', 'Lanches', 'Salgados', 'Porções', 'Brasileiro', 'Italiano', 'Japonês', 'Chinês', 'Carnes', 'Frutos do Mar', 'Mediterrâneo', 'Mexicano', 'Vegetariano', 'Sorveteria'],
  // 'Estética': ['Cabeleireiro', 'Manicure', 'Pedicure', 'Especialista em depilação', 'Esteticista', 'Designer de sobrancelhas', 'Maquiadora', 'Barbeiro']
};

const Cadastro: React.FC = () => {

  const validator = require('validator');
  const TAB_KEY_CODE = 9;
  const ENTER_KEY_CODE = 13;
  const history = useHistory();
  const campoTelefone = useRef<any>(null);
  const [nome, setNome] = useState("");
  const [cpf, setCPF] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [endereco, setEndereco] = useState("");
  // const [dataAniversario, setDataAniversario] = useState("");
  // const [dataAniversarioBr, setDataAniversarioBr] = useState("");
  // const [showModalDataCad, setShowModalDataCad] = useState(false);
  // const popover1 = useRef<HTMLIonPopoverElement>(null);
  const [convite, setConvite] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [checked, setChecked] = useState(false);
  const [showLoading, setShowLoading] = useState(false);


  // const openPopoverCad = (e: any) => {
  //   popover1.current!.event = e;
  //   setShowModalDataCad(true);
  // };



  const [selectedArea, setSelectedArea] = useState<string>('');
  const [selectedSpecialties, setSelectedSpecialties] = useState<string[]>([]);

  const [mostartAlerta] = useIonAlert();
  const [localEnderecoSelecionado, setLocalEnderecoSelecionado] = useState<any>("");
  const [listaEnderecos, setListaEnderecos] = useState<any>([]);


  const handleAreaChange = (e: CustomEvent) => {
    setSelectedArea(e.detail.value);
    setSelectedSpecialties([]);
  };

  const handleSpecialtyChange = (specialty: string) => {
    setSelectedSpecialties((prevSelectedSpecialties) => {
      if (prevSelectedSpecialties.includes(specialty)) {
        return prevSelectedSpecialties.filter((item) => item !== specialty);
      } else {
        return [...prevSelectedSpecialties, specialty];
      }
    });
  };

  const validarCampos = () => {
    if (!nome) {
      setTextToast('O campo nome é obrigatório.');
      setShowToast(true);
      return false;
    }

    if (!cpf) {
      setTextToast('O campo CPF é obrigatório.');
      setShowToast(true);
      return false;
    }

    if (!telefone) {
      setTextToast('O campo telefone é obrigatório.');
      setShowToast(true);
      return false;
    }

    if (!email) {
      setTextToast('O campo email é obrigatório.');
      setShowToast(true);
      return false;
    }

    if (!endereco) {
      setTextToast('O campo endereço é obrigatório.');
      setShowToast(true);
      return false;
    }

    // if (!dataAniversarioBr) {
    //   setTextToast('O campo data de aniversário é obrigatório.');
    //   setShowToast(true);
    //   return false;
    // }

    return true; // Se todos os campos forem válidos
  };

  const cadastrar = () => {

    let validadorCelular = /^((\+?55\ ?[1-9]{2}\ ?)|(\+?55\ ?\([1-9]{2}\)\ ?)|(0[1-9]{2}\ ?)|(\([1-9]{2}\)\ ?)|([1-9]{2}\ ?))((\d{4}\-?\d{4})|(9[1-9]{1}\d{3}\-?\d{4}))$/;


    if (validarCampos()) {

      if (!selectedArea) {
        setTextToast("O campo área é obrigatório.");
        setShowToast(true);
      }
      else if (selectedSpecialties.length == 0) {
        setTextToast("O campo especialidade é obrigatório.");
        setShowToast(true);
      }
      else if (selectedSpecialties.length > 6) {
        setTextToast("Selecione até 6 especialidades.");
        setShowToast(true);
      }
      else if (checked == false) {
        setTextToast("Você precisa aceitar os termos de uso e política de privacidade para efetuar o cadastro.");
        setShowToast(true);
      }
      else {
        if (!validarCpfNovo(cpf)) {
          setTextToast("CPF Inválido.");
          setShowToast(true);
        }
        /*
        else if (!validator.isMobilePhone(telefone, 'pt-BR')) {
          setTextToast("Celular Inválido.");
          setShowToast(true);
        }
        */
        else if (!validadorCelular.test(telefone)) {
          setTextToast("Celular Inválido.");
          setShowToast(true);
        }
        else if (!validator.isEmail(email)) {
          setTextToast("E-mail Inválido.");
          setShowToast(true);
        }
        else {

          const objectRequest = {
            nomeValue: nome,
            cpfLoginValue: cpf.replace("-", "").replace(".", "").replace(".", ""),
            celularLoginValue: "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
            emailLoginValue: email,
            areaValue: selectedArea,
            especialidadeValue: selectedSpecialties,
            endereco: endereco,
            cupomValue: convite
          }

          setShowLoading(true);
          requestService(
            usuarioCadastrar.url.replace("{id}", cpf.replace("-", "").replace(".", "").replace(".", "")),
            {
              method: usuarioCadastrar.method,
              body: JSON.stringify(objectRequest),
              credentials: 'include'
            },
            history,
            (response: any) => {
              console.log(response);
              if (response) {
                window.localStorage.setItem("cpf", cpf.replace("-", "").replace(".", "").replace(".", ""));
                window.localStorage.setItem("telefone", "55" + telefone.replace(/\(/g, "").replace(/\)/g, "").replace(/\ /g, "").replace(/\-/g, ""));
                window.localStorage.setItem("token", response.token);
                window.localStorage.setItem("perfil", response.perfil);

                // setTextToast(response.message);
                // setShowToast(true);
                setShowLoading(false);
                history.replace("/cadastro-sucesso");
              }
            },
            (error: any) => {
              console.log(error);
              setShowLoading(false);
              setTextToast(error?.message);
              setShowToast(true);
              if (error.data) {
                console.log("entrou varios endereços");
                if (Array.isArray(error.data) && error.data.length > 0) {
                  setListaEnderecos(error.data);
                }
              }
            }
          )
        }
      }
    }
  }

  function readListaEnderecos() {
    return listaEnderecos.map((value: any, index: any) => (
      <IonItem
        key={"endereco" + value.place_id}
        button
        onClick={() => setLocalEnderecoSelecionado(value.formatted_address)}
        color={localEnderecoSelecionado === value.formatted_address ? 'tertiary' : ''}
      >
        <IonLabel>{value.formatted_address}</IonLabel>
      </IonItem>
    ));
  }


  function onChangeCPF(e: any) {

    //console.log(cpf.length);
    //console.log(e.target.value.length);
    e.persist();
    e.preventDefault();

    // if (e.keyCode === ENTER_KEY_CODE || e.keyCode === TAB_KEY_CODE) {
    //   e.target.value = e.target.value.substring(0, 14);
    //   setCPF(e.target.value.substring(0, 14));
    //   campoTelefone.current.setFocus();
    // }

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    console.log(cpfval);
    setCPF(cpfval);
  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();

    // if (e.keyCode === ENTER_KEY_CODE) {
    //   e.target.value = e.target.value.substring(0, 15);
    //   setTelefone(e.target.value.substring(0, 15));
    //   cadastrar();
    // }

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    console.log(telefone.length);
    console.log(e.target.value.length);

    let replaceAt = function (str: any, index: any, replacement: any) {
      return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    let form = "(__) _____-____";

    let val = e.target.value;

    let i = 1;
    if (telefone.length > 0) {
      i = 0;
    }
    let j = 0;
    let aumentou = false;

    if (val.length > telefone.length) {

      for (; i < form.length; i++) {
        if (j < val.length) {

          form = replaceAt(form, i, val.charAt(j))

          if (form.charAt(i + 1) === '(' || form.charAt(i + 1) === ')' || form.charAt(i + 1) === '-') {
            if (val.charAt(j + 1) !== form.charAt(i + 1)) {
              if (form.charAt(i + 1) === ')') {
                i++;
              }
              i++;
              aumentou = true;
              j++;
            } else {
              j++
            }
          } else {
            j++;
          }
        } else {
          break;
        }
      }
    } else {
      if (telefone.length === 1 || telefone.length === 11) {
        form = val.substr(0, val.length - 1);
      } else if (telefone.length === 5) {
        form = val.substr(0, val.length - 2);
      } else {
        form = val;
      }
      i = form.length;
    }
    setTelefone(form.substr(0, i));
  }


  function validarCpfNovo(val: any): boolean {
    if (val.length == 14) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    } else { return false; }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle>Criar sua conta</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"Cadastro"}>
        <IonLabel className="titulo"><strong>Conta de Autônomo</strong></IonLabel>
        <div className="input">
          <span>Nome*</span>
          <IonInput value={nome} autoCorrect='true' autoCapitalize='true' maxlength={50} placeholder="" onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
        </div>
        <div className="input">
          <span>CPF*</span>
          <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} />
        </div>
        <div className="input">
          <span>Telefone*</span>
          <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} />
        </div>
        <div className="input">
          <span>E-mail*</span>
          <IonInput value={email} autoCorrect='true' maxlength={50} type="email" placeholder="" onIonInput={(e: any) => setEmail(e.target.value)}></IonInput>
        </div>
        <div className="input">
          <span>Endereço*</span>
          <IonInput value={endereco} autoCorrect='true' autoCapitalize='true' maxlength={200} placeholder="" onIonInput={(e: any) => setEndereco(e.target.value)}></IonInput>
        </div>

        {/* <div className="input">
          <span> Data de Nascimento</span>
          <IonInput value={dataAniversarioBr} placeholder="Selecione" onClick={e => { openPopoverCad(e); }}></IonInput>
        </div> */}

        <div className="input">
          <span>Convite</span>
          <IonInput value={convite} maxlength={50} placeholder="" onIonInput={(e: any) => setConvite(e.target.value)}></IonInput>
        </div>


        <div className="input">
          <span>Em qual área você trabalha?*</span>
        </div>


        <div className="input">

          <IonRadioGroup value={selectedArea} onIonChange={handleAreaChange}>
            {Object.entries(specialties).map(([area, specialtiesArray]) => (
              <IonItem lines='none' key={area} className="radio-item">
                <IonLabel>{area}</IonLabel>
                <IonRadio slot="start" value={area} />
              </IonItem>
            ))}
          </IonRadioGroup>
        </div>


        <div className="input" hidden={!selectedArea}>
          <span>Qual sua especialidade?* (Selecione até 6 especialidades)</span>
        </div>

        <div className="input">
          {selectedArea && (
            <IonList>
              {selectedArea && specialties[selectedArea as keyof typeof specialties]?.map((specialty: string) => (
                <IonItem lines='none' key={specialty}>
                  <IonCheckbox
                    checked={selectedSpecialties.includes(specialty)}
                    onIonChange={() => handleSpecialtyChange(specialty)}
                  />
                  <IonLabel>{specialty}</IonLabel>

                </IonItem>
              ))}
            </IonList>
          )}
        </div>

        <div className="input" style={{ display: 'block', alignItems: 'center', paddingTop: '30px' }}>
          <IonCheckbox style={{ 'margin-right': '8px' }} checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
          Eu li e aceito os&nbsp;<a style={{ "color": "red" }} target='_blank' href={'https://xeguei-plt-welcome.s3.sa-east-1.amazonaws.com/termos/prestador/Termos_de_uso.pdf'}>Termos de Uso <IonIcon src={openOutline} /></a>&nbsp;e&nbsp;<a style={{ "color": "red" }} target='_blank' href={'https://xeguei-plt-welcome.s3.sa-east-1.amazonaws.com/termos/prestador/politica_de_privacidade.pdf'}>Política de Privacidade <IonIcon src={openOutline} /></a>
        </div>


        <IonButton onClick={cadastrar}>Cadastrar</IonButton>

        {/* <IonPopover ref={popover1}
          isOpen={showModalDataCad}
          onDidDismiss={() => setShowModalDataCad(false)} >
          <IonContent>
            <IonDatetime

              presentation='date'

              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataAniversarioBr(dataFormatada);
                  setDataAniversario(data);
                }
              }}></IonDatetime>
          </IonContent>
        </IonPopover > */}

        <IonModal isOpen={(Array.isArray(listaEnderecos) && listaEnderecos.length > 0)} onDidDismiss={() => { setListaEnderecos([]); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Selecione um endereço</IonTitle>
              <IonButtons slot="start" onClick={() => setListaEnderecos([])}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {readListaEnderecos()}
            </IonList>
          </IonContent>
          <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {

              mostartAlerta({
                backdropDismiss: false,
                header: 'Número e complemento',
                message: 'Adicione Número e complemento',
                inputs: [
                  {
                    name: 'mensagem',
                    type: 'textarea',
                    id: 'name2-id',
                    placeholder: 'Digite aqui o número e complemento',
                  }],
                buttons: ['Cancel', {
                  text: 'OK', handler: (d) => {
                    setEndereco(localEnderecoSelecionado + ", " + d.mensagem);
                  }
                }],
                onDidDismiss: (e) => { console.log('did dismiss'); }
              })

              setListaEnderecos([]);
            }}>Avançar</IonButton>
          </IonFooter>
        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={5000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
    </IonPage>
  );
};

export default Cadastro;
