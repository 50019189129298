import { IonActionSheet, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonPopover, IonRefresher, IonRefresherContent, IonRow, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, useIonAlert, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { albums, arrowBack, chatbox, closeOutline, documentOutline, logoUsd, power, printOutline, search, trophy } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { alterarStatusProjeto, listaEquipePorPerfil, projetoGerarAcompanhamento, projetoGerarCupom, projetoGerarCupomPdf, reabrirProjeto, requestService, urlBase } from '../../../../../Utils/Services';
import AppTypeaheadItem from '../../../../../components/AppTypeaheadItem';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Convidados from '../Convidados/Convidados';
import Parceria from '../Parceria/Parceria';
import './ProjetosHistorico.css';

const ProjetosHistorico: React.FC = () => {
  const history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [arrayProjetos, setArrayProjetos] = useState<any>([]);
  const [projetoId, setProjetoId] = useState("");
  const [projetoNome, setProjetoNome] = useState("");
  const [empresaId, setEmpresaId] = useState("");
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [projetopk, setProjetopk] = useState("");
  const [projetosk, setProjetosk] = useState("");
  const [projetogsi2sk, setProjetogsi2sk] = useState("");
  const [showCopiado, setShowCopiado] = useState(false);
  const [cliente, setCliente] = useState<any>();
  const [arquiteto, setArquiteto] = useState<any>();
  const [listaCliente, setListaCliente] = useState<any>([]);
  const [listaClientesFiltro, setListaClientesFiltro] = useState<any>([]);
  const [listaArquiteto, setListaArquiteto] = useState<any>([]);
  const [pesquisaData, setPesquisaData] = useState<any>("Pesquisar");
  const [pesquisaNome, setPesquisaNome] = useState<any>("Pesquisar");
  const [checked, setChecked] = useState(false);
  const [checkedInterno, setCheckedInterno] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState(true);
  const [showModalData, setShowModalData] = useState(false);
  const popover = useRef<HTMLIonPopoverElement>(null);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [mostartAlerta] = useIonAlert();
  const [mostrarToast, fecharToast] = useIonToast();
  const [showDetalhesConvidado, setShowDetalhesConvidado] = useState(false);
  const [showDetalhesFinanceiro, setShowDetalhesFinanceiro] = useState(false);
  const [tipo, setTipo] = useState<any>();
  const [comissao, setComissao] = useState<any>();
  const [polo, setPolo] = useState<any>();
  const [pontos, setPontos] = useState<any>();
  const [showDetalhesParceria, setShowDetalhesParceria] = useState(false);
  const [local, setLocal] = useState<any>("");
  const { loja, setLoja } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const [showNovoAgendamento, setShowNovoAgendamento] = useState(false);
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const [showModalServicoProduto, setShowModalServicoProduto] = useState(false);
  const [indiceWorkflow, setIndiceWorkflow] = useState("");
  const [statusWorkflow, setStatusWorkflow] = useState("");
  const [showWorkflowAvancar, setShowWorkflowAvancar] = useState(false);
  const [listaStatus, setListaStatus] = useState<any>([]);
  const { filtroCampoSelecionadoProjeto, setFiltroCampoSelecionadoProjeto } = useContext(MessageContext);
  const { filtroMostrarDataProjeto, setFiltroMostrarDataProjeto } = useContext(MessageContext);
  const { filtroPesquisaProjeto, setFiltroPesquisaProjeto } = useContext(MessageContext);
  const { filtroStatusProjeto, setFiltroStatusProjeto } = useContext(MessageContext);
  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);
  const { recarregarFinanceiro, setRecarregarFinanceiro } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const inputRef = useRef<any>(null);

  const [showAtualizarInformacoes, setShowAtualizarInformacoes] = useState(false);
  const [showEncerrar, setShowEncerrar] = useState(false);

  const inputAtualizarInformacoesRef = useRef<any>(null);

  const inputAgendamentoNovoRef = useRef<any>(null);
  const inputDetalhesClienteRef = useRef<any>(null);
  const inputDetalhesArquitetoRef = useRef<any>(null);
  const inputConvidadosRef = useRef<any>(null);

  const [showModalCliente, setShowModalCliente] = useState(false);
  const { pkUsuario, setPkUsuario } = useContext(MessageContext);
  const [clienteText, setClienteText] = useState<any>();

  const [showActionSheet, setShowActionSheet] = useState(false);

  const [dadosProjeto, setDadosProjeto] = useState<any>();

  const [selectedSegment, setSelectedSegment] = useState('');

  useEffect(() => {
    // primeira vez q entra e quando troca de loja
    console.log("entrou useEffect mudou loja - projetos", loja);
    console.log("entrou useEffect mudou loja - projetos", filtroStatusProjeto);
    setFiltroCampoSelecionadoProjeto("data");
    setFiltroMostrarDataProjeto(true);
    setFiltroStatusProjeto("Encerrado");
    // setRecarregarProjeto(false);
    carregarProjetosSegmentadaData("Encerrado", "");
    setPesquisaData('Pesquisar');
  }, []
  );

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu(window.localStorage.getItem("servicoTipoPlural") || "Projetos");
  })

  const filtroSelectionChanged = (filtroClientes: string) => {

    if (filtroClientes != "") {
      setFiltroPesquisaProjeto(filtroClientes);
      const cliente = listaClientesFiltro.find((prospect: any) => prospect.pk === filtroClientes);
      setPesquisaNome(cliente.nome);
    }
    else {
      setFiltroPesquisaProjeto("");
      setPesquisaNome("Pesquisar");
    }
    carregarProjetosSegmentadaData(filtroStatusProjeto, filtroClientes);
    setShowModalFiltro(false);
  };

  async function lerCLientesFiltro() {


    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23"));//.concat("?status=ativo");

    console.log("lojas", loja);
    setShowLoadingPage(true);
    const resp = await fetch(montarUrl.replace("{perfil}", "cliente"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });

    let equipe = await resp.json();
    setShowLoadingPage(false);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      let lista = (equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
      setListaClientesFiltro(lista);
    }

  }

  function trocarCampoFiltro(e: any) {
    setFiltroCampoSelecionadoProjeto(e.detail.value);

    if (e.detail.value === 'data') {
      console.log('entrou data');
      setFiltroMostrarDataProjeto(true);


    }
    else {
      console.log('entrou nome');
      lerCLientesFiltro();
      setFiltroMostrarDataProjeto(false);

    }
    setPesquisaNome("Pesquisar");
    setPesquisaData("Pesquisar");
    setFiltroPesquisaProjeto("");
    setArrayProjetos([]);
  }

  function doRefresh(event: any) {
    carregarProjetosSegmentadaData(filtroStatusProjeto, filtroPesquisaProjeto);
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  function carregarDetalhes(projetoid: any, abrirInserirAgendamento: boolean) {
    history.push("projetos/projetosdetalhes", { projetoid: projetoid, abrirInserirAgendamento: abrirInserirAgendamento })
  }

  function carregarWorkflow(configsLoja: any, projetoid: any) {
    history.push("projetos/projetosworkflow", { projetoid: projetoid })

  }

  function carregarServicos(projetoid: any) {
    history.push("projetos/projetosservicos", { id: projetoid.replaceAll(/#/g, "%23") })

  }

  function carregarProdutos(projetoid: any) {
    history.push("projetos/projetosprodutos", { id: projetoid.replaceAll(/#/g, "%23") })

  }

  async function gerarCupom(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", projetoid);

    setShowLoadingPage(true);
    const resp = await fetch(projetoGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarCupom.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom',
        message: '<strong>Cupom</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoadingPage(false);

  }

  async function gerarCupomPdf(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", projetoid);

    setShowLoadingPage(true);
    const resp = await fetch(projetoGerarCupomPdf.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarCupomPdf.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom PDF',
        message: '<strong>Cupom PDF</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoadingPage(false);

  }

  async function gerarAcompanhamento(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    console.log("projetoid", projetoid);

    setShowLoadingPage(true);
    const resp = await fetch(projetoGerarAcompanhamento.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarAcompanhamento.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {
      console.log("result", result);

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar PDF',
        message: '<strong>PDF</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoadingPage(false);

  }

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  function alterarStatus(pk: any, interno: boolean) {
    setShowLoadingPage(true);
    requestService(alterarStatusProjeto.url.replaceAll("{id}", pk.replaceAll(/#/g, "%23") + "?interno=" + interno), {
      method: alterarStatusProjeto.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      credentials: 'include'
    },
      history,
      (response: any) => {
        console.log(response);
        carregarProjetosSegmentadaData(filtroStatusProjeto, filtroPesquisaProjeto);
      },
      (error: any) => {
        console.log(error);
        history.replace("/login/");
      }).finally(() => {
        setShowLoadingPage(false);
      });
  }
  const getColor = (percentual: any) => {
    const percent = parseFloat(percentual?.toString().replace(/\./g, '').replace(/\,/g, '.') || 0);
    if (percent <= 33) {
      return "rgba(212, 37, 28, 0.5)"; // Vermelho com 70% de opacidade
    } else if (percent <= 66) {
      return "rgba(29, 52, 96, 0.5)"; // Azul com 70% de opacidade
    } else {
      return "rgba(51, 172, 27, 0.5)"; // Verde com 70% de opacidade
    }
  };

  function preencheCard(value: any, indice: any) {
    return (
      <IonCard className={"cardProjeto"} key={"projeto" + indice + value.pk}>
        <IonCardHeader id={value.pk} style={{ margin: 0 }}>
          <div className="tempo2">
            <div className="duracao">
              <IonLabel>{(value.statusWorkflow)}</IonLabel>
            </div>
          </div>
          <IonItem style={{ width: "100%", borderRadius: "10px 10px 0 0" }} lines="none" detail={true} onClick={() => {
            carregarDetalhes(value.pk.replaceAll(/#/g, '%23'), false);
          }} button>
            <IonLabel style={{ margin: 0 }} slot="start">
              {(window.localStorage.getItem("servicoTipo") || "Projeto") + " - " + value.nomeProjeto}
            </IonLabel>
            <IonLabel style={{ margin: 0 }} slot='end'>detalhes</IonLabel>
          </IonItem>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow key={"projetorow1" + indice + value.pk}>
              <IonCol>
                <div className="projeto">
                  {/* <IonLabel position="fixed" hidden={acessos?.projetos != 'edicao'}>
                    {
                      value.gsi4sk?.substr(-7) == "interno" ?
                        <IonIcon color="danger" icon={lockClosed}
                          onClick={() => {
                            alterarStatus(value.pk, false);
                          }} /> :
                        <IonIcon color="success" icon={lockOpen}
                          onClick={() => {
                            alterarStatus(value.pk, true);
                          }} />
                    } Cliente</IonLabel> */}
                  <IonLabel className="operacaoTexto">Cliente</IonLabel>

                  <IonLabel className="projetoTexto">{value.clienteNome}</IonLabel>


                  <IonIcon id="icone-chatbox" color="primary" icon={chatbox} hidden={value.clienteInterno}
                    onClick={(e) => {
                      history.push("projetos/mensagem", { projetoid: value.pk.replaceAll(/#/g, '%23'), projetonome: value.nomeProjeto });
                      //carregarVenda(value.vendaid.replaceAll(/#/g, '%23'), value.gsi2pk.replaceAll(/#/g, '%23'));
                    }} />
                  {/* {
                    acessos?.vendas == 'leitura' || acessos?.vendas == 'edicao' ?
                      value.vendaid ?
                        <IonIcon color="primary" icon={trendingUp}
                          onClick={(e) => {
                            carregarVenda(value.vendaid.replaceAll(/#/g, '%23'), value.gsi2pk.replaceAll(/#/g, '%23'));
                          }} /> :
                        <></>
                      : <></>
                  } */}

                </div>
                <div className="projeto">
                  <IonLabel></IonLabel>
                  <IonLabel className="projetoTexto"> <div>
                    <a target='_blank' href={'https://api.whatsapp.com/send?phone=' + value.clienteTelefone}>
                      <p style={{ color: "black" }}><b>{formataTelefone(value.clienteTelefone)}</b></p>
                    </a>
                  </div></IonLabel>
                </div>
              </IonCol>
            </IonRow>
            <IonRow key={"projetorow2" + indice + value.pk} hidden={!(value.responsavelid !== undefined && value.responsavelid !== '')}>
              <IonCol>
                <div className="operacao">
                  <IonLabel position="fixed">Arquiteto</IonLabel>
                  <IonLabel className="operacaoTexto">{value.responsavelNome}</IonLabel>
                </div>
                <div className="operacao">
                  <IonLabel></IonLabel>
                  <IonLabel className="operacaoTexto"> <div>
                    <a target='_blank' href={'https://api.whatsapp.com/send?phone=' + value.responsavelTelefone}>
                      <p style={{ color: "black" }}><b>{(value.responsavelid !== undefined && value.responsavelid !== '') ? formataTelefone(value.responsavelTelefone) : ""}</b></p>
                    </a>
                  </div></IonLabel>
                </div>

                <div>
                  <IonChip hidden={!(window.localStorage.getItem("parceria") ? true : false)} color={value.comissao ? 'success' : 'primary'}
                    onClick={(e) => {
                      setProjetoId(value.pk);
                      setComissao(value.comissao);
                      setTipo("Parceria");
                      setShowDetalhesParceria(true);
                    }}>
                    <IonIcon icon={logoUsd}></IonIcon>
                    <IonLabel style={{ height: "20px" }}>{value.comissao ? value.comissao : 'Parceria'}</IonLabel>
                  </IonChip>
                  <IonChip hidden={!(window.localStorage.getItem("parceria") ? true : false)} color={value.pontos ? 'success' : 'primary'}
                    onClick={(e) => {
                      setProjetoId(value.pk);
                      setPolo(value.polo);
                      setPontos(value.pontos);
                      setTipo("Pontos");
                      setShowDetalhesParceria(true);
                    }}>
                    <IonIcon icon={trophy} style={{ height: "20px" }}></IonIcon>
                    <IonLabel style={{ height: "20px" }}>{value.pontos ? value.pontos + ' - ' + value.polo : 'Pontos'}</IonLabel>
                  </IonChip>

                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol key={"projetorow4" + indice + value.pk}>
                <div className="operacao">
                  <IonLabel position="fixed">{window.localStorage.getItem("nomeLinhaDoTempo") || "Linha do tempo"}</IonLabel>

                  {value.layoutSimplificado ?
                    <IonLabel className="operacaoTexto">{value.statusWorkflow}</IonLabel>
                    :
                    <IonChip slot="end" color='primary' style={{ padding: "7px", margin: "0px", fontWeight: 'bold', fontSize: "24px" }} onClick={(e) => {
                      carregarWorkflow(value.configWorkflow, value.pk.replaceAll(/#/g, '%23'));
                    }}>
                      <IonIcon icon={albums} style={{ height: "20px" }}></IonIcon>
                      <IonLabel style={{ width: "auto" }}
                        className="ion-text-wrap">{value.statusWorkflow}</IonLabel>
                    </IonChip>
                  }
                </div>
              </IonCol>
            </IonRow>

            <IonRow hidden={window.localStorage.getItem("esconderValoresProjeto") == "true" || window.localStorage.getItem("servicoPrincipal") != "Pedidos"}>
              <IonCol key={"projetorow5" + indice + value.pk}>
                <div className="operacao">
                  <IonLabel position="fixed">Itens</IonLabel>


                  <IonChip slot="end" color='primary' style={{ padding: "7px", margin: "0px", fontWeight: 'bold', fontSize: "24px" }} onClick={(e) => {
                    carregarProdutos(value.pk);
                  }}>
                    <IonIcon icon={logoUsd} style={{ height: "20px" }}></IonIcon>
                    <IonLabel style={{ width: "auto" }}
                      className="ion-text-wrap">{value.valorTotalProdutos || "0,00"}</IonLabel>
                  </IonChip>

                </div>
              </IonCol>
            </IonRow>

            <IonRow hidden={window.localStorage.getItem("esconderValoresProjeto") == "true" || window.localStorage.getItem("servicoPrincipal") == "Pedidos"}>
              <IonCol key={"projetorow6" + indice + value.pk}>
                <div className="operacao">
                  <IonLabel position="fixed">Itens</IonLabel>


                  <IonChip slot="end" color='primary' style={{ padding: "7px", margin: "0px" }} onClick={(e) => {
                    carregarServicos(value.pk);
                  }}>
                    <IonIcon icon={logoUsd} style={{ height: "20px" }}></IonIcon>
                    <IonLabel style={{ width: "auto" }}
                      className="ion-text-wrap">{value.valorTotalServicos || "0,00"}</IonLabel>
                  </IonChip>

                </div>
              </IonCol>
            </IonRow>

            <IonRow hidden={!(value.servicos?.length > 0)}>

              <IonCol key={"projetorow7" + indice + value.pk}>
                <div className="operacao">

                  <IonLabel position="fixed">Status</IonLabel>

                  <div onClick={(e) => {
                    carregarServicos(value.pk);
                  }} style={{
                    position: "relative", height: "32px", width: "100%", backgroundColor: "#f5f5f5",
                    borderRadius: "20px"
                  }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        height: "100%",
                        width: `${parseFloat(value.percentualTotalServicos?.toString().replace(/\./g, '').replace(/\,/g, '.') || 0)}%`,
                        backgroundColor: getColor(value.percentualTotalServicos),
                        zIndex: "1",
                        borderRadius: "20px",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: "2"
                      }}
                    >
                      {value.percentualTotalServicos || "0"}%
                      {/* {value.layoutSimplificado ?
                        <IonLabel className="operacaoTexto">{value.statusWorkflow}</IonLabel>
                        :
                        <IonLabel className="operacaoTexto" onClick={(e) => {
                          carregarWorkflow(value.configWorkflow, value.pk.replaceAll(/#/g, '%23'));
                        }}>{value.statusWorkflow} </IonLabel>

                      } */}
                    </div>
                  </div>

                </div>
              </IonCol>
            </IonRow>

            <IonRow key={"projetorow3" + indice + value.pk}>
              <IonCol>
                <div className="operacao">
                  <IonLabel position="fixed">Anotações</IonLabel>
                </div>
                <div className="operacao">
                  <IonLabel key={"projetolabel" + indice + value.pk} className="operacaoTexto" style={{ margin: 0 }}>
                    {
                      value.anotacoes?.sort(function (a: any, b: any) {
                        if (a.data < b.data) {
                          return 1;
                        }
                        if (a.data > b.data) {
                          return -1;
                        }
                        return 0;
                      }).slice(0, 5).map((anotacoesValue: any) => (
                        <p key={"projetop" + indice + value.pk + anotacoesValue.data} style={{ color: "#000000", fontSize: "12px" }}><b>{new Date(anotacoesValue.data).toLocaleDateString() + " " + new Date(anotacoesValue.data).toLocaleTimeString()}</b> - {anotacoesValue.anotacao}</p>
                      ))}
                  </IonLabel>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonItem lines="none">

            <IonChip slot="end" color='primary' hidden={(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")}
              onClick={async (e) => {
                await gerarAcompanhamento(value.pk);
              }}>
              <IonIcon icon={documentOutline}></IonIcon>
              <IonLabel>Gerar PDF</IonLabel>
            </IonChip>




            <IonChip style={{ fontSize: "18px", borderRadius: '8px' }} hidden={!(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")} slot="end" color='primary' onClick={() => {
              setProjetoId(value.pk); setShowActionSheet(true);
            }}>
              <IonIcon icon={printOutline}></IonIcon>
              <IonLabel>Cupom</IonLabel>
            </IonChip>

          </IonItem>

          <IonItem style={{ padding: 0, margin: 0, "borderRadius": "10px" }} color="grey" lines='none' hidden={acessos?.projetos != 'edicao'}>
            <IonSegment value={selectedSegment} onIonChange={handleSegmentChange}>
              <IonSegmentButton hidden={value.status != "Encerrado"} style={{ height: "25px", padding: 0, margin: 0 }} color='primary' onClick={(e) => {
                mostartAlerta({
                  backdropDismiss: false,
                  header: 'Reabrir ' + (window.localStorage.getItem("servicoTipo") || "Projeto"),
                  message: 'Deseja <strong>reabrir</strong>?',
                  buttons: [
                    {
                      text: 'Cancelar',
                      role: 'cancel',
                    },
                    {
                      text: 'Confirmar',
                      cssClass: 'primary',
                      handler: () => {
                        setShowLoadingPage(true);
                        let retorno = reabrir2(value.pk);
                        if (retorno) {
                          retorno?.then(res => {
                            if (res.status === 200) {
                              value.pk = "zero";
                              mostrarToast({
                                duration: 2000,
                                position: 'middle',
                                buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                message: (window.localStorage.getItem("servicoTipo") || "Projeto") + " reaberto com sucesso."
                              });
                            }
                            else {
                              mostrarToast({
                                position: 'middle',
                                buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                message: 'Erro ao tentar reabrir.'
                              });
                            }
                          }).catch(() => {
                            mostrarToast({
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Erro ao tentar cancelado.'
                            });
                          }).finally(() => {
                            carregarProjetosSegmentadaData(filtroStatusProjeto, filtroPesquisaProjeto);
                            setShowLoadingPage(false);
                          });
                          return true;
                        }
                        else {
                          mostrarToast({
                            position: 'middle',
                            buttons: [{ text: 'ok', handler: () => fecharToast() }],
                            message: 'Erro ao tentar fechar.'
                          });
                          setShowLoadingPage(false);
                          return false;
                        }
                      }
                    }
                  ],
                  onDidDismiss: (e) => console.log('did dismiss'),
                })
              }}>
                <IonIcon icon={power} color={"success"} style={{ padding: 0, margin: 0 }}></IonIcon>
                <IonLabel style={{ "fontSize": "8px", padding: 0, margin: 0 }}>Reabrir</IonLabel>
              </IonSegmentButton>

            </IonSegment>
          </IonItem>
        </IonCardContent>
      </IonCard >
    )
  }

  function reabrir2(projeto: string) {

    return fetch(reabrirProjeto.url.replace("{id}", projeto.replaceAll(/#/g, "%23")),
      {
        method: reabrirProjeto.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });
  }

  const loadItens = () => {

    if (arrayProjetos === undefined || arrayProjetos.length === 0) {
      return (
        <IonCard key="primeira">
          <IonCardHeader key="primeiraheader" >
            <IonCardTitle>Neste momento não existe {window.localStorage.getItem("servicoTipo") || "Projeto"}.</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      )
    }

    return arrayProjetos.map((value: any, index: any, arr: any) =>

      (!arr[index - 1]) ?
        (<div key={"divprojetopai" + index}>
          <IonCard className={"card"} key={"projeto" + index}>
            <IonCardHeader key={"headerprojeto" + index}>
              <IonCardTitle key={"tituloprojeto" + index}>
                <div key={"divprojeto" + index} className={"cabecalho"} >{new Date(value.dataCadastro).toLocaleString('pt-BR').substring(0, 10)}</div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
          {preencheCard(value, index)}
        </div>)
        :
        (((new Date(value.dataCadastro).toLocaleString('pt-BR').substring(0, 10) !== new Date(arr[index - 1].dataCadastro).toLocaleString('pt-BR').substring(0, 10)))) ?
          (<div key={"divprojetopai" + index}>
            <IonCard className={"card"} key={"projeto" + index}>
              <IonCardHeader key={"headerprojeto" + index}>
                <IonCardTitle key={"tituloprojeto" + index}>
                  <div key={"divprojeto" + index} className={"cabecalho"} >{new Date(value.dataCadastro).toLocaleString('pt-BR').substring(0, 10)}</div>
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
            {preencheCard(value, index)}
          </div>
          ) :
          (
            <div key={"divprojetopai" + index}>
              {preencheCard(value, index)}
            </div>
          )
    );
  }

  async function carregarProjetosSegmentadaData(filtro: any, data: any, lojasParam?: any) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      console.log("valor", data);
      console.log("filtroCampoSelecionadoProjeto", filtroCampoSelecionadoProjeto);
      let valor = data;
      if (data) {
        valor = data.replaceAll(/#/g, "%23");
      }
      const resp: Response = await fetch(urlBase.url + "/projetos?campo=" + (filtroCampoSelecionadoProjeto || "data") +
        "&valor=" + valor +
        "&filtro=" + filtro +
        "&lojas=" + Buffer.from(lojasParam || loja).toString('base64'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          setShowLoadingPage(false);
          history.replace("/login/");
        }

        if (res && res.LastEvaluatedKey) {
          setProjetopk(res.LastEvaluatedKey.pk);
          setProjetosk(res.LastEvaluatedKey.sk);
          setProjetogsi2sk(res.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setDesabilitarInfiniteScroll(true);
        }

        setArrayProjetos(res.Items);
        setShowLoadingPage(false);
      });
    }
    consultar();
  }

  async function carregarMaisProjetos($event: CustomEvent<void>) {
    console.log("carregarMaisProjetos",);
    if (projetogsi2sk.includes(filtroStatusProjeto)) {
      await carregarMaisProjetosChamada().then(() => {
      });
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function carregarMaisProjetosChamada() {
    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let valor = filtroPesquisaProjeto;
    if (filtroPesquisaProjeto) {
      valor = filtroPesquisaProjeto.replaceAll(/#/g, "%23");
    }


    console.log(valor);

    const res: Response = await fetch(urlBase.url + "/projetos?campo=" + (filtroCampoSelecionadoProjeto || "data") +
      "&valor=" + valor +
      "&filtro=" + filtroStatusProjeto +
      "&isk=" + projetogsi2sk +
      "&pk=" + projetopk +
      "&sk=" + projetosk +
      "&lojas=" + Buffer.from(loja).toString('base64'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (projetos) => {

        if (projetos.Items) {
          for (let i = 0; i < projetos.Items.length; i++) {
            arrayProjetos.push(projetos.Items[i]);
          }

          if (projetos.LastEvaluatedKey) {
            setProjetopk(projetos.LastEvaluatedKey.pk);
            setProjetosk(projetos.LastEvaluatedKey.sk);
            setProjetogsi2sk(projetos.LastEvaluatedKey.gsi2sk);
            setDesabilitarInfiniteScroll(false);
          }
          else {
            setProjetopk("");
            setProjetosk("");
            setProjetogsi2sk("");
            setDesabilitarInfiniteScroll(true);
          }
        }

      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");

  }

  const handleSegmentChange = (e: any) => {
    const value = e.detail.value;
    setSelectedSegment(value);
  };

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setShowModalData(true);
  };

  async function closeModalEncerrar() {
    setShowEncerrar(false);
  }

  async function closeModalEncerrarSucess() {
    setShowEncerrar(false);
    carregarProjetosSegmentadaData(filtroStatusProjeto, filtroPesquisaProjeto);

  }

  async function closeModalConvidado() {
    setShowDetalhesConvidado(false);
  }

  async function closeModalSimpleConvidado() {
    setShowDetalhesConvidado(false);
  }


  async function closeModalParceria() {
    setShowDetalhesParceria(false);
    carregarProjetosSegmentadaData(filtroStatusProjeto, filtroPesquisaProjeto);
  }

  async function closeModalSimpleParceria() {
    setShowDetalhesParceria(false);
  }

  function getButtons() {
    let resultado: any = [
      {
        text: 'Cancelar',
        icon: closeOutline,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      },

    ];


    if (window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas") {


      resultado.push(
        {
          text: 'Imprimir TXT',
          icon: printOutline,
          handler: async () => {
            setShowActionSheet(false);
            await gerarCupom(projetoId);

          }

        },
        {
          text: 'Imprimir PDF',
          icon: documentOutline,
          handler: async () => {
            setShowActionSheet(false);
            await gerarCupomPdf(projetoId);

          }

        }
      );
    }
    else {

      resultado.push(
        {
          text: 'PDF',
          icon: documentOutline,
          handler: async () => {
            setShowActionSheet(false);
            await gerarAcompanhamento(dadosProjeto.pk);
          }
        }
      );
    }




    return resultado;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => {
              history.goBack();
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>

          <IonTitle>Histórico {(window.localStorage.getItem("servicoTipoPlural") || "Projetos")}</IonTitle>



          <IonSelect interface='popover' style={{ fontSize: "14px" }} name='Filtros' title='Filtros' value={filtroCampoSelecionadoProjeto} multiple={false} cancelText="Cancelar" okText="Ok" onIonChange={e => { trocarCampoFiltro(e); }} slot="end">
            <IonSelectOption value="data">Data</IonSelectOption>
            <IonSelectOption value="nome">Clientes</IonSelectOption>
          </IonSelect>

          <IonButton style={{ width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={filtroMostrarDataProjeto} onClick={e => { setShowModalFiltro(true); }}>
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={filtroPesquisaProjeto != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaNome} </IonLabel>
          </IonButton>

          <IonButton style={{ zIndex: '0', width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={!filtroMostrarDataProjeto} onClick={e => { openPopover(e); }} >
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={filtroPesquisaProjeto != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaData} </IonLabel>

          </IonButton>

          <IonModal isOpen={showModalFiltro} onDidDismiss={() => setShowModalFiltro(false)} >
            <AppTypeaheadItem
              title="Clientes"
              camposFiltro="nome,telefone"
              campo="nome"
              items={listaClientesFiltro}
              selectedItem={filtroPesquisaProjeto}
              onSelectionCancel={() => setShowModalFiltro(false)}
              onSelectionChange={filtroSelectionChanged}
            />
          </IonModal>
        </IonToolbar>
      </IonHeader>


      <IonContent fullscreen scrollEvents={true} ref={contentRef} color='warning'>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className='ProjetosSlides'>
          {loadItens()}
        </div>

        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisProjetos(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais itens..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonToast
          isOpen={showCopiado}
          onDidDismiss={() => setShowCopiado(false)}
          message="Campo copiado."
          duration={3000}
        />

        <div className="content">

        </div>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />
      </IonContent>

      <IonPopover ref={popover}
        isOpen={showModalData}
        onDidDismiss={() => setShowModalData(false)} >
        <IonDatetime
          presentation='date'
          onIonChange={e => {
            if (e.detail.value) {
              let data = e.detail.value.toString().substring(0, 10);
              const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                Date.parse(data)
              );
              setPesquisaData(dataFormatada);
              setFiltroPesquisaProjeto(data);
              carregarProjetosSegmentadaData(filtroStatusProjeto, data);
            }
          }}></IonDatetime>

      </IonPopover >

      <IonModal onDidPresent={() => {
        if (inputConvidadosRef.current) {
          inputConvidadosRef.current.setFocus();
        }
      }} isOpen={showDetalhesConvidado} onDidDismiss={() => { setShowDetalhesConvidado(false); }}>
        <Convidados projetoid={projetoId} inputRef={inputConvidadosRef} projetonome={projetoNome} empresaid={empresaId} closeAction={closeModalConvidado} closeActionSimple={closeModalSimpleConvidado}></Convidados>
      </IonModal>

      <IonModal isOpen={showDetalhesParceria} onDidDismiss={() => { setShowDetalhesParceria(false); }}>
        <Parceria projetoid={projetoId} tipo={tipo} comissao={comissao} pontos={pontos} polo={polo} closeAction={closeModalParceria} closeActionSimple={closeModalSimpleParceria}></Parceria>
      </IonModal>

      <IonModal isOpen={showModalServicoProduto} onDidDismiss={() => setShowModalServicoProduto(false)} initialBreakpoint={0.2} breakpoints={[0, 0.2]}>
        <IonContent className="ion-padding">
          <IonItem lines='none'>
            <IonLabel className="ion-text-wrap" style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'center' }}><b>Deseja adicionar os itens agora?</b></IonLabel>
          </IonItem>
          <div style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>

            <IonChip color="primary" hidden={window.localStorage.getItem("servicoPrincipal") != "Pedidos"}
              onClick={(e) => {
                setShowModalServicoProduto(false); carregarProdutos(projetoId);
              }}>
              {/* <IonIcon icon={cube} style={{ height: "20px" }}></IonIcon> */}
              <IonLabel style={{ height: "20px" }}>Sim</IonLabel>
            </IonChip>
            <IonChip color="primary" hidden={window.localStorage.getItem("servicoPrincipal") == "Pedidos"}
              onClick={(e) => {
                setShowModalServicoProduto(false); carregarServicos(projetoId);
              }}>
              {/* <IonIcon icon={construct}></IonIcon> */}
              <IonLabel style={{ height: "20px" }}>Sim</IonLabel>
            </IonChip>

            <IonChip color="primary"
              onClick={(e) => {
                setShowModalServicoProduto(false);
              }}>
              {/* <IonIcon icon={construct}></IonIcon> */}
              <IonLabel style={{ height: "20px" }}>Não</IonLabel>
            </IonChip>


          </div>
        </IonContent>
      </IonModal>

      <IonActionSheet
        backdropDismiss={true}
        translucent={true}
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        // cssClass='my-custom-class'        
        buttons={getButtons()}
      />

    </IonPage>
  );
};

export default ProjetosHistorico;
