// import '@capacitor-community/http';
import { Plugins } from '@capacitor/core';
import { IonButton, IonContent, IonInput, IonLabel, IonPage, IonToast, isPlatform } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { logar, requestService } from '../../../Utils/Services';
import './Login.css';
import imgTopo from './imgs/LogoCor.png';


const Login: React.FC = () => {
  const { Http } = Plugins;
  const history = useHistory();
  const [telefone, setTelefone] = useState("");
  const [senha, setSenha] = useState("");
  const [cpf, setCPF] = useState("");
  const [primeiroFoco, setPrimeiroFoco] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [textBotao, setTextBotao] = useState("Próximo");
  const campoCPF = useRef<any>(null);
  const campoTelefone = useRef<any>(null);
  const campoSenha = useRef<any>(null);
  const TAB_KEY_CODE = 9;
  const ENTER_KEY_CODE = 13;

  function onChangeCPF(e: any) {

    setPrimeiroFoco(true);
    e.persist();
    e.preventDefault();

    if (e.keyCode === ENTER_KEY_CODE || e.keyCode === TAB_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      campoTelefone.current.setFocus();
    }

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    setCPF(cpfval);
  }

  const logarEvent = () => {
    if (botaoAtivo) {
      setBotaoAtivo(false);
      setTextBotao("Aguarde...");

      let usuarioCPF = cpf.replace("-", "").replace(".", "").replace(".", "");

      const objectRequest = {
        telefone: "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
        dispositivoIos: isPlatform('ios')
      }

      requestService(
        logar.url.replace("{cpf}", usuarioCPF),
        {
          method: logar.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          body: JSON.stringify(objectRequest),
        },
        history,
        (response: any) => {
          if (response) {

            window.localStorage.setItem("cpf", usuarioCPF);
            window.localStorage.setItem("token", response.token);
            window.localStorage.setItem("perfil", response.perfil);

            window.localStorage.setItem("telefone", "55" + telefone.replace(/\(/g, "").replace(/\)/g, "").replace(/\ /g, "").replace(/\-/g, ""));

            if (response.perfil) {
              // window.localStorage.removeItem("lojaSelecionada");
              setCPF("");
              setSenha("");
              setTelefone("");
              setBotaoAtivo(true);
              setTextBotao("Entrar");

              history.push("/validar-otp");
            }
            else {
              history.replace("/empresa/inicio");
              //!(!isPlatform('mobileweb') && isPlatform('mobile')) ? history.replace("/empresa/painel") : history.replace("/empresa/inicio")
            }
          } else if (response.status === 400) {

          }
        },
        (error: any) => {
          setTextToast("Informações inválidas");
          setShowToast(true);
          setBotaoAtivo(true);
          setTextBotao("Entrar");
        }
      )
    }
  }

  function onKeyUpTelefone(e: any) {
    e.persist();
    e.preventDefault();


    if (e.keyCode === ENTER_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      logarEvent();
    }

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    let val = e.target.value;
    var telVal = val.replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})(\d+?)$/, "$1");
    setTelefone(telVal);
  }


  return (
    <IonPage>
      <IonContent className={"Login"}>
        <div id="blocoTopo"></div>
        <img src={imgTopo} alt="Logo" />
        <IonLabel className="titulo">Acesse aqui o Xeguei</IonLabel>
        <div className="input">
          <span>CPF</span>
          <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} onIonInput={(e: any) => setCPF(e.target.value)} />
        </div>
        <div className="input">
          <span>Telefone</span>
          <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} onIonInput={(e: any) => setTelefone(e.target.value)} />
        </div>
        <IonButton disabled={!botaoAtivo} onClick={() => { logarEvent(); }}>{textBotao}</IonButton>

        <div className="divisoria">
          <span></span>

        </div>
        <IonButton expand='block' fill="solid" color="tertiary" onClick={() => { history.push("/tipoconta"); }}>Criar sua conta</IonButton>        
        
        {/* <Link to="/ajuda"><IonLabel>Precisa de ajuda?</IonLabel></Link> */}
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
