import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonToolbar, useIonAlert, useIonToast } from '@ionic/react';
import { arrowBack, trash } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { removerNotificacao } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import "./Notificacoes.css";


type Props = {
  closeAction: Function;
  closeActionSimple: Function;
};

const Notificacoes: React.FC<Props> = (props: Props) => {
  const history = useHistory<any>();

  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showLoadingPage, setShowLoadingPage] = useState(false);

  const [mostartAlerta] = useIonAlert();
  const [mostrarToast, fecharToast] = useIonToast();

  async function funcRemoverLoja(pk: string) {

    let resp = await fetch(removerNotificacao.url.replace("{id}", pk.replace(/#/g, "%23")),
      {
        method: removerNotificacao.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include'
      });

    let retorno = await resp.json();

    console.log("retorno", retorno);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    return resp;
  }

  const loadNotificacoes = () => {
    return notificacoes?.sort(function (a: any, b: any) {
      if (a.data < b.data) {
        return 1;
      }
      if (a.data > b.data) {
        return -1;
      }
      return 0;
    }).map((value: any) => (

      <IonItem lines="none">

        <IonLabel className="ion-text-wrap" onClick={() => {
          if (value.tipo == "mensagem") {
            history.push("./projetos/mensagem", { projetoid: value.pkUrl.replaceAll(/#/g, '%23'), projetonome: value.assunto });
            props.closeActionSimple();
          }
          else if (value.tipo == "vendasdetalhes") {
            history.push("./vendas/vendasdetalhes", { vendaid: value.pkUrl.replaceAll(/#/g, '%23'), abrirInserirAgendamento: false });
            props.closeActionSimple();
          }
          else if (value.tipo == "projetosdetalhes") {
            history.push("./projetos/projetosdetalhes", { projetoid: value.pkUrl.replaceAll(/#/g, '%23'), abrirInserirAgendamento: false });
            props.closeActionSimple();
          }
          else if (value.tipo == "projetosworkflow") {
            history.push("./projetos/projetosworkflow", { projetoid: value.pkUrl.replaceAll(/#/g, '%23'), abrirInserirAgendamento: false });
            props.closeActionSimple();
          }
          else if (value.tipo == "agendamentosdetalhes") {
            history.push("./agendamentos/agendamentosdetalhes", { agendamentoid: value.pkUrl.replaceAll(/#/g, '%23') });
            props.closeActionSimple();
          }
          else if (value.tipo == "acompanhar") {
            history.push("./operacoes/acompanhar", { operacaoid: value.pkUrl.replaceAll(/#/g, '%23') });
            props.closeActionSimple();
          }
          else {
            mostartAlerta({
              backdropDismiss: false,
              header: 'Notificação recebida',
              message: value.assunto + (' - ' + value.mensagem),
              buttons: [
                {
                  text: 'OK',
                  cssClass: 'primary',
                  handler: () => {
                    return true;
                  }
                }
              ],
              onDidDismiss: (e) => console.log('did dismiss'),
            })
          }
        }
        }>
          <h3 style={{ "verticalAlign": "middle" }}><strong>{new Date(value.data).toLocaleDateString() + " " + new Date(value.data).toLocaleTimeString()}</strong></h3>
          <h3>{value.assunto + (' - ' + value.mensagem)}</h3>
        </IonLabel>
        <IonIcon icon={trash} color="danger" style={{ fontSize: "20px" }} onClick={() =>
          mostartAlerta({
            backdropDismiss: false,
            header: 'Remover Notificação',
            message: 'Realizar <strong>remoção</strong>?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Remover',
                cssClass: 'primary',
                handler: () => {
                  remover(value.id);
                  return true;
                }
              }
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
          })
        }>
        </IonIcon>

      </IonItem>

    ))
  }

  function remover(id: any) {
    setShowLoadingPage(true);
    let retorno = funcRemoverLoja(id);
    if (retorno) {
      retorno?.then(res => {
        if (res.status === 200) {
          console.log("res", res)
          mostrarToast({
            duration: 2000,
            position: 'middle',
            buttons: [{ text: 'ok', handler: () => fecharToast() }],
            message: 'Removido com sucesso.'
          });
        }
        else {
          mostrarToast({
            position: 'middle',
            buttons: [{ text: 'ok', handler: () => fecharToast() }],
            message: 'Erro ao tentar remover.'
          });
        }
      }).catch(() => {
        mostrarToast({
          position: 'middle',
          buttons: [{ text: 'ok', handler: () => fecharToast() }],
          message: 'Erro ao tentar remover.'
        });
      }).finally(() => {
        setShowLoadingPage(false);
      });
    }
    else {
      mostrarToast({
        position: 'middle',
        buttons: [{ text: 'ok', handler: () => fecharToast() }],
        message: 'Erro ao tentar remover.'
      });
      setShowLoadingPage(false);
    }
  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { props.closeActionSimple();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonLabel>Notificações</IonLabel>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false} scrollEvents={true}>
        <IonList>

          {loadNotificacoes()}

        </IonList>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />

      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton size='large' expand="block" onClick={() => {
            remover("usuarios#" + (window.localStorage.getItem("cpf") || ""));
            props.closeAction();
          }}>Limpar notificações</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );


};

export default ({ closeAction, closeActionSimple }: { closeAction: Function, closeActionSimple: Function }) => (
  <Notificacoes closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </Notificacoes>
)