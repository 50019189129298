import { IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonRow, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { add, arrowBack, list, trash } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { empresaAlterar, empresaCarregar, removerFormularioCategoriaEmpresa, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import "./FormularioCategorias.css";

type Props = {
  abrirMenu: boolean;
  projetoid: string;
  empresaid: string;
  configs: Object;
  abrirInserirAgendamento: boolean;
  onDismiss: () => void;
};

const FormularioCategorias: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [listaCategoriaFormulario, setListaCategoriaFormulario] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [nome, setNome] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");


  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const inputRef = useRef<any>(null);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarConta();
    if (inputRef.current) {
      inputRef.current.setFocus();
    }
  });


  function carregarConta() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(empresaCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setListaCategoriaFormulario(response.Item.categoriaFormulario || []);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }



  function deletarCategoria(nome: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delCategoria = async () => {
      const resp = await fetch(removerFormularioCategoriaEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerFormularioCategoriaEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'nome': nome }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno", retorno);
      setTextToast("Categoria removida com sucesso.");
      setShowToast(true);
      carregarConta();
    }

    delCategoria();

  }

  const loadCategoriaFormulario = () => {

    console.log();
    if (listaCategoriaFormulario == undefined) return;

    if (listaCategoriaFormulario.length === 0) {
      return (

        <IonItem lines="full" key={"semAgenda"}>

          <IonLabel>
            <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhuma categoria cadastrada</strong></h2>
          </IonLabel>

        </IonItem>


      );
    }

    return listaCategoriaFormulario.map((value: any, index: any) => (
      <IonItem lines="full" key={index}>
        <IonIcon icon={list} slot="start"> </IonIcon>
        <IonLabel>
          {value}
        </IonLabel>
        <IonIcon slot="end" color='primary' icon={trash} onClick={() => { deletarCategoria(value) }} />



      </IonItem>
    ));
  }

  function cadastrar() {
    const objectRequest = {
      nome: nome
    }
    console.log("objectRequest", objectRequest);

    let id = loja;

    console.log("id", id);

    requestService(
      empresaAlterar.url.replace("{id}", id.replaceAll("#", "%23")).concat("?formulariocategoria=sim"),
      {
        method: empresaAlterar.method,
        body: JSON.stringify(objectRequest),
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
      history,
      (response: any) => {
        console.log(response);
        if (response) {
          setTextToast("Cadastro realizado com sucesso.");
          setShowToast(true);

          carregarConta();
          setShowDetalhes(false);

        } else if (response.status === 400) {
          console.log(response);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);

        }
        setShowLoading(false);
      },
      (error: any) => {
        console.log(error);
        setTextToast("Ocorreu um erro ao efetuar o cadastro.");
        setShowToast(true);
        setShowLoading(false);
      })
  }

  return (

    <IonPage className="FormularioCategorias">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Categorias do Formulário</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            setNome("");
            setShowDetalhes(true);
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonList>
          {loadCategoriaFormulario()}

        </IonList>

        <IonModal onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
          setNome("");
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowDetalhes(false)} />
                </IonButtons>
                <IonTitle>Nova categoria</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonList>

                      <IonItem lines="none">
                        <IonLabel position="stacked">Nome*</IonLabel>
                        <IonInput placeholder="Digite o nome da categoria" value={nome} ref={(ref) => inputRef.current = ref} autoCorrect='true' autoCapitalize='true' required onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonContent>
            <IonFooter className="ion-no-border">
              <IonToolbar>
                <IonButton onClick={() => cadastrar()} expand="block" type="submit">Cadastrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default FormularioCategorias;