import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { finalizarProjeto, requestService } from '../../../../../Utils/Services';
import './Parceria.css';

type Props = {
  projetoid: any;
  comissao: any;
  pontos: any;
  polo: any;
  tipo: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const Parceria: React.FC<Props> = (props: Props) => {

  const history = useHistory();
  const location = useLocation<any>();

  const [textToast, setTextToast] = useState("");
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [showLoading, setShowLoading] = useState(false);


  const [comissao, setComissao] = useState<any>();
  const [polo, setPolo] = useState<any>();
  const [pontos, setPontos] = useState<any>();

  const [showIncluirUsuario, setShowIncluirUsuario] = useState(false);

  useEffect(() => {

    if (props.projetoid != "") {
      setComissao(props.comissao || "");
      setPontos(props.pontos || "");
      setPolo(props.polo || "");





      console.log("entrou no useEffect");
    }
  }, [props.projetoid]);

  let params: any = {
    projetoid: "",
    comissao: "",
    pontos: "",
    polo: "",
  }
  params = useParams();

  if (location && location.state && location.state.projetoid && location.state.tipo) {
    params.projetoid = location.state.projetoid;
    params.tipo = location.state.tipo;
  }

  if (location && location.state && location.state.comissao) {
    params.comissao = location.state.comissao;
  }

  if (location && location.state && location.state.polo && location.state.pontos) {
    params.polo = location.state.polo;
    params.pontos = location.state.pontos;
  }



  if (props.projetoid && props.tipo) {
    params = {
      projetoid: props.projetoid,
      tipo: props.tipo
    }
  }


  if (props.polo && props.pontos) {
    params.polo = props.polo;
    params.pontos = props.pontos;
  }


  if (props.comissao) {
    params.comissao = props.comissao;
  }


  function cadastrar() {

    if (props.tipo == "Parceria") {
      if (comissao != "" && !parseFloat(comissao)) {
        setComissao("");
        setTextToast("Valor da comissão inválido");
        setShowErro(true);
        return;
      }
    }
    else {
      if (polo == "") {
        setTextToast("Selecione um polo");
        setShowErro(true);
        return;
      }
    }
    setShowIncluirUsuario(true);
  }

  function onChangeComissao(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    setComissao(v);
  }


  return (
    <>

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeAction()} />
          </IonButtons>
          <IonTitle>{props.tipo}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList>

                <IonItem lines="none" hidden={props.tipo == "Pontos"}>
                  <IonLabel position="stacked">Comissão</IonLabel>
                  <IonInput type="tel" maxlength={14} placeholder="Digite o valor" value={comissao} onKeyUp={onChangeComissao} />
                </IonItem>


                <IonItem lines="none" hidden={props.tipo == "Parceria"}>
                  <IonLabel position="stacked">Polo*</IonLabel>
                </IonItem>

                <IonItem lines="none" hidden={props.tipo == "Parceria"}>

                  <IonLabel position="stacked">Polos</IonLabel>

                  <IonSelect name='Polos' placeholder='Selecione ao Polo' title='Lojas' value={polo} multiple={false}
                    cancelText="Cancel"
                    okText="Ok" onIonChange={e => {
                      console.log(e.detail.value);
                      setPolo(e.detail.value);
                    }} slot="end">
                    {JSON.parse(window.localStorage.getItem("polos") || "[]").map((valor: any) => (
                      <IonSelectOption value={valor.nome}>{valor.nome}</IonSelectOption>
                    ))}
                  </IonSelect>

                </IonItem>

                <IonItem lines="none" hidden={props.tipo == "Parceria"}>
                  <IonLabel position="stacked">Pontos</IonLabel>
                  <IonInput placeholder="Digite os pontos" type="number" maxlength={5} value={pontos} required onIonInput={(e: any) => setPontos(e.target.value)}></IonInput>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert isOpen={showIncluirUsuario}
          onDidDismiss={() => setShowIncluirUsuario(false)}
          header={props.tipo}
          message={'Você <strong>confirma</strong> o lançamento de ' + (props.tipo == 'Pontos' ? pontos || '0' + " pontos?" : comissao || '0' + " reais?")}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                const objectRequest = {
                  projetoid: props.projetoid,
                  comissao: comissao,
                  polo: polo,
                  pontos: pontos
                }
                console.log("objectRequest", objectRequest);

                requestService(finalizarProjeto.url.replace("{id}", props.projetoid.replaceAll(/#/g, "%23")).concat("?tipo=").concat(props.tipo), {
                  method: finalizarProjeto.method,
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include',
                  body: JSON.stringify(objectRequest)
                },
                  history,
                  (response: any) => {
                    props.closeAction();
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                  });

              }
            }
          ]}
        />

      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit">SALVAR</IonButton>
        </IonToolbar>
      </IonFooter>



      <IonAlert
        isOpen={showSucesso}
        onDidDismiss={() => setShowSucesso(false)}
        header={'Sucesso'}
        message={textToast}
        buttons={['OK']}
      />

      <IonAlert
        isOpen={showErro}
        onDidDismiss={() => setShowErro(false)}
        header={'Erro'}
        message={textToast}
        buttons={['OK']}
      />

      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => { setShowLoading(false); }}
        spinner='circles'
      />

    </>
  );
};

export default ({ projetoid, comissao, pontos, polo, tipo, closeAction, closeActionSimple }: { projetoid: any, comissao: any, pontos: any, polo: any, tipo: any, closeAction: Function, closeActionSimple: Function }) => (
  <Parceria projetoid={projetoid} comissao={comissao} pontos={pontos} polo={polo} tipo={tipo} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </Parceria>
)
