// import '@capacitor-community/http';
import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonDatetime, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPopover, IonRow, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { alteraEquipe, cadastraEquipe, requestService } from '../../../../../Utils/Services';
import './PrestadoresCad.css';

type MyModalProps = {
  usuario: any;
  inputRef: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const PrestadoresCad: React.FC<MyModalProps> = (props: MyModalProps) => {

  const history = useHistory();

  const { loja } = useContext(MessageContext);

  const [textToast, setTextToast] = useState("");
  const [primeiroFoco, setPrimeiroFoco] = useState(false);
  const [pk, setPk] = useState("");
  const [retornoPk, setRetornoPk] = useState("");
  const [cpf, setCPF] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [endereco, setEndereco] = useState("");
  const [dataAniversario, setDataAniversario] = useState("");
  const [dataAniversarioBr, setDataAniversarioBr] = useState("");
  const campoTelefone = useRef<any>(null);
  const [selected, setSelected] = useState<any>("prestador");
  const [selectedColor, setSelectedColor] = useState<any>();
  const [showIncluirUsuario, setShowIncluirUsuario] = useState(false);
  const [alteracao, setAlteracao] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [lojas, setLojas] = useState<string>(loja);
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [checkedAtivo, setCheckedAtivo] = useState(true);
  const [showModalDataCad, setShowModalDataCad] = useState(false);
  const popover1 = useRef<HTMLIonPopoverElement>(null);

  const [chamadaRealizada, setChamadaRealizada] = useState(false);
  if (!chamadaRealizada) {
    console.log("Cad", props.usuario);
    if (props.usuario) {

      setChamadaRealizada(true);
      setAlteracao(true);
      setPk(props.usuario.pk);
      setCPF(formatCPF(props.usuario.cpf));
      setNome(props.usuario.nome);
      setSelectedColor(props.usuario.color);
      setEndereco(props.usuario.endereco);
      setCheckedAtivo(props.usuario.statusUsuario == "ativo");
      setEmail(props.usuario.email);
      setTelefone(formataTelefone(props.usuario.telefone));
      if (props.usuario.dataAniversario) {
        setDataAniversario(props.usuario.dataAniversario);
        setDataAniversarioBr(new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(

          Date.parse(props.usuario.dataAniversario)
        ));
      }
      setSelected(props.usuario.perfil);
      setLojas(loja);
    }
  }

  useEffect(() => {
    // Define o foco no input quando o componente é montado
    if (props.inputRef.current) {
      props.inputRef.current.focus();
    }
  }, [props.inputRef]);

  function onChangeCPF(e: any) {

    setPrimeiroFoco(true);
    e.persist();
    e.preventDefault();


    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCPF(cpfval);

  }

  function onKeyUpTelefone(e: any) {

    e.persist();
    e.preventDefault();


    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    let val = e.target.value;

    const maskPhone = val
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})(\d+?)$/, "$1");
    setTelefone(maskPhone);

  }

  function formatCPF(e: string) {
    let cpfval = e.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    console.log(cpfval);
    return cpfval;
  }

  const openPopoverCad = (e: any) => {
    popover1.current!.event = e;
    setShowModalDataCad(true);
  };

  function formataTelefone(numero: any) {
    if (numero?.length == 13) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else if (numero?.length == 12) {
      let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
      BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
      return BRNumber;
    }
    else { return "" };
  }

  function cadastrar() {

    console.log("aaaa", selected);

    if (nome.length == 0 || cpf.length != 14 || telefone.length != 15 || selected === undefined || selected === "" ||
      (
        (window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0 && lojas.length < 1)
      )
    ) {

      setTextToast("Todos os campos são obrigatórios, exceto o email.");
      setShowErro(true);
    }
    else {
      setShowIncluirUsuario(true);
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} />
          </IonButtons>
          <IonTitle>Cadastro de entregadores</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonList>
                <IonItem lines="none">
                  <IonLabel position="stacked">Nome*</IonLabel>
                  <IonInput placeholder="Digite o nome completo" value={nome} ref={(ref) => props.inputRef.current = ref} autoCorrect='true' autoCapitalize='true' required onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="stacked">CPF*</IonLabel>
                  <IonInput disabled={alteracao} type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="stacked">Telefone*</IonLabel>
                  <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} />
                </IonItem>

                <IonItem lines="none">
                  <IonLabel position="stacked">E-mail</IonLabel>
                  <IonInput value={email} type='email' autoCorrect='true' placeholder="Digite o e-mail" onIonInput={(e: any) => setEmail(e.target.value)}></IonInput>
                </IonItem>


                <IonItem lines="none">
                  <IonLabel position="stacked">Aniversário</IonLabel>
                  <IonInput value={dataAniversarioBr} placeholder="Selecione" onClick={e => { openPopoverCad(e); }}></IonInput>
                </IonItem>

                <IonItem lines="none" hidden={!alteracao} >
                  <IonLabel>Ativo</IonLabel>
                  <IonToggle slot='end' checked={checkedAtivo} onIonChange={e => {
                    setCheckedAtivo(e.detail.checked);
                  }} />

                </IonItem>

                <IonItem lines="none">
                  <IonLabel position='stacked'>Cor</IonLabel>
                </IonItem>
                <IonItem lines="none">
                  <IonGrid>
                    <IonRow>
                      <IonCol style={{ "background": "#FF6666" }} onClick={() => { setSelectedColor("#FF6666"); }}> </IonCol>
                      <IonCol style={{ "background": "#FFB266" }} onClick={() => { setSelectedColor("#FFB266"); }}> </IonCol>
                      <IonCol style={{ "background": "#FFFF66" }} onClick={() => { setSelectedColor("#FFFF66"); }}> </IonCol>
                      <IonCol style={{ "background": "#B2FF66" }} onClick={() => { setSelectedColor("#B2FF66"); }}> </IonCol>
                      <IonCol style={{ "background": "#66FF66" }} onClick={() => { setSelectedColor("#66FF66"); }}> </IonCol>
                      <IonCol style={{ "background": "#66FFB2" }} onClick={() => { setSelectedColor("#66FFB2"); }}> </IonCol>
                      <IonCol style={{ "background": "#66FFFF" }} onClick={() => { setSelectedColor("#66FFFF"); }}> </IonCol>
                      <IonCol style={{ "background": "#66B2FF" }} onClick={() => { setSelectedColor("#66B2FF"); }}> </IonCol>
                      <IonCol style={{ "background": "#6666FF" }} onClick={() => { setSelectedColor("#6666FF"); }}> </IonCol>
                      <IonCol style={{ "background": "#B266FF" }} onClick={() => { setSelectedColor("#B266FF"); }}> </IonCol>
                      <IonCol style={{ "background": "#FF66FF" }} onClick={() => { setSelectedColor("#FF66FF"); }}> </IonCol>
                      <IonCol style={{ "background": "#FF66B2" }} onClick={() => { setSelectedColor("#FF66B2"); }}> </IonCol>
                      <IonCol style={{ "background": "#C0C0C0" }} onClick={() => { setSelectedColor("#C0C0C0"); }}> </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol style={{ "background": "#FF9999" }} onClick={() => { setSelectedColor("#FF9999"); }}> </IonCol>
                      <IonCol style={{ "background": "#FFCC99" }} onClick={() => { setSelectedColor("#FFCC99"); }}> </IonCol>
                      <IonCol style={{ "background": "#FFFF99" }} onClick={() => { setSelectedColor("#FFFF99"); }}> </IonCol>
                      <IonCol style={{ "background": "#CCFF99" }} onClick={() => { setSelectedColor("#CCFF99"); }}> </IonCol>
                      <IonCol style={{ "background": "#99FF99" }} onClick={() => { setSelectedColor("#99FF99"); }}> </IonCol>
                      <IonCol style={{ "background": "#99FFCC" }} onClick={() => { setSelectedColor("#99FFCC"); }}> </IonCol>
                      <IonCol style={{ "background": "#99FFFF" }} onClick={() => { setSelectedColor("#99FFFF"); }}> </IonCol>
                      <IonCol style={{ "background": "#99CCFF" }} onClick={() => { setSelectedColor("#99CCFF"); }}> </IonCol>
                      <IonCol style={{ "background": "#9999FF" }} onClick={() => { setSelectedColor("#9999FF"); }}> </IonCol>
                      <IonCol style={{ "background": "#CC99FF" }} onClick={() => { setSelectedColor("#CC99FF"); }}> </IonCol>
                      <IonCol style={{ "background": "#FF99FF" }} onClick={() => { setSelectedColor("#FF99FF"); }}> </IonCol>
                      <IonCol style={{ "background": "#FF99CC" }} onClick={() => { setSelectedColor("#FF99CC"); }}> </IonCol>
                      <IonCol style={{ "background": "#E0E0E0" }} onClick={() => { setSelectedColor("#E0E0E0"); }}> </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol style={{ "background": "#FFCCCC" }} onClick={() => { setSelectedColor("#FFCCCC"); }}> </IonCol>
                      <IonCol style={{ "background": "#FFE5CC" }} onClick={() => { setSelectedColor("#FFE5CC"); }}> </IonCol>
                      <IonCol style={{ "background": "#FFFFCC" }} onClick={() => { setSelectedColor("#FFFFCC"); }}> </IonCol>
                      <IonCol style={{ "background": "#E5FFCC" }} onClick={() => { setSelectedColor("#E5FFCC"); }}> </IonCol>
                      <IonCol style={{ "background": "#CCFFCC" }} onClick={() => { setSelectedColor("#CCFFCC"); }}> </IonCol>
                      <IonCol style={{ "background": "#CCFFE5" }} onClick={() => { setSelectedColor("#CCFFE5"); }}> </IonCol>
                      <IonCol style={{ "background": "#CCFFFF" }} onClick={() => { setSelectedColor("#CCFFFF"); }}> </IonCol>
                      <IonCol style={{ "background": "#CCE5FF" }} onClick={() => { setSelectedColor("#CCE5FF"); }}> </IonCol>
                      <IonCol style={{ "background": "#CCCCFF" }} onClick={() => { setSelectedColor("#CCCCFF"); }}> </IonCol>
                      <IonCol style={{ "background": "#E5CCFF" }} onClick={() => { setSelectedColor("#E5CCFF"); }}> </IonCol>
                      <IonCol style={{ "background": "#FFCCFF" }} onClick={() => { setSelectedColor("#FFCCFF"); }}> </IonCol>
                      <IonCol style={{ "background": "#FFCCE5" }} onClick={() => { setSelectedColor("#FFCCE5"); }}> </IonCol>
                      <IonCol style={{ "background": "#FFFFFF" }} onClick={() => { setSelectedColor("#FFFFFF"); }}> </IonCol>
                    </IonRow>

                  </IonGrid>
                </IonItem>
                <IonItem>

                  <IonGrid>
                    <IonRow>
                      <IonCol style={{ "background": selectedColor }}> </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
                <IonItem disabled={alteracao} lines="none" hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 1) ? false : true}>
                  <IonLabel position='stacked'>Lojas</IonLabel>
                </IonItem>
                <IonItem disabled={alteracao} lines="none" hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 1) ? false : true}>
                  <IonLabel position='stacked'></IonLabel>
                  <IonSelect name='Lojas' placeholder='Selecione' title='Lojas' value={lojas} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojas(e.detail.value); }} slot="end">
                    {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                      <IonSelectOption value={valor.empresaLojaId}>{valor.empresaLojaNome}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert isOpen={showIncluirUsuario}
          onDidDismiss={() => setShowIncluirUsuario(false)}
          header={'Cadastro de usuário'}
          message={'Você <strong>confirma</strong> inclusão?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                let dataNiver = "";

                console.log("dataAniversario", dataAniversario);
                if (dataAniversario) {
                  dataNiver = dataAniversario;
                }
                const objectRequest = {
                  cpf: cpf.replace("-", "").replace(".", "").replace(".", ""),
                  pk: pk,
                  nome: nome,
                  email: email,
                  endereco: endereco,
                  color: selectedColor,
                  telefone: "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
                  perfil: selected,
                  statusUsuario: checkedAtivo ? "ativo" : "inativo",
                  dataAniversario: dataNiver,
                  lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
                    return lojas.includes(value.empresaLojaId);
                  })
                }


                if (!alteracao) {

                  requestService(cadastraEquipe.url, {
                    method: cadastraEquipe.method,
                    headers: [["token", localStorage.getItem("token") || ""]],
                    credentials: 'include',
                    body: JSON.stringify(objectRequest)
                  },
                    history,
                    (response: any) => {
                      setRetornoPk(response.usuario.pk);
                      setNome("");
                      setCPF("");
                      setSelectedColor("");
                      setEndereco("");
                      setTelefone("");
                      setCheckedAtivo(true);
                      setEmail("");
                      setDataAniversario("");
                      setSelected("usuario");
                      setTextToast("Cadastro realizado com sucesso.");
                      setShowSucesso(true);
                    },
                    (error: any) => {
                      setTextToast(error.message);
                      setShowErro(true);
                    });
                }
                else {
                  requestService(alteraEquipe.url, {
                    method: alteraEquipe.method,
                    headers: [["token", localStorage.getItem("token") || ""]],
                    credentials: 'include',
                    body: JSON.stringify(objectRequest)
                  },
                    history,
                    (response: any) => {
                      setNome("");
                      setCPF("");
                      setSelectedColor("");
                      setEndereco("");
                      setTelefone("");
                      setCheckedAtivo(true);
                      setEmail("");
                      setDataAniversario("");
                      setSelected("usuario");
                      setTextToast("Alteração realizada com sucesso.");
                      setShowSucesso(true);
                    },
                    (error: any) => {
                      setTextToast(error.message);
                      setShowErro(true);
                    });
                }
              }
            }
          ]}
        />

        <IonPopover ref={popover1}
          isOpen={showModalDataCad}
          onDidDismiss={() => setShowModalDataCad(false)} >
          <IonContent>
            <IonDatetime

              presentation='date'

              onIonChange={e => {

                if (e.detail.value) {
                  let data = e.detail.value.toString().substring(0, 10);
                  const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(data)
                  );
                  setDataAniversarioBr(dataFormatada);
                  setDataAniversario(data);
                }
              }}></IonDatetime>
          </IonContent>
        </IonPopover >

        <IonAlert isOpen={showSucesso}
          onDidDismiss={() => { setShowSucesso(false); }}
          header={'Sucesso'}
          message={textToast}
          backdropDismiss={false}
          buttons={[
            {
              text: 'OK',
              handler: () => { setShowSucesso(false); props.closeAction(retornoPk); }
            }
          ]} />

        <IonAlert isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />

        <IonLoading
          cssClass='my-custoDm-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          message={'Incluindo...'}

        />

      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton disabled={!botaoAtivo} onClick={() => cadastrar()} expand="block" type="submit">SALVAR</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default ({ usuario, inputRef, closeAction, closeActionSimple }: { usuario: any, inputRef: any, closeAction: Function, closeActionSimple: Function }) => (
  <PrestadoresCad usuario={usuario} inputRef={inputRef} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </PrestadoresCad>
)