import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonSearchbar,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import type { Item } from './types';

interface TypeaheadProps {
    items: Item[];
    camposFiltro?: string;
    campo?: string;
    selectedItem: string;
    title?: string;
    autoFocus?: boolean;
    onSelectionCancel?: () => void;
    onSelectionChange?: (item: string) => void;
}

function AppTypeahead(props: TypeaheadProps) {
    const [filteredItems, setFilteredItems] = useState<Item[]>([...props.items]);
    const [workingSelectedValue, setWorkingSelectedValue] = useState<string>(props.selectedItem);
    const searchbarRef = useRef<HTMLIonSearchbarElement>(null);

    useEffect(() => {
        if (props.autoFocus && searchbarRef.current) {
            searchbarRef.current.setFocus().catch((err) => {
                console.log("Erro ao definir foco na barra de busca: ", err);
            });
        }
    }, [props.autoFocus]);

    const cancelChanges = () => {
        const { onSelectionCancel } = props;
        if (onSelectionCancel !== undefined) {
            onSelectionCancel();
        }
    };

    const confirmChanges = () => {
        const { onSelectionChange } = props;
        if (onSelectionChange !== undefined) {
            onSelectionChange(workingSelectedValue || "");
        }
    };

    const searchbarInput = (ev: any) => {
        filterList(ev.target.value);
    };

    const filterList = (searchQuery: string | null | undefined) => {
        if (searchQuery === undefined || searchQuery === null) {
            setFilteredItems([...props.items]);
        } else {
            const normalizedQuery = searchQuery.toLowerCase();
            setFilteredItems(
                props.items.filter((item: any) => {
                    const campos = (props.camposFiltro || "nome").split(',');
                    return campos.some(camposFiltro => {
                        const valorCampo = item[camposFiltro.trim()];
                        return typeof valorCampo === 'string' && valorCampo.toLowerCase().includes(normalizedQuery);
                    });
                })
            );
        }
    };

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonIcon slot="icon-only" icon={arrowBack} onClick={cancelChanges} />
                    </IonButtons>
                    <IonTitle>{props.title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton fill='solid' color="tertiary" onClick={confirmChanges}>OK</IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar ref={searchbarRef} onIonInput={searchbarInput}></IonSearchbar>
                </IonToolbar>
            </IonHeader>

            <IonContent class="ion-padding">
                <IonList>
                    <IonRadioGroup allowEmptySelection={true} value={workingSelectedValue} onIonChange={e => setWorkingSelectedValue(e.detail.value)}>
                        {filteredItems.map((item: any) => (
                            <IonItem lines="none" key={"item" + item.pk}>
                                <IonRadio slot="start" value={item.pk}></IonRadio>
                                {`${item[props.campo || "nome"]}`}
                            </IonItem>
                        ))}
                    </IonRadioGroup>
                </IonList>
            </IonContent>
        </>
    );
}

export default AppTypeahead;
