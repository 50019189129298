import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { add, arrowBack } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { cadastraConvidado, excluiConvidado, listaConvidados, requestService } from '../../../../../Utils/Services';
import './Convidados.css';

type Props = {
  projetoid: any;
  projetonome: any;
  empresaid: string;
  inputRef: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const Convidados: React.FC<Props> = (props: Props) => {

  const history = useHistory();
  const location = useLocation<any>();

  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayOperacoes, setArrayOperacoes] = useState<any>([]);
  const [textToast, setTextToast] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [pk, setPk] = useState("");
  const [sk, setSk] = useState("");

  const [showExcluirUsuario, setShowExcluirUsuario] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [pesquisa, setPesquisa] = useState<string>();
  const [usuario, setUsuario] = useState<any>();


  const [cpf, setCPF] = useState("");
  const [nome, setNome] = useState("");
  const [showIncluirUsuario, setShowIncluirUsuario] = useState(false);

  useEffect(() => {

    if (props.projetoid != "") {
      console.log("entrou no useEffect");
      carregarUsuarios();
    }
  }, [props.projetoid]);

  useEffect(() => {
    // Define o foco no input quando o componente é montado
    if (props.inputRef.current) {
      props.inputRef.current.focus();
    }
  }, [props.inputRef]);

  let params: any = {
    projetonome: "",
    projetoid: "",
    empresaid: "",
  }
  params = useParams();

  if (location && location.state && location.state.projetoid && location.state.projetonome && location.state.empresaid) {
    params.projetonome = location.state.projetonome;
    params.projetoid = location.state.projetoid;
    params.empresaid = location.state.empresaid;
  }

  if (props.projetoid && props.projetonome && props.empresaid) {
    params = {
      projetoid: props.projetoid,
      projetonome: props.projetonome,
      empresaid: props.empresaid
    }
  }

  function cpf_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  const loadItens = () => {

    if (arrayOperacoes === undefined || arrayOperacoes.length === 0) {
      return (
        <IonCard key="primeira">
          <IonCardHeader id="primeira" >
            <IonCardTitle>Aqui aparecem os convidados</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      );
    }

    return arrayOperacoes.map((value: any, index: any, arr: any) =>

      <IonCard key={Math.random()} className="equipeCard" hidden={(!pesquisa || pesquisa === "" || value.nomeMinusculo?.indexOf(pesquisa.toString()) > -1) ? false : true} >
        <IonCardHeader id={value.pk} style={{ "background": value.color }}>
          <IonCardTitle>{value.nome}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="equipe">
                  <IonLabel>Documento</IonLabel>
                  <IonLabel className="equipeTexto">{cpf_mask(value.cpf)}</IonLabel>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonItem lines="none">
            <IonButton size="small" fill='solid' shape="round" color="tertiary" slot="end" className="botaoExcluir"
              onClick={() => {
                console.log(value.pk);
                setPk(value.pk);
                setSk(value.sk);
                setMensagem("Deseja realmente parar de compartilhar com o convidado " + value.nome + "?");
                setShowExcluirUsuario(true)
              }}>
              Parar de compartilhar
            </IonButton>
          </IonItem>

        </IonCardContent>

      </IonCard>

    );

  }

  function carregarUsuarios() {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let resp: any;
      resp = await fetch(listaConvidados.url
        .concat("?projetoid=")
        .concat(params.projetoid)
        .concat("&marca=")
        .concat(localStorage.getItem("empresaMarca") || "")
        .replaceAll(/#/g, "%23"),
        {
          method: listaConvidados.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      let equipe = await resp.json();

      console.log("equipe", equipe);
      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }
      else {
        setArrayOperacoes(equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1));
      }

    }
    consultar();
  }

  function doRefresh(event: any) {
    carregarUsuarios();
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  function onChangeCPF(e: any) {

    e.persist();
    e.preventDefault();



    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCPF(cpfval);
  }

  function cadastrar() {
    if (nome.length == 0 || cpf.length != 14) {

      setTextToast("Todos os campos são obrigatórios.");
      setShowErro(true);
    }
    else {
      setShowIncluirUsuario(true);
    }
  }


  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} />
          </IonButtons>
          <IonTitle>Compartilhar</IonTitle>
        </IonToolbar>
        <IonToolbar color="primary" >

          <IonSearchbar value={pesquisa} onIonClear={(e) => setPesquisa(undefined)} onIonChange={(e) => { setPesquisa(e.detail.value?.toLocaleLowerCase()) }} placeholder='Nome do convidado' ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent className="equipecontent" fullscreen >
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonModal isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
          setNome("");
          setCPF("");
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowDetalhes(false)} />
                </IonButtons>
                <IonTitle>Novo compartilhamento</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonList>

                      <IonItem lines="none">
                        <IonLabel position="stacked">Nome*</IonLabel>
                        <IonInput placeholder="Digite o nome completo" value={nome} ref={(ref) => props.inputRef.current = ref} autoCorrect='true' autoCapitalize='true' required onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel position="stacked">CPF*</IonLabel>
                        <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} />
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonAlert isOpen={showIncluirUsuario}
                onDidDismiss={() => setShowIncluirUsuario(false)}
                header={'Compartilhar'}
                message={'Você <strong>confirma</strong> compartilhamento?'}
                buttons={[
                  {
                    text: 'Cancelar',
                    role: 'cancel',
                  },
                  {
                    text: 'Confirmar',
                    cssClass: 'primary',
                    handler: () => {
                      const objectRequest = {
                        projetoid: params.projetoid,
                        projetoNome: params.projetonome,
                        nome: nome,
                        cpf: cpf.replace("-", "").replace(".", "").replace(".", ""),
                        perfil: "convidado",
                        empresaid: params.empresaid,
                        empresaMarca: localStorage.getItem("empresaMarca") || ""

                      }
                      console.log("objectRequest", objectRequest);

                      requestService(cadastraConvidado.url, {
                        method: cadastraConvidado.method,
                        headers: [["token", localStorage.getItem("token") || ""]],
                        credentials: 'include',
                        body: JSON.stringify(objectRequest)
                      },
                        history,
                        (response: any) => {
                          setNome("");
                          setCPF("");
                          carregarUsuarios();
                          setShowDetalhes(false);
                        },
                        (error: any) => {
                          setTextToast(error.message);
                          setShowErro(true);
                        });

                    }
                  }
                ]}
              />

            </IonContent>
            <IonFooter className="ion-no-border">
              <IonToolbar>
                <IonButton onClick={() => cadastrar()} expand="block" type="submit">COMPARTILHAR</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>
        <IonButton hidden={true} onClick={() => { setUsuario(null); setShowDetalhes(true) }}>Novo</IonButton>
        <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => { setUsuario(null); setShowDetalhes(true) }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        {loadItens()}

        <IonAlert
          isOpen={showExcluirUsuario}
          onDidDismiss={() => setShowExcluirUsuario(false)}
          header={'Parar de Compartilhar'}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                console.log("pk", pk);
                console.log("sk", sk);

                requestService(excluiConvidado.url.replace("{pk}", pk.replaceAll(/#/g, "%23")).replace("{sk}", sk.replaceAll(/#/g, "%23")), {
                  method: excluiConvidado.method,
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    carregarUsuarios();
                    setTextToast(response.message);
                    setShowSucesso(true);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                  });
              }
            }
          ]}
        />

        <IonAlert
          isOpen={showSucesso}
          onDidDismiss={() => setShowSucesso(false)}
          header={'Sucesso'}
          message={textToast}
          buttons={['OK']}
        />

        <IonAlert
          isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
    </>
  );
};

export default ({ projetoid, projetonome, empresaid, inputRef, closeAction, closeActionSimple }: { projetoid: any, projetonome: any, empresaid: any, inputRef: any, closeAction: Function, closeActionSimple: Function }) => (
  <Convidados projetoid={projetoid} inputRef={inputRef} projetonome={projetonome} empresaid={empresaid} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </Convidados>
)
