import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonSearchbar, IonTitle, IonToolbar, isPlatform, useIonViewDidEnter } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import "./VendasPainel.css";

import 'moment/locale/pt-br';
//import 'react-big-calendar/lib/sass/styles';
import { Toast } from '@capacitor/toast';
import { Buffer } from 'buffer';
import { arrowBack, call, checkmark, mail } from 'ionicons/icons';
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import { alterarStatusVenda, requestService, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';

interface Item {
  id: string;
  numero: number;
  name: string;
  dataCadastro: string;
  prospectNome: string;
  bgColorPercentual: string;
  pk: string;
  gsi2pk: string;
  cadastradoPor: string;
  prospectEmail: string;
  prospectTelefone: string;
  prospectCpf: string;
}

interface Column {
  id: string;
  name: string;
  items: Item[];
  bgcolor: string;
}

type Props = {
  abrirMenu: boolean;
  empresaid: string;
  onDismiss: () => void;
};


const VendasPainel: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();
  const [lojas, setLojas] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.empresaLojaId || "");

  const [showLoading, setShowLoading] = useState(false);
  const [stateLastEvaluatedKey, setStateLastEvaluatedKey] = useState<any>();

  const [stages, setStages] = useState<any>([]);
  const [empresaid, setEmpresaid] = useState<string>("");

  const [alertConfirmar, setAlertConfirmar] = useState(false);
  const { recarregarVenda, setRecarregarVenda } = useContext(MessageContext);
  const [pkVendaSelecionado, setPkVendaSelecionado] = useState<string>("");
  const [indiceSelecionado, setIndiceSelecionado] = useState<string>("");
  const [indiceCardSelecionado, setIndiceCardSelecionado] = useState<number>(0);
  const [statusSelecionado, setStatusSelecionado] = useState<string>("");
  const [nomeSelecionado, setNomeSelecionado] = useState<string>("");
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const [columns, setColumns] = useState<Column[]>([]);
  const [baseColumns, setBaseColumns] = useState(columns);
  const [searchQuery, setSearchQuery] = useState<string | null>("");

  let params: any = {
    empresaid: "",
  }
  params = useParams();

  if (location && location.state && location.state.empresaid) {
    params.empresaid = location.state.empresaid;
  }


  if (props.empresaid) {
    params = {
      empresaid: props.empresaid
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (params.empresaid) {
        carregarBoard();
      }
    }, 20000); // 20 segundos = 20000 milissegundos

    // Limpeza do intervalo quando o componente for desmontado
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {

    console.log("empresaid", empresaid);

    if (empresaid != "") {
      setShowLoading(true);
      carregarBoard(empresaid).finally(() => {
        setShowLoading(false);
      });
    }
  }, [empresaid]);


  useIonViewDidEnter(() => {

    setEsconderMenu(true);
    setNomeMenu("");


    if (params.empresaid) {
      setEmpresaid(params.empresaid);
    }

  });


  async function carregarBoard(loja?: any) {
    const consultar = async () => {
      // setShowLoading(true);

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let urlFinal = urlBase.url + "/vendas/painel?campo=data" +
        "&filtro=Ativo" +
        "&lojas=" + Buffer.from((loja || lojas).replaceAll(/#/g, '%23')).toString('base64');

      const resp: Response = await fetch(urlFinal,
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      let res = await resp.json();

      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }

      if (res && res.LastEvaluatedKey) {
        setStateLastEvaluatedKey(res.LastEvaluatedKey);
      }
      else {
        setStateLastEvaluatedKey(null);
      }

      if (res && res.Items) {


        let listaOrdenada = res.Items.sort((a: any, b: any) => (a.ordenacao > b.ordenacao) ? 1 : -1);

        //let board: any[] = [];
        let colunas: any[] = [];
        JSON.parse(window.localStorage.getItem("configWorkflowVenda") || "[]").map((valor: any, index: any) => {

          let item: any[] = [];
          listaOrdenada.map((value: any, index: any) => {

            if (value.statusWorkflow == valor.status) {
              let task: any = {
                id: value.pk,
                "numero": value.numero,
                "name": value.nomeVenda, "pk": value.pk, "gsi2pk": value.gsi2pk, "dataCadastro": value.dataCadastro, "cadastradoPor": value.cadastradoPor,
                "prospectNome": value.prospectNome,
                "prospectEmail": value.prospectEmail, "prospectTelefone": value.prospectTelefone, "prospectCpf": value.prospectCpf
              };

              // console.log("value.nomeVenda", value.nomeVenda);
              // console.log("value.ordenacao", value.ordenacao);
              item.push(task);
            }

          })

          let column: any = {
            id: valor.indice,
            name: valor.status,
            bgcolor: valor.bgColor,
            items: item
          };

          colunas.push(column);

        });
        setColumns(colunas);
        setBaseColumns(colunas);

        // console.log("colunas", colunas);
      }

      // setShowLoading(false);

    };
    return await consultar();
  }

  function alterarStatus(pk: any, indice: any, status: any, indiceCard: any) {

    let params = {
      indice: indice,
      status: status,
      indiceCard: indiceCard
    };
    console.log("params", params);
    setShowLoading(true);
    requestService(alterarStatusVenda.url.replaceAll("{id}", pk.replaceAll(/#/g, "%23")), {
      method: alterarStatusVenda.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(params),
      credentials: 'include'
    },
      history,
      (response: any) => {
        console.log(response);
        //carregarBoard();
        setRecarregarVenda(true);
      },
      (error: any) => {
        console.log(error);
        Toast.show({
          text: error.message,
          position: "center",
          duration: "long"
        });
      }).finally(() => {
        setShowLoading(false);
      });



  }


  function carregarDetalhes(vendaid: any, empresaid: any, abrirInserirAgendamento: boolean) {
    setEmpresaid("");
    history.push("../vendas/vendasdetalhes", { vendaid: vendaid, empresaid: empresaid, abrirInserirAgendamento: abrirInserirAgendamento })
  }

  function carregarWorkflow(configsLoja: any, vendaid: any, empresaid: any) {
    setEmpresaid("");
    history.push("../vendas/vendasworkflow", { vendaid: vendaid, empresaid: empresaid })
  }


  function formatarDataHoraCompleta(data: Date) {
    return adicionaZeroNaData(data.getDate().toString()) + "/" + (adicionaZeroNaData(data.getMonth() + 1).toString()) + "/" + (data.getFullYear().toString()) + " " + ("0" + data.getHours().toString()).substr(-2) + ":" + ("0" + data.getMinutes().toString()).substr(-2);
  }

  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const onDragEnd = (result: DropResult, provided: ResponderProvided): void => {


    const { source, destination, draggableId } = result;
    console.log("source", source);
    console.log("destination", destination);
    console.log("draggableId", draggableId);

    if (!destination) {
      return;
    }

    let sourceColumnItems: Item[] = [];
    let destinationColumnItems: Item[] = [];
    let draggedItem: Item | undefined;

    let sourceColumnId = 0;
    let destinationColumnId = 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].id === source.droppableId) {
        sourceColumnItems = columns[i].items;
        sourceColumnId = i;
      } else if (columns[i].id === destination.droppableId) {
        destinationColumnItems = columns[i].items;
        destinationColumnId = i;
      }
    }

    draggedItem = sourceColumnItems.find(item => item.id === draggableId);

    if (!draggedItem) {
      console.error('Dragged item not found');
      return;
    }

    const filteredSourceColumnItems = sourceColumnItems.filter(item => item.id !== draggableId);

    if (source.droppableId === destination.droppableId) {
      filteredSourceColumnItems.splice(destination.index, 0, draggedItem);

      const columnsCopy = [...columns];
      columnsCopy[sourceColumnId] = {
        ...columnsCopy[sourceColumnId],
        items: filteredSourceColumnItems,
      };
      setColumns(columnsCopy);
    } else {
      destinationColumnItems.splice(destination.index, 0, draggedItem);

      const columnsCopy = [...columns];
      columnsCopy[sourceColumnId] = {
        ...columnsCopy[sourceColumnId],
        items: filteredSourceColumnItems,
      };
      columnsCopy[destinationColumnId] = {
        ...columnsCopy[destinationColumnId],
        items: destinationColumnItems,
      };
      setColumns(columnsCopy);
    }

    // setPkVendaSelecionado(draggableId)

    const found = JSON.parse(window.localStorage.getItem("configWorkflowVenda") || "[]").find((obj: any) => {
      return obj.indice === destination.droppableId;
    });

    // setIndiceSelecionado(destination.droppableId);
    // setStatusSelecionado(found.status);

    // setAlertConfirmar(true);

    alterarStatus(draggableId, destination.droppableId, found.status, destination.index);


  };

  const searchbarInput = (ev: any) => {
    const query = ev.target.value;
    setSearchQuery(query);
    filterList(query);
  };

  const filterList = (searchQuery: string | null | undefined) => {
    if (searchQuery === undefined || searchQuery === null) {
      setColumns([...baseColumns]); // Reset to original data
    } else {
      const normalizedQuery = searchQuery.toLowerCase();
      const newFilteredColumns = baseColumns.map((column: any) => {

        const filteredItems = column.items.filter((item: any) => {
          const numeroString = item.numero ? item.numero.toString() : "";
          const prospectNomeString = item.prospectNome ? item.prospectNome.toString().toLowerCase() : "";
          const prospectTelefoneString = item.prospectTelefone ? item.prospectTelefone.toString().toLowerCase() : "";
          const nameString = item.name ? item.name.toString().toLowerCase() : "";
          const cadastradoPorString = item.cadastradoPor ? item.cadastradoPor.toString().toLowerCase() : "";

          return (
            numeroString === normalizedQuery || // Match entire string for numero
            prospectNomeString.includes(normalizedQuery) ||
            prospectTelefoneString.includes(normalizedQuery) ||
            nameString.includes(normalizedQuery) ||
            cadastradoPorString.includes(normalizedQuery)
          );
        });

        return {
          ...column,
          items: filteredItems,
        };
      });

      setColumns(newFilteredColumns);
    }
  };

  return (

    <IonPage className="VendasPainel">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Painel de Vendas</IonTitle>
        </IonToolbar>
        <IonToolbar color="primary">

          <IonSearchbar
            onIonInput={searchbarInput}
            placeholder={"Busque pelo Prospect ou Venda"}
            value={searchQuery}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonAlert isOpen={alertConfirmar}
          onDidDismiss={() => setAlertConfirmar(false)}
          header={'Alterar status'}
          message={'Deseja alterar a venda <strong>' + nomeSelecionado + '</strong> para etapa <strong>' + statusSelecionado + '</strong>?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: async () => {
                alterarStatus(pkVendaSelecionado, indiceSelecionado, statusSelecionado, indiceCardSelecionado);
              }
            }
          ]}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

        <DragDropContext onDragEnd={onDragEnd}>
          <div style={{ display: 'flex', justifyContent: 'left', padding: '10px', overflowX: 'auto' }}>
            {columns.map((column: Column) => (
              <div key={column.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexShrink: 0 }}>
                <Droppable droppableId={column.id}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        backgroundColor: column.bgcolor,
                        width: (!isPlatform('mobileweb') && isPlatform('mobile')) ? 180 : 240,
                        height: 'fit-content',
                        padding: 2,
                        margin: 2,
                        borderRadius: 4,
                      }}
                    >

                      <p
                        id={column.name}
                        className="droppable"
                        style={{
                          border: "#000 1px dashed",
                          padding: "6px",
                          margin: "1px",
                          minHeight: '60px'
                        }}>
                        {column.name}
                      </p>

                      {/* <div style={{ marginBottom: 10, fontWeight: 'bold', fontSize: 16 }}>{column.name}</div> */}
                      <div>
                        {column.items.map((item: Item, index: number) => (
                          <Draggable draggableId={item.id} index={index} key={item.id}>
                            {(provided) => (
                              <div
                                onClick={() => carregarDetalhes(item.pk.replaceAll(/#/g, '%23'), item.gsi2pk.replaceAll(/#/g, '%23'), false)}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                style={{
                                  ...provided.draggableProps.style,
                                  margin: '0 0 10px 0',
                                }}
                              >
                                <IonCard style={{ maxWidth: '400px', padding: '0px', margin: '6px', backgroundColor: item.bgColorPercentual }}>
                                  <div style={{ padding: "2px", margin: "2px", minHeight: '80px' }} >
                                    <IonCardContent
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#000',
                                        fontSize: '10px',
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        position: 'relative',
                                      }}
                                    >
                                      {/* Ícones */}
                                      <div style={{ position: 'absolute', top: '0', right: '0' }}>
                                        <div style={{ display: 'flex' }}>
                                          {/* Ícone de CPF */}
                                          {item.prospectCpf !== "" && (

                                            <IonIcon color='primary' icon={checkmark} size='small' />
                                          )}

                                          {/* Ícone de Telefone */}
                                          {item.prospectTelefone !== "" && item.prospectTelefone !== "55" && (
                                            <IonIcon color='primary' icon={call} size='small' />
                                          )}

                                          {/* Ícone de E-mail */}
                                          {item.prospectEmail !== "" && (
                                            <IonIcon color='primary' icon={mail} size='small' />
                                          )}
                                        </div>
                                      </div>

                                      {/* Informações da tarefa */}
                                      <div>
                                        <div style={{ fontWeight: 'bold', display: 'inline-block' }}>
                                          {item.numero}
                                        </div><br />
                                        <div style={{ fontWeight: 'bold', display: 'inline-block' }}>
                                          {item.name}
                                        </div><br />
                                        <div style={{ fontSize: "14px", fontWeight: "bold", display: 'inline-block' }}>
                                          {item.prospectNome}
                                        </div><br />
                                        <div style={{ display: 'inline-block' }}>
                                          {formatarDataHoraCompleta(new Date(item.dataCadastro))}
                                        </div><br />
                                        <div style={{ display: 'inline-block' }}>
                                          {item.cadastradoPor}
                                        </div>
                                      </div>
                                    </IonCardContent>
                                  </div>
                                </IonCard>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>

      </IonContent>

    </IonPage>
  );


};

export default VendasPainel;