import { IonButton, IonButtons, IonContent, IonDatetime, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonPopover, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import "./FinanceiroLancamentos.css";

import { Toast } from '@capacitor/toast';
import { Buffer } from 'buffer';
import { arrowBack } from 'ionicons/icons';
import 'moment/locale/pt-br';
import { empresaCarregar, incluirFinanceiro, projetoLista, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';

type Props = {
  projetoid: any;
  tipo: any;
  observacao: any;
  closeAction: Function;
  closeActionSimple: Function;
};

const FinanceiroLancamentos: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [showModalTipoLancamento, setShowModalTipoLancamento] = useState(false);
  const [observacoes, setObservacoes] = useState<any>();
  const [cobranca, setCobranca] = useState<any>();
  const [dataLancamento, setDataLancamento] = useState<any>();
  const [dataLancamentoBr, setDataLancamentoBr] = useState<any>();
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [categoria, setCategoria] = useState<any>();
  const [listaCategoria, setListaCategoria] = useState<any>([]);
  const [listaUsuario, setListaUsuario] = useState<any>([]);
  const [projeto, setProjeto] = useState<string>("");
  const [listaProjeto, setListaProjeto] = useState<any>([]);
  const [showModalDataCad, setShowModalDataCad] = useState(false);

  const [valor, setValor] = useState<any>();

  const popover1 = useRef<HTMLIonPopoverElement>(null);

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { loja, setLoja } = useContext(MessageContext);

  const [checkedEnviarMensagem, setCheckedEnviarMensagem] = useState(false);
  const [tipo, setTipo] = useState<string>("");

  const [fecharTipoLancamento, setFecharTipoLancamento] = useState(false);

  const [opcoes, setOpcoes] = useState<string>("unica");
  const [parcelas, setParcelas] = useState<string>("");

  const { recarregarFinanceiro, setRecarregarFinanceiro } = useContext(MessageContext);

  let params: any = {
    projetoid: ""
  }

  params = useParams();

  if (location && location.state && location.state.projetoid) {
    params.projetoid = location.state.projetoid;
  }

  if (props.projetoid) {
    params = {
      projetoid: props.projetoid
    }
  }

  useEffect(() => {

    console.log("props.projetoid", props.projetoid);
    if (props.projetoid != null) {
      let id = atob(props.projetoid);
      console.log("props.projetoid", props.projetoid);
      setProjeto(id);







      console.log("entrou no useEffect", id);
    }
  }, [props.projetoid]);



  useEffect(() => {
    console.log("*******************", props.observacao);
    if (props.observacao != null) {
      setObservacoes(props.observacao);
    }
  }, [props.observacao]);

  useEffect(() => {
    if (props.tipo != null) {
      setTipo(props.tipo);
      setFecharTipoLancamento(true);
      avancarLancamento();
    }
  }, [props.tipo]);


  async function avancarLancamento() {

    setCategoria("");


    let data = new Date().toString();
    const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
      Date.parse(data)
    );
    setDataLancamentoBr(dataFormatada);
    setDataLancamento(data);

    setValor("");

    console.log("projeto", projeto);
    //setProjeto("");

    setCheckedEnviarMensagem(false);
    setCobranca("");

    const consultarEmpresa = async () => {
      requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {

        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log("response_empresa", response);
          if (tipo == "receita") {
            setListaCategoria(response.Item.categoriaReceita || []);
          }
          else {
            setListaCategoria(response.Item.categoriaDespesa || []);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });
    }

    const consultarProjeto = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let montarUrl = projetoLista.url.replace("{loja}", loja.replaceAll(/#/g, "%23"));
      const resp = await fetch(montarUrl,
        {
          method: "GET",
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      let projetos = await resp.json();
      console.log("projetos", projetos);
      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }
      else {
        if (projetos.Items) {
          let lista = projetos.Items.sort((a: any, b: any) => {
            const nomeProjetoA = typeof a.nomeProjeto === 'string' ? a.nomeProjeto.toLowerCase() : '';
            const nomeProjetoB = typeof b.nomeProjeto === 'string' ? b.nomeProjeto.toLowerCase() : '';

            if (nomeProjetoA > nomeProjetoB) return 1;
            if (nomeProjetoA < nomeProjetoB) return -1;
            return 0;
          });
          setListaProjeto(lista);
        }
      }
    }

    await consultarEmpresa();
    await consultarProjeto();

    setShowModalTipoLancamento(true);

  }

  function validarCamposLancamento() {


    if (categoria.length === 0) {
      setTextToast("Campo Categoria é obrigatório.");
      setShowToast(true);
      return false;
    }

    if (tipo.length === 0) {
      setTextToast("Campo Tipo é obrigatório.");
      setShowToast(true);
      return false;
    }

    if (valor.length === 0) {
      setTextToast("Campo Valor é obrigatório.");
      setShowToast(true);
      return false;
    }

    if (opcoes == "parcelas" && parcelas == "") {
      setTextToast("Campo parcela é obrigatório.");
      setShowToast(true);
      return false;
    }

    let dia = Number(dataLancamento.substring(0, 10).split("-")[2]);
    console.log("diaaa", dia)

    if (opcoes != "unica" && dia > 28) {
      setTextToast("O dia da parcela precisa ser menor que 29.");
      setShowToast(true);
      return false;
    }

    return true;
  }

  function iniciarLancamento() {

    if (!validarCamposLancamento())
      return;




    if (botaoAtivo) {
      setShowLoading(true);
      setBotaoAtivo(false);

      let found;
      found = listaProjeto.find((obj: any) => {
        return obj.pk === projeto;
      });



      console.log("found", found);

      let objRequest = {
        "categoria": categoria,
        "observacoes": observacoes,
        "tipo": tipo,
        "data": new Date(dataLancamento),
        "valor": valor,
        "projetoid": projeto || "",
        "nomeProjeto": found?.nomeProjeto || "",
        "empresaid": loja || "",
        "clienteid": found?.clienteid || "",
        "opcoes": opcoes || "",
        "parcelas": parcelas || "",
        "enviarMensagem": checkedEnviarMensagem,
        "cobranca": cobranca
      };

      console.log("objRequest", objRequest);



      requestService(incluirFinanceiro.url, {
        method: incluirFinanceiro.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
        history,
        (response: any) => {

          console.log("response_incluirFinanceiro", response);
          if (response.message) {
            setShowModalTipoLancamento(false);
            setRecarregarFinanceiro(true);
            props.closeAction();
            Toast.show({
              text: "Lançamento criado com sucesso!",
              position: "center",
              duration: "long"
            });
          }
        },
        (error: any) => {
          console.log(error);
          setShowLoading(false);
          Toast.show({
            text: error.message,
            position: "center",
            duration: "long"
          });
        })
    }

    setShowLoading(false);
    setBotaoAtivo(true);
  }

  function readProjeto() {
    return listaProjeto.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={value.pk} value={value.pk}>{value.nomeProjeto}</IonSelectOption>
    )
    )
  };

  function readCategoria() {
    return listaCategoria.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={value} value={value}>{value}</IonSelectOption>
    )
    )
  };

  const openPopoverCad = (e: any) => {
    popover1.current!.event = e;
    setShowModalDataCad(true);
  };

  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    setValor(v);
  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { props.closeActionSimple() }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Novo lançamento</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>

        <IonList lines="full" class="ion-no-margin">
          <IonListHeader lines="full">
            <IonLabel>
              Qual tipo de lançamento deseja realizar?
            </IonLabel>
          </IonListHeader>
          <IonItem lines="none" >
            <IonLabel>Lançamento*</IonLabel>
            <IonSelect name='Tipo' value={tipo} multiple={false} placeholder="Selecione" cancelText="Cancel" okText="Ok" onIonChange={e => {
              setTipo(e.detail.value);
            }} slot="end">

              <IonSelectOption value="receita">Receita</IonSelectOption>
              <IonSelectOption value="despesa">Despesa</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>

        <div className="content">


          <IonModal isOpen={showModalTipoLancamento} onDidDismiss={() => {
            setShowModalTipoLancamento(false)
            if (fecharTipoLancamento) {
              props.closeActionSimple();
            }
          }

          } >
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle>Nova {tipo}</IonTitle>
                <IonButtons slot="start" onClick={() => { setShowModalTipoLancamento(false); }}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
              <IonList lines="full" class="ion-no-margin">

                <IonItem lines="none">
                  <IonLabel>Categoria*</IonLabel>
                  <IonSelect slot="end" value={categoria} placeholder="Selecione" onIonChange={e => { setCategoria(e.detail.value); }}>
                    {readCategoria()}
                  </IonSelect>
                </IonItem>

                <IonItem lines={"none"} hidden={params.projetoid != undefined}>
                  <IonLabel>{window.localStorage.getItem("servicoTipo") || "Projeto"}</IonLabel>
                  <IonSelect slot="end" value={projeto} placeholder="Selecione" onIonChange={e => {
                    {
                      console.log(e.detail.value);
                      setProjeto(e.detail.value);
                    }
                  }}>
                    {readProjeto()}
                  </IonSelect>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Data*</IonLabel>
                  <IonLabel style={{ "color": "#BDBDBD" }} slot="end" onClick={e => { openPopoverCad(e); }}>{dataLancamentoBr}</IonLabel>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Valor*</IonLabel>
                  <IonInput type="tel" maxlength={14} placeholder="Digite o valor" value={valor} onKeyUp={onChangeValor} />
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Opções*</IonLabel>
                  <IonSelect name='Opções' value={opcoes} multiple={false} placeholder="Selecione" cancelText="Cancel" okText="Ok" onIonChange={e => {
                    setOpcoes(e.detail.value);
                  }} slot="end">

                    <IonSelectOption value="unica">Única</IonSelectOption>
                    <IonSelectOption value="parcelas">Parcelas</IonSelectOption>
                    <IonSelectOption value="recorrente">Recorrente</IonSelectOption>

                  </IonSelect>
                  <IonSelect hidden={opcoes != "parcelas"} name='Parcelas' value={parcelas} multiple={false} placeholder="Selecione" cancelText="Cancel" okText="Ok" onIonChange={e => {
                    setParcelas(e.detail.value);
                  }} slot="end">

                    <IonSelectOption value="2">2x</IonSelectOption>
                    <IonSelectOption value="3">3x</IonSelectOption>
                    <IonSelectOption value="4">4x</IonSelectOption>
                    <IonSelectOption value="5">5x</IonSelectOption>
                    <IonSelectOption value="6">6x</IonSelectOption>
                    <IonSelectOption value="7">7x</IonSelectOption>
                    <IonSelectOption value="8">8x</IonSelectOption>
                    <IonSelectOption value="9">9x</IonSelectOption>
                    <IonSelectOption value="10">10x</IonSelectOption>
                    <IonSelectOption value="11">11x</IonSelectOption>
                    <IonSelectOption value="12">12x</IonSelectOption>

                  </IonSelect>
                </IonItem>



                <IonItem lines="none">
                  <IonLabel>Observações</IonLabel>
                  <IonTextarea style={{ "--background": "#eeeeee", marginLeft: "10px" }} rows={6} value={observacoes} maxlength={250} placeholder="" onIonInput={(e: any) => setObservacoes(e.target.value)}></IonTextarea>
                </IonItem>




                <IonItem lines="none" hidden={tipo == "despesa" || projeto == ""}>
                  <IonLabel>Enviar Cobrança</IonLabel>
                  <IonToggle slot='end' checked={checkedEnviarMensagem} onIonChange={e => {
                    if (e.detail.checked) {
                      setCobranca("Lembrete de vencimento no valor de " + (valor || "XXXXX") + ". Caso já tenha feito o pagamento, por favor, desconsidere esta mensagem.");
                    }
                    setCheckedEnviarMensagem(e.detail.checked);
                  }} />

                </IonItem>

                <IonItem lines="none" hidden={checkedEnviarMensagem == false}>
                  <IonLabel>Cobrança</IonLabel>
                  <IonTextarea style={{ "--background": "#eeeeee", marginLeft: "10px" }} rows={6} value={cobranca} maxlength={250} placeholder="" onIonInput={(e: any) => setCobranca(e.target.value)}></IonTextarea>
                </IonItem>


              </IonList>

            </IonContent>
            <IonFooter className="ion-no-border" >
              <IonToolbar>
                <IonButton expand="block" size='large' color="primary" onClick={() => { iniciarLancamento(); }}>Registrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </IonModal>

          <IonPopover ref={popover1}
            isOpen={showModalDataCad}
            onDidDismiss={() => setShowModalDataCad(false)} >
            <IonContent>
              <IonDatetime

                presentation='date'

                onIonChange={e => {

                  if (e.detail.value) {
                    let data = e.detail.value.toString().substring(0, 10);
                    const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                      Date.parse(data)
                    );
                    setDataLancamentoBr(dataFormatada);
                    setDataLancamento(data);
                  }
                  //setShowModalDataCad(false);
                }}></IonDatetime>
            </IonContent>
          </IonPopover >

          <IonToast
            isOpen={showToast}
            position="middle" //top, bottom, middle
            onDidDismiss={() => setShowToast(false)}
            message={textToast}
            duration={3000}
          />
        </div>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
      <IonFooter className="ion-no-border" >
        <IonToolbar>
          <IonButton expand="block" size='large' color="primary" onClick={() => {
            if (tipo) {
              setObservacoes("");
              avancarLancamento();
            }
            else {
              setTextToast("Precisa selecionar o tipo para criar um lançamento.");
              setShowToast(true);

            }
          }}>Avançar</IonButton>
        </IonToolbar>
      </IonFooter>

    </>
  );


};

export default ({ projetoid, tipo, observacao, closeAction, closeActionSimple }: { projetoid: any, tipo: any, observacao: any, closeAction: Function, closeActionSimple: Function }) => (
  <FinanceiroLancamentos projetoid={projetoid} tipo={tipo} observacao={observacao} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </FinanceiroLancamentos>
)