import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonMenuButton, IonModal, IonPage, IonPopover, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonToast, IonToolbar, isPlatform, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { addCircle, chatbubbles, checkmarkOutline, clipboardOutline, closeOutline, ellipsisVertical, folderOutline, notifications, refresh, removeCircle, timeOutline, trash } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { abrirMesa, apiProjetosCaixasGET, encerrarComanda, incluirProjeto, listaEquipePorPerfil, projetoGerarCupom, requestService, unirComanda, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import CaixaGeralAbrir from '../CaixaGeralAbrir/CaixaGeralAbrir';
import Endereco from '../Endereco/Endereco';
import Notificacoes from '../Notificacoes/Notificacoes';
import './Comandas.css';

type Props = {
  closeAction: Function;
  closeActionSimple: Function;
}

const Comandas: React.FC<Props> = (props: Props) => {
  // Navigation
  const history = useHistory();
  const location = useLocation<any>();

  // Alert and Toast
  const [mostartAlerta] = useIonAlert();
  const [textToast, setTextToast] = useState("");
  const [showToast, setShowToast] = useState(false);

  // Loading State
  const [showLoading, setShowLoading] = useState(false);

  // Contexts
  const { loja, setLoja } = useContext(MessageContext);
  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);
  const { mensagens, setMensagens } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const { pkUsuario, setPkUsuario } = useContext(MessageContext);

  // State: Loja
  const [lojas, setLojas] = useState<string>(loja);

  // State: Filtro e Listagem
  const [textoFiltro, setTextoFiltro] = useState<any>();
  const [listaItemProdutos, setListaItemProdutos] = useState<any>([]);

  // State: Detalhes do Endereço
  const [showDetalhesEndereco, setShowDetalhesEndereco] = useState(false);
  const inputRefEndereco = useRef<any>(null);
  const [endereco, setEndereco] = useState("");
  const [enderecoLat, setEnderecoLat] = useState("");
  const [enderecoLng, setEnderecoLng] = useState("");
  const [complemento, setComplemento] = useState("");
  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);

  // State: Cliente
  const [listaComanda, setListaComanda] = useState<any>([]);
  const [pkCliente, setPkCliente] = useState<any>();


  // State: Valores e Pagamento
  const [troco, setTroco] = useState<any>();
  const [valorTotal, setValorTotal] = useState<any>();
  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>();
  const [formaPagamento, setFormaPagamento] = useState('');
  const [taxaEntrega, setTaxaEntrega] = useState<any>();
  const [desconto, setDesconto] = useState<any>();
  const [taxaServico, setTaxaServico] = useState<any>();

  // Refs
  const campoTelefone = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const contentCatalogoRef = useRef<any>(null);

  // Dados da Empresa
  const [dadosEmpresa, setDadosEmpresa] = useState<any>([]);

  const [selectedButton, setSelectedButton] = useState<string>("comanda");

  const [ultimoCaixa, setUltimoCaixa] = useState<any>();
  const [diasAberto, setDiasAberto] = useState(0);

  const [listaClienteComanda, setListaClienteComanda] = useState<any>([]);

  const [filter, setFilter] = useState<"todas" | "livres" | "ocupadas">("todas");

  const [showModalAbrirCaixa, setShowModalAbrirCaixa] = useState(false);

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);


  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };


  useEffect(() => {
    if (ultimoCaixa?.dataAbertura) {
      const dataAbertura: any = new Date(ultimoCaixa.dataAbertura);
      const hoje: any = new Date();
      hoje.setHours(0, 0, 0, 0); // Zera as horas para comparar apenas o dia
      dataAbertura.setHours(0, 0, 0, 0); // Zera as horas na data de abertura também

      // Calcula a diferença em milissegundos e converte para dias
      const diffDias = Math.floor((hoje - dataAbertura) / (1000 * 60 * 60 * 24));

      console.log("diffDias", diffDias);

      setDiasAberto(diffDias);
    }
  }, [ultimoCaixa]);

  // useEffect(() => {

  //   try {
  //     if (selectedButton) {

  //       buscarComandas(selectedButton);
  //     }
  //     window.localStorage.setItem("opcaoCriarPedido", selectedButton);



  //   } catch (error) {
  //     console.log("erro ao calcular", error);
  //   }
  // }, [selectedButton]);


  useEffect(() => {

    try {
      let taxaServicoFloat = parseFloat(taxaServico?.replace(/\./g, '').replace(/\,/g, '.'));
      let descontoFloat = parseFloat(desconto?.replace(/\./g, '').replace(/\,/g, '.'));
      let taxaEntregaFloat = parseFloat(taxaEntrega?.replace(/\./g, '').replace(/\,/g, '.'));

      let valorTotalRecalculado = 0;
      if (valorTotalProdutos) {
        valorTotalRecalculado = parseFloat(valorTotalProdutos.replace(/\./g, '').replace(/\,/g, '.'));
      }

      if (taxaEntrega) {
        valorTotalRecalculado += (taxaEntregaFloat ? taxaEntregaFloat : 0);
      }

      if (desconto) {
        valorTotalRecalculado += - (descontoFloat ? descontoFloat : 0);
      }
      if (taxaServico) {
        valorTotalRecalculado += (valorTotalRecalculado * (taxaServicoFloat / 100));
      }

      setValorTotal(valorTotalRecalculado.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [taxaEntrega, taxaServico, desconto, valorTotalProdutos]);

  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("Comandas");
    buscarComandas("comanda");
    carregarCaixas();
  })


  function carregarCaixas() {
    return requestService(
      apiProjetosCaixasGET.url.replace("{id}", loja.replaceAll("#", "%23")),
      {
        method: apiProjetosCaixasGET.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
      history,
      (response: any) => {
        if (response && response.Items && response.Items.length > 0) {
          setUltimoCaixa(response.Items[0]);
        }
      },
      (error: any) => {
        console.log(error);
      }
    )
  };

  async function consultaComandaUnificada(clienteid: any) {





    setShowLoading(true);


    setListaItemProdutos([]);

    requestService(unirComanda.url.replaceAll("{id}", clienteid.replaceAll(/#/g, '%23')), {
      method: unirComanda.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response) {
          console.log("response", response);

          if (response && response.message) {
            // setListaItemProdutos(response.message);


            let listaProd: any[] = [];

            // Itera sobre cada projeto na lista de mensagens
            response.message.listaProjetos?.forEach((projeto: any) => {
              // Verifica se o projeto possui produtos e se a lista de produtos não está vazia
              if (projeto.produtos && projeto.produtos.length > 0) {
                // Adiciona cada produto à lista `listaProd`
                projeto.produtos.forEach((item: any) => {
                  listaProd.push(item);
                });
              }
            });

            console.log("listaProd", listaProd);

            // Define a lista de itens de produtos com a lista coletada
            setListaItemProdutos(listaProd);


            // setValorTotal(response.message.valorTotal);

            // try {

            //   if (response.message.valorTotal) {
            //     let taxaServicoFloat = parseFloat(taxaServico?.replace(/\./g, '').replace(/\,/g, '.'));
            //     let descontoFloat = parseFloat(desconto?.replace(/\./g, '').replace(/\,/g, '.'));
            //     let taxaEntregaFloat = parseFloat(taxaEntrega?.replace(/\./g, '').replace(/\,/g, '.'));

            //     let valorTotalRecalculado = parseFloat(response.message.valorTotal?.replace(/\./g, '').replace(/\,/g, '.'));

            //     if (taxaEntrega) {
            //       valorTotalRecalculado += (taxaEntregaFloat ? taxaEntregaFloat : 0);
            //     }

            //     if (desconto) {
            //       valorTotalRecalculado += - (descontoFloat ? descontoFloat : 0);
            //     }
            //     if (taxaServico) {
            //       valorTotalRecalculado += (valorTotalRecalculado * (taxaServicoFloat / 100));
            //     }

            //     setValorTotal(valorTotalRecalculado.toLocaleString('pt-BR', {
            //       minimumFractionDigits: 2,
            //       maximumFractionDigits: 2
            //     }));
            //   }

            // } catch (error) {
            //   console.log("erro ao calcular", error);
            // }


            // setExibirCupom(true);

            // setShowModalInserirProjeto(false);
            // setTextToast("Comanda fechada com sucesso!");
            // setShowToast(true);
          }

        }
        //setBotaoAtivo(true);
        setShowLoading(false);
      },
      (error: any) => {
        console.log(error);
        //setBotaoAtivo(true);
        setShowLoading(false);
        setTextToast(error.message);
        setShowToast(true);
      })



  }



  async function unirListas(perfil: any) {
    let listaComandas = await buscarComandasDynamoDb(perfil);
    let listaComandasAbertas = await buscarComandasAbertasDynamoDb();

    // Se qualquer uma das listas for undefined, inicialize-a como uma lista vazia
    listaComandas = listaComandas || [];
    listaComandasAbertas = listaComandasAbertas || [];

    // Criando um mapa para listaComandasAbertas usando clienteid como chave
    let mapaComandasAbertas = new Map(listaComandasAbertas.map((item: any) => [item.clienteid, item]));

    // Unindo as listas
    let listaUnida = listaComandas.map((comanda: any) => {
      let comandaAberta: any = mapaComandasAbertas.get(comanda.pk);

      return {
        ...comanda, // Mapeia as propriedades de comanda
        ...comandaAberta // Mapeia as propriedades de comandaAberta se houver correspondência
      };
    });

    // console.log(listaUnida);

    return listaUnida;
  }

  const buscarComandas = async (perfil: any) => {
    try {
      setShowLoading(true);
      let listaComandas = await unirListas(perfil);

      setListaClienteComanda([]);
      if (Array.isArray(listaComandas) && listaComandas.length > 0) {
        let lista = (listaComandas.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))

        // console.log("lista", lista);
        setListaClienteComanda(lista);

      }
    } catch (error: any) {
      console.error("Ocorreu um problema inesperado. Por favor, tente novamente mais tarde. Detalhes do erro: ", error);
      setTextToast(error);
      setShowToast(true);
    } finally {
      setShowLoading(false);
    }

  }

  const buscarComandasDynamoDb = async (perfil: any) => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo&lojas=" + Buffer.from(loja).toString('base64'));


    const resp = await fetch(montarUrl.replace("{perfil}", perfil),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let comandas = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (comandas.Items) {
        return comandas.Items;
      }
    }
  }

  const buscarComandasAbertasDynamoDb = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let urlFinal = urlBase.url + "/projetos/listaabertas?filtro=comandas".concat("&lojas=" + Buffer.from(loja).toString('base64'));

    const resp: Response = await fetch(urlFinal,
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let comandas = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (comandas) {
        return comandas;
      }
    }
  }


  function continuarMesa(cliente: any) {

    setShowLoading(true);

    let objRequest = {
      tipo: "comandas",
      clienteid: cliente.replaceAll(/#/g, '%23'),
    };

    return requestService(abrirMesa.url.replaceAll("{id}", cliente.replaceAll(/#/g, '%23')).concat("?lojas=" + Buffer.from(loja).toString('base64')), {
      method: abrirMesa.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response && response.message) {
          console.log("response", response);

          if (selectedButton == "balcao") {
            history.push("caixa/produtos", { id: response.message.pk.replaceAll(/#/g, '%23') });
          }
          else {
            history.push("comandas/produtos", { id: response.message.pk.replaceAll(/#/g, '%23') });
          }
        }

      },
      (error: any) => {
        console.log(error);
        setTextToast(error.message);
        setShowToast(true);
      }).finally(() => {
        setShowLoading(false);
      })


  }


  function tempoAndamento(dataCadastro: any) {
    if (!dataCadastro) {
      // Se não houver dataCadastro, retorna vazio
      return "";
    }

    // Convertendo a dataCadastro para um objeto de data
    const dataCadastrada = new Date(dataCadastro);
    const agora = new Date();

    // Calcula a diferença em milissegundos
    const diferencaMs = agora.getTime() - dataCadastrada.getTime();

    // Converte para unidades de tempo
    const minutos = Math.floor(diferencaMs / 1000 / 60) % 60; // Restante dos minutos
    const horas = Math.floor(diferencaMs / 1000 / 60 / 60) % 24; // Restante das horas
    const dias = Math.floor(diferencaMs / 1000 / 60 / 60 / 24); // Total de dias

    // Formatação da saída
    let resultado = "";

    if (dias > 0) {
      resultado += `${dias} ${dias === 1 ? "dia" : "dias"}`;
      return resultado;
    }
    if (horas > 0) {
      resultado += `${dias > 0 ? ", " : ""}${horas} ${horas === 1 ? "hora" : "horas"}`;
    }
    if (minutos > 0) {
      resultado += `${dias > 0 || horas > 0 ? " e " : ""}${minutos} ${minutos === 1 ? "minuto" : "minutos"}`;
    }

    return resultado || "Agora mesmo";
  }

  function readListaComandas() {

    let listaFiltrada = textoFiltro
      ? listaClienteComanda.filter((value: any) =>
        value.nome.toLowerCase().includes(textoFiltro?.toLowerCase())
      )
      : listaClienteComanda;

    if (filter == "ocupadas") {
      listaFiltrada = listaFiltrada.filter((value: any) =>
        value.sk != ""
      )
    }

    return listaFiltrada.map((value: any, index: any) => (
      <>
        {((filter == "todas" || (value.sk && filter == "ocupadas") || (!value.sk && filter == "livres")) &&
          <IonCol sizeMd="3" size="6" key={value.id}>
            <IonCard style={{ margin: "2px", minHeight: "130px" }} disabled={!(ultimoCaixa && ultimoCaixa.status == "aberto")}
              // color={(value.clienteid ?? value.pk) == pkCliente ? "danger" : 'light'}
              button
              onClick={() => {

                console.log("value.clienteid", value.clienteid);
                console.log("value.pk", value.pk);
                setPkCliente(value.clienteid || value.pk);

                if (value.clienteid) {
                  if (selectedButton == "balcao") {
                    history.push("caixa/produtos", { id: value.pk.replaceAll(/#/g, '%23') });
                  }
                  else {
                    history.push("comandas/produtos", { id: value.pk.replaceAll(/#/g, '%23') });
                  }
                }
                else {
                  if (acessos?.comandas == 'edicao') {
                    mostartAlerta({
                      backdropDismiss: false,
                      header: value.nome,
                      message: "Deseja " + (value.clienteid ? 'continuar' : 'abrir') + '?',
                      buttons: [
                        {
                          text: 'Cancelar',
                          role: 'cancel',
                          handler: () => {
                            setPkCliente("");
                          }
                        },
                        {
                          text: 'Confirmar',
                          handler: async () => {


                            continuarMesa(value.pk);

                          }
                        }
                      ],
                      onDidDismiss: () => { console.log('did dismiss'); }
                    });
                  }
                  else {
                    setTextToast("Você não tem permissão para acessar esta funcionalidade.");
                    setShowToast(true);
                  }
                }
              }}
            >

              <IonCardContent>
                {value.sk ? (
                  <IonChip color={'danger'}>
                    <IonIcon icon={clipboardOutline} />
                    <IonLabel>Aberta</IonLabel>
                  </IonChip>
                ) : (
                  <IonChip color={'success'}>
                    <IonIcon icon={checkmarkOutline} />
                    <IonLabel>Livre</IonLabel>
                  </IonChip>
                )}
                <div>
                  <IonLabel className="ion-text-wrap">
                    <b>{value.clienteNome || value.nome}</b>
                  </IonLabel>
                </div>

                <IonLabel>
                  <p>{value.valorTotal ? `R$ ${value.valorTotal}` : 'R$ 0,00'}</p>
                </IonLabel>

                {value.dataCadastro && (
                  <div style={{ textAlign: "right", fontSize: "12px" }}>
                    <IonIcon icon={timeOutline} /> {tempoAndamento(value.dataCadastro)}
                  </div>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol >
        )
        }
      </>

    ));
  }


  function validarCampos() {

    if (!pkCliente) {
      setTextToast("Comanda não selecionada.");
      setShowToast(true);
      return;
    }
    else if (listaItemProdutos.length == 0) {
      setTextToast("Adicione pelo menos um item.");
      setShowToast(true);
      return false;
    }
    return true;
  }

  const openNewTabWithHTML = async (projeto: any) => {
    let newWindow: any;
    newWindow = window.open('', '_blank');

    console.log(projeto);
    // Gera o conteúdo HTML dinamicamente
    const htmlContentTab = await montarCupomHtml(projeto);

    // Escreve o conteúdo HTML na nova aba
    newWindow.document.open();
    newWindow.document.write(htmlContentTab);
    newWindow.document.close();

    // Chama a função de impressão na nova aba
    newWindow.print();
    setTimeout(function () {
      // Fecha a aba/janela aberta
      newWindow.close();
    }, 300);
  };

  async function montarCupomHtml(projeto: any) {

    function removerAcentosECedilha(texto: any) {
      return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ç/g, 'c').replace(/Ç/g, 'C');
    }

    function aplicarMascaraCPFouCNPJ(texto: any) {
      if (!texto) return '';
      texto = texto.replace(/\D/g, ''); // Remove tudo que não é dígito

      if (texto.length === 11) {
        // Máscara para CPF
        return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (texto.length === 14) {
        // Máscara para CNPJ
        return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }

      return texto; // Retorna o texto sem máscara se não tiver 11 ou 14 dígitos
    }

    function formataTelefone(numero: any) {
      if (numero?.length == 13) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else if (numero?.length == 12) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else { return "" };
    }

    function quebrarTextoEmLinhas(texto: any, tamanhoMaximo: any) {
      const palavras = texto.split(' ');
      let linhas = [];
      let linhaAtual = '';

      palavras.forEach((palavra: any) => {
        if ((linhaAtual + palavra).length <= tamanhoMaximo) {
          linhaAtual += palavra + ' ';
        } else {
          linhas.push(linhaAtual.trim());
          linhaAtual = palavra + ' ';
        }
      });

      if (linhaAtual.trim()) {
        linhas.push(linhaAtual.trim());
      }

      return linhas;
    }

    let dataCupom: any;
    if (projeto.dataCupom) {
      dataCupom = new Date(projeto.dataCupom);

      //dataCupom.setHours(dataCupom.getHours() - 3);
    }
    let html = `

     <!DOCTYPE html>
        <html lang="pt-BR">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>CUPOM NÃO FISCAL</title>
            <style>
                body {
                    font-family: 'Courier New', Courier, monospace;
                    margin: 0;
                    padding: 0;
                }

      <style>
                @media print {
                    body {
                        width: 370px; /* Define a largura da página como a largura do papel da impressora térmica */
                        margin: 0;
                    }

                    .no-print {
                        display: none; /* Esconde elementos que não devem aparecer na impressão */
                    }
                }
                .bold {
                    font-weight: bold;
                }
                .regular {
                    font-size: 12pt;
                }
                .regular-itens {
                    font-size: 12pt;
                }
                .table-container {
                    width: 100%;
                    max-width: 370px;
                    margin: 0 auto;
                    text-align: left;
                }
                table {
                    width: 100%;

                    border-collapse: collapse;
                }
                th, td {
                    border: none;
                    padding: 5px;
                    text-align: left;
                    margin: 0px;
                    padding: 0px;
                }
                th {
                    background-color: #f2f2f2;
                }
                .header, .footer {
                    margin-top: 20px;
                }
                .footer {
                    margin-top: 40px;
                }
                .separator {
                    text-align: left;
                    margin: 0px;
                    padding: 0px;
                }
                /* CSS for the giant button */
                .print-button {
                    display: block;
                    width: 100%;
                    padding: 20px;
                    margin: 20px 0;
                    background-color: #4CAF50; /* Green background */
                    color: white;
                    font-size: 24px;
                    font-weight: bold;
                    text-align: center;
                    border: none;
                    border-radius: 10px;
                    cursor: pointer;
                }
        
                
                .print-button:hover {
                    background-color: #45a049; /* Darker green on hover */
                }
                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    padding: 10px 20px;
                    background-color: #f44336; /* Red background */
                    color: white;
                    font-size: 16px;
                    font-weight: bold;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                }
                
                .close-button:hover {
                    background-color: #d32f2f; /* Darker red on hover */
                }
            </style>

  

      <div class="table-container">

       
          <div class="header bold">
              <div>${projeto.empresa.empresaNome}</div>     
              <div class="regular">CNPJ: ${aplicarMascaraCPFouCNPJ(projeto.empresa.empresaCnpj)}</div>   
              ${quebrarTextoEmLinhas(`Endereço: ${projeto.empresa.empresaEndereco}`, 35)
        .map(linha => `<div class="regular">${linha}</div>`).join('')}
              <div class="regular">Tel: ${formataTelefone(projeto.empresa.empresaTelefone)}</div>        
              <div class="regular">
              ${(dataCupom)
        ? `Data: ${dataCupom.toLocaleDateString('pt-BR')} Hora: ${dataCupom.toLocaleTimeString('pt-BR')}`
        : ""}
              </div>
          </div>
          <div class="separator">**************************************</div>
           <div class="header bold">
              <div>${projeto.cliente.nome}</div>
          </div>
           `;

    if ((!projeto.interno)) {

      html += `
      <div class="header bold">            
          <div class="regular">Tel: ${formataTelefone(projeto.cliente.telefone)}</div>
              
          ${quebrarTextoEmLinhas(`Endereço: ${[projeto.endereco, projeto.complemento]
        .filter(Boolean)
        .join(', ') || ""}`, 35)
          .map(linha => `<div class="regular">${linha}</div>`).join('')}
      </div>
       `;
    }

    html += `
          <div class="separator">**************************************</div>
          <div class="header bold">CUPOM NÃO FISCAL</div>
          <div class="separator">======================================</div>
          <div class="regular">${projeto.servicoTipo || "Projeto"}: ${projeto.nomeProjeto}</div>
          <div class="separator">======================================</div>
  `;

    if (projeto.produtos && projeto.produtos.length > 0) {
      html += `
          <div class="table-container">
          <table>
              <tbody>   
                  <tr>
                      <td colspan="2">QTDE&nbsp|&nbspDESCRIÇÃO</td>
                  </tr>           
                  <tr><td colspan="2"><div class="separator">--------------------------------------</div></td></tr> 
                  ${projeto.produtos.map((iv: any, index: any) => `
                     
                          
                              ${(Array.isArray(iv.descricao)) ? (
          iv.descricao.map((itemDesc: any, indexLinha: any) => `
              
                                  ${indexLinha === 0 ?

              quebrarTextoEmLinhas(iv.tipo === "pizzas" ? `${iv.tamanho} ${iv.quantidadeSabores} SABORES (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})` : `${iv.categoria} (R$ ${iv.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha2) => `
                                      <tr>
                                        ${indexLinha2 === 0 ? `
                                          <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                                          ` : `
                                          <td>&nbsp</td>
                                          <td class="regular-itens">${linha}</td>
                                         
                                          `}
                                        
                                  
                                      </tr>
                                      
                                  `).join('')





              : ''}
                              
                                  
                                   ${(itemDesc.descricao) && (



              quebrarTextoEmLinhas(`- ${itemDesc.descricao} (R$ ${itemDesc.valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })})`, 30)
                .map((linha, indexLinha) => `
                                      <tr>
                                          <td>&nbsp</td>
                                          <td class="regular-itens">${linha}</td>
                                  
                                      </tr>
                                      
                                  `).join('')

            )}

                                 
                                  `).join('')
        ) : (
          quebrarTextoEmLinhas(`${iv.descricao} (R$ ${iv.valor.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })})`, 30)
            .map((linha, indexLinha) => `
                                       <tr>
                                      ${indexLinha === 0 ? `
                                          <td colspan="2" class="regular-itens">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} | ${linha}</td>
                                          ` : `
                                          <td>&nbsp</td>
                                          <td class="regular-itens">${linha}</td>
                                         
                                          `}
                                      </tr>
                                      
                                  `).join('')
        )}
                             
              
              
                      <tr>   
                          <td colspan="2" class="regular-itens" style="text-align: left;">VALOR: R$ ${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      </tr>
                      <tr><td colspan="2"><div class="separator">--------------------------------------</div></td></tr>`).join('')}
              </tbody>   
          </table>
          </div>
        
          <div class="bold">TOTAL: R$ ${projeto.valorTotalProdutos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
      `;
    }

    if (projeto.servicos && projeto.servicos.length > 0) {
      if (projeto.produtos && projeto.produtos.length > 0) {
        html += `<div class="separator">**************************************</div>`;
      }
      html += `
          <div class="table-container">     
         
          <table>
              <tbody>   
                  <tr>
                      <td>#</td>
                      <td>DESCRIÇÃO</td>
                      <td>QTD</td>
                      <td>VALOR</td>
                  </tr>               
                  ${projeto.servicos.map((iv: any, index: any) => `
                      <tr>  
                                               
                          <td class="regular-itens">${quebrarTextoEmLinhas(`${iv.descricao} x ${iv.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 30)
          .map((linha, indexLinha) => `<tr> <td class="regular-itens">${indexLinha == 0 ? String(index + 1).padStart(2, '0') : "&nbsp"}</td>    <td class="regular-itens">${linha}</td></tr>`).join('')}</td> 
                          <td class="regular-itens" style="text-align: right;">&nbsp</td>
                          <td class="regular-itens" style="text-align: right;">&nbsp</td>
                          <td class="regular-itens" style="text-align: right;">${iv.quantidade.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                          <td class="regular-itens" style="text-align: right;">${iv.subtotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      </tr>`).join('')}
              </tbody>
          </table>
          </div>
          <div class="separator">--------------------------------------</div>
          <div class="bold">TOTAL: R$ ${projeto.valorTotalServicos?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
      `;
    }


    if (projeto.formaPagamento) {
      html += `
          <div class="separator">**************************************</div>
          <div class="bold">FORMA PAGAMENTO: ${projeto.formaPagamento?.toUpperCase()}</div>
      `;
    }
    if (projeto.troco) {
      html += `
          <div class="bold">TROCO: R$ ${projeto.troco?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
      `;
    }
    if (projeto.taxaEntrega && !projeto.interno) {
      html += `            
          <div class="bold">TAXA DE ENTREGA: +R$ ${projeto.taxaEntrega?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
      `;
    }
    if (projeto.desconto) {
      html += `            
          <div class="bold">DESCONTO: -R$ ${projeto.desconto?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
      `;
    }

    if (projeto.interno && projeto.taxaServico) {
      html += `            
          <div class="bold">TAXA: +R$ ${projeto.taxaServico?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</div>
      `;
    }
    if (projeto.valorTotal) {
      html += `
          <div class="separator">**************************************</div>
          <div class="bold">TOTAL ${projeto.servicoTipo?.toUpperCase() || "PROJETO"}: R$ ${projeto.valorTotal?.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
      `;
    }

    html += `
          <div class="separator">.</div>
          <div class="separator">.</div>
          <div class="separator">.</div>
      </div>
            
        
        </body>
        </html>
  `;

    return removerAcentosECedilha(html);
  }

  function avancar(pularEndereco: boolean = false) {

    if (!validarCampos())
      return;

    setShowLoading(true);

    let objRequest = {
      "nomeCliente": "",
      "telefoneCliente": "",
      lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
        return lojas.includes(value.empresaLojaId);
      }),
      "endereco": "",
      "complemento": "",
      "enderecoLat": "",
      "enderecoLng": "",
      "clienteid": pkCliente.replaceAll(/#/g, '%23'),
      "responsavelid": "",
      "empresaid": loja || "",
      "responsaveis": [pkUsuario.replaceAll(/#/g, '%23')],
      "interno": false,
      "produtos": listaItemProdutos,
      "formaPagamento": formaPagamento,
      "troco": troco,
      "taxaEntrega": taxaEntrega,
      "desconto": desconto,
      "taxaServico": taxaServico

    };

    requestService(incluirProjeto.url, {
      method: incluirProjeto.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {


        if (response.message) {


          if (response.message?.pk) {


            if (!isPlatform('mobileweb') && isPlatform('mobile')) {
              gerarCupom(response.message.pk);
              // history.goBack();
              setRecarregarProjeto(true);
              setListaItemProdutos([]);
            }
            else {




              mostartAlerta({
                backdropDismiss: false,
                header: 'Cadastro realizado com sucesso',
                message: 'Deseja imprimir?',

                buttons: [{
                  text: 'Cancelar',
                  role: 'cancel',
                  handler: () => {
                    // history.goBack();
                    setRecarregarProjeto(true);
                    setListaItemProdutos([]);
                  }
                },
                {
                  text: 'Confirmar',
                  handler: async () => {

                    await openNewTabWithHTML(response.message.vo);
                    // history.goBack();
                    setRecarregarProjeto(true);
                    setListaItemProdutos([]);
                  }
                }],
                onDidDismiss: () => { console.log('did dismiss'); }
              })

            }




          }

        }
      },
      (error: any) => {
        console.log(error);




        mostartAlerta({
          backdropDismiss: false,
          header: 'Endereço com problema',
          message: error.message,

          buttons: [{
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Confirmar',
            handler: () => {
              avancar(true);
            }
          }],
          onDidDismiss: () => { console.log('did dismiss'); }
        })
      }).finally(() => {
        setShowLoading(false);
      });




  }


  async function gerarCupom(projetoid: any) {


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    setShowLoading(true);
    const resp = await fetch(projetoGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23")),
      {
        method: projetoGerarCupom.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    const result = await resp.json();
    if (result) {

      mostartAlerta({
        backdropDismiss: false,
        header: 'Gerar Cupom',
        message: '<strong>Cupom</strong> foi gerado com sucesso.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
          },
          {
            text: 'Visualizar',
            cssClass: 'primary',
            handler: () => {
              window.open(result, '_blank');
            }
          }
        ],
        onDidDismiss: (e) => console.log('did dismiss'),
      })

    }

    setShowLoading(false);

  }

  async function closeModalEndereco(enderecoSelecionado: any, latitudeSelecionado: any, enderecoLngSelecionado: any) {
    console.log("enderecoSelecionado", enderecoSelecionado);
    console.log("latitudeSelecionado", latitudeSelecionado);
    console.log("enderecoLngSelecionado", enderecoLngSelecionado);

    setEndereco(enderecoSelecionado);
    setEnderecoLat(latitudeSelecionado);
    setEnderecoLng(enderecoLngSelecionado);

    setShowDetalhesEndereco(false);
  }

  async function closeModalSimpleEndereco() {
    setShowDetalhesEndereco(false);
  }

  function deletarItemProdutos(index: number) {
    // Filtra a lista de produtos, removendo o item com o índice fornecido
    const updatedList = listaItemProdutos.filter((_: any, i: any) => i !== index);

    // Atualiza a lista de produtos com o item removido
    setListaItemProdutos(updatedList);

    // Calcula a nova soma dos valores após a remoção
    const totalValor = updatedList.reduce((total: any, item: any) => {
      const itemValorNumerico = parseFloat(
        item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
      return total + itemValorNumerico;
    }, 0);

    // Exemplo: você pode armazenar a nova soma em outro estado ou usar conforme necessário
    console.log("Nova soma dos valores:", totalValor);

    setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));

  }

  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setTroco(v);
  }





  const loadItemProdutos2 = () => {

    if (listaItemProdutos == undefined) return;

    if (listaItemProdutos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>

        <IonGrid>
          {listaItemProdutos.map((value: any, index: any) => (
            <>

              <IonRow style={{ borderColor: '#dddddd', borderWidth: '1px', borderBottomStyle: 'dashed' }}>


                <IonCol size="9">


                  {(value.descricao && Array.isArray(value.descricao)) ? (

                    <>
                      {(value.tipo === "pizzas") ? (
                        <div>{value.tamanho} {value.quantidadeSabores} SABORES</div>
                      ) : (
                        <div>{value.categoria}</div>
                      )}
                      {value.descricao.map((item: any, index: number) => (
                        <div key={"desc" + index}>
                          - {item.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {item.valor?.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</IonLabel>)
                        </div>
                      ))}
                    </>

                  ) : (
                    <div key={"desc" + index}>
                      {value.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {value.valor?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</IonLabel>)

                    </div>
                  )}



                </IonCol>


                <IonCol style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} sizeXs="3" >
                  <IonLabel style={{ textAlign: "right" }}>{value.subtotal}</IonLabel>
                </IonCol>
                <IonCol sizeSm='6'>
                  <IonLabel style={{ textAlign: "right", fontSize: "12px" }} >
                    <IonChip style={{ margin: 0, padding: '5px' }}>
                      <IonIcon size='large' color='danger' icon={removeCircle} onClick={() => {

                        // Faz uma cópia da lista atual de produtos
                        let updatedList = [...listaItemProdutos];

                        // Acessa o item que será atualizado
                        let itemToUpdate = { ...updatedList[index] };

                        // Formata a quantidade atual
                        const quantidadeFormatada = parseFloat(
                          itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        if (quantidadeFormatada > 1) {
                          // Decrementa a quantidade
                          let quantidadeNova = quantidadeFormatada - 1;

                          // Formata o valor e calcula o novo valor com base na nova quantidade
                          const valorFormatado = parseFloat(
                            itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );

                          // Atualiza a quantidade e o valor do item
                          itemToUpdate.quantidade = quantidadeNova;
                          const valorFloat = quantidadeNova * valorFormatado;

                          itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          });

                          // Substitui o item atualizado na lista copiada
                          updatedList[index] = itemToUpdate;

                          let totalValor = updatedList.reduce((total: any, item: any) => {
                            const itemValorNumerico = parseFloat(
                              item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                            );
                            return total + itemValorNumerico;
                          }, 0);

                          setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }));

                          // Atualiza o estado com a nova lista
                          setListaItemProdutos(updatedList);

                        }
                        // else {
                        //   // Se a quantidade for 1, pode-se optar por remover o item completamente ou manter o valor como está.
                        //   // Se preferir remover o item:
                        //   updatedList.splice(index, 1);
                        //   setListaItemProdutos(updatedList);
                        // }

                      }}></IonIcon>
                      <IonLabel style={{ fontSize: '20px' }}>{value.quantidade.toLocaleString('pt-br', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}</IonLabel>
                      <IonIcon size='large' color='success' icon={addCircle} onClick={() => {

                        // Faz uma cópia da lista atual de produtos
                        let updatedList = [...listaItemProdutos];

                        // Acessa o item que será atualizado
                        let itemToUpdate = { ...updatedList[index] };

                        // Formata e incrementa a quantidade
                        const quantidadeFormatada = parseFloat(
                          itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        let quantidadeNova = quantidadeFormatada + 1;

                        // Formata o valor e calcula o novo valor com base na nova quantidade
                        const valorFormatado = parseFloat(
                          itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        // Atualiza a quantidade e o valor do item
                        itemToUpdate.quantidade = quantidadeNova;
                        const valorFloat = quantidadeNova * valorFormatado;

                        itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        });

                        // Substitui o item atualizado na lista copiada
                        updatedList[index] = itemToUpdate;

                        let totalValor = updatedList.reduce((total: any, item: any) => {
                          const itemValorNumerico = parseFloat(
                            item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );
                          return total + itemValorNumerico;
                        }, 0);

                        setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));

                        // Atualiza o estado com a nova lista
                        setListaItemProdutos(updatedList);


                      }}></IonIcon>
                    </IonChip>
                  </IonLabel>
                </IonCol>
                <IonCol style={{ display: "flex", justifyContent: "flex-end" }} sizeSm='6'>
                  <IonButtons>
                    <IonButton
                      onClick={() => {
                        mostartAlerta({
                          backdropDismiss: false,
                          header: 'Remover item',
                          message: 'Deseja <strong>remover</strong>?',
                          buttons: [
                            {
                              text: 'Cancelar',
                              role: 'cancel',
                            },
                            {
                              text: 'Confirmar',
                              cssClass: 'primary',
                              handler: () => {
                                deletarItemProdutos(index);
                              }
                            }
                          ],
                          onDidDismiss: (e) => console.log('did dismiss'),
                        });
                      }}
                    >
                      <IonIcon color="primary" icon={trash} />
                    </IonButton>
                  </IonButtons>
                </IonCol>

              </IonRow>
            </>
          ))}
        </IonGrid>
      </>
    );
  }


  async function closeModalProdutosCompor() {
    // setShowDetalhesCompor(false);
  }

  const scrollToCatalogo = () => {
    contentCatalogoRef.current && contentCatalogoRef.current.scrollIntoView();
  };

  async function adicionarItem(produto: any) {

    if (produto) {

      let updatedList = [...listaItemProdutos, produto];

      setListaItemProdutos(updatedList);

      let totalValor = updatedList.reduce((total: any, item: any) => {
        const itemValorNumerico = parseFloat(
          item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
        );
        return total + itemValorNumerico;
      }, 0);

      setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));


    }
    scrollToCatalogo();
  }

  async function fecharComanda() {

    setShowLoading(true);
    //setBotaoAtivo(false);

    // const found = listaCliente.find((obj: any) => {
    //   return obj.pk === cliente;
    // });

    // console.log("found", found);

    let objRequest = {
      clienteid: pkCliente.replaceAll(/#/g, '%23'),
      formaPagamento: formaPagamento,
      desconto: desconto,
      taxaServico: taxaServico
    };

    return requestService(encerrarComanda.url.replaceAll("{id}", pkCliente.replaceAll(/#/g, '%23')), {
      method: encerrarComanda.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response) {
          // console.log("response", response);
          setRecarregarProjeto(true);
          setListaItemProdutos([]);
          // history.goBack();
        }
        //setBotaoAtivo(true);
        setShowLoading(false);
      },
      (error: any) => {
        console.log(error);
        //setBotaoAtivo(true);
        setShowLoading(false);
        setTextToast(error.message);
        setShowToast(true);
      })


  }


  // const handleEtapaChange = (value: any) => {
  //   setSelectedButton(value);
  //   buscarComandas(value);
  //   window.localStorage.setItem("opcaoCriarPedido", value);

  // };

  function doRefresh(event: any) {
    buscarComandas("comanda");
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalAbrirCaixa() {
    setShowModalAbrirCaixa(false);
  }

  async function closeModalAbrirCaixaSuccess() {
    carregarCaixas();
    setShowModalAbrirCaixa(false);
  }

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>


          <IonButtons slot="end">
            <IonButton onClick={() => { setShowNotificacoes(true); }}>
              <IonIcon slot="icon-only" icon={notifications} />
              <IonBadge id="cart-badge" color="danger">{notificacoes?.length}</IonBadge>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
            <IonButton onClick={() => { history.push("./chat"); }}>
              <IonIcon slot="icon-only" icon={chatbubbles} />
              <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>

          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>


              <IonItem lines="none" button hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao' && !(window.localStorage.getItem("servicoPrincipal") == "Pedidos" || window.localStorage.getItem("servicoPrincipal") == "Vendas")}
                onClick={() => {
                  history.push("comandas/comandashistorico"); closePopover();
                }}>
                <IonIcon icon={folderOutline} slot="start" />
                <IonLabel>Histórico</IonLabel>
              </IonItem>

              <IonItem lines="none" button onClick={closePopover}>
                <IonIcon icon={closeOutline} slot="start" />
                <IonLabel>Cancelar</IonLabel>
              </IonItem>
            </IonList>
          </IonPopover>

        </IonToolbar>
        {/* {listaComandass?.length > 0 && (
          <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: listaComandass.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
            {listaComandass.map((item: any, index: any) => (
              <IonItem lines="none" key={"filtro" + index} button onClick={() => handleSelectItem(item)}>
                <IonLabel>{item.nome + " - " + formataTelefone(item.telefone)}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        )} */}


        {/* <IonToolbar style={{ height: "48px" }}>
          <IonSegment value={selectedButton} onIonChange={(e) => handleEtapaChange(e.detail.value)}>
            <IonSegmentButton style={{ height: "30px" }} value="balcao">
              <IonLabel style={{ fontSize: "14px" }}>{"Balcão"} </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              style={{ height: "30px" }}
              value="comanda"
            >
              <IonLabel style={{ fontSize: "14px" }}>{(window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas")}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar> */}

        <IonToolbar>
          <IonSearchbar
            ref={inputRef}
            value={textoFiltro}
            onIonChange={(e) => {
              setTextoFiltro(e.detail.value);
            }}
            enterkeyhint="search"
            placeholder="Buscar"
          />
        </IonToolbar>
      </IonHeader>




      <IonContent>

        {/* <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => { setShowDetalhesCompor(true); }}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab> */}

        <IonFab vertical="top" horizontal="end" slot="fixed" hidden={isPlatform("android") || isPlatform("ios")}>
          <IonFabButton color="tertiary" onClick={() => { buscarComandas("comanda"); }} >
            <IonIcon color="light" icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>


        <IonGrid>
          {/* <IonRow className="ion-justify-content-center"> */}
          <IonRow>
            {readListaComandas()}
          </IonRow>
        </IonGrid>




        <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
          <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
        </IonModal>


        <IonModal
          onDidPresent={() => {
            if (inputRefEndereco.current) {
              inputRefEndereco.current.setFocus();
            }
          }} isOpen={showDetalhesEndereco} onDidDismiss={() => { setShowDetalhesEndereco(false); }}>
          <Endereco endereco={endereco} inputRef={inputRefEndereco} closeAction={closeModalEndereco} closeActionSimple={closeModalSimpleEndereco}></Endereco>
        </IonModal>

        <IonModal className="small-modal" isOpen={showModalAbrirCaixa} onDidDismiss={() => { setShowModalAbrirCaixa(false); }}>
          <CaixaGeralAbrir closeAction={closeModalAbrirCaixaSuccess} closeActionSimple={closeModalAbrirCaixa}></CaixaGeralAbrir>
        </IonModal>

      </IonContent>

      <IonFooter>
        <IonToolbar color="warning" hidden={ultimoCaixa?.status === "aberto" || false}>
          <IonGrid>
            <IonRow class="ion-align-items-center">

              <IonCol size="auto">
                <IonLabel>
                  <>Para começar efetuar vendas é preciso <strong>abrir o caixa</strong></>
                </IonLabel>
              </IonCol>
              <IonCol class="ion-text-right">
                <IonButton
                  disabled={acessos?.caixa != 'edicao'}
                  color="danger" size="small" onClick={async () => {
                    setShowModalAbrirCaixa(true);
                  }}>
                  Abrir Caixa
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>


        <IonToolbar color="warning" hidden={!(ultimoCaixa && ultimoCaixa.status === "aberto" && diasAberto > 0)}>
          <IonGrid>
            <IonRow class="ion-align-items-center">
              <IonCol size="auto">
                <IonLabel>
                  <>Seu caixa está <strong>{diasAberto} dia(s) aberto</strong></>
                </IonLabel>
              </IonCol>
              <IonCol class="ion-text-right">
                <IonButton color="danger" size="small" onClick={() => history.push("./caixageral")}>
                  Fechar Caixa
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>

      </IonFooter>

      <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
      />


      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => { setShowLoading(false); }}
        spinner='circles'
      />

    </IonPage >
  );
};

export default Comandas;