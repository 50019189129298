import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonLabel, IonLoading, IonModal, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, isPlatform, useIonViewDidEnter } from '@ionic/react';
import { int } from '@zxing/library/esm/customTypings';
import dayjs from 'dayjs';
import { arrowBack, notifications, refresh, star } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { FunnelChart } from 'react-funnel-pipeline';
import 'react-funnel-pipeline/dist/index.css';
import { Chart } from "react-google-charts";
import { useHistory } from 'react-router-dom';
import { reportsBuscar, urlBase } from '../../../../../Utils/Services';

import { Buffer } from 'buffer';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Notificacoes from '../Notificacoes/Notificacoes';
import './Painel.css';

type Props = {
  recarregar: boolean;
};

const Painel: React.FC<Props> = (props: Props) => {

  const history = useHistory();
  const [arrayOperacoes, setArrayOperacoes] = useState<any>([]);
  const [chartUsuarios, setChartUsuarios] = useState<any>({});
  const [chartsprojetosAtivos, setChartProjetosAtivos] = useState<any>({});
  const [chartsvendasAtivos, setChartVendasAtivos] = useState<any>({});
  const [chartOperacoesIniciadas, setChartOperacoesIniciadas] = useState<any>({});
  const [chartOperacoesEncerradas, setChartOperacoesEncerradas] = useState<any>({});
  const [chartOperacoesAvaliacoes, setChartOperacoesAvaliacoes] = useState<any>({});

  const [workflowVenda, setWorkflowVenda] = useState<any>(JSON.parse(window.localStorage.getItem("configWorkflowVenda") || "[]"));
  const [workflowProjeto, setWorkflowProjeto] = useState<any>(JSON.parse(window.localStorage.getItem("configWorkflow") || "[]"));

  const { loja, setLoja } = useContext(MessageContext);
  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);

  const { mensagens, setMensagens } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const [showLoading, setShowLoading] = useState(false);

  const [listaDespesasEmAtraso, setListaDespesasEmAtraso] = useState<any>([]);
  const [listaDespesasAVencer, setListaDespesasAVencer] = useState<any>([]);

  const [listaReceitasEmAtraso, setListaReceitasEmAtraso] = useState<any>([]);
  const [listaReceitasAReceber, setListaReceitasAReceber] = useState<any>([]);

  // const [listaAniversariantes, setListaAniversariantes] = useState<any>([]);

  const [stateLastEvaluatedKey, setStateLastEvaluatedKey] = useState<any>();

  const [listaLembretes, setListaLembretes] = useState<any>([]);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);


  // useEffect(() => {
  //   // primeira vez q entra e quando troca de loja
  //   // console.log("entrou useEffect mudou loja - dashboard", loja);
  //   carregarTodos();
  // }, [loja]
  // );

  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("");
    carregarTodos();
    setWorkflowProjeto([...workflowProjeto]);
  });

  const loadOperacoes = () => {

    if (acessos?.operacoes != 'leitura' && acessos?.operacoes != 'edicao') {
      return (<></>)
    }


    return arrayOperacoes.map((value: any, index: any, arr: any) =>
      (value.relatorio === "operacoesAtivas") ?
        (
          <div >
            <IonCard style={{ padding: "0px", margin: "0px" }} key={"operacoesAtivas" + index} onClick={() => {
              // history.replace("/empresa/operacoes");
            }}>
              <IonCardHeader id={value.tipo}>
                <IonCardTitle>Operações em andamento</IonCardTitle>
                <IonCardSubtitle></IonCardSubtitle>
                <IonLabel></IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <div className="chartPrincipal">{value.total}</div>
                <div className="container">
                  <div className="box">
                    <div className="box-row">
                      <div className="box-cell" hidden={!value.totalEntrega || value.totalEntrega == 0}>{value.totalEntrega || 0}<div style={{ fontSize: "10px" }}>Entrega</div></div>
                      <div className="box-cell" hidden={!value.totalServico || value.totalServico == 0}>{value.totalServico || 0}<div style={{ fontSize: "10px" }}>Serviço</div></div>
                      <div className="box-cell" hidden={!value.totalReuniao || value.totalReuniao == 0}>{value.totalReuniao || 0}<div style={{ fontSize: "10px" }}>Reunião</div></div>
                      <div className="box-cell" hidden={!value.totalConsulta || value.totalConsulta == 0}>{value.totalConsulta || 0}<div style={{ fontSize: "10px" }}>Consulta</div></div>

                      <div className="box-cell" hidden={!value.totalVisitaTecnica || value.totalVisitaTecnica == 0}>{value.totalVisitaTecnica || 0}<div style={{ fontSize: "10px" }}>Visita Técnica</div></div>
                      <div className="box-cell" hidden={!value.totalReserva || value.totalReserva == 0}>{value.totalReserva || 0}<div style={{ fontSize: "10px" }}>Reserva</div></div>
                      <div className="box-cell" hidden={!value.totalCheckIn || value.totalCheckIn == 0}>{value.totalCheckIn || 0}<div style={{ fontSize: "10px" }}>Check In</div></div>
                      <div className="box-cell" hidden={!value.totalCheckOut || value.totalCheckOut == 0}>{value.totalCheckOut || 0}<div style={{ fontSize: "10px" }}>Check Out</div></div>
                    </div>
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </div>
        ) :
        (null)
    );
  };


  const loadAgendamentos = () => {

    if (acessos?.agenda != 'leitura' && acessos?.agenda != 'edicao') {
      return (<></>)
    }


    return arrayOperacoes.map((value: any, index: any, arr: any) =>
      (value.relatorio === "agendadasAtivas") ?
        (
          <div >
            <IonCard style={{ padding: "0px", margin: "0px" }} key={"agendadasAtivas" + index} onClick={() => {
              // history.replace("/empresa/agendamentos");
            }}>
              <IonCardHeader id={value.tipo}>
                <IonCardTitle>Agendamentos abertos</IonCardTitle>
                <IonCardSubtitle></IonCardSubtitle>
                <IonLabel></IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <div className="chartPrincipal">{value.total}</div>
                <div className="container">
                  <div className="box">
                    <div className="box-row">
                      <div className="box-cell" hidden={!value.totalEntrega || value.totalEntrega == 0}>{value.totalEntrega || 0}<div style={{ fontSize: "10px" }}>Entrega</div></div>
                      <div className="box-cell" hidden={!value.totalServico || value.totalServico == 0}>{value.totalServico || 0}<div style={{ fontSize: "10px" }}>Serviço</div></div>
                      <div className="box-cell" hidden={!value.totalReuniao || value.totalReuniao == 0}>{value.totalReuniao || 0}<div style={{ fontSize: "10px" }}>Reunião</div></div>
                      <div className="box-cell" hidden={!value.totalConsulta || value.totalConsulta == 0}>{value.totalConsulta || 0}<div style={{ fontSize: "10px" }}>Consulta</div></div>

                      <div className="box-cell" hidden={!value.totalVisitaTecnica || value.totalVisitaTecnica == 0}>{value.totalVisitaTecnica || 0}<div style={{ fontSize: "10px" }}>Visita Técnica</div></div>
                      <div className="box-cell" hidden={!value.totalReserva || value.totalReserva == 0}>{value.totalReserva || 0}<div style={{ fontSize: "10px" }}>Reserva</div></div>
                      <div className="box-cell" hidden={!value.totalCheckIn || value.totalCheckIn == 0}>{value.totalCheckIn || 0}<div style={{ fontSize: "10px" }}>Check In</div></div>
                      <div className="box-cell" hidden={!value.totalCheckOut || value.totalCheckOut == 0}>{value.totalCheckOut || 0}<div style={{ fontSize: "10px" }}>Check Out</div></div>
                    </div>
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </div>
        ) :
        (null)
    );
  };

  const optionsProjetos = {
    //title: (window.localStorage.getItem("servicoTipoPlural") || "Projetos") + " por " + (window.localStorage.getItem("nomeLinhaDoTempo") || "Etapas"),
    hAxis: {
      format: '#',
      slantedText: true,
      slantedTextAngle: 45
    },
    legend: { position: "none" },
  };

  const optionsUsuarios = {
    title: "",
    pieSliceText: 'value',
    sliceVisibilityThreshold: 0
  };


  const loadGraficoUsuarios = () => {

    if (acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao') {
      return (<></>)
    }


    return arrayOperacoes.map((value: any, index: any, arr: any) =>
      (value.tipo === "charts#usuarios") ?
        (



          <IonCard style={{ minHeight: '300px', padding: "0px", margin: "0px" }}>
            <IonCardHeader id={value.tipo} onClick={() => {
              // history.replace("/empresa/operacoes"); 
            }}>
              <IonCardTitle>Quantidade de usuários</IonCardTitle>
              <IonLabel></IonLabel>
            </IonCardHeader>
            <IonCardContent style={{}}>
              <Chart
                chartType="PieChart"
                data={chartUsuarios}
                options={optionsUsuarios}
              />
            </IonCardContent>
          </IonCard>






        ) :
        (null)
    );
  };

  const loadStatusVendas = (value: any, index: any, nome?: any) => {

    // console.log(value);
    // return  <div hidden={!value} className="box-cell-vendas">{value}<div className="textovertical">Etapa <p style={{ color: "#ff0000", fontSize: "10px" }}>{index}</p></div></div>
    return (value) ?
      (
        <div hidden={!value} className="box-cell-vendas">{value}<div className="textovertical">{nome}</div></div>

      ) :
      ((value != undefined) ?
        (
          <div className="box-cell-vendas">{0}<div className="textovertical">{nome}</div></div>

        ) :
        (null))
  };


  const loadStatusProjetos = (value: any, index: any) => {

    // console.log(value);
    // return  <div hidden={!value} className="box-cell-projetos">{value}<div className="textovertical">Etapa <p style={{ color: "#ff0000", fontSize: "10px" }}>{index}</p></div></div>
    return (value) ?
      (
        <div hidden={!value} className="box-cell-projetos">{value}<div className="textovertical">Etapa <p style={{ color: "#ff0000", fontSize: "10px" }}>{index}</p></div></div>

      ) :
      ((value != undefined) ?
        (
          <div className="box-cell-projetos">{0}<div className="textovertical">Etapa <p style={{ color: "#ff0000", fontSize: "10px" }}>{index}</p></div></div>

        ) :
        (null))
  };




  const optionsOperacoesIniciadas = {
    // title: "Quantidade de Operações Iniciadas",
    vAxis: {
      format: '#'
    },
    hAxis: {
      slantedText: true, slantedTextAngle: 90
    },
    legend: { position: "none" },
  };

  const optionsOperacoesEncerradas = {
    // title: "Quantidade de Operações Encerradas",
    vAxis: {
      format: '#'
    },
    hAxis: {
      slantedText: true, slantedTextAngle: 90
    },
    legend: { position: "none" },
  };

  const loadItens = () => {

    if (acessos?.operacoes != 'leitura' && acessos?.operacoes != 'edicao') {
      return (<></>)
    }

    //Coloca card informando que não gráficos a serem exibidos
    if (arrayOperacoes === undefined || arrayOperacoes.length === 0) {
      return (
        <IonCard key="nodata">
          <IonCardHeader id="nodata" >
            <IonCardTitle>Sem gráficos a serem exibidos</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      );
    }

    return arrayOperacoes.map((value: any, index: any, arr: any) =>


      (value.relatorio === "operacoesIniciadas") ?
        (


          <IonCol size="12" sizeLg='6' sizeXl='6'>

            <IonCard style={{ minHeight: '300px', padding: "0px", margin: "0px" }}>
              <IonCardHeader id={value.tipo} onClick={() => {
                // history.replace("/empresa/operacoes"); 
              }}>
                <IonCardTitle>Operações iniciadas</IonCardTitle>
                <IonLabel></IonLabel>
              </IonCardHeader>
              <IonCardContent style={{}}>
                <Chart
                  chartType="ColumnChart"
                  data={chartOperacoesIniciadas}
                  options={optionsOperacoesIniciadas}
                />
              </IonCardContent>
            </IonCard>
          </IonCol>




        ) :
        (value.relatorio === "operacoesEncerradas") ?
          (



            <IonCol size="12" sizeLg='6' sizeXl='6'>
              <IonCard style={{ minHeight: '300px', padding: "0px", margin: "0px" }}>
                <IonCardHeader id={value.tipo} onClick={() => {
                  // history.replace("/empresa/operacoes"); 
                }}>
                  <IonCardTitle>Operações encerradas</IonCardTitle>
                  <IonLabel></IonLabel>
                </IonCardHeader>
                <IonCardContent style={{}}>
                  <Chart
                    chartType="ColumnChart"
                    data={chartOperacoesEncerradas}
                    options={optionsOperacoesEncerradas}
                  />
                </IonCardContent>
              </IonCard>
            </IonCol>

          ) :
          (value.relatorio === "operacoesAtivas") ?
            (null) :
            (value.relatorio === "operacoesTotais") ?
              (

                <IonCol size="12" sizeLg='6' sizeXl='6'>

                  <div >

                    <IonCard style={{ padding: "0px", margin: "0px" }} key={"operacoesTotais" + index} onClick={() => {
                      // history.replace("/empresa/operacoes"); 
                    }}>

                      <IonCardHeader id={value.tipo}>
                        <IonCardTitle>Operações Hoje</IonCardTitle>
                        <IonCardSubtitle></IonCardSubtitle>
                        <IonLabel></IonLabel>
                      </IonCardHeader>
                      <IonCardContent>
                        <div className="chartPrincipal">{value.totais.total}</div>
                        <div className="container">
                          <div className="box">
                            <div className="box-row">
                              <div className="box-cell" hidden={!value.totais.totalEntrega || value.totais.totalEntrega == 0}>{value.totais.totalEntrega || 0}<div style={{ fontSize: "10px" }}>Entrega</div></div>
                              <div className="box-cell" hidden={!value.totais.totalServico || value.totais.totalServico == 0}>{value.totais.totalServico || 0}<div style={{ fontSize: "10px" }}>Serviço</div></div>
                              <div className="box-cell" hidden={!value.totais.totalReuniao || value.totais.totalReuniao == 0}>{value.totais.totalReuniao || 0}<div style={{ fontSize: "10px" }}>Reunião</div></div>
                              <div className="box-cell" hidden={!value.totais.totalConsulta || value.totais.totalConsulta == 0}>{value.totais.totalConsulta || 0}<div style={{ fontSize: "10px" }}>Consulta</div></div>

                              <div className="box-cell" hidden={!value.totais.totalVisitaTecnica || value.totais.totalVisitaTecnica == 0}>{value.totais.totalVisitaTecnica || 0}<div style={{ fontSize: "10px" }}>Visita Técnica</div></div>
                              <div className="box-cell" hidden={!value.totais.totalReserva || value.totais.totalReserva == 0}>{value.totais.totalReserva || 0}<div style={{ fontSize: "10px" }}>Reserva</div></div>
                              <div className="box-cell" hidden={!value.totais.totalCheckIn || value.totais.totalCheckIn == 0}>{value.totais.totalCheckIn || 0}<div style={{ fontSize: "10px" }}>Check In</div></div>
                              <div className="box-cell" hidden={!value.totais.totalCheckOut || value.totais.totalCheckOut == 0}>{value.totais.totalCheckOut || 0}<div style={{ fontSize: "10px" }}>Check Out</div></div>
                            </div>
                          </div>
                        </div>
                      </IonCardContent>
                    </IonCard>
                  </div>
                </IonCol>
              ) :
              // (value.relatorio === "operacoesAvaliacoes") ?
              //   (
              //     <div >
              //       <IonCard key={value.tipo}>
              //         <IonCardHeader id={value.tipo} onClick={() => {
              //           // history.replace("/empresa/operacoes"); 
              //         }}>
              //           <IonCardTitle>Avaliação</IonCardTitle>
              //           <IonLabel></IonLabel>
              //         </IonCardHeader>
              //         <IonCardContent>
              //           <div className="chartPrincipal">{(value.avaliacoes.media).toFixed(1)}</div>
              //           <Rating name="half-rating" value={value.avaliacoes.media} defaultValue={value.avaliacoes.media} precision={0.5} />
              //           <div style={{ "marginBottom": "20px" }}><IonText>{value.avaliacoes.avaliacoes || 0} avaliações</IonText></div>
              //           <div style={{ background: "#f2f2f2", display: "block", borderRadius: 10, "marginTop": "30px", "paddingLeft": "10px", "paddingRight": "10px", maxWidth: "500px", margin: "auto" }}>
              //             <Bar data={chartOperacoesAvaliacoes} options={options.HorizontalBar} />
              //           </div>
              //         </IonCardContent>
              //       </IonCard>
              //     </div>
              //   ) :
              (null)
    );

    /*
        return (<IonCard>
          <IonCardHeader key="usuarios" >
            <IonCardTitle>Usuários Cadastrados</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
            <Pie data={chartUsuarios} options={options} />
          </IonCardContent>
        </IonCard>);
    */
  }

  function carregarGraficos(element: any, index: any, array: any) {

    if (element.tipo === "charts#projetosAtivos") {
      const data: any = [["Etapa", "Quantidade"]];

      workflowProjeto?.map((value: any, index: any) => {
        data.push([value.status, element["total" + index]]);
      });
      setChartProjetosAtivos(
        [
          ['Etapa', 'Quantidade'],
          ...workflowProjeto?.map((value: any) => { return [value.status, element["total" + value.indice]] })
        ]
      );
    }
    else if (element.tipo === "charts#vendasAtivos") {
      setChartVendasAtivos(
        [
          { name: 'status', value: 'valor' },
          ...workflowVenda?.map((value: any) => { return { name: value.status, value: element["total" + value.indice] }; })
        ]
      );
    }
    else if (element.tipo === "charts#usuarios") {

      const data = [
        ["Perfil", ""],
        ["Clientes", element.chartUsuariosClientes],
        ["Prestadores", element.chartUsuariosPrestadores],
        ["Arquitetos", element.chartUsuariosArquitetos],
        ["Prospects", element.chartUsuariosProspects],
        ["Administradores", element.chartUsuariosAdm],
        ["Usuários", element.chartUsuariosUsuarios],
      ];
      setChartUsuarios(data);

    }
    else if (element.relatorio === "operacoesIniciadas") {



      const data = [
        ["", ""],
        ["0h", element.operacoesIniciadas?.h0 || 0],
        ["1h", element.operacoesIniciadas?.h1 || 0],
        ["2h", element.operacoesIniciadas?.h2 || 0],
        ["3h", element.operacoesIniciadas?.h3 || 0],
        ["4h", element.operacoesIniciadas?.h4 || 0],
        ["5h", element.operacoesIniciadas?.h5 || 0],
        ["6h", element.operacoesIniciadas?.h6 || 0],
        ["7h", element.operacoesIniciadas?.h7 || 0],
        ["8h", element.operacoesIniciadas?.h8 || 0],
        ["9h", element.operacoesIniciadas?.h9 || 0],
        ["10h", element.operacoesIniciadas?.h10 || 0],
        ["11h", element.operacoesIniciadas?.h11 || 0],
        ["12h", element.operacoesIniciadas?.h12 || 0],
        ["13h", element.operacoesIniciadas?.h13 || 0],
        ["14h", element.operacoesIniciadas?.h14 || 0],
        ["15h", element.operacoesIniciadas?.h15 || 0],
        ["16h", element.operacoesIniciadas?.h16 || 0],
        ["17h", element.operacoesIniciadas?.h17 || 0],
        ["18h", element.operacoesIniciadas?.h18 || 0],
        ["19h", element.operacoesIniciadas?.h19 || 0],
        ["20h", element.operacoesIniciadas?.h20 || 0],
        ["21h", element.operacoesIniciadas?.h21 || 0],
        ["22h", element.operacoesIniciadas?.h22 || 0],
        ["23h", element.operacoesIniciadas?.h23 || 0],

      ];
      setChartOperacoesIniciadas(data);

    }
    else if (element.relatorio === "operacoesEncerradas") {



      const data = [
        ["", ""],
        ["0h", element.operacoesEncerradas?.h0 || 0],
        ["1h", element.operacoesEncerradas?.h1 || 0],
        ["2h", element.operacoesEncerradas?.h2 || 0],
        ["3h", element.operacoesEncerradas?.h3 || 0],
        ["4h", element.operacoesEncerradas?.h4 || 0],
        ["5h", element.operacoesEncerradas?.h5 || 0],
        ["6h", element.operacoesEncerradas?.h6 || 0],
        ["7h", element.operacoesEncerradas?.h7 || 0],
        ["8h", element.operacoesEncerradas?.h8 || 0],
        ["9h", element.operacoesEncerradas?.h9 || 0],
        ["10h", element.operacoesEncerradas?.h10 || 0],
        ["11h", element.operacoesEncerradas?.h11 || 0],
        ["12h", element.operacoesEncerradas?.h12 || 0],
        ["13h", element.operacoesEncerradas?.h13 || 0],
        ["14h", element.operacoesEncerradas?.h14 || 0],
        ["15h", element.operacoesEncerradas?.h15 || 0],
        ["16h", element.operacoesEncerradas?.h16 || 0],
        ["17h", element.operacoesEncerradas?.h17 || 0],
        ["18h", element.operacoesEncerradas?.h18 || 0],
        ["19h", element.operacoesEncerradas?.h19 || 0],
        ["20h", element.operacoesEncerradas?.h20 || 0],
        ["21h", element.operacoesEncerradas?.h21 || 0],
        ["22h", element.operacoesEncerradas?.h22 || 0],
        ["23h", element.operacoesEncerradas?.h23 || 0],

      ];
      setChartOperacoesEncerradas(data);
    }
    else if (element.relatorio === "operacoesAvaliacoes") {
      setChartOperacoesAvaliacoes({
        labels: ['5', '4', '3', '2', '1'],
        options: {
          indexAxis: 'y',
          plugins: {
            legend: { position: 'right' }
          }
        },
        datasets: [
          {
            //label: operacoes.Items[0].chartUsuariosTotal.toString() + ' de usuários',
            type: 'bar',
            label: 'Operações',
            data: [
              element.avaliacoes?.[5] || 0,
              element.avaliacoes?.[4] || 0,
              element.avaliacoes?.[3] || 0,
              element.avaliacoes?.[2] || 0,
              element.avaliacoes?.[1] || 0,
            ],
            backgroundColor: '#ff6a14',//'#1D3460',//'rgb(255, 99, 132)',
            borderColor: 'white',
            borderWidth: 1,
            //barThickness: 20,
            maxBarThickness: 15,
            scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y:
              {
                grid: {
                  display: false
                }
              }
            }
          },
        ],
      });
    }
    else {

    }

  }

  function carregarTodos() {
    // carregarLembretes();
    carregarRelatorios();
    carregarRelatorioFinanceiroSegmentada();
    // carregarAniversariantesMes();
  }

  function carregarRelatorios() {
    const consultar = async () => {

      const resp = await fetch(reportsBuscar.url.concat("?filtro=", Buffer.from(loja).toString("base64")),
        {
          method: reportsBuscar.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          credentials: 'include'
        });
      const operacoes = await resp.json();

      if (operacoes.Items) {
        setArrayOperacoes(operacoes.Items);

        operacoes.Items.forEach(carregarGraficos);
      }
    };

    consultar();
  }

  function addDays(date: Date, days: int) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  async function retornaMes(data: Date) {
    return adicionaZeroNaData(data.getMonth() + 1).toString();
  }

  function carregarRelatorioFinanceiroSegmentada() {
    const consultar = async () => {

      let dataFormatada = dayjs(addDays(new Date(), -4)).format('YYYY-MM-DD');
      let dataFormatada2 = dayjs(addDays(new Date(), +4)).format('YYYY-MM-DD');

      // console.log("dataFormatada", dataFormatada);
      // console.log("dataFormatada2", dataFormatada2);

      let urlFinal = urlBase.url + "/financeiro/relatorio?campo=data" +
        "&valor=" + dataFormatada +
        "&valor2=" + dataFormatada2 +
        "&lojas=" + Buffer.from(loja).toString('base64');

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      const resp: Response = await fetch(urlFinal,
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });


      const res = await resp.json();

      if (res.Items) {



        let despesasEmAtraso = res.Items.filter((obj: any) => {
          return (obj.baixado == false || obj.baixado == undefined) && obj.tipoLancamento == "despesa" && obj.data.substring(0, 10) < new Date().toISOString().substring(0, 10);
        });

        // console.log("despesasEmAtraso", despesasEmAtraso);
        setListaDespesasEmAtraso(despesasEmAtraso);

        let despesasAVencer = res.Items.filter((obj: any) => {
          return (obj.baixado == false || obj.baixado == undefined) && obj.tipoLancamento == "despesa" && obj.data.substring(0, 10) >= new Date().toISOString().substring(0, 10);
        });
        setListaDespesasAVencer(despesasAVencer.reverse());

        let receitasEmAtraso = res.Items.filter((obj: any) => {
          return (obj.baixado == false || obj.baixado == undefined) && obj.tipoLancamento == "receita" && obj.data.substring(0, 10) < new Date().toISOString().substring(0, 10);
        });
        setListaReceitasEmAtraso(receitasEmAtraso);

        let receitasAReceber = res.Items.filter((obj: any) => {
          return (obj.baixado == false || obj.baixado == undefined) && obj.tipoLancamento == "receita" && obj.data.substring(0, 10) >= new Date().toISOString().substring(0, 10);
        });
        setListaReceitasAReceber(receitasAReceber.reverse());

      }


    };
    return consultar();
  }

  function carregarLembretes() {
    const consultar = async () => {

      let dataFormatada = dayjs(new Date()).format('YYYY-MM-DD');
      let dataFormatada2 = dayjs(addDays(new Date(), +4)).format('YYYY-MM-DD');

      // console.log("dataFormatada", dataFormatada);
      // console.log("dataFormatada2", dataFormatada2);

      let urlFinal = urlBase.url + "/lembretes/relatorio?campo=data" +
        "&valor=" + dataFormatada +
        "&valor2=" + dataFormatada2 +
        "&lojas=" + Buffer.from(loja).toString('base64');

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      const resp: Response = await fetch(urlFinal,
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });


      const res = await resp.json();

      if (res.Items) {
        setListaLembretes(res.Items.reverse());
      }


    };
    return consultar();
  }

  // function carregarAniversariantesMes() {
  //   const consultar = async () => {




  //     let token: string = "";
  //     const tkn = localStorage.getItem("token");
  //     if (typeof (tkn) === "string") {
  //       token = tkn;
  //     }

  //     let mes = await retornaMes(new Date());
  //     const resp: Response = await fetch(urlBase.url + "/usuarios?mesaniversario=" + mes,
  //       {
  //         method: urlBase.method,
  //         headers: [
  //           ["token", token]
  //         ],
  //         credentials: 'include'
  //       });


  //     const res = await resp.json();

  //     if (res.Items) {
  //       // console.log("a.dataAniversario", res.Items);
  //       let lista = (res.Items.sort((a: any, b: any) => (a.dataAniversario.split("-")[2] > b.dataAniversario.split("-")[2]) ? 1 : -1))
  //       setListaAniversariantes(lista);
  //     }


  //   };
  //   return consultar();
  // }

  const loadListaLembretes = (lista: any) => {

    if (lista == undefined) return;

    if (lista.length === 0) {
      return (

        <IonRow key={"lembretesrow1" + Math.random()} >

          <IonCol>
            <IonLabel style={{ fontSize: "10px" }}>Nenhum lembrete encontrado</IonLabel>
          </IonCol>
        </IonRow>


      );
    }



    return lista.map((value: any, indice: any) => {
      return (
        (value.data.substring(0, 10) == new Date().toISOString().substring(0, 10))) ?

        <IonRow style={{ fontWeight: 'bold', borderRadius: "5px", background: "#dddddd", marginTop: "2px" }} key={"lembretesrow1" + indice + value.valor + Math.random()}>

          <IonCol size="2">
            <IonLabel style={{ fontSize: "10px" }}>{new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
              Date.parse(value.data)
            ).substring(0, 5)}</IonLabel>
          </IonCol>

          <IonCol size="5">
            <IonLabel style={{ fontSize: "10px" }}>{value.descricao}</IonLabel>
          </IonCol>


          <IonCol size="4">
            <IonLabel style={{ fontSize: "10px" }}>{value.clienteNome}</IonLabel>
          </IonCol>

          {/* <IonCol size="2">
                <IonLabel style={{ fontSize: "10px" }}>{value.perfil}</IonLabel>
              </IonCol> */}


          <IonCol size="1" style={{ textAlign: "right" }}>
            <IonLabel><IonIcon hidden={value.perfil != "cliente"} color={value.enviarMensagem == true ? "success" : "danger"} icon={notifications}></IonIcon></IonLabel>

          </IonCol>



        </IonRow>

        :
        (
          <IonRow style={{ marginTop: "2px" }} key={"lembretesrow1" + indice + value.valor + Math.random()}>

            <IonCol size="2">
              <IonLabel style={{ fontSize: "10px" }}>{new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                Date.parse(value.data)
              ).substring(0, 5)}</IonLabel>
            </IonCol>

            <IonCol size="5">
              <IonLabel style={{ fontSize: "10px" }}>{value.descricao}</IonLabel>
            </IonCol>


            <IonCol size="4">
              <IonLabel style={{ fontSize: "10px" }}>{value.clienteNome}</IonLabel>
            </IonCol>

            {/* <IonCol size="2">
                      <IonLabel style={{ fontSize: "10px" }}>{value.perfil}</IonLabel>
                    </IonCol> */}


            <IonCol size="1" style={{ textAlign: "right" }}>
              <IonLabel><IonIcon hidden={value.perfil != "cliente"} color={value.enviarMensagem == true ? "success" : "danger"} icon={notifications}></IonIcon></IonLabel>

            </IonCol>

          </IonRow>
        );
    }

    );
  }

  const loadListaLancamentos = (lista: any) => {

    if (lista == undefined) return;

    if (lista.length === 0) {
      return (

        <IonRow key={"financeirorow1" + Math.random()} >

          <IonCol>
            <IonLabel style={{ fontSize: "10px" }}>Nenhum lançamento encontrado</IonLabel>
          </IonCol>
        </IonRow>


      );
    }



    return lista.map((value: any, indice: any) => (
      (value.data.substring(0, 10) == new Date().toISOString().substring(0, 10))) ?

      <IonRow style={{ fontWeight: 'bold', borderRadius: "5px", background: "#dddddd", marginTop: "2px" }} key={"financeirorow1" + indice + value.valor + Math.random()} >

        <IonCol size="2">
          <IonLabel style={{ fontSize: "10px" }}>{new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
            Date.parse(value.data)
          ).substring(0, 5)}</IonLabel>
        </IonCol>

        <IonCol size="3">
          <IonLabel style={{ fontSize: "10px" }}>{value.categoria}</IonLabel>
        </IonCol>


        <IonCol size="3" style={{ textAlign: "right" }}>
          <IonLabel style={{ fontSize: "10px" }} color={value.tipoLancamento == "receita" ? "success" : "danger"}  >{value.valor.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
            <IonIcon icon={value.opcoes == "parcelas" ? star + "x" : (value.opcoes == "recorrente" ? refresh : "")}></IonIcon></IonLabel>
        </IonCol>

        <IonCol size="3">
          <IonLabel style={{ fontSize: "10px", width: "50px" }}>{value.nomeProjeto}</IonLabel>
        </IonCol>

        <IonCol size="1" style={{ textAlign: "right" }} hidden={value.tipoLancamento == "despesa"}>
          <IonLabel><IonIcon color={value.enviarMensagem == true ? "success" : "danger"} icon={notifications}></IonIcon></IonLabel>

        </IonCol>

      </IonRow>

      :
      (
        <IonRow style={{ marginTop: "2px" }} key={"financeirorow1" + indice + value.valor + Math.random()} >

          <IonCol size="2">
            <IonLabel style={{ fontSize: "10px" }}>{new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
              Date.parse(value.data)
            ).substring(0, 5)}</IonLabel>
          </IonCol>

          <IonCol size="3">
            <IonLabel style={{ fontSize: "10px" }}>{value.categoria}</IonLabel>
          </IonCol>


          <IonCol size="3" style={{ textAlign: "right" }}>
            <IonLabel style={{ fontSize: "10px" }} color={value.tipoLancamento == "receita" ? "success" : "danger"}  >{value.valor.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
              <IonIcon icon={value.opcoes == "parcelas" ? star + "x" : (value.opcoes == "recorrente" ? refresh : "")}></IonIcon></IonLabel>
          </IonCol>

          <IonCol size="3">
            <IonLabel style={{ fontSize: "10px", width: "50px" }}>{value.nomeProjeto}</IonLabel>
          </IonCol>

          <IonCol size="1" style={{ textAlign: "right" }} hidden={value.tipoLancamento == "despesa"}>
            <IonLabel><IonIcon color={value.enviarMensagem == true ? "success" : "danger"} icon={notifications}></IonIcon></IonLabel>

          </IonCol>

        </IonRow>
      )

    );
  }

  // const loadListaAniversariantes = () => {

  //   if (listaAniversariantes == undefined) return;

  //   if (listaAniversariantes.length === 0) {
  //     return (

  //       <IonRow key={"financeirorow1" + Math.random()} >

  //         <IonCol>
  //           <IonLabel style={{ fontSize: "10px" }}>Nenhum aniversariante encontrado</IonLabel>
  //         </IonCol>
  //       </IonRow>


  //     );
  //   }



  //   return listaAniversariantes.map((value: any, indice: any) => (
  //     (value.dataAniversario == new Date().toISOString().substring(0, 10))) ?

  //     <IonRow style={{ fontWeight: 'bold', borderRadius: "5px", background: "#dddddd", marginTop: "2px" }} key={"financeirorow1" + indice + Math.random()} >


  //       <IonCol size="2">
  //         <IonLabel style={{ fontSize: "10px" }}>{
  //           value.dataAniversario ? new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
  //             Date.parse(value.dataAniversario)
  //           ).substring(0, 5) : ""}</IonLabel>

  //       </IonCol>

  //       <IonCol size="7">
  //         <IonLabel style={{ fontSize: "10px" }}>{value.nome}</IonLabel>
  //       </IonCol>
  //       <IonCol size="3">
  //         <IonLabel style={{ fontSize: "10px" }}>{value.perfil}</IonLabel>
  //       </IonCol>

  //     </IonRow>
  //     :
  //     (
  //       <IonRow style={{ marginTop: "2px" }} key={"financeirorow1" + indice + Math.random()} >



  //         <IonCol size="2">
  //           <IonLabel style={{ fontSize: "10px" }}>{
  //             value.dataAniversario ? new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
  //               Date.parse(value.dataAniversario)
  //             ).substring(0, 5) : ""}</IonLabel>

  //         </IonCol>

  //         <IonCol size="7">
  //           <IonLabel style={{ fontSize: "10px" }}>{value.nome}</IonLabel>
  //         </IonCol>
  //         <IonCol size="3">
  //           <IonLabel style={{ fontSize: "10px" }}>{value.perfil}</IonLabel>
  //         </IonCol>

  //       </IonRow>
  //     )

  //   );
  // }



  function doRefresh(event: any) {
    carregarTodos();
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }


  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }

  return (
    <IonPage class="relatorio">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">

            < IonButton onClick={() => {
              history.goBack();
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>

          </IonButtons>

          <IonTitle>Painel</IonTitle>


          {/* <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons> */}

          {/* <IonButtons slot="end">
            <IonButton onClick={() => { setShowNotificacoes(true); }}>
              <IonIcon slot="icon-only" icon={notifications} />
              <IonBadge id="cart-badge" color="danger">{notificacoes?.length}</IonBadge>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
            <IonButton onClick={() => { history.push("./chat"); }}>
              <IonIcon slot="icon-only" icon={chatbubbles} />
              <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
            </IonButton>
          </IonButtons> */}

        </IonToolbar>
      </IonHeader>
      <IonContent className={"Relatorios"}>

        <IonFab vertical="top" horizontal="end" slot="fixed" hidden={isPlatform("android") || isPlatform("ios")}>
          <IonFabButton color="tertiary" onClick={() => { carregarTodos(); }} >
            <IonIcon color="light" icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>




        {/* <div style={{ justifyContent: 'center', paddingTop: '0px', paddingBottom: '0px' }}> */}
        <div className="divPainel">
          <IonGrid>













            {/* <IonRow hidden={acessos?.lembretes != 'leitura' && acessos?.lembretes != 'edicao'} >
              <IonCardHeader>
                <IonCardTitle>Lembretes</IonCardTitle>
              </IonCardHeader>
            </IonRow>
            <IonRow hidden={acessos?.lembretes != 'leitura' && acessos?.lembretes != 'edicao'} >
              <IonCol hidden={acessos?.lembretes != 'leitura' && acessos?.lembretes != 'edicao'} size="12" sizeLg='12' sizeXl='12'>
                <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                 
                  <IonCardContent style={{}}>

                    <IonGrid style={{ padding: "0px", margin: "0px" }}>
                      <IonRow style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }} key={"lembretesrow1" + Math.random()} >
                        <IonCol size="2">
                          <IonLabel style={{ fontSize: "10px" }}>Data</IonLabel>
                        </IonCol>
                        <IonCol size="5">
                          <IonLabel style={{ fontSize: "10px" }}>Descrição</IonLabel>
                        </IonCol>
                        <IonCol size="4">
                          <IonLabel style={{ fontSize: "10px" }}>Nome</IonLabel>
                        </IonCol>
                     
                        <IonCol size="1">
                          <IonLabel style={{ fontSize: "10px" }}></IonLabel>
                        </IonCol>
                      </IonRow>
                      {loadListaLembretes(listaLembretes)}
                    </IonGrid>

                  </IonCardContent>
                </IonCard>
              </IonCol>

            </IonRow> */}

























            {/* <IonRow hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'}>
                <IonCardHeader>
                  <IonCardTitle>Usuários</IonCardTitle>
                </IonCardHeader>
              </IonRow> */}
            {/* <IonRow hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'} >
                <IonCol size="12" sizeLg='6' sizeXl='6'>
                  <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                    <IonCardHeader id={'quantidadeUsuarios'} >
                      <IonCardTitle>Quantidade por perfil</IonCardTitle>
                      <IonLabel></IonLabel>
                    </IonCardHeader>
                    <IonCardContent style={{}}>
                      <Chart
                        chartType="PieChart"
                        data={chartUsuarios}
                        options={optionsUsuarios}
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>

                <IonCol hidden={acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao'} size="12" sizeLg='6' sizeXl='6'>
                  <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                    <IonCardHeader id={'AniversariantesDoMes'} >

                      <IonCardTitle>Aniversariantes do mês</IonCardTitle>
                      <IonLabel></IonLabel>
                    </IonCardHeader>
                    <IonCardContent style={{}}>

                      <IonGrid style={{ padding: "0px", margin: "0px" }}>
                        <IonRow style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }} key={"financeirorow1" + Math.random()} >
                          <IonCol size="2">
                            <IonLabel style={{ fontSize: "10px" }}>Dia</IonLabel>
                          </IonCol>
                          <IonCol size="7">
                            <IonLabel style={{ fontSize: "10px" }}>Nome</IonLabel>
                          </IonCol>
                          <IonCol size="3">
                            <IonLabel style={{ fontSize: "10px" }}>Perfil</IonLabel>
                          </IonCol>
                        </IonRow>
                        {loadListaAniversariantes()}
                      </IonGrid>

                    </IonCardContent>
                  </IonCard>
                </IonCol>

              </IonRow> */}
            <IonRow hidden={(acessos?.vendas != 'leitura' && acessos?.vendas != 'edicao') || !window.localStorage.getItem("configWorkflowVenda")} >
              <IonCardHeader>
                <IonCardTitle>Funil de Vendas</IonCardTitle>
              </IonCardHeader>
            </IonRow>
            <IonRow hidden={(acessos?.vendas != 'leitura' && acessos?.vendas != 'edicao') || !window.localStorage.getItem("configWorkflowVenda")} >

              <IonCol size="12" sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='12' sizeXl='12'>
                <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                  {/* <IonCardHeader id={'funildevendas'}>
                      <IonCardTitle>Funil</IonCardTitle>
                      <IonLabel></IonLabel>
                    </IonCardHeader> */}
                  <IonCardContent>
                    <FunnelChart
                      data={chartsvendasAtivos}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow hidden={acessos?.projetos != 'leitura' && acessos?.projetos != 'edicao' || !window.localStorage.getItem("configWorkflow")} >
              <IonCardHeader>
                <IonCardTitle>{window.localStorage.getItem("servicoTipoPlural") || "Projetos"}</IonCardTitle>
              </IonCardHeader>
            </IonRow>
            <IonRow hidden={acessos?.projetos != 'leitura' && acessos?.projetos != 'edicao' || !window.localStorage.getItem("configWorkflow")} >
              <IonCol size="12" sizeLg='12' sizeXl='12'>
                <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                  {/* <IonCardHeader id={'Projetos por Etapas'}>
                      <IonCardTitle>{(window.localStorage.getItem("nomeLinhaDoTempo") || "Etapas")}</IonCardTitle>
                    </IonCardHeader> */}
                  <IonCardContent>
                    <Chart
                      width='100%'
                      height='300px'
                      chartType="ColumnChart"
                      data={chartsprojetosAtivos}
                      options={optionsProjetos}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow hidden={acessos?.agenda != 'leitura' && acessos?.agenda != 'edicao'}>
              <IonCardHeader>
                <IonCardTitle>Agenda</IonCardTitle>
              </IonCardHeader>
            </IonRow>
            <IonRow hidden={acessos?.agenda != 'leitura' && acessos?.agenda != 'edicao'}>
              <IonCol size="12" sizeLg='6' sizeXl='6'>
                {loadAgendamentos()}
              </IonCol>
            </IonRow>
            <IonRow hidden={(acessos?.operacoes != 'leitura' && acessos?.operacoes != 'edicao') || window.localStorage.getItem("naoOperacao") == "true"}>
              <IonCardHeader>
                <IonCardTitle>Operações</IonCardTitle>
              </IonCardHeader>
            </IonRow>
            <IonRow hidden={(acessos?.operacoes != 'leitura' && acessos?.operacoes != 'edicao') || window.localStorage.getItem("naoOperacao") == "true"}>
              <IonCol size="12" sizeLg='6' sizeXl='6'>
                {loadOperacoes()}
              </IonCol>
              {loadItens()}


            </IonRow>
            <IonRow hidden={acessos?.financeiro != 'leitura' && acessos?.financeiro != 'edicao'} >
              <IonCardHeader>
                <IonCardTitle>Financeiro</IonCardTitle>
              </IonCardHeader>
            </IonRow>
            <IonRow hidden={acessos?.financeiro != 'leitura' && acessos?.financeiro != 'edicao'} >
              <IonCol hidden={acessos?.financeiro != 'leitura' && acessos?.financeiro != 'edicao'} size="12" sizeLg='6' sizeXl='6'>
                <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                  <IonCardHeader id={'DespesasEmAtraso'} >

                    <IonCardTitle>Despesas em atraso</IonCardTitle>
                    <IonLabel></IonLabel>
                  </IonCardHeader>
                  <IonCardContent style={{}}>

                    <IonGrid style={{ padding: "0px", margin: "0px" }}>
                      <IonRow style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }} key={"financeirorow1" + Math.random()} >
                        <IonCol size="2">
                          <IonLabel style={{ fontSize: "10px" }}>Data</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Categoria</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Valor</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Projeto</IonLabel>
                        </IonCol>
                        <IonCol size="1" >
                          <IonLabel style={{ fontSize: "10px" }}></IonLabel>

                        </IonCol>
                      </IonRow>
                      {loadListaLancamentos(listaDespesasEmAtraso)}
                    </IonGrid>

                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol hidden={acessos?.financeiro != 'leitura' && acessos?.financeiro != 'edicao'} size="12" sizeLg='6' sizeXl='6'>
                <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                  <IonCardHeader id={'DespesasAVencer'} >

                    <IonCardTitle>Despesas a vencer</IonCardTitle>
                    <IonLabel></IonLabel>
                  </IonCardHeader>
                  <IonCardContent style={{}}>

                    <IonGrid style={{ padding: "0px", margin: "0px" }}>
                      <IonRow style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }} key={"financeirorow1" + Math.random()} >
                        <IonCol size="2">
                          <IonLabel style={{ fontSize: "10px" }}>Data</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Categoria</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Valor</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Projeto</IonLabel>
                        </IonCol>
                        <IonCol size="1" >
                          <IonLabel style={{ fontSize: "10px" }}></IonLabel>

                        </IonCol>
                      </IonRow>
                      {loadListaLancamentos(listaDespesasAVencer)}
                    </IonGrid>

                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol hidden={acessos?.financeiro != 'leitura' && acessos?.financeiro != 'edicao'} size="12" sizeLg='6' sizeXl='6'>
                <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                  <IonCardHeader id={'DespesasEmAtraso'} >

                    <IonCardTitle>Receitas em atraso</IonCardTitle>
                    <IonLabel></IonLabel>
                  </IonCardHeader>
                  <IonCardContent style={{}}>

                    <IonGrid style={{ padding: "0px", margin: "0px" }}>
                      <IonRow style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }} key={"financeirorow1" + Math.random()} >
                        <IonCol size="2">
                          <IonLabel style={{ fontSize: "10px" }}>Data</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Categoria</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Valor</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Projeto</IonLabel>
                        </IonCol>
                        <IonCol size="1" >
                          <IonLabel style={{ fontSize: "10px" }}></IonLabel>

                        </IonCol>

                      </IonRow>
                      {loadListaLancamentos(listaReceitasEmAtraso)}
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol hidden={acessos?.financeiro != 'leitura' && acessos?.financeiro != 'edicao'} size="12" sizeLg='6' sizeXl='6'>
                <IonCard style={{ minHeight: '200px', padding: "0px", margin: "0px" }}>
                  <IonCardHeader id={'DespesasEmAtraso'} >

                    <IonCardTitle>Receitas a receber</IonCardTitle>
                    <IonLabel></IonLabel>
                  </IonCardHeader>
                  <IonCardContent style={{}}>

                    <IonGrid style={{ padding: "0px", margin: "0px" }}>
                      <IonRow style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }} key={"financeirorow1" + Math.random()} >
                        <IonCol size="2">
                          <IonLabel style={{ fontSize: "10px" }}>Data</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Categoria</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Valor</IonLabel>
                        </IonCol>
                        <IonCol size="3">
                          <IonLabel style={{ fontSize: "10px" }}>Projeto</IonLabel>
                        </IonCol>
                        <IonCol size="1" >
                          <IonLabel style={{ fontSize: "10px" }}></IonLabel>

                        </IonCol>
                      </IonRow>
                      {loadListaLancamentos(listaReceitasAReceber)}
                    </IonGrid>

                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>

          </IonGrid>


        </div>
        {/* </div> */}

        <div className="content">
          <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
            <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
          </IonModal>

        </div>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'

        />

      </IonContent>
    </IonPage>
  );
};

export default Painel;
