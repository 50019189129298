import { Toast } from '@capacitor/toast';
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { alertCircle, arrowBack } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import './UploadArquivo.css';

type Props = {
  accept: any;
  inputRef: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const UploadArquivo: React.FC<Props> = (props: Props) => {


  const [fileArquivos, setFileArquivos] = useState<any>();
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);

  useEffect(() => {
    // Define o foco no input quando o componente é montado
    if (props.inputRef.current) {
      props.inputRef.current.focus();
    }
  }, [props.inputRef]);

  useIonViewDidEnter(() => {

  })

  const handleUpload = async () => {

    if (!fileArquivos) {
      setAlertaPreenchimento(true);
      return null;
    }

    console.log("fileArquivos", fileArquivos);


    let file = fileArquivos.files[0];
    console.log("file", file);

    if (file.size < 15000000) {
      let fileParts = file.name.split('.');
      let fileNameArquivo = fileParts[0];
      let fileacceptArquivo = fileParts[1];
      console.log("Preparing the upload");
      console.log(fileParts);

      try {
        props.closeAction(file, fileNameArquivo, fileacceptArquivo);

      } catch (error) {
        console.log("error", error);
        Toast.show({
          text: "Ocorreu um erro ao adicionar um arquivo, tente novamente.",
          position: "center",
          duration: "long"
        });
      }

    }
    else {
      Toast.show({
        text: "Somente arquivos até 15 MB.",
        position: "center",
        duration: "long"
      });
    }
  }

  return (
    <>

      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} />
          </IonButtons>
          <IonTitle>Adicionar Arquivo</IonTitle>

        </IonToolbar>

      </IonHeader>

      <IonContent class="ion-padding">
        <IonItem lines="none">
          <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
          <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
        </IonItem>

        <IonItem lines="none" >
          <input ref={(ref) => { setFileArquivos(ref); }} type="file" accept="accept" />
        </IonItem>
      </IonContent>
      <IonFooter className="ion-no-border" >
        <IonToolbar>
          <IonButton expand="block" size='large' onClick={() => { handleUpload().catch((e) => { console.log("cancelado"); }); }}>Adicionar Arquivo</IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default ({ accept, inputRef, closeAction, closeActionSimple }: { accept: any, inputRef: any, closeAction: Function, closeActionSimple: Function }) => (
  <UploadArquivo accept={accept} inputRef={inputRef} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </UploadArquivo>
)
