import { IonButton, IonContent, IonHeader, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './CadastroSucesso.css';

const CadastroSucesso: React.FC = () => {
  const history = useHistory();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Acesso liberado</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonText className="text-content">
          <p>Seja bem-vindo.</p>
          <p>Seu acesso à plataforma Xeguei foi <b>liberado com sucesso</b>! Você já pode começar a utilizar todos os recursos disponíveis.</p>
          {/* <p>Como sugestão, assista aos vídeos da plataforma Xeguei:</p> */}
        </IonText>
        {/* <IonList style={{marginBottom: "18px"}}>
          <IonItem lines='none'>
            <IonLabel>
              <a target='_blank' href="https://xegueimobile.com.br/videos.html">Assistir Vídeos</a>
            </IonLabel>
          </IonItem>         
        </IonList> */}
       
          <IonText className="text-content">
            <p>Se precisar de ajuda ou tiver alguma dúvida, não hesite em entrar em contato conosco pelo WhatsApp
              <a target='_blank' href={"https://api.whatsapp.com/send?phone=5511941744046"} style={{ textDecoration: 'none', marginLeft: '4px' }}>
                (11) 94174-4046
              </a>.
            </p>
          </IonText>
        <div className="button-container" style={{ marginBottom: "18px" }}>
          <IonButton expand="block" onClick={() => {
            history.replace("/login");
          }}>Quero acessar minha conta</IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CadastroSucesso;
