import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonCol, IonContent, IonDatetime, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonRefresher, IonRefresherContent, IonRow, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToolbar, isPlatform, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { add, arrowBack, chatbubbles, close, informationCircle, notifications, pencil, refresh, search, star } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { baixarFinanceiro, projetoLista, requestService, urlBase } from '../../../../../Utils/Services';
import AppTypeaheadItem from '../../../../../components/AppTypeaheadItem';
import { MessageContext } from '../../../../../contexts/MessageContext';
import FinanceiroAlterar from '../FinanceiroAlterar/FinanceiroAlterar';
import FinanceiroLancamentos from '../FinanceiroLancamentos/FinanceiroLancamentos';
import Notificacoes from '../Notificacoes/Notificacoes';
import './Financeiro.css';

const Financeiro: React.FC = () => {

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const history = useHistory();
  const [arrayFinanceiro, setArrayFinanceiro] = useState<any>([]);
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [financeiropk, setFinanceiropk] = useState("");
  const [financeiroFunilk, setFinanceirok] = useState("");
  const [financeirogsi2sk, setFinanceirogsi2sk] = useState("");
  const [showCopiado, setShowCopiado] = useState(false);
  const [listaProjeto, setListaProjeto] = useState<any>([]);
  const [filtroSegment, setFiltroSegment] = useState<string>("");
  const [pesquisaData, setPesquisaData] = useState<any>("Pesquisar");
  const [pesquisaNome, setPesquisaNome] = useState<any>("Pesquisar");

  const [financeiro, setFinanceiro] = useState<any>();

  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [showModalData, setShowModalData] = useState(false);
  const popover = useRef<HTMLIonPopoverElement>(null);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [showDetalhesFinanceiro, setShowDetalhesFinanceiro] = useState(false);
  const [showDetalhesFinanceiroAlterar, setShowDetalhesFinanceiroAlterar] = useState(false);
  const { loja, setLoja } = useContext(MessageContext);

  const [exibirInfo, setExibirInfo] = useState(false);

  const [dataVencimento, setDataVencimento] = useState<any>("");
  const [dataCadastro, setDataCadastro] = useState<any>("");
  const [dataBaixa, setDataBaixa] = useState<any>("");
  const [tipo, setTipo] = useState<any>("");
  const [enviarMensagem, setEnviarMensagem] = useState<any>("");
  const [cadastradoPor, setCadastradoPor] = useState<any>("");
  const [baixadoPor, setBaixadoPor] = useState<any>("");
  const [observacoes, setObservacoes] = useState<any>("");
  const [cobranca, setCobranca] = useState<any>("");
  const [opcoes, setOpcoes] = useState<any>("");
  const [parcelas, setVezes] = useState<any>("");
  const [parcela, setParcela] = useState<any>("");

  const { notificacoes, setNotificacoes } = useContext(MessageContext);
  const [showNotificacoes, setShowNotificacoes] = useState(false);

  const { mensagens, setMensagens } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));

  const [showModalFiltro, setShowModalFiltro] = useState(false);

  const { filtroCampoSelecionadoFinanceiro, setFiltroCampoSelecionadoFinanceiro } = useContext(MessageContext);
  const { filtroMostrarDataFinanceiro, setFiltroMostrarDataFinanceiro } = useContext(MessageContext);
  const { filtroPesquisaFinanceiro, setFiltroPesquisaFinanceiro } = useContext(MessageContext);
  const { filtroStatusFinanceiro, setFiltroStatusFinanceiro } = useContext(MessageContext);
  const { recarregarFinanceiro, setRecarregarFinanceiro } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  useEffect(() => {
    // primeira vez q entra e quando troca de loja
    console.log("entrou useEffect mudou loja - Financeiros", loja);
    console.log("entrou useEffect mudou loja - Financeiros", filtroStatusFinanceiro);
    setFiltroCampoSelecionadoFinanceiro("data");
    setFiltroMostrarDataFinanceiro(true);
    setFiltroStatusFinanceiro("Ativo");
    // setRecarregarFinanceiro(false);
    carregarFinanceiroSegmentadaData();
    setPesquisaData('Pesquisar');
  }, [loja]
  );

  useEffect(() => {
    console.log("entrou useEffect mudou loja - filtroCampoSelecionadoFinanceiro, filtroPesquisaFinanceiro, filtroSegment", filtroCampoSelecionadoFinanceiro);
    console.log("entrou useEffect mudou loja - filtroCampoSelecionadoFinanceiro, filtroPesquisaFinanceiro, filtroSegment", filtroPesquisaFinanceiro);
    console.log("entrou useEffect mudou loja - filtroCampoSelecionadoFinanceiro, filtroPesquisaFinanceiro, filtroSegment", filtroSegment);
    if (filtroPesquisaFinanceiro != "" || filtroSegment != "") {
      carregarFinanceiroSegmentadaData();
    }
  }, [filtroPesquisaFinanceiro, filtroSegment]
  );

  useEffect(() => {
    if (recarregarFinanceiro) {
      carregarFinanceiroSegmentadaData();
      setRecarregarFinanceiro(false);
    }
  }, [recarregarFinanceiro]
  );

  useIonViewDidEnter(() => {
    setEsconderMenu(false);
    setNomeMenu("Financeiro");
  })

  const filtroSelectionChanged = (filtroProjetos: string) => {

    if (filtroProjetos != "") {
      setFiltroPesquisaFinanceiro(filtroProjetos);
      const cliente = listaProjeto.find((prospect: any) => prospect.pk === filtroProjetos);
      setPesquisaNome(cliente.nomeProjeto);
    }
    else {
      setFiltroPesquisaFinanceiro("");
      setPesquisaNome("Pesquisar");
    }
    carregarFinanceiroSegmentadaData();
    setShowModalFiltro(false);
  };

  const consultarProjeto = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = projetoLista.url.replace("{loja}", loja.replaceAll(/#/g, "%23"));

    console.log("*******************montarUrl", montarUrl);
    setShowLoadingPage(true);
    const resp = await fetch(montarUrl,
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let projetos = await resp.json();
    setShowLoadingPage(false);
    console.log("projetos", projetos);
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (projetos.Items) {
        let lista = projetos.Items.sort((a: any, b: any) => {
          const nomeProjetoA = typeof a.nomeProjeto === 'string' ? a.nomeProjeto.toLowerCase() : '';
          const nomeProjetoB = typeof b.nomeProjeto === 'string' ? b.nomeProjeto.toLowerCase() : '';

          if (nomeProjetoA > nomeProjetoB) return 1;
          if (nomeProjetoA < nomeProjetoB) return -1;
          return 0;
        });
        setListaProjeto(lista);
      }
    }
  }

  function trocarCampoFiltro(e: any) {
    setFiltroSegment("");
    setFiltroCampoSelecionadoFinanceiro(e.detail.value);
    if (e.detail.value === 'data') {
      console.log('entrou data');
      setFiltroMostrarDataFinanceiro(true);


    }
    else {
      console.log('entrou nome');
      consultarProjeto();
      setFiltroMostrarDataFinanceiro(false);

    }
    setPesquisaNome("Pesquisar");
    setPesquisaData("Pesquisar");
    setFiltroPesquisaFinanceiro("");
    setArrayFinanceiro([]);
  }


  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  function doRefresh(event: any) {
    carregarFinanceiroSegmentadaData();
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  function carregarDetalhesProjeto(projetoid: any, empresaid: any, abrirInserirAgendamento: boolean) {
    if (projetoid) {
      history.push("projetos/projetosdetalhes", { projetoid: projetoid, empresaid: empresaid, abrirInserirAgendamento: abrirInserirAgendamento, esconderOperacoes: true })
    }
  }

  function baixaLancamento(pk: any, checked: boolean) {


    let objRequest = {
      "baixado": checked
    };

    console.log("objRequest", objRequest);



    requestService(baixarFinanceiro.url.replace("{id}", pk.replaceAll(/#/g, "%23")), {
      method: baixarFinanceiro.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(objRequest),
      credentials: 'include'
    },
      history,
      (response: any) => {

        console.log("response_incluirFinanceiro", response);
        if (response.message) {

          setTextToast("Lançamento baixado com sucesso!");
          setShowToast(true);

        }

      },
      (error: any) => {
        console.log(error);


        setTextToast(error.message);
        setShowToast(true);


      })
  }


  function preencheCard(value: any, indice: any) {
    return (
      <IonItem lines="none" className={"cardFinanceiro"} key={"financeiro" + indice + value.valor + Math.random()}>
        <IonGrid style={{ padding: "0px" }}>

          <IonRow key={"financeirorow1" + indice + value.valor + Math.random()} >
            <IonCol size="3" sizeMd="4" sizeLg="4">
              <IonLabel style={{ fontSize: "14px" }}>{value.categoria}</IonLabel>
            </IonCol>


            <IonCol size="3" sizeMd="2" sizeLg="2" style={{ textAlign: "right" }}>
              <IonLabel style={{ fontSize: "14px" }} color={value.tipoLancamento == "receita" ? "success" : "danger"}  >{value.valor.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
                <IonIcon icon={value.opcoes == "parcelas" ? star + "x" : (value.opcoes == "recorrente" ? refresh : "")}></IonIcon></IonLabel>
            </IonCol>

            <IonCol size="1" sizeMd="1" sizeLg="1" >
              <IonLabel>
                <IonCheckbox disabled={acessos?.financeiro != 'edicao'} key={"financeirocheckbox" + indice + value.valor + Math.random()} style={{ fontSize: "14px", margin: "0px" }} checked={value.baixado}

                  onIonChange={e => {
                    console.log(e.detail.checked);
                    baixaLancamento(value.pk.replaceAll(/#/g, '%23'), e.detail.checked);

                    let obj = [...arrayFinanceiro];
                    obj[indice] = { ...obj[indice], baixado: e.detail.checked };

                    setArrayFinanceiro(obj);
                  }}

                ></IonCheckbox>
              </IonLabel>


            </IonCol>

            <IonCol size="5" sizeMd="5" sizeLg="5" onClick={(e) => {
              carregarDetalhesProjeto(value.projetoid.replaceAll(/#/g, '%23'), value.gsi2pk.replaceAll(/#/g, '%23'), false);
            }}>
              <IonLabel style={{ fontSize: "14px" }}>{value.nomeProjeto}</IonLabel>
            </IonCol>











            <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>

              <IonButtons slot='start'>

                <IonButton hidden={value.tipoLancamento == "despesa"}
                  onClick={() => {
                    if (value.enviarMensagem) {
                      setTextToast("Cliente vai ser avisado pelo sistema sobre essa cobrança.");
                    }
                    else {
                      setTextToast("Cliente não vai receber essa cobrança.");
                    }
                    setShowToast(true);
                  }}>
                  <IonIcon size="small" color={value.enviarMensagem == true ? "success" : "danger"} icon={notifications}></IonIcon>
                </IonButton>

                <IonButton hidden={acessos?.financeiro != 'edicao'} onClick={() => {
                  setFinanceiro(value);
                  setShowDetalhesFinanceiroAlterar(true);
                }}>
                  <IonIcon icon={pencil} />
                </IonButton>

                <IonButton
                  onClick={() => {
                    setObservacoes(value.observacoes);
                    setCobranca(value.cobranca);
                    setDataVencimento(value.data);
                    setCadastradoPor(value.cadastradoPor);
                    setDataCadastro(value.dataCadastro);
                    setDataBaixa(value.dataBaixa);
                    setBaixadoPor(value.baixadoPor);
                    setEnviarMensagem(value.enviarMensagem);
                    setTipo(value.tipoLancamento);
                    setOpcoes(value.opcoes);
                    setVezes(value.parcelas);
                    setParcela(value.parcela);
                    setExibirInfo(!exibirInfo)
                  }}>
                  <IonIcon icon={informationCircle} />
                </IonButton>

              </IonButtons>
            </div>




          </IonRow>
        </IonGrid>
      </IonItem >
    )
  }

  const loadItens = () => {

    if (arrayFinanceiro === undefined || arrayFinanceiro.length === 0) {
      return (
        <IonCard key="primeira">
          <IonCardHeader key="primeiraheader" >
            <IonCardTitle>Neste momento não existe nenhum lançamento.</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      )
    }

    return arrayFinanceiro.map((value: any, index: any, arr: any) =>

      (!arr[index - 1]) ?
        (<div key={"divfinanceiropai" + index}>
          <IonCard className={"card"} key={"financeiro" + index}>
            <IonCardHeader key={"headerfinanceiro" + index}>
              <IonCardTitle key={"titulofinanceiro" + index}>
                <div key={"divfinanceiro" + index} className={"cabecalho"} >{
                  new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    Date.parse(value.data)
                  )
                }</div>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
          {preencheCard(value, index)}
        </div>)
        :
        (value.data.substring(0, 10) !== arr[index - 1].data.substring(0, 10)) ?
          (<div key={"divfinanceiropai" + index}>
            <IonCard className={"card"} key={"financeiro" + index}>
              <IonCardHeader key={"headerfinanceiro" + index}>
                <IonCardTitle key={"titulofinanceiro" + index}>
                  <div key={"divfinanceiro" + index} className={"cabecalho"} >{
                    new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                      Date.parse(value.data)
                    )
                  }</div>
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
            {preencheCard(value, index)}
          </div>
          ) :
          (
            <div key={"divfinanceiropai" + index}>
              {preencheCard(value, index)}
            </div>
          )
    );
  }

  async function carregarFinanceiroSegmentadaData() {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let data = filtroPesquisaFinanceiro;
      let valor = data;
      if (data) {
        valor = data.replaceAll(/#/g, "%23");
      }

      setShowLoadingPage(true);
      const resp: Response = await fetch(urlBase.url + "/financeiro?campo=" + filtroCampoSelecionadoFinanceiro +
        "&filtro=" + filtroSegment +
        "&valor=" + valor +
        "&lojas=" + Buffer.from(loja).toString('base64'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      await resp.json().then(async (res) => {
        if (resp.status === 400 || resp.status === 401) {
          setShowLoadingPage(false);
          history.replace("/login/");
        }

        if (res && res.LastEvaluatedKey) {
          setFinanceiropk(res.LastEvaluatedKey.pk);
          setFinanceirok(res.LastEvaluatedKey.sk);
          setFinanceirogsi2sk(res.LastEvaluatedKey.gsi2sk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setDesabilitarInfiniteScroll(true);
        }

        setArrayFinanceiro(res.Items);
        setShowLoadingPage(false);
      }).finally(() => {
        setShowLoadingPage(false);
      });
    }
    consultar();
  }

  async function carregarMaisFinanceiro($event: CustomEvent<void>) {
    console.log("carregarMaisFinanceiro",);
    if (financeirogsi2sk.includes(filtroSegment)) {
      await carregarMaisFinanceiroChamada().then(() => {
      });
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  async function carregarMaisFinanceiroChamada() {
    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let valor = filtroPesquisaFinanceiro;
    if (filtroPesquisaFinanceiro) {
      valor = filtroPesquisaFinanceiro.replaceAll(/#/g, "%23");
    }


    console.log(valor);

    const res: Response = await fetch(urlBase.url + "/financeiro?campo=" + filtroCampoSelecionadoFinanceiro +
      "&valor=" + valor +
      "&isk=" + financeirogsi2sk +
      "&pk=" + financeiropk +
      "&sk=" + financeiroFunilk +
      "&lojas=" + Buffer.from(loja).toString('base64'),
      {
        method: urlBase.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (financeiroFunil) => {

        if (financeiroFunil.Items) {
          for (let i = 0; i < financeiroFunil.Items.length; i++) {
            arrayFinanceiro.push(financeiroFunil.Items[i]);
          }

          if (financeiroFunil.LastEvaluatedKey) {
            setFinanceiropk(financeiroFunil.LastEvaluatedKey.pk);
            setFinanceirok(financeiroFunil.LastEvaluatedKey.sk);
            setFinanceirogsi2sk(financeiroFunil.LastEvaluatedKey.gsi2sk);
            setDesabilitarInfiniteScroll(false);
          }
          else {
            setFinanceiropk("");
            setFinanceirok("");
            setFinanceirogsi2sk("");
            setDesabilitarInfiniteScroll(true);
          }
        }

      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");

  }


  function avancarFinanceiro() {
    setShowDetalhesFinanceiro(true);
  }

  async function handleSegmentChange(e: any) {
    setFiltroSegment(e.detail.value);
  }

  const openPopover = (e: any) => {

    setFiltroSegment("");

    popover.current!.event = e;
    setShowModalData(true);
  };

  async function closeModalFinanceiro() {
    setShowDetalhesFinanceiro(false);
    carregarFinanceiroSegmentadaData();
  }

  async function closeModalSimpleFinanceiro() {
    setShowDetalhesFinanceiro(false);
  }


  async function closeModalFinanceiroAlterar() {
    setShowDetalhesFinanceiroAlterar(false);
    carregarFinanceiroSegmentadaData();
  }

  async function closeModalSimpleFinanceiroAlterar() {
    setShowDetalhesFinanceiroAlterar(false);
  }

  async function closeModalNotificacoes() {
    setShowNotificacoes(false);
  }

  async function closeModalSimpleNotificacoes() {
    setShowNotificacoes(false);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">

            < IonButton onClick={() => {
              history.goBack();
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>


            {/* {
                ((!isPlatform('mobileweb') && isPlatform('mobile'))) ?
                  (
                    < IonButton onClick={() => {
                      history.goBack();
                    }}>
                      <IonIcon slot="icon-only" icon={arrowBack} />
                    </IonButton>
                  ) :
                  (
                    <IonButtons slot="start">
                      <IonMenuButton></IonMenuButton>
                    </IonButtons>
                  )
              } */}
          </IonButtons>

          <IonTitle>Financeiro</IonTitle>

          <IonSelect interface='popover' style={{ fontSize: "14px" }} name='Filtros' title='Filtros' value={filtroCampoSelecionadoFinanceiro} multiple={false} cancelText="Cancelar" okText="Ok" onIonChange={e => { trocarCampoFiltro(e); }} slot="end">
            <IonSelectOption value="data">Data</IonSelectOption>
            <IonSelectOption value="nome">{window.localStorage.getItem("servicoTipoPlural") || "Projetos"}</IonSelectOption>
          </IonSelect>

          <IonButton style={{ width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={filtroMostrarDataFinanceiro} onClick={e => { setShowModalFiltro(true); }}>
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={filtroPesquisaFinanceiro != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaNome} </IonLabel>
          </IonButton>

          <IonButton style={{ width: "170px", paddingRight: "10px" }} color="light" slot="end" size="small" hidden={!filtroMostrarDataFinanceiro} onClick={e => { openPopover(e); }} >
            <IonIcon style={{ "color": "#BDBDBD", paddingRight: "10px", fontSize: "12px" }} icon={search} hidden={filtroPesquisaFinanceiro != ""}></IonIcon>
            <IonLabel style={{ textAlign: "left", overflow: "hidden", "color": "#BDBDBD", fontSize: "12px" }}>{pesquisaData} </IonLabel>
          </IonButton>

          <IonModal isOpen={showModalFiltro} onDidDismiss={() => setShowModalFiltro(false)} >
            <AppTypeaheadItem
              title={window.localStorage.getItem("servicoTipoPlural") || "Projetos"}
              campo="nomeProjeto"
              items={listaProjeto}
              selectedItem={filtroPesquisaFinanceiro}
              onSelectionCancel={() => setShowModalFiltro(false)}
              onSelectionChange={filtroSelectionChanged}
            />
          </IonModal>

          <IonButtons slot="end">
            <IonButton onClick={() => { setShowNotificacoes(true); }}>
              <IonIcon slot="icon-only" icon={notifications} />
              <IonBadge id="cart-badge" color="danger">{notificacoes?.length}</IonBadge>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end" hidden={acessos?.chat != 'leitura' && acessos?.chat != 'edicao'}>
            <IonButton onClick={() => { history.push("./chat"); }}>
              <IonIcon slot="icon-only" icon={chatbubbles} />
              <IonBadge id="cart-badge" color="danger">{mensagens?.length}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar style={{ height: "48px" }}>
          <IonSegment value={filtroSegment} onIonChange={(e) => { scrollToTop(); handleSegmentChange(e); }}>
            <IonSegmentButton style={{ height: "30px" }} value="01" >
              <IonLabel style={{ fontSize: "10px" }}>JAN</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="02" >
              <IonLabel style={{ fontSize: "10px" }}>FEV</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="03" >
              <IonLabel style={{ fontSize: "10px" }}>MAR</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="04" >
              <IonLabel style={{ fontSize: "10px" }}>ABR</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="05" >
              <IonLabel style={{ fontSize: "10px" }}>MAI</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="06" >
              <IonLabel style={{ fontSize: "10px" }}>JUN</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="07" >
              <IonLabel style={{ fontSize: "10px" }}>JUL</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="08" >
              <IonLabel style={{ fontSize: "10px" }}>AGO</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="09" >
              <IonLabel style={{ fontSize: "10px" }}>SET</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="10" >
              <IonLabel style={{ fontSize: "10px" }}>OUT</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="11" >
              <IonLabel style={{ fontSize: "10px" }}>NOV</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton style={{ height: "30px" }} value="12" >
              <IonLabel style={{ fontSize: "10px" }}>DEZ</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>

      </IonHeader>


      <IonContent fullscreen scrollEvents={true} ref={contentRef} color='warning'>



        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.financeiro != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            avancarFinanceiro();
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonFab vertical="top" horizontal="end" slot="fixed" edge hidden={isPlatform("android") || isPlatform("ios")}>
          <IonFabButton color="tertiary" onClick={() => { carregarFinanceiroSegmentadaData(); }} >
            <IonIcon color="light" icon={refresh}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>


        <div className='FinanceiroSlides'>

          <div style={{ position: "absolute", right: "14px", zIndex: 9999999, paddingTop: "10px", backgroundColor: "#ffffff", borderColor: "#000000", borderRadius: "10px 10px 10px 10px", textAlign: "right", padding: "10px 10px 10px 10px", marginTop: "16px", border: "1px solid #e0e0e0" }} hidden={!exibirInfo}>
            <div>
              <div style={{ position: "relative", top: "0px", left: "0px", textAlign: "left" }}>
                <IonIcon size="large" slot="icon-only" icon={close} onClick={() => { setExibirInfo(false); }}></IonIcon>
              </div>
              <IonList style={{ paddingTop: "0px" }}>

                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px" }} lines="none">
                  <IonLabel>Data Vencimento: </IonLabel>
                  {dataVencimento ?

                    new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                      Date.parse(dataVencimento)
                    )

                    : ""}



                </IonItem>

                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px" }} lines="none">
                  <IonLabel>Cadastrado por: </IonLabel>
                  {cadastradoPor}



                </IonItem>

                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px" }} lines="none">
                  <IonLabel>Data Cadastro: </IonLabel>

                  {dataCadastro ?

                    new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                      Date.parse(dataCadastro)
                    )

                    : ""}



                </IonItem>


                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px" }} lines="none">
                  <IonLabel>Baixado por: </IonLabel>
                  {baixadoPor}



                </IonItem>


                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px" }} lines="none">
                  <IonLabel>Data Baixa: </IonLabel>

                  {dataBaixa ?

                    new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                      Date.parse(dataBaixa)
                    )

                    : ""}
                </IonItem>


                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px" }} lines="none">
                  <IonLabel>Opção: </IonLabel>
                  {opcoes}{parcelas ? (parcela ? " - " + parcela + "/" + parcelas : "") : ""}



                </IonItem>






                <IonItem style={{ fontSize: "12px" }} lines="none">
                  <IonLabel>Observações: </IonLabel>
                  <IonTextarea disabled={true} style={{ "--background": "#eeeeee", marginLeft: "10px" }} rows={6} value={observacoes} maxlength={250} placeholder="" onIonInput={(e: any) => setObservacoes(e.target.value)}></IonTextarea>
                </IonItem>

                <IonItem style={{ minHeight: "34px", height: "34px", fontSize: "12px" }} lines="none" hidden={tipo == "despesa"}>
                  <IonLabel>Enviar Cobrança: </IonLabel>
                  {enviarMensagem ? "sim" : "não"}
                </IonItem>

                <IonItem style={{ fontSize: "12px" }} lines="none" hidden={!enviarMensagem}>
                  <IonLabel>Cobrança: </IonLabel>
                  <IonTextarea disabled={true} style={{ "--background": "#eeeeee", marginLeft: "10px" }} rows={6} value={cobranca} maxlength={250} placeholder="" onIonInput={(e: any) => setCobranca(e.target.value)}></IonTextarea>
                </IonItem>

              </IonList>

            </div>

          </div>

          {loadItens()}





        </div>

        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisFinanceiro(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais lançamentos..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonToast
          isOpen={showCopiado}
          onDidDismiss={() => setShowCopiado(false)}
          message="Campo copiado."
          duration={3000}
        />

        <div className="content">
          <IonModal isOpen={showDetalhesFinanceiro} onDidDismiss={() => { setShowDetalhesFinanceiro(false); }}>
            <FinanceiroLancamentos projetoid={null} tipo={null} observacao={null} closeAction={closeModalFinanceiro} closeActionSimple={closeModalSimpleFinanceiro}></FinanceiroLancamentos>
          </IonModal>


          <IonModal isOpen={showDetalhesFinanceiroAlterar} onDidDismiss={() => { setShowDetalhesFinanceiroAlterar(false); }}>
            <FinanceiroAlterar financeiro={financeiro} closeAction={closeModalFinanceiroAlterar} closeActionSimple={closeModalSimpleFinanceiroAlterar}></FinanceiroAlterar>
          </IonModal>

          <IonModal isOpen={showNotificacoes} onDidDismiss={() => { setShowNotificacoes(false); }}>
            <Notificacoes closeAction={closeModalNotificacoes} closeActionSimple={closeModalSimpleNotificacoes}></Notificacoes>
          </IonModal>

        </div>

        <IonToast
          isOpen={showToast}
          position="middle"
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />

      </IonContent>

      <IonPopover ref={popover}
        isOpen={showModalData}
        onDidDismiss={() => setShowModalData(false)} >
        <IonDatetime
          presentation='date'
          onIonChange={e => {
            if (e.detail.value) {
              let data = e.detail.value.toString().substring(0, 10);
              const dataFormatada = new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                Date.parse(data)
              );
              setPesquisaData(dataFormatada);
              setFiltroPesquisaFinanceiro(data);
            }
          }}></IonDatetime>

      </IonPopover >
    </IonPage>
  );
};

export default Financeiro;
