import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonLoading, IonRow, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import "./Desconto.css";


type Props = {
  projetoid: string;
  closeAction: Function;
  closeActionSimple: Function;
};

const Desconto: React.FC<Props> = (props: Props) => {

  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [desconto, setDesconto] = useState('');

  useEffect(() => {
    if (props.projetoid != "") {
      console.log("entrou no useEffect");
    }
  }, []);


  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    setDesconto(v);
  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">

          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
              props.closeActionSimple();
            }} />
          </IonButtons>
          <IonTitle>Desconto</IonTitle>

        </IonToolbar>


      </IonHeader>

      <IonContent fullscreen={false}>

        <IonGrid >
          <IonRow className="ion-justify-content-center" style={{ paddingTop: "20px", textAlign: "center", fontSize: "20px" }}>
            <IonLabel>Selecione um desconto entre com um valor manualmente</IonLabel>
          </IonRow>

          <IonRow className="ion-justify-content-center" style={{ paddingTop: "20px" }}>
            <IonButton
              color={desconto === "5,00" ? "primary" : "light"}
              onClick={() => setDesconto("5,00")}
            >
              5,00
            </IonButton>
            <IonButton
              color={desconto === "10,00" ? "primary" : "light"}
              onClick={() => setDesconto("10,00")}
            >
              10,00
            </IonButton>
            <IonButton
              color={desconto === "25,00" ? "primary" : "light"}
              onClick={() => setDesconto("25,00")}
            >
              25,00
            </IonButton>
            <IonButton
              color={desconto === "50,00" ? "primary" : "light"}
              onClick={() => setDesconto("50,00")}
            >
              50,00
            </IonButton>
            <IonButton
              color={desconto === "100,00" ? "primary" : "light"}
              onClick={() => setDesconto("100,00")}
            >
              100,00
            </IonButton>
          </IonRow>

          <IonRow className="ion-justify-content-center" style={{ paddingTop: "20px", textAlign: "center", fontSize: "26px" }}>
            <IonCol size="6">

              <IonInput style={{
                "--background": "#eeeeee",
                borderRadius: "20px",      // Arredonda as bordas
                boxSizing: "border-box"
              }} type="tel" maxlength={14} placeholder="0,00" value={desconto} onKeyUp={onChangeValor} />

            </IonCol>
          </IonRow>
        </IonGrid>


        <IonToast
          isOpen={showToast}
          position="middle"
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />
      </IonContent>
      <IonFooter>

        <IonToolbar>
          <IonGrid>

            <IonRow>
              <IonCol size="12">
                <IonButton style={{ height: "46px", fontSize: "18px" }} expand="block"
                  type="submit"
                  onClick={async () => {
                    props.closeAction(desconto);
                  }}
                >
                  Salvar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>




        </IonToolbar>
      </IonFooter>
    </>


  );
};

export default ({ projetoid, closeAction, closeActionSimple }: { projetoid: any, closeAction: Function, closeActionSimple: Function }) => (
  <Desconto projetoid={projetoid} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </Desconto>
)
