import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonReorder, IonReorderGroup, IonRow, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, ItemReorderEventDetail, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { add, arrowBack, checkmark, close, pencil, reorderTwo, trash } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { alterarItemFormularioEmpresa, empresaCarregar, inserirItemFormularioEmpresa, ordernarItemFormularioEmpresa, removerItemFormularioEmpresa, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import "./FormularioCadastro.css";

type Props = {
  abrirMenu: boolean;
  onDismiss: () => void;
};

export interface Opcao {
  descricao: string;
}

const FormularioCadastro: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [listaItemFormulario, setListaItemFormulario] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const [indice, setIndice] = useState("");
  const [pergunta, setPergunta] = useState("");
  const [tipo, setTipo] = useState("");
  const [categoria, setCategoria] = useState("");
  const [obrigatorio, setObrigatorio] = useState("");
  const [listaOpcoes, setListaOpcoes] = useState<Opcao[]>([]);

  const [descricaoOpcao, setDescricaoOpcao] = useState("");


  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [listaCategoria, setListaCategoria] = useState<any>([]);

  const [isDisabled, setIsDisabled] = useState(true);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const inputRef = useRef<any>(null);

  const [mostartAlerta] = useIonAlert();

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarConta();
  });


  function carregarConta() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(empresaCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setListaItemFormulario(response.Item.formulario || []);
          setListaCategoria(response.Item.categoriaFormulario || []);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }



  function deletarItemFormulario(indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delItemFormulario = async () => {
      const resp = await fetch(removerItemFormularioEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerItemFormularioEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Item do formulário removido com sucesso.");
        setShowToast(true);
        setListaItemFormulario(retorno.Item?.Attributes?.formulario || []);
      }
      else {
        setTextToast("Ocorreu um erro ao efetuar a exclusão.");
        setShowToast(true);
      }
    }

    delItemFormulario();

  }


  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    // console.log('Before complete', listaItemFormulario);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    setListaItemFormulario(event.detail.complete(listaItemFormulario));

    // After complete is called the items will be in the new order
    // console.log('After complete', listaItemFormulario);


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const ordItemFormulario = async () => {
      const resp = await fetch(ordernarItemFormularioEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: ordernarItemFormularioEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'formulario': listaItemFormulario }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno ordenar", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Ordenação alterada com sucesso.");
        setShowToast(true);
        setListaItemFormulario(retorno.Item?.Attributes?.formulario || []);
      }
      else {
        setTextToast("Ocorreu um erro ao ordernar.");
        setShowToast(true);
      }
    }

    ordItemFormulario();

  }


  const loadItemFormulario = () => {

    if (listaItemFormulario == undefined) return;

    if (listaItemFormulario.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonReorderGroup disabled={isDisabled} onIonItemReorder={handleReorder}>
          {listaItemFormulario.map((value: any, index: any) => (
            <IonItem lines="none" key={"itemformulario" + index}>

              <IonLabel>{value.pergunta}</IonLabel>
              <IonLabel>{value.tipo}</IonLabel>
              <IonLabel>{value.categoria}</IonLabel>
              <IonLabel>
                <IonButton style={{ margin: "0px", padding: "0px" }} fill="clear" onClick={() => {
                  if (value.obrigatorio == "sim") {
                    setTextToast("Sim");
                  }
                  else {
                    setTextToast("Não");
                  }
                  setShowToast(true);
                }}>
                  <IonIcon color="primary" icon={value.obrigatorio == "sim" ? checkmark : close}></IonIcon>
                </IonButton>

              </IonLabel>
              <IonLabel >  </IonLabel>


              <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonReorder slot="end"></IonReorder>
              </div>
              <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonButtons >


                  {/* <IonButton hidden={!isDisabled} onClick={() => {
                    if (value.obrigatorio == "sim") {
                      setTextToast("Campo Obrigatório.");
                    }
                    else {
                      setTextToast("Campo não Obrigatório.");
                    }
                    setShowToast(true);
                  }}>
                    <IonIcon color="primary" icon={value.obrigatorio == "sim" ? checkmarkCircle : checkmarkCircleOutline}></IonIcon>
                  </IonButton> */}


                  <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                    onClick={() => {
                      setIndice(value.indice);
                      setPergunta(value.pergunta);
                      setTipo(value.tipo);
                      setDescricaoOpcao("");
                      setCategoria(value.categoria);
                      setObrigatorio(value.obrigatorio);

                      let op: Opcao[] = [];
                      value.opcoes?.forEach((value: any) => {
                        op.push({ descricao: value.descricao });
                      });
                      setListaOpcoes(op);

                      setShowDetalhes(true);

                    }}>
                    <IonIcon color="primary" icon={pencil} />
                  </IonButton>

                  <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                    onClick={() => {
                      mostartAlerta({
                        backdropDismiss: false,
                        header: 'Remover item',
                        message: 'Deseja <strong>remover</strong>?',
                        buttons: [
                          {
                            text: 'Cancelar',
                            role: 'cancel',
                          },
                          {
                            text: 'Confirmar',
                            cssClass: 'primary',
                            handler: () => {
                              deletarItemFormulario(value.indice);
                            }
                          }
                        ],
                        onDidDismiss: (e) => console.log('did dismiss'),
                      })
                    }}>
                    <IonIcon color="primary" icon={trash} />
                  </IonButton>

                </IonButtons>

              </div>
            </IonItem>

          ))}
        </IonReorderGroup>
      </>
    );
  }

  function cadastrar() {
    if (!pergunta || pergunta.trim() == ""
      || !tipo || tipo.trim() == ""
      || !categoria || categoria.trim() == ""
      || !obrigatorio || obrigatorio.trim() == "") {
      setTextToast("Todos os campos são obrigatórios.");
      setShowToast(true);
      return false;
    }
    else if (tipo == "select" && listaOpcoes.length == 0) {
      setTextToast("Adicione uma opção quando o campo for do Tipo Seleção.");
      setShowToast(true);
      return false;
    }
    else {



      console.log("indice", indice);
      if (indice != "") {
        const objectRequest = {
          pergunta: pergunta,
          tipo: tipo,
          categoria: categoria,
          obrigatorio: obrigatorio,
          indice: indice,
          opcoes: listaOpcoes
        }
        console.log("objectRequest", objectRequest);

        let id = loja;

        console.log("id", id);

        requestService(
          alterarItemFormularioEmpresa.url.replace("{id}", id.replaceAll("#", "%23")),
          {
            method: alterarItemFormularioEmpresa.method,
            body: JSON.stringify(objectRequest),
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            credentials: 'include'
          },
          history,
          (response: any) => {
            console.log(response);
            if (response) {
              setTextToast("Alteração realizada com sucesso.");
              setShowToast(true);

              setListaItemFormulario(response.message.Attributes.formulario || []);
              setShowDetalhes(false);

            } else if (response.status === 400) {
              console.log(response);
              setTextToast("Ocorreu um erro ao efetuar a alteração.");
              setShowToast(true);

            }
            setShowLoading(false);
          },
          (error: any) => {
            console.log(error);
            setTextToast("Ocorreu um erro ao efetuar a alteração.");
            setShowToast(true);
            setShowLoading(false);
          })
      } else {
        const objectRequest = {
          pergunta: pergunta,
          tipo: tipo,
          categoria: categoria,
          obrigatorio: obrigatorio,
          opcoes: listaOpcoes
        }
        console.log("objectRequest", objectRequest);

        let id = loja;

        console.log("id", id);

        requestService(
          inserirItemFormularioEmpresa.url.replace("{id}", id.replaceAll("#", "%23")),
          {
            method: inserirItemFormularioEmpresa.method,
            body: JSON.stringify(objectRequest),
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            credentials: 'include'
          },
          history,
          (response: any) => {
            console.log(response);
            if (response) {
              setTextToast("Cadastro realizado com sucesso.");
              setShowToast(true);

              setListaItemFormulario(response.message.Attributes.formulario || []);
              setShowDetalhes(false);

            } else if (response.status === 400) {
              console.log(response);
              setTextToast("Ocorreu um erro ao efetuar o cadastro.");
              setShowToast(true);

            }
            setShowLoading(false);
          },
          (error: any) => {
            console.log(error);
            setTextToast("Ocorreu um erro ao efetuar o cadastro.");
            setShowToast(true);
            setShowLoading(false);
          })
      }
    }

  }

  function readCategoria() {
    return listaCategoria.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={value} value={value}>{value}</IonSelectOption>
    )
    )
  };


  function salvarOpcao() {

    if (!descricaoOpcao || descricaoOpcao.trim() == "") {
      setTextToast("Campo Descrição é obrigatório.");
      setShowToast(true);
      return false;
    }
    else {
      let novaOpcao = { descricao: descricaoOpcao };
      setListaOpcoes([...listaOpcoes, novaOpcao]);

      setDescricaoOpcao("");
    }

  }

  function deletarOpcao(index: Number): void {
    // Filtrar a lista de opções para criar uma nova lista excluindo o item na posição index
    const novaListaOpcoes = listaOpcoes.filter((_, i) => i !== index);
    // Atualizar o estado da lista de opções com a nova lista
    setListaOpcoes(novaListaOpcoes);
  }



  function readOpcoes() {


    return listaOpcoes.map((value: any, index: any, array: any) =>
    (
      <>

        <IonItem lines="none" key={index} >
          <IonCard style={{ width: "100%" }}>
            <IonCardContent style={{ padding: "8px" }}>
              <IonGrid >
                <IonRow>
                  {/* Data */}
                  <IonCol size="12" className="ion-text-wrap ion-text-start">
                    <strong>Opção:</strong> {value.descricao}
                  </IonCol>
                </IonRow>
              </IonGrid>

              <div className="ion-text-end" style={{ marginTop: '10px' }}>

                <IonButton fill="clear"
                  size="small"
                  onClick={() => {
                    mostartAlerta({
                      backdropDismiss: false,
                      header: 'Remover item',
                      message: 'Deseja <strong>remover</strong>?',
                      buttons: [
                        {
                          text: 'Cancelar',
                          role: 'cancel',
                        },
                        {
                          text: 'Confirmar',
                          cssClass: 'primary',
                          handler: () => {
                            deletarOpcao(index);
                          }
                        }
                      ],
                      onDidDismiss: (e) => console.log('did dismiss'),
                    })
                  }}>
                  <IonIcon color="danger" icon={trash} />
                </IonButton>
              </div>
            </IonCardContent>
          </IonCard>



        </IonItem>


      </>
    )
    )
  };

  return (

    <IonPage className="FormularioCadastro">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Formulário</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            setIndice("");
            setPergunta("");
            setTipo("");
            setDescricaoOpcao("");
            setCategoria("");
            setObrigatorio("");
            setListaOpcoes([]);
            setShowDetalhes(true);
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>



        <IonList>
          <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>

            <IonLabel style={{ fontSize: "12px" }}>Pergunta</IonLabel>
            <IonLabel style={{ fontSize: "12px" }}>Tipo</IonLabel>
            <IonLabel style={{ fontSize: "12px" }}>Categoria</IonLabel>
            <IonLabel style={{ fontSize: "12px" }}>Obrigatório</IonLabel>
            <IonLabel style={{ textAlign: "right" }}>
              <IonBadge hidden={acessos?.configuracoes != 'edicao'}
                onClick={() => setIsDisabled((current) => !current)}>
                <IonIcon color="medium" icon={reorderTwo}></IonIcon>
              </IonBadge>
            </IonLabel>
          </IonItem>
          {loadItemFormulario()}



          {/* <IonFab horizontal="end" edge style={{ "top": "0px", "right": "-4px" }} hidden={acessos?.configuracoes != 'edicao'}>
            <IonFabButton size="small" color="tertiary" 
              <IonIcon color="light" icon={menu}></IonIcon>
            </IonFabButton>
          </IonFab> */}
        </IonList>


        {/* <IonButton slot="end" >Ordenar</IonButton> */}



        <IonModal onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowDetalhes(false)} />
                </IonButtons>
                <IonTitle>Novo item</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonList lines="full">
                      {/* Pergunta */}
                      <IonItem>
                        <IonLabel position="stacked">Pergunta*</IonLabel>
                        <IonInput
                          placeholder="Digite a pergunta"
                          value={pergunta}
                          ref={(ref) => inputRef.current = ref}
                          autoCorrect='true' autoCapitalize='true'
                          required
                          onIonInput={(e: any) => setPergunta(e.target.value)}
                        />
                      </IonItem>

                      {/* Tipo */}
                      <IonItem>
                        <IonLabel position="stacked">Tipo*</IonLabel>
                        <IonSelect

                          value={tipo}
                          placeholder="Selecione"
                          onIonChange={e => setTipo(e.detail.value)}
                        >
                          <IonSelectOption value="text">Texto</IonSelectOption>
                          <IonSelectOption value="select">Seleção</IonSelectOption>
                          <IonSelectOption value="image">Fotos</IonSelectOption>
                          <IonSelectOption value="signature">Assinaturas</IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      {/* Descrição da Opção (se o tipo for "select") */}
                      <IonItem hidden={tipo !== "select"}>
                        <IonLabel position="stacked">Descrição*</IonLabel>
                        <IonInput
                          placeholder="Digite a opção"
                          value={descricaoOpcao}
                          autoCorrect='true' autoCapitalize='true'
                          required
                          onIonInput={(e: any) => setDescricaoOpcao(e.target.value)}
                        />
                      </IonItem>

                      {/* Botão "Salvar" para a descrição da opção (se o tipo for "select") */}
                      <div style={{ textAlign: 'right', paddingRight: "8px" }}>
                        <IonButton hidden={tipo !== "select"} onClick={() => salvarOpcao()}>Adicionar</IonButton>
                      </div>
                      {/* Lista de Opções (se o tipo for "select" e houver opções) */}

                      <IonItem lines="full">

                        <IonList style={{ width: "100%", padding: "0px", margin: "0px" }}>
                          {readOpcoes()}
                        </IonList>

                      </IonItem>

                      {/* Categoria */}
                      <IonItem>
                        <IonLabel position="stacked">Categoria*</IonLabel>
                        <IonSelect

                          value={categoria}
                          placeholder="Selecione"
                          onIonChange={e => { setCategoria(e.detail.value); }}
                        >
                          {readCategoria()}
                        </IonSelect>
                      </IonItem>

                      {/* Obrigatório */}
                      <IonItem>
                        <IonLabel position="stacked">Obrigatório*</IonLabel>
                        <IonSelect

                          value={obrigatorio}
                          placeholder="Selecione"
                          onIonChange={e => setObrigatorio(e.detail.value)}
                        >
                          <IonSelectOption value="sim">Sim</IonSelectOption>
                          <IonSelectOption value="nao">Não</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonContent>
            <IonFooter className="ion-no-border">
              <IonToolbar>
                <IonButton onClick={() => cadastrar()} expand="block" type="submit">Cadastrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default FormularioCadastro;