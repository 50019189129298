import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { getAuth, indexedDBLocalPersistence, initializeAuth } from "firebase/auth";
import {
  getFirestore
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCIpIiOu4ewzfYhDdMIk6AQPXVZUrsMJjI",
  authDomain: "xeguei-empresa.firebaseapp.com",
  projectId: "xeguei-empresa",
  storageBucket: "xeguei-empresa.appspot.com",
  messagingSenderId: "139010064166",
  appId: "1:139010064166:web:6493709a710d66dd6acbe7",
  measurementId: "G-WCRX81E60L"
};

const app = initializeApp(firebaseConfig);

export const authentication = Capacitor.isNativePlatform() ? initializeAuth(app, {
  persistence: indexedDBLocalPersistence
}) : getAuth(app);

export const db = getFirestore(app);