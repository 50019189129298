import { IonActionSheet, IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal, IonPage, IonRow, IonSegment, IonSegmentButton, IonTextarea, IonTitle, IonToggle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import "./ProjetosWorkflow.css";

import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { Toast } from '@capacitor/toast';
import imageCompression from 'browser-image-compression';
import * as icons from 'ionicons/icons';
import { addCircle, alertCircle, arrowBack, arrowForwardOutline, camera, closeOutline, create, informationCircleOutline, logoUsd, notificationsOutline, receipt } from 'ionicons/icons';
import { carregaAssinatura, projetoRegistrarInformacao, removerArquivoProjeto, removerFotoProjeto, requestService, urlBase } from '../../../../../Utils/Services';
import { UserPhoto } from '../../../../../components/usePhotoGallery';

import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";

import { Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '@ionic/react/css/ionic-swiper.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { MessageContext } from '../../../../../contexts/MessageContext';
import ProjetosWorkflowAvancar from '../ProjetosWorkflowAvancar/ProjetosWorkflowAvancar';

type IconType = typeof import('ionicons/icons/index');


type Props = {
  abrirMenu: boolean;
  projetoid: string;
  onDismiss: () => void;
};

const ProjetosWorkflow: React.FC<Props> = (props: Props) => {
  
  const history = useHistory<any>();
  const location = useLocation<any>();

  const [dadosProjeto, setDadosProjeto] = useState<any>([]);
  const [operacaoCarregada, setOperacaoCarregada] = useState(false);
  const [temHoraFim, setTemHoraFim] = useState(false);
  const [showActionSheet, setShowActionSheet] = useState(false);

  const [showModalInformacoes, setShowModalInformacoes] = useState(false);
  const [tituloInformacoes, setTituloInformacoes] = React.useState<string>();
  const [textoInformacoes, setTextoInformacoes] = React.useState<string>();

  const [showModalValores, setShowModalValores] = useState(false);
  const [tituloValores, setTituloValores] = React.useState<string>();
  const [textoValores, setTextoValores] = React.useState<string>();

  const [showModalArquivos, setShowModalArquivos] = useState(false);
  const [tituloArquivos, setTituloArquivos] = React.useState<string>();
  const [fileArquivos, setFileArquivos] = useState<any>();
  const [textoArquivos, setTextoArquivos] = React.useState<string>();

  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);

  const [selectedTab, setSelectedTab] = useState<string>("0");

  const [informacoes, setInformacoes] = useState<any>([]);
  const [valores, setValores] = useState<any>([]);
  const [fotos, setFotos] = useState<any>([]);
  const [arquivos, setArquivos] = useState<any>([]);
  const [photos, setPhotos] = useState<UserPhoto[]>([]);

  const [showLoadingPage, setShowLoadingPage] = useState(false);

  const [idProjeto, setIdProjeto] = useState("");
  const [showModalDadosAssinatura, setShowModalDadosAssinatura] = useState(false);
  const [checkedAssinarDocumento, setCheckedAssinarDocumento] = useState(false);
  const [dadosAssinatura, setDadosAssinatura] = useState<any>([]);
  const [assinaturaBlob, setAssinaturaBlob] = useState<string>();

  const [caminhoParaExcluir, setCaminhoParaExcluir] = React.useState<string>();
  const [caminhoIndiceParaExcluir, setCaminhoIndiceParaExcluir] = React.useState<string>();
  const [showAlertConfirmarExclusao, setShowAlertConfirmarExclusao] = useState(false);
  const [showAlertConfirmarExclusaoFoto, setShowAlertConfirmarExclusaoFoto] = useState(false);

  const [projetoId, setProjetoId] = useState("");
  const [indiceWorkflow, setIndiceWorkflow] = useState("");
  const [statusWorkflow, setStatusWorkflow] = useState("");
  const [listaStatus, setListaStatus] = useState<any>([]);
  const [showWorkflowAvancar, setShowWorkflowAvancar] = useState(false);

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const inputRef = useRef<any>(null);

  let params: any = {
    projetoid: "",
    abrirInserirAgendamento: false
  }
  params = useParams();

  if (location && location.state && location.state.projetoid) {
    params.projetoid = location.state.projetoid;
  }


  if (props.projetoid) {
    params = {
      projetoid: props.projetoid
    }
  }

  useEffect(() => {
    if (idProjeto != "") {
      console.log("entrou no useEffect");
      carregarProjeto();
    }
  }, [idProjeto]);

  useIonViewDidEnter(() => {
    console.log("entrou useIonViewDidEnter workflow");
    setEsconderMenu(true);
    setNomeMenu("");
    if (params.projetoid) {
      setIdProjeto(params.projetoid);
    }
  });

  const takePhoto = async (idProjeto: string, indice: string) => {

    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      allowEditing: false,
      direction: CameraDirection.Rear,
      source: CameraSource.Prompt, //CameraSource.Camera || CameraSource.Photos,//.Prompt,
      promptLabelHeader: 'Fotos',
      promptLabelPhoto: 'Biblioteca de fotos',
      promptLabelPicture: 'Câmera',
      promptLabelCancel: 'Cancelar'
    });

    var binary_string = window.atob(cameraPhoto.base64String || '');
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(bytes.buffer)], {
      type: `image/${cameraPhoto.format}`,
    });

    const file = new File([blob], "Name", {
      lastModified: new Date().getTime(),
      type: blob.type,
    });

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1102,
      useWebWorker: true,
      initialQuality: 0.5
    }
    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB


      let base64 = await getBase64(compressedFile);
      await incluirFoto(idProjeto, indice, base64, "", "Fotos");




    } catch (error) {
      console.log(error);
    }

  };

  async function getBase64(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(file)
    })
  }


  async function incluirAquivo(projetoId: string, indice: string, file: any, fileTypeArquivo: any, nomeArquivo: string, tipoArquivo: string) {


    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let params = {
        nome: nomeArquivo,
        campo: tipoArquivo,
        mensagem: fileTypeArquivo,
        indice: indice,
        assinarDocumento: checkedAssinarDocumento
      }

      try {
        setShowLoadingPage(true);

        const resp = await fetch(projetoRegistrarInformacao.url.replaceAll("{pk}", projetoId.replaceAll(/#/g, "%23")),
          {
            method: projetoRegistrarInformacao.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();

        console.log("response", response);
        if (response.Item) {



          console.log("file.type", file.type);
          const uploadToS3 = await fetch(response.Item, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });

          if (uploadToS3.status == 200) {

            Toast.show({
              text: "Arquivo foi adicionado com sucesso.",
              position: "center",
              duration: "long"
            });

            setTextoArquivos("");
            setFileArquivos("");
          }
          else {
            Toast.show({
              text: "Ocorreu um erro ao fazer o upload do arquivo.",
              position: "center",
              duration: "long"
            });

          }

          setShowModalInformacoes(false);
          setShowModalValores(false);
        }
        setShowLoadingPage(false);

      } catch (error) {
        console.log("erro", error);
        setShowLoadingPage(false);
      }
    }

    atualizar();

  };


  async function incluirFoto(projetoId: string, indice: string, base64: any, nomeArquivo: string, tipoArquivo: string) {


    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let params = {
        nome: nomeArquivo,
        campo: tipoArquivo,
        mensagem: base64,
        indice: indice
      }

      try {
        setShowLoadingPage(true);

        const resp = await fetch(projetoRegistrarInformacao.url.replaceAll("{pk}", projetoId.replaceAll(/#/g, "%23")),
          {
            method: projetoRegistrarInformacao.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();
        if (response.Item.Attributes) {


          setShowModalInformacoes(false);
          setShowModalValores(false);
          preencherDadosOperacao(response.Item.Attributes, false);

          setTextoArquivos("");
          setFileArquivos("");

          console.log("retornoobjetocheio", response);
        }
        setShowLoadingPage(false);


      } catch (error) {
        console.log("erro", error);
        setShowLoadingPage(false);
      }
    }

    atualizar();

  };

  function carregarProjeto() {
    if (params.projetoid != "" && params.projetoid != undefined) {
      if (!operacaoCarregada) {
        setOperacaoCarregada(true);

        const getoperacao = async () => {
          var token: string = "";
          var tkn = localStorage.getItem("token");
          if (typeof (tkn) === "string") { token = tkn; }
          console.log("projetoid", params.projetoid);
          setShowLoadingPage(true);
          const resp = await fetch(urlBase.url + "/projetos/" + params.projetoid.replaceAll(/#/g, '%23'),
            {
              method: urlBase.method,
              headers: [
                ["token", token]
              ],
              credentials: 'include'
            });
          const operacao = await resp.json();
          //if (resp.status === 400 || resp.status === 401) history.replace("/login/");
          if (operacao.Item !== undefined) {


            preencherDadosOperacao(operacao.Item, true);


            console.log("abrirInserirAgendamento", params.abrirInserirAgendamento);

            setTemHoraFim(operacao.Item.dataEncerramento ? true : false);

          }
          setShowLoadingPage(false);
        }

        getoperacao();
      }
    }
    return "";
  }

  function carregarProjetoSemTrocarAba() {
    if (params.projetoid != "" && params.projetoid != undefined) {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }
      console.log("projetoid", params.projetoid);
      const getoperacao = async () => {
        const resp = await fetch(urlBase.url + "/projetos/" + params.projetoid.replaceAll(/#/g, '%23'),
          {
            method: urlBase.method,
            headers: [
              ["token", token]
            ],
            credentials: 'include'
          });
        const operacao = await resp.json();
        //if (resp.status === 400 || resp.status === 401) history.replace("/login/");
        if (operacao.Item !== undefined) {


          preencherDadosOperacao(operacao.Item, false);


          console.log("abrirInserirAgendamento", params.abrirInserirAgendamento);


          setTemHoraFim(operacao.Item.dataEncerramento ? true : false);

        }
      }

      getoperacao();

    }
    return "";
  }

  function preencherDadosOperacao(projeto: any, slideDoStatus: Boolean) {

    console.log("operacao", projeto);
    setDadosProjeto(projeto);


    let preencherInformacoes: any[] = [];
    let preencherValores: any[] = [];
    let preencherFotos: any[] = [];
    let preencherArquivos: any[] = [];
    let newPhotosProjeto: UserPhoto[] = [];
    let newArquivosProjeto: UserPhoto[] = [];

    console.log("contar", projeto.configWorkflow.length);
    projeto.configWorkflow.map((value: any, index: any) => {
      console.log("preencherDadosOperacao_slideDoStatus", slideDoStatus);
      if (slideDoStatus && value.status === projeto.statusWorkflow) {
        swiperInstance?.slideTo(parseInt(index));
      }
      preencherInformacoes.push(value.campoInformacoes);
      preencherValores.push(value.campoValores);


      if (value.campoFotos) {
        let _photos: string[] = value.campoFotos;


        _photos.map(async (valorPath: any, indexPath: any) => {
          newPhotosProjeto.push({ filepath: valorPath, webviewpath: valorPath });
        })

        preencherFotos.push(newPhotosProjeto);
        newPhotosProjeto = [];

      }
      else {
        preencherFotos.push("");
      }

      if (value.campoArquivos) {
        let _photos: string[] = value.campoArquivos;


        _photos.map(async (valorPath: any, indexPath: any) => {
          newArquivosProjeto.push({ filepath: valorPath, webviewpath: valorPath });
        })

        preencherArquivos.push(newArquivosProjeto);
        newArquivosProjeto = [];

      }
      else {
        preencherArquivos.push("");
      }





    });


    console.log("preencherInformacoes", preencherInformacoes);
    setInformacoes(preencherInformacoes);
    setValores(preencherValores);
    setFotos(preencherFotos);
    console.log("preencherArquivos", preencherArquivos);
    setArquivos(preencherArquivos);
  }

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  function onTabChange(value: string) {
    console.log(value);
    if (value === "" || value == null || value === undefined) return;

    swiperInstance?.slideTo(parseInt(value));
  }


  async function onSlideChange() {
    let slideIndex = swiperInstance?.activeIndex;

    console.log(slideIndex);

    if (slideIndex == null || slideIndex === undefined)
      return;

    setSelectedTab(slideIndex.toString());

    document.getElementById("tab-" + slideIndex)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }


  function deletarFoto(url: any, indice: any) {

    console.log(params.projetoid, url);





    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    setShowLoadingPage(true);
    const delFoto = async () => {
      const resp = await fetch(removerFotoProjeto.url.replace("{id}", params.projetoid.replace(/#/g, "%23")),
        {
          method: removerFotoProjeto.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'photo': url, 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      //if (resp.status === 400 || resp.status === 401) history.replace("/login/");

      console.log("retorno", retorno);


      if (retorno.Item && retorno.Item.Attributes && retorno.Item.Attributes) {


        preencherDadosOperacao(retorno.Item.Attributes, false);


      }
      setShowLoadingPage(false);
    }

    delFoto();


  }

  function deletarArquivo(url: any, indice: any) {

    console.log(params.projetoid, url);





    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }
    setShowLoadingPage(true);
    const delFoto = async () => {
      const resp = await fetch(removerArquivoProjeto.url.replace("{id}", params.projetoid.replace(/#/g, "%23")),
        {
          method: removerArquivoProjeto.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'arquivo': url, 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      //if (resp.status === 400 || resp.status === 401) history.replace("/login/");

      console.log("retorno", retorno);

      if (retorno.Item && retorno.Item.Attributes && retorno.Item.Attributes) {


        preencherDadosOperacao(retorno.Item.Attributes, false);


      }
      setShowLoadingPage(false);
    }

    delFoto();


  }


  function preencheFotos(value: any, indice: any) {

    return (
      (fotos[indice]) ?
        (<>
          {fotos[indice].map((photo: any, index: any) => (
            <IonCol size="6" key={index} >
              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '10px', position: 'relative' }}>
                <div style={{ maxWidth: '350px', width: '100%', minHeight: '100px' }}>

                  {/* <a target='_blank' href={photo.webviewpath?.replace("small", "big")}>

                  <IonImg src={photo.webviewpath} />
                </a> */}

                  <a target='_blank' href={photo.webviewpath?.replace("small", "big")}>
                    <img style={{ width: '100%', borderRadius: '5px' }} src={photo.webviewpath?.replace("small", "big")}></img>
                  </a>
                  <IonButton fill='clear' color="tertiary" size="small" expand="block" onClick={() => {
                    setCaminhoParaExcluir(photo.webviewpath); setCaminhoIndiceParaExcluir(indice); setShowAlertConfirmarExclusaoFoto(true);
                  }}>Excluir</IonButton>


                </div>
              </div>
            </IonCol >
          ))
          }

        </>) :
        (
          <>
            {/* {preencheCard(value, index)} */}
          </>
        )
    )
  }

  function preencheArquivos(value: any, indice: any) {

    return (
      (arquivos[indice]) ?
        (<>
          {arquivos[indice].map((arq: any, index: any) => (
            <IonCol size="6" key={index} >
              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px', paddingBottom: '10px', position: 'relative' }}>
                <div style={{ maxWidth: '350px', width: '100%' }}>
                  <a target='_blank' href={arq.webviewpath?.split(";")[1]}>
                    {arq.webviewpath?.split(";")[0].split("|")[0]}
                  </a>

                  <IonButton style={{ margin: '10px 0 10px 0', fontWeight: 'normal', width: '100%', "font-size": "12px" }} hidden={arq.webviewpath?.split(";")[0].split("|")[1] == "cont" || arq.webviewpath?.split(";")[0].split("|")[1] == undefined} color="tertiary" expand='block' fill="solid" size="small" onClick={() => {
                    carregarDadosAssinatura(arq.webviewpath?.split(";")[0].split("|")[1]);
                  }}>Assinado</IonButton>

                  <IonButton style={{ margin: '10px 0 10px 0', fontWeight: 'normal', width: '100%', "font-size": "12px", "font-weight": "bold" }} hidden={arq.webviewpath?.split(";")[0].split("|")[1] != "cont"} fill='clear' color="tertiary" size="small" expand="block" onClick={() => {
                    Toast.show({
                      text: "O Cliente ainda não assinou o documento.",
                      position: "center",
                      duration: "long"
                    });
                  }}>Não Assinado</IonButton>

                  <IonButton style={{ margin: '10px 0 10px 0', fontWeight: 'normal', width: '100%' }} fill='clear' color="tertiary" size="small" expand="block" onClick={() => {
                    setCaminhoParaExcluir(arq.webviewpath); setCaminhoIndiceParaExcluir(indice); setShowAlertConfirmarExclusao(true);
                  }}>Excluir</IonButton>
                </div>
              </div>
            </IonCol>
          ))}

        </>) :
        (
          <>
            {/* {preencheCard(value, index)} */}
          </>
        )
    )
  }


  function carregarDadosAssinatura(pk: any) {
    setShowModalDadosAssinatura(true);

    const consultar = async () => {

      setShowLoadingPage(true);

      requestService(carregaAssinatura.url.replace("{id}", pk.replaceAll(/#/g, '%23')), {
        method: carregaAssinatura.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.Item);
          setDadosAssinatura(response.Item);
          setAssinaturaBlob(response.Item.assinaturaBase64);
          setShowLoadingPage(false);
          setShowModalDadosAssinatura(true);

        },
        (error: any) => {
          setShowLoadingPage(false);
          setShowModalDadosAssinatura(true);
          console.log(error);
        });



    }
    consultar();

  }

  function preencheCard(value: any, index: any) {


    return (
      (<>
        <IonList style={{ "margin": "0px" }}>
          <IonItem style={{ "margin": "0px" }} lines="none" hidden={value.exibirInformacoes === "false" || (window.localStorage.getItem("perfil") == "usuario" && value.somenteAdministrador == "true")}>
            <IonIcon style={{ "margin-right": "6px" }} icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              {/* <h1>Informações</h1> */}

              <IonListHeader hidden={false} lines="full">
                <IonLabel>Informações</IonLabel>
                <IonItem lines="none" hidden={acessos?.projetos != 'edicao'}>
                  <IonIcon src={create} color="primary" onClick={async () => { setTituloInformacoes(value.status); setTextoInformacoes(informacoes[index]); setShowModalInformacoes(true); }}></IonIcon>
                </IonItem>
              </IonListHeader>

              <div dangerouslySetInnerHTML={{ __html: informacoes[index] }} style={{ whiteSpace: "pre-wrap", paddingTop: "6px" }} >
              </div>
            </div>
          </IonItem>

          <IonItem style={{ "margin": "0px" }} lines="none" hidden={value.exibirValor === "false" || (window.localStorage.getItem("perfil") == "usuario" && value.somenteAdministrador == "true")}>
            <IonIcon style={{ "margin-right": "6px" }} icon={logoUsd} slot="start" />
            <div className="contentList">

              <IonListHeader hidden={false} lines="full">
                <IonLabel>Valores</IonLabel>
                <IonItem lines="none" hidden={acessos?.projetos != 'edicao'}>
                  <IonIcon src={create} color="primary" onClick={async () => { setTituloValores(value.status); setTextoValores(valores[index]); setShowModalValores(true); }}></IonIcon>
                </IonItem>
              </IonListHeader>

              <div dangerouslySetInnerHTML={{ __html: valores[index] }} style={{ whiteSpace: "pre-wrap", paddingTop: "6px" }} >

              </div>
            </div>
          </IonItem>

          <IonItem style={{ "margin": "0px" }} lines="none" hidden={value.exibirFotos === "false" || (window.localStorage.getItem("perfil") == "usuario" && value.somenteAdministrador == "true")}>
            <IonIcon style={{ "margin-right": "6px" }} icon={camera} slot="start" />
            <div className="contentList">


              <IonListHeader hidden={false} lines="full">
                <IonLabel>Fotos</IonLabel>
                <IonItem lines="none" hidden={acessos?.projetos != 'edicao'}>
                  <IonIcon src={addCircle} color="primary" onClick={async () => { await takePhoto(params.projetoid, index.toString()).catch((e) => { console.log("cancelado"); }); }}></IonIcon>
                </IonItem>
              </IonListHeader>




              <IonList lines="full" class="ion-no-margin">
                <IonGrid>
                  <IonRow>

                    {preencheFotos(value, index)}
                  </IonRow>
                </IonGrid>
              </IonList>
            </div>
          </IonItem>

          <IonItem style={{ "margin": "0px" }} lines="none" hidden={value.exibirArquivos === "false" || (window.localStorage.getItem("perfil") == "usuario" && value.somenteAdministrador == "true")}>
            <IonIcon style={{ "margin-right": "6px" }} icon={receipt} slot="start" />
            <div className="contentList">



              <IonListHeader hidden={false} lines="full">
                <IonLabel>Arquivos</IonLabel>
                <IonItem lines="none" hidden={acessos?.projetos != 'edicao'}>
                  <IonIcon src={addCircle} color="primary" onClick={async () => { setTituloArquivos(value.status); setShowModalArquivos(true) }}></IonIcon>
                </IonItem>
              </IonListHeader>




              <IonList lines="full" class="ion-no-margin">
                <IonGrid>
                  <IonRow>

                    {preencheArquivos(value, index)}
                  </IonRow>
                </IonGrid>
              </IonList>
            </div>
          </IonItem>
        </IonList>


      </>)
    )
  }

  function loadItensMenu(value: any, index: any) {

    let nomeIcone: string = value.icone;
    return (

      <IonSegmentButton value={index} id={"tab-" + index}>

        <p><IonIcon icon={icons[nomeIcone as keyof IconType]} ></IonIcon></p>
      </IonSegmentButton>

    )
  }


  function registrarInformacao(pk: any, campo: any, mensagem: any, indice: any) {
    console.log("pk", pk);
    console.log("mensagem", mensagem);
    console.log("indice", indice);

    if (!mensagem || mensagem.replace(/<p><br><\/p>/g, "").trim() == "") {
      setAlertaPreenchimento(true);
      return null;
    }

    let params = {
      campo: campo,
      mensagem: mensagem,
      indice: indice
    };
    console.log("params", params);
    requestService(projetoRegistrarInformacao.url.replaceAll("{pk}", pk.replaceAll(/#/g, "%23")), {
      method: projetoRegistrarInformacao.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify(params),
      credentials: 'include'
    },
      history,
      (response: any) => {
        console.log(response);

        setShowModalInformacoes(false);
        setShowModalValores(false);
        preencherDadosOperacao(response.message.Attributes, false);

      },
      (error: any) => {
        console.log(error);
      });



  }

  const handleUpload = async (idProjeto: string, indice: string) => {

    if (!fileArquivos || !textoArquivos || textoArquivos.trim() == "") {
      setAlertaPreenchimento(true);
      return null;
    }

    console.log("fileArquivos", fileArquivos);


    let file = fileArquivos.files[0];
    console.log("file", file);

    if (file.size < 15000000) {
      let fileParts = file.name.split('.');
      let fileNameArquivo = fileParts[0];
      let fileTypeArquivo = fileParts[1];
      console.log("Preparing the upload");
      console.log(fileParts);

      try {
        incluirAquivo(idProjeto, indice, file, fileTypeArquivo, textoArquivos || "", "Arquivos");

      } catch (error) {
        console.log("error", error);
        Toast.show({
          text: "Ocorreu um erro ao adicionar um arquivo, tente novamente.",
          position: "center",
          duration: "long"
        });
      }

    }
    else {
      Toast.show({
        text: "Somente arquivos até 15 MB.",
        position: "center",
        duration: "long"
      });
    }
  }

  const TOOLBAR_OPTIONS = [
    ["bold", "italic", "underline", "strike"],
    [{ color: ["black", "red", "blue", "green", "gray", "orange"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ['link', 'video'],

    ["clean"],

  ];

  function getButtons() {

    let resultado: any = [
      {
        text: 'Cancelar',
        icon: closeOutline,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }
    ];
    if (acessos?.projetos == 'edicao') {

      resultado.push(
        {
          text: "Avançar etapa",
          icon: arrowForwardOutline,
          handler: () => {
            setProjetoId(dadosProjeto.pk);
            setIndiceWorkflow(dadosProjeto.indiceWorkflow);
            setStatusWorkflow(dadosProjeto.statusWorkflow);
            setListaStatus(dadosProjeto.configWorkflow);
            setShowWorkflowAvancar(true);
          }
        }
      );

      resultado.push(
        {
          text: "Enviar mensagem",
          icon: notificationsOutline,
          handler: () => {

            history.push("mensagem", { projetoid: dadosProjeto.pk.replaceAll(/#/g, '%23'), projetonome: dadosProjeto.nomeProjeto });
            // setMensagem("Psiu, passando para avisar que tem novidades na empresa " + dadosProjeto.empresaNome + ".");
            // setShowModalMensagem(true);
          }
        }
      );
    }
    return resultado;
  }

  async function closeModalWorkflowAvancar() {
    setShowWorkflowAvancar(false);
  }

  async function closeModalAlterarWorkflowAvancar(dadosOperacao: any) {
    preencherDadosOperacao(dadosOperacao, true);
    setShowWorkflowAvancar(false);
    setRecarregarProjeto(true);
  }

  return (

    <IonPage className="ProjetosWorkflow">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonItem lines="none" color="primary" >
            <IonTitle color="dark" className="ion-text-wrap" style={{ "padding-left": "20px", "padding-top": "0px", "padding-bottom": "0px", "text-align": "left" }}>{dadosProjeto ? dadosProjeto.nomeProjeto + " - " + dadosProjeto.statusWorkflow : ""}</IonTitle>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>

        <div className="header">
          <div className="info" >
            <IonToolbar>
              <IonSegment scrollable value={selectedTab} onIonChange={(e) => { scrollToTop(); onTabChange(e.detail.value ?? "") }}>
                {dadosProjeto?.configWorkflow?.map((valor: any, index: any) => (
                  loadItensMenu(valor, index)
                ))}
              </IonSegment>
            </IonToolbar>
          </div>
        </div>
        <div>
          <Swiper onSwiper={(swiper) => {
            setSwiperInstance(swiper);
          }} className={"ItinerarioSlides"} onSlideChange={() => onSlideChange()}>

            {dadosProjeto?.configWorkflow?.map((value: any, index: any) => (
              <SwiperSlide>
                <IonList style={{ width: '100%' }}>
                  <IonListHeader lines="full">
                    <IonLabel>{value.status}</IonLabel>
                  </IonListHeader>
                  {preencheCard(value, index)}
                </IonList>
              </SwiperSlide >
            ))}

          </Swiper>
        </div>

        <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          cssClass='my-custom-class'
          buttons={getButtons()}

        />






        <IonModal isOpen={showModalInformacoes} onDidDismiss={() => { setShowModalInformacoes(false); setTextoInformacoes(''); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Informações</IonTitle>
              <IonButtons slot="start" onClick={() => { setShowModalInformacoes(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonItem lines="none">
                <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
                <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
              </IonItem>
              <IonItem lines="none">
                <IonLabel >Informações referente a etapa de <b>{tituloInformacoes}</b>:</IonLabel>




              </IonItem>
              <div style={{ "padding": "4px" }}>
                <ReactQuill
                  theme="snow"
                  value={textoInformacoes}
                  onChange={e => setTextoInformacoes(e)}
                  modules={{
                    toolbar: {
                      container: TOOLBAR_OPTIONS
                    }
                  }}
                />
              </div>
            </IonList>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' onClick={() => { registrarInformacao(params.projetoid.replaceAll(/#/g, '%23'), "Informações", (textoInformacoes || ""), selectedTab) }}>Inserir informações</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>





        <IonModal isOpen={showModalValores} onDidDismiss={() => { setShowModalValores(false); setTextoValores(''); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Valores</IonTitle>
              <IonButtons slot="start" onClick={() => { setShowModalValores(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <IonList>
              <IonItem lines="none">
                <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
                <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
              </IonItem>
              <IonItem lines="none" >
                <IonLabel >Valores referente a etapa de <b>{tituloValores}</b>:</IonLabel>
              </IonItem>
              <div style={{ "padding": "4px" }}>
                <ReactQuill
                  theme="snow"
                  value={textoValores}
                  onChange={e => setTextoValores(e)}
                  modules={{
                    toolbar: {
                      container: TOOLBAR_OPTIONS
                    }
                  }}
                />
              </div>
            </IonList>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' onClick={() => { registrarInformacao(params.projetoid.replaceAll(/#/g, '%23'), "Valores", (textoValores || ""), selectedTab) }}>Inserir informações</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>

        <IonModal onDidPresent={() => {
              if (inputRef.current) {
                inputRef.current.setFocus();
              }
            }} isOpen={showModalArquivos} onDidDismiss={() => { carregarProjetoSemTrocarAba(); setShowModalArquivos(false); setTextoArquivos(''); setFileArquivos(''); setAlertaPreenchimento(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Adicionar Arquivos</IonTitle>
              <IonButtons slot="start" onClick={() => { setShowModalArquivos(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <IonItem lines="none">
              <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
              <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
            </IonItem>
            <IonItem lines="none" >
              <IonLabel className="ion-text-wrap" position="stacked">Arquivo referente a etapa de <b>{tituloArquivos}</b>:</IonLabel>
              <IonTextarea ref={(ref) => inputRef.current = ref} rows={1} maxlength={50} value={textoArquivos} autoCorrect='true' autoCapitalize='true' placeholder="Nome do arquivo" onIonChange={e => setTextoArquivos(e.detail.value!)}></IonTextarea>
            </IonItem>

            <IonItem lines="none">
              <IonLabel>Assinar documento?</IonLabel>
              <IonToggle checked={checkedAssinarDocumento} onIonChange={e => {
                setCheckedAssinarDocumento(e.detail.checked);
              }} />
            </IonItem>

            <IonItem lines="none" >
              <input ref={(ref) => { setFileArquivos(ref); }} type="file" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*" />
            </IonItem>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' onClick={() => { handleUpload(params.projetoid, selectedTab.toString()).catch((e) => { console.log("cancelado"); }); }}>Adicionar Arquivo</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={showModalDadosAssinatura} onDidDismiss={() => { setShowModalDadosAssinatura(false); }} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Assinatura</IonTitle>
              <IonButtons slot="start" onClick={() => { setShowModalDadosAssinatura(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">

            <IonLabel>Assinado Por:</IonLabel>
            <IonItem lines="none">
              <IonLabel>{dadosAssinatura.nome}</IonLabel>
            </IonItem>

            <IonLabel>CPF:</IonLabel>
            <IonItem lines="none">
              <IonLabel>{dadosAssinatura.cpf}</IonLabel>
            </IonItem>

            <IonLabel>Data da assinatura:</IonLabel>
            <IonItem lines="none">
              <IonLabel>{dadosAssinatura.dataAssinatura}</IonLabel>
            </IonItem>


            <IonLabel>Nome do documento:</IonLabel>
            <IonItem lines="none">
              <IonLabel>{dadosAssinatura.nomeDocumento}</IonLabel>
            </IonItem>

            <IonLabel>Autenticidade:</IonLabel>
            <IonItem lines="none">
              <IonTextarea style={{ "--background": "#eeeeee", "font-size": "10px" }} rows={6} value={dadosAssinatura.informacoesAdicionais} maxlength={250} disabled={true}></IonTextarea>
            </IonItem>

            <IonLabel>Assinatura:</IonLabel>
            <IonItem lines="none">
              <IonImg src={assinaturaBlob} />

            </IonItem>



          </IonContent>

        </IonModal>

        <IonModal isOpen={showWorkflowAvancar} onDidDismiss={() => { setShowWorkflowAvancar(false); }}>
          <ProjetosWorkflowAvancar projetoid={projetoId} statusWorkflow={statusWorkflow} indiceWorkflow={indiceWorkflow} listaStatus={listaStatus} closeAction={closeModalWorkflowAvancar} alterarAction={closeModalAlterarWorkflowAvancar}></ProjetosWorkflowAvancar>
        </IonModal>

        <IonAlert isOpen={showAlertConfirmarExclusao}
          onDidDismiss={() => setShowAlertConfirmarExclusao(false)}
          header={'Confirmar Exclusão'}
          message={'Você <strong>confirma</strong> excluir esse arquivo?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                deletarArquivo(caminhoParaExcluir, caminhoIndiceParaExcluir);
                setShowAlertConfirmarExclusao(false);
              }

            }
          ]}
        />

        <IonAlert isOpen={showAlertConfirmarExclusaoFoto}
          onDidDismiss={() => setShowAlertConfirmarExclusaoFoto(false)}
          header={'Confirmar Exclusão'}
          message={'Você <strong>confirma</strong> excluir essa imagem?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                deletarFoto(caminhoParaExcluir, caminhoIndiceParaExcluir);
                setShowAlertConfirmarExclusaoFoto(false);
              }

            }
          ]}
        />


        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoadingPage}
          onDidDismiss={() => { setShowLoadingPage(false); }}
          spinner='circles'
        />

      </IonContent>
      <IonFooter className="ion-no-border" hidden={(temHoraFim)} >
        <IonToolbar>
          <IonButton size='large' expand="block" onClick={() => setShowActionSheet(true)}>Ações</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );


};

export default ProjetosWorkflow;