import { IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonTitle, IonToast, IonToolbar, isPlatform, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { addCircle, arrowBack, arrowDown, arrowUp, pricetagOutline, printOutline, removeCircle, trash } from 'ionicons/icons';
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { alterarStatusProjeto, empresaCarregar, enviarMesa, mesaGerarCupom, requestService, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import CaixaFechar from '../CaixaFechar/CaixaFechar';
import Desconto from '../Desconto/Desconto';
import ProjetosProdutosComporNovoMemory from '../ProjetosProdutosComporNovoMemory/ProjetosProdutosComporNovoMemory';
import "./CaixaProdutosMemory.css";

type Props = {
  id: any;
  nome: any;
};

const CaixaProdutosMemory: React.FC<Props> = (props: Props) => {

  // Hooks de navegação
  const history = useHistory<any>();
  const location = useLocation<any>();

  // Estados relacionados a loading e feedback visual
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  // Estados de modais e alertas
  const [showCaixaFechar, setShowCaixaFechar] = useState(false);
  const [showModalDesconto, setShowModalDesconto] = useState(false);
  const [mostrarAlerta] = useIonAlert();

  // Estados de controle de exibição
  const [stateShowMenu, setStateShowMenu] = useState(!true);
  const [stateShowCaixa, setStateShowCaixa] = useState(!true);
  const [showHabilitarEdicao, setShowHabilitarEdicao] = useState(false);

  // Estados de dados e listas
  const [listaItemProdutos, setListaItemProdutos] = useState<any>([]);
  const [dadosCaixa, setDadosCaixa] = useState<any>([]);
  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>();
  const [valorTotal, setValorTotal] = useState<any>();

  // Contextos compartilhados
  const { loja, setLoja } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);
  // const [dadosEmpresa, setDadosEmpresa] = useState<any>([]); // Comentei pois já está no context

  // Estados persistidos no localStorage
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));

  // Referências
  const buscarInputRef = useRef<any>(null);
  const contentCatalogoRef = useRef<any>(null);
  const contentRef = createRef<HTMLIonContentElement>();

  // Funções de rolagem
  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollToTop();
    }
    setStateShowMenu(!true);
    setStateShowCaixa(!false);
  };

  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollToBottom();
    }
    setStateShowMenu(!false);
    setStateShowCaixa(!true);
  };

  const scrollToCatalogo = () => {
    if (contentCatalogoRef.current) {
      contentCatalogoRef.current.scrollIntoView();
    }
  };

  let params: any = {
    id: ""
  }
  params = useParams();

  if (location && location.state && location.state.id) {
    params.id = location.state.id;
  }

  if (props.id) {
    params = {
      id: props.id,
    }
  }

  useEffect(() => {
    if (params.id) {
      console.log("entrou no useEffect");
      carregarCaixa();
      carregarEmpresa();
    }
  }, [params.id]);

  useEffect(() => {

    try {
      let taxaServicoFloat = parseFloat(dadosCaixa.taxaServico?.replace(/\./g, '').replace(/\,/g, '.'));
      let descontoFloat = parseFloat(dadosCaixa.desconto?.replace(/\./g, '').replace(/\,/g, '.'));
      let taxaEntregaFloat = parseFloat(dadosCaixa.taxaEntrega?.replace(/\./g, '').replace(/\,/g, '.'));

      let valorTotalRecalculado = 0;
      if (valorTotalProdutos) {
        valorTotalRecalculado = parseFloat(valorTotalProdutos.replace(/\./g, '').replace(/\,/g, '.'));
      }

      if (dadosCaixa.taxaEntrega) {
        valorTotalRecalculado += (taxaEntregaFloat ? taxaEntregaFloat : 0);
      }

      if (dadosCaixa.desconto) {
        valorTotalRecalculado += - (descontoFloat ? descontoFloat : 0);
      }
      if (dadosCaixa.taxaServico) {
        valorTotalRecalculado += (valorTotalRecalculado * (taxaServicoFloat / 100));
      }

      setValorTotal(valorTotalRecalculado.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [valorTotalProdutos]);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
  });

  function carregarEmpresa() {

    const consultar = async () => {

      requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          setDadosEmpresa(response.Item);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function carregarCaixa() {

    const consultar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }
      setShowLoading(true);
      const resp = await fetch(urlBase.url + "/projetos/" + params.id.replaceAll(/#/g, "%23") + "/semassinar",
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        }).finally(() => {
          setShowLoading(false);
        });
      const response = await resp.json();
      if (response.Item !== undefined) {

        setDadosCaixa(response.Item);
        setListaItemProdutos(response.Item.produtos || []);

        setValorTotalProdutos((response.Item.valorTotalProdutos || 0).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));

      }

      if (buscarInputRef.current) {
        buscarInputRef.current.setFocus();
      }
    }

    consultar();
  }

  function deletarItemProdutos(index: number) {
    // Filtra a lista de produtos, removendo o item com o índice fornecido
    const updatedList = listaItemProdutos.filter((_: any, i: any) => i !== index);

    // Atualiza a lista de produtos com o item removido
    setListaItemProdutos(updatedList);

    // Calcula a nova soma dos valores após a remoção
    const totalValor = updatedList.reduce((total: any, item: any) => {
      const itemValorNumerico = parseFloat(
        item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
      return total + itemValorNumerico;
    }, 0);

    // Exemplo: você pode armazenar a nova soma em outro estado ou usar conforme necessário
    console.log("Nova soma dos valores:", totalValor);

    setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));

  }

  async function adicionarItem(produto: any) {

    if (produto) {

      let updatedList = [...listaItemProdutos, produto];

      setListaItemProdutos(updatedList);


      let totalValor = updatedList.reduce((total: any, item: any) => {
        const itemValorNumerico = parseFloat(
          item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
        );
        return total + itemValorNumerico;
      }, 0);

      setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

      setTextToast("Item adicionado com sucesso.");
      setShowToast(true);

    }
    scrollToCatalogo();
  }

  async function salvar(marcarComoEnviado: any = false) {

    return new Promise<void>(async (resolve, reject) => {

      let objRequest = {
        "produtos": listaItemProdutos,
        "enviado": marcarComoEnviado

      };

      requestService(enviarMesa.url.replaceAll("{id}", params.id.replaceAll(/#/g, '%23')), {
        method: enviarMesa.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
        history,
        async (response: any) => {


          if (response.message && response.message.Attributes) {

            setDadosCaixa(response.message.Attributes);
            setListaItemProdutos(response.message.Attributes.produtos || []);

            setValorTotalProdutos((response.message.Attributes.valorTotalProdutos || 0).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));

            resolve(response.message.Attributes);

          }
          else {
            reject(new Error("Erro: Resposta inválida ou incompleta."));
          }
        },
        (error: any) => {
          console.log(error);
          reject(error);  // Promessa rejeitada com erro
        });
    });
  }

  async function alterar(
    formaPagamento: any,
    taxaServico: any,
    taxaEntrega: any,
    desconto: any
  ) {
    return new Promise((resolve, reject) => {
      const objectRequest = {
        formaPagamento: formaPagamento, //formaPagamento,
        // troco: calcularValorTroco(valorTotal, arrayFormaPagamento),
        taxaEntrega: taxaEntrega,
        taxaServico: taxaServico,
        desconto: desconto,
      };

      requestService(
        alterarStatusProjeto.url.replaceAll("{id}", params.id.replaceAll(/#/g, "%23")) + "?alteracao=true",
        {
          method: alterarStatusProjeto.method,
          headers: [["token", localStorage.getItem("token") || ""]],
          credentials: "include",
          body: JSON.stringify(objectRequest),
        },
        history,
        (response: any) => {
          // console.log("response", response);
          setDadosCaixa(response);
          setValorTotal((response.valorTotal || 0).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
          resolve(response); // Resolve a Promise se a alteração for bem-sucedida
        },
        (error: any) => {
          setTextToast(error.message);
          setShowToast(true);
          reject(error); // Rejeita a Promise se houver erro
        }
      );
    });
  }


  function gerarEscPos(projeto: any) {
    // Função para remover acentos e cedilhas
    function removerAcentosECedilha(texto: string): string {
      return texto
        .normalize('NFD')  // Normaliza o texto para decompô-lo em caracteres base e acentos
        .replace(/[\u0300-\u036f]/g, '')  // Remove os acentos
        .replace(/ç/g, 'c')  // Substitui 'ç' por 'c'
        .replace(/Ç/g, 'C');  // Substitui 'Ç' por 'C'
    }

    // Função para aplicar a máscara de CPF/CNPJ
    function aplicarMascaraCPFouCNPJ(texto: any) {
      if (!texto) return '';
      texto = texto.replace(/\D/g, '');
      if (texto.length === 11) {
        return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (texto.length === 14) {
        return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }
      return texto;
    }

    // Função para formatar telefone
    function formataTelefone(numero: any) {
      if (numero?.length == 13) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else if (numero?.length == 12) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else { return "" };
    }

    function quebrarTextoEmLinhas(texto: any, tamanhoMaximo: any) {
      const palavras = texto.split(' ');
      let linhas = [];
      let linhaAtual = '';

      palavras.forEach((palavra: any) => {
        if ((linhaAtual + palavra).length <= tamanhoMaximo) {
          linhaAtual += palavra + ' ';
        } else {
          linhas.push(linhaAtual.trim());
          linhaAtual = palavra + ' ';
        }
      });

      if (linhaAtual.trim()) {
        linhas.push(linhaAtual.trim());
      }

      return linhas;
    }

    // Função para formatar valores monetários
    const formatarValor = (valor: any) => {
      if (!valor) return '0,00';

      // Remove pontos de milhares e substitui a vírgula decimal por ponto
      const valorNumerico = parseFloat(valor.toString().replace(/\./g, '').replace(',', '.'));

      // Formata o número para o padrão brasileiro
      return valorNumerico.toFixed(2).replace('.', ',');
    };

    // Função para gerar o cabeçalho e as informações do cupom
    function gerarCabecalho(projeto: any, empresa: any) {
      let cabecalhoText = "";

      // Informações da empresa
      cabecalhoText += `${empresa?.empresaNome}\n`;
      cabecalhoText += `CNPJ: ${aplicarMascaraCPFouCNPJ(empresa?.empresaCnpj)}\n`;

      // Endereço da empresa
      quebrarTextoEmLinhas(`Endereço: ${empresa.empresaEndereco}`, 35).forEach((linha: any) => {
        cabecalhoText += `${linha}\n`;
      });

      cabecalhoText += `Tel: ${formataTelefone(empresa?.empresaTelefone)}\n`;

      // Data e hora do cupom, se disponível
      if (projeto.dataCupom) {
        cabecalhoText += `Data: ${new Date(projeto.dataCupom).toLocaleDateString('pt-BR')} Hora: ${new Date(projeto.dataCupom).toLocaleTimeString('pt-BR')}\n`;
      }

      cabecalhoText += "**************************************\n";

      // Informações do cliente
      cabecalhoText += `${projeto.clienteNome}\n`;

      // Se o projeto não for interno, exibe informações adicionais do cliente
      if (!projeto.interno) {
        cabecalhoText += `Tel: ${formataTelefone(projeto.clienteTelefone)}\n`;

        // Endereço do cliente, se disponível
        quebrarTextoEmLinhas(`Endereço: ${[projeto.endereco, projeto.complemento].filter(Boolean).join(', ') || ""}`, 35).forEach((linha: any) => {
          cabecalhoText += `${linha}\n`;
        });
      }

      cabecalhoText += "**************************************\n";
      cabecalhoText += "CUPOM NÃO FISCAL\n";

      // Se o projeto não for interno, exibe o nome do projeto
      if (!projeto.interno) {
        cabecalhoText += "======================================\n";
        cabecalhoText += `${empresa?.servicoTipo || "Projeto"}: ${projeto.nomeProjeto}\n`;
        cabecalhoText += "======================================\n";
      }

      return cabecalhoText;
    }

    // Função para gerar o corpo do cupom (produtos)
    function gerarProdutos(projeto: any) {
      let produtosText = "";

      produtosText += "QTDE | DESCRIÇÃO\n";
      produtosText += "--------------------------------------\n";

      if (projeto.produtos && Array.isArray(projeto.produtos) && projeto.produtos.length > 0) {
        projeto.produtos.forEach((produto: any) => {
          // Verifica se o produto possui uma descrição complexa
          if (Array.isArray(produto.descricao)) {
            produto.descricao.forEach((itemDesc: any, indexLinha: number) => {
              // Exibe a linha principal do produto apenas uma vez
              if (indexLinha === 0) {
                produtosText += `${produto.quantidade} | ${produto.tipo === "pizzas" ? `${produto.tamanho} ${produto.quantidadeSabores} SABORES (R$ ${formatarValor(produto.valor)})` : `${produto.categoria} (R$ ${formatarValor(produto.valor)})`}\n`;
              }

              // Exibe as descrições adicionais, se houver
              if (itemDesc.descricao) {
                produtosText += `  - ${itemDesc.descricao} (R$ ${formatarValor(itemDesc.valor)})\n`;
              }
            });
          } else {
            // Para produtos com uma única descrição simples
            produtosText += `${produto.quantidade} | ${produto.descricao} (R$ ${formatarValor(produto.valor)})\n`;
          }

          // Adiciona o subtotal de cada produto
          produtosText += `VALOR: R$ ${formatarValor(produto.subtotal)}\n`;
          produtosText += "--------------------------------------\n";
        });

        // Adiciona o total de todos os produtos
        if (projeto.valorTotalProdutos) {
          produtosText += `TOTAL: R$ ${formatarValor(projeto.valorTotalProdutos)}\n`;
        }
      }

      return produtosText;
    }

    // Função para gerar o footer com as informações de pagamento
    function gerarFooter(projeto: any, empresa: any) {
      let pagamentoText = "";

      // Forma de pagamento
      if (projeto.formaPagamento) {
        if (!Array.isArray(projeto.formaPagamento)) {
          pagamentoText += `**************************************\n`;
          pagamentoText += `FORMA PAGAMENTO: ${projeto.formaPagamento?.toUpperCase()}\n`;
        }
      }

      // Troco, se houver
      if (projeto.troco && projeto.troco !== "0,00") {
        pagamentoText += `TROCO: R$ ${formatarValor(projeto.troco)}\n`;
      }

      // Taxa de entrega, se houver
      if (projeto.taxaEntrega) {
        pagamentoText += `TAXA DE ENTREGA: +R$ ${formatarValor(projeto.taxaEntrega)}\n`;
      }

      // Desconto, se houver
      if (projeto.desconto) {
        pagamentoText += `DESCONTO: -R$ ${formatarValor(projeto.desconto)}\n`;
      }

      // Valor total, se houver
      if (projeto.valorTotal) {
        pagamentoText += `**************************************\n`;
        pagamentoText += `TOTAL ${empresa.servicoTipo?.toUpperCase() || "PROJETO"}: R$ ${formatarValor(projeto.valorTotal)}\n`;
      }

      return pagamentoText;
    }

    try {


      console.log("dadosImpressao1");
      // Montando o cupom com os dados no formato ESC/POS
      const cabecalho = gerarCabecalho(projeto, dadosEmpresa);

      console.log("cabecalho", cabecalho);
      const produtos = gerarProdutos(projeto);
      console.log("produtos", produtos);
      const rodape = gerarFooter(projeto, dadosEmpresa);
      console.log("rodape", rodape);
      // Comandos ESC/POS básicos

      const ESC = 0x1B;  // ESC
      const LF = 0x0A;   // Line Feed
      const CORTA_PAPEL = [ESC, 0x69];   // Corta Papel
      const NEGRITO_ON = [ESC, 0x45, 0x01];  // Ativar negrito
      const NEGRITO_OFF = [ESC, 0x45, 0x00]; // Desativar negrito
      const ALIMENTAR_PAPEL = [ESC, 0x64, 0x10]; // Alimentação de papel


      const dadosImpressao: any = [
        // ...ALIMENTAR_PAPEL, // Alimentação de papel
        LF,
        ...NEGRITO_ON,
        ...removerAcentosECedilha(cabecalho).split("").map(c => c.charCodeAt(0)),
        ...NEGRITO_OFF, // Desativar negrito
        LF, // Pular linha
        ...removerAcentosECedilha(produtos).split("").map(c => c.charCodeAt(0)),
        LF, // Pular linha
        ...removerAcentosECedilha(rodape).split("").map(c => c.charCodeAt(0)),
        LF, LF, LF, LF, LF,
        // ...ALIMENTAR_PAPEL, // Alimentação de papel
        ...CORTA_PAPEL

      ];

      // Retorna os dados como Uint8Array
      return new Uint8Array(dadosImpressao);
    } catch (error) {
      console.error('Erro ao imprimir cupom:', error);
      return null; // Retorna null em caso de erro
    }
  }

  async function imprimir(projeto: any) {

    // gerarEscPos(projeto || dadosCaixa);
    // return;

    if (dadosEmpresa.impressoraRedeHabilitar && (!isPlatform('mobileweb') && isPlatform('mobile'))) {

      const ipImpressora = dadosEmpresa.impressoraRedeIp;
      const portaImpressora = dadosEmpresa.impressoraRedePorta;

      const conectarEEnviar = () => {
        // Cria o socket TCP
        window.chrome.sockets.tcp.create({}, (createInfo) => {
          const socketId = createInfo.socketId;

          // Define o timeout para mostrar a mensagem de erro após 3 segundos
          const timeoutId = setTimeout(() => {
            setTextToast("Erro ao conectar.");
            setShowToast(true);
            window.chrome.sockets.tcp.close(socketId);
          }, 3000); // 3 segundos

          // Tenta conectar ao IP e porta especificados
          window.chrome.sockets.tcp.connect(socketId, ipImpressora, portaImpressora, (result) => {
            if (result === 0) {
              // Conexão bem-sucedida, cancela o timeout
              clearTimeout(timeoutId);

              console.log('Conexão bem-sucedida! Enviando dados...');

              const comandosEscPos = gerarEscPos(projeto || dadosCaixa);
              if (comandosEscPos) {
                window.chrome.sockets.tcp.send(socketId, comandosEscPos.buffer, (sendInfo) => {
                  console.log('Comando enviado com sucesso:', sendInfo);
                  window.chrome.sockets.tcp.close(socketId);
                });
              } else {
                console.error('Erro ao gerar ESC/POS');
              }
            } else {
              // Conexão falhou, cancela o timeout e mostra mensagem de erro
              clearTimeout(timeoutId);
              console.error(`Erro ao conectar à impressora. Verifique a conexão.`);
              setTextToast("Erro ao conectar.");
              setShowToast(true);
              window.chrome.sockets.tcp.close(socketId);
            }
          });
        });
      };


      // Chame a função com o URL do HTML que você deseja imprimir
      conectarEEnviar();


    }
    else {

      return new Promise<void>((resolve, reject) => {
        gerarCupomMesa(params.id)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }


  // Função para converter HTML para ESC/POS
  function htmlToEscPos(htmlContent: any) {

    const encodedText: any = new TextEncoder().encode(htmlContent); // Converte a string para um Uint8Array (comandos binários)

    const escPosCommands = [];
    // Adiciona comandos de formatação antes e depois do conteúdo HTML
    escPosCommands.push(0x1b, 0x40); // Reset
    escPosCommands.push(0x1b, 0x61, 0x01); // Alinha ao centro

    // Exemplo simples de como lidar com tags HTML
    htmlContent = htmlContent.replace(/<b>(.*?)<\/b>/g, (match: any, p1: any) => {
      escPosCommands.push(0x1b, 0x45, 0x01); // Negrito
      return p1 + String.fromCharCode(0x1b, 0x45, 0x00); // Remove negrito
    });

    // Codifica o texto final para um Uint8Array
    // const encodedText = new TextEncoder().encode(htmlContent);
    escPosCommands.push(...encodedText);

    // Adiciona outros comandos, como alimentação de linha e corte de papel
    escPosCommands.push(0x1b, 0x64, 0x02); // Alimentação de linha
    escPosCommands.push(0x1b, 0x64, 0x02); // Alimentação de linha
    escPosCommands.push(0x1b, 0x64, 0x02); // Alimentação de linha
    escPosCommands.push(0x1b, 0x64, 0x02); // Alimentação de linha
    escPosCommands.push(0x1b, 0x64, 0x02); // Alimentação de linha
    escPosCommands.push(0x1b, 0x69); // Corte de papel

    return new Uint8Array(escPosCommands);
  }



  async function gerarCupomMesa(projetoid: any) {
    return new Promise(async (resolve, reject) => {
      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof tkn === "string") {
        token = tkn;
      }
      console.log("projetoid", projetoid);

      let montaUrl = mesaGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23"));

      try {
        const resp = await fetch(montaUrl, {
          method: mesaGerarCupom.method,
          headers: [["token", token]],
          credentials: "include",
        });

        const result = await resp.json();
        if (result) {


          // console.log("result", result);

          mostrarAlerta({
            backdropDismiss: false,
            header: "Gerar Cupom",
            message: "<strong>Cupom</strong> foi gerado com sucesso.",
            buttons: [
              {
                text: "Cancelar",
                role: "cancel",
              },
              {
                text: "Visualizar",
                cssClass: "primary",
                handler: () => {
                  window.open(result, "_blank");
                },
              },
            ],
            onDidDismiss: (e) => console.log("did dismiss"),
          });
          resolve(result); // Resolve a Promise se a impressão for bem-sucedida
        }
      } catch (error: any) {
        setTextToast(error.message);
        setShowToast(true);
        reject(error); // Rejeita a Promise em caso de erro
      }
    });
  }

  async function closeModalCaixaFechar(formaPagamento: any) {
    setShowCaixaFechar(false);
    await alterar(formaPagamento, dadosCaixa.taxaServico, dadosCaixa.taxaEntrega, dadosCaixa.desconto);
  }

  async function closeModalCaixaFecharSucess() {
    setShowCaixaFechar(false);
    history.goBack();
  }

  async function closeModalDesconto() {
    setShowModalDesconto(false);
  }

  async function closeModalDescontoSucess(desconto: any) {
    setShowModalDesconto(false);
    await alterar(dadosCaixa.formaPagamento, dadosCaixa.taxaServico, dadosCaixa.taxaEntrega, desconto);
  }

  const loadItemProdutos = () => {

    if (listaItemProdutos == undefined) return;

    if (listaItemProdutos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }

    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonGrid>

          {listaItemProdutos.map((value: any, index: any) => (
            <>


              {value.enviado ? (
                <div style={{ position: 'relative' }}>
                  <div style={{ position: 'absolute', top: '4px', right: '0px', textAlign: 'right' }}>
                    <IonChip

                      style={{
                        height: "20px",
                        backgroundColor: 'var(--ion-color-medium)',
                        color: '#fff',
                        fontWeight: 'bold',

                      }}
                    >
                      ENVIADO
                    </IonChip>
                  </div>
                </div>
              ) :
                value.salvo ? (
                  <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '4px', right: '0px', textAlign: 'right' }}>
                      <IonChip

                        style={{
                          height: "20px",
                          backgroundColor: 'var(--ion-color-primary)',
                          color: '#fff',
                          fontWeight: 'bold',

                        }}
                      >
                        SALVO
                      </IonChip>
                    </div>
                  </div>
                ) : (
                  <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '4px', right: '0px', textAlign: 'right' }}>
                      <IonChip

                        style={{
                          height: "20px",
                          backgroundColor: 'var(--ion-color-success)',
                          color: '#fff',
                          fontWeight: 'bold',

                        }}
                      >
                        NOVO
                      </IonChip>
                    </div>
                  </div>
                )

              }

              <IonRow style={{ borderColor: '#dddddd', borderWidth: '1px', borderBottomStyle: 'dashed' }}>


                <IonCol size="9">



                  {(value.descricao && Array.isArray(value.descricao)) ? (

                    <>
                      {(value.tipo === "pizzas") ? (
                        <div>{value.tamanho} {value.quantidadeSabores} SABORES</div>
                      ) : (
                        <div>{value.categoria}</div>
                      )}
                      {value.descricao.map((item: any, index: number) => (
                        <div key={"desc" + index}>
                          - {item.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {item.valor?.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</IonLabel>)
                        </div>
                      ))}
                    </>

                  ) : (
                    <div key={"desc" + index}>
                      {value.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {value.valor?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</IonLabel>)

                    </div>
                  )}



                </IonCol>


                <IonCol style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} sizeXs="3" >
                  <IonLabel style={{ textAlign: "right" }}>{value.subtotal}</IonLabel>
                </IonCol>
                <IonCol sizeSm='6'>
                  <IonLabel style={{ textAlign: "right", fontSize: "12px" }} >
                    <IonChip style={{ margin: 0, padding: '5px' }} disabled={!showHabilitarEdicao && value.enviado}>
                      <IonIcon size='large' color='danger' icon={removeCircle} onClick={() => {

                        // Faz uma cópia da lista atual de produtos
                        let updatedList = [...listaItemProdutos];

                        // Acessa o item que será atualizado
                        let itemToUpdate = { ...updatedList[index] };

                        // Formata a quantidade atual
                        const quantidadeFormatada = parseFloat(
                          itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        if (quantidadeFormatada > 1) {
                          // Decrementa a quantidade
                          let quantidadeNova = quantidadeFormatada - 1;

                          // Formata o valor e calcula o novo valor com base na nova quantidade
                          const valorFormatado = parseFloat(
                            itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );

                          // Atualiza a quantidade e o valor do item
                          itemToUpdate.quantidade = quantidadeNova;
                          const valorFloat = quantidadeNova * valorFormatado;

                          itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          });

                          // Substitui o item atualizado na lista copiada
                          updatedList[index] = itemToUpdate;

                          let totalValor = updatedList.reduce((total: any, item: any) => {
                            const itemValorNumerico = parseFloat(
                              item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                            );
                            return total + itemValorNumerico;
                          }, 0);

                          setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }));

                          // Atualiza o estado com a nova lista
                          setListaItemProdutos(updatedList);

                        }

                      }}></IonIcon>
                      <IonLabel style={{ fontSize: '20px' }}>{value.quantidade.toLocaleString('pt-br', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}</IonLabel>
                      <IonIcon size='large' color='success' icon={addCircle} onClick={() => {

                        // Faz uma cópia da lista atual de produtos
                        let updatedList = [...listaItemProdutos];

                        // Acessa o item que será atualizado
                        let itemToUpdate = { ...updatedList[index] };

                        // Formata e incrementa a quantidade
                        const quantidadeFormatada = parseFloat(
                          itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        let quantidadeNova = quantidadeFormatada + 1;

                        // Formata o valor e calcula o novo valor com base na nova quantidade
                        const valorFormatado = parseFloat(
                          itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        // Atualiza a quantidade e o valor do item
                        itemToUpdate.quantidade = quantidadeNova;
                        const valorFloat = quantidadeNova * valorFormatado;

                        itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        });

                        // Substitui o item atualizado na lista copiada
                        updatedList[index] = itemToUpdate;

                        let totalValor = updatedList.reduce((total: any, item: any) => {
                          const itemValorNumerico = parseFloat(
                            item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );
                          return total + itemValorNumerico;
                        }, 0);

                        setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));

                        // Atualiza o estado com a nova lista
                        setListaItemProdutos(updatedList);


                      }}></IonIcon>
                    </IonChip>
                  </IonLabel>
                </IonCol>
                <IonCol style={{ display: "flex", justifyContent: "flex-end" }} sizeSm='6'>
                  <IonButtons>
                    <IonButton disabled={!showHabilitarEdicao && value.enviado}
                      onClick={() => {
                        mostrarAlerta({
                          backdropDismiss: false,
                          header: 'Remover item',
                          message: 'Deseja <strong>remover</strong>?',
                          buttons: [
                            {
                              text: 'Cancelar',
                              role: 'cancel',
                            },
                            {
                              text: 'Confirmar',
                              cssClass: 'primary',
                              handler: () => {
                                deletarItemProdutos(index);
                              }
                            }
                          ],
                          onDidDismiss: (e) => console.log('did dismiss'),
                        });
                      }}
                    >
                      <IonIcon color="primary" icon={trash} />
                    </IonButton>
                  </IonButtons>
                </IonCol>

              </IonRow>
            </>
          ))}
        </IonGrid>
      </>
    );
  }

  return (

    <IonPage className="CaixaProdutosMemory">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {



              if (listaItemProdutos.filter((produto: any) => (!produto.salvo)).length > 0) {
                mostrarAlerta({
                  backdropDismiss: false,
                  header: 'Descartar novos itens',
                  message: "Itens novos adicionados. Deseja descartar?",

                  buttons: [{
                    text: 'Cancelar',
                    role: 'cancel'
                  },
                  {
                    text: 'Confirmar',
                    handler: async () => {
                      history.goBack();
                    }
                  }],
                  onDidDismiss: () => { console.log('did dismiss'); }
                })

              }
              else { history.goBack(); }

            }
            } />
          </IonButtons>
          <IonTitle>{dadosCaixa?.clienteNome}</IonTitle>
        </IonToolbar>




      </IonHeader>

      <IonContent ref={contentRef} fullscreen={false}>
        <IonGrid style={{ height: '100%', padding: "0px", margin: "0px" }} ref={contentCatalogoRef}>
          <IonRow style={{ height: '100%' }}>

            <IonCol hidden={stateShowMenu} style={{ height: '100%', padding: "0px", margin: "0px" }} sizeMd="8" size="12">
              <div style={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
                <IonFab vertical='bottom' horizontal='end' style={{ "bottom": "20px", "right": "20px" }} className='visible-mobile'>
                  <IonFabButton onClick={() => scrollToBottom()} color='tertiary'>
                    <IonIcon icon={arrowDown}></IonIcon>
                  </IonFabButton>
                </IonFab>
                <ProjetosProdutosComporNovoMemory
                  empresa={dadosEmpresa}
                  disabled={false}
                  adicionarItem={adicionarItem}
                />
              </div>
            </IonCol>


            <IonCol hidden={stateShowCaixa} style={{ height: '100%', padding: "0px", margin: "0px" }} sizeMd="4" size="12">
              <div style={{ height: '100%', padding: '10px' }}>
                <IonFab vertical='top' horizontal='end' style={{ "top": "20px", "right": "20px" }} className='visible-mobile'>
                  <IonFabButton onClick={() => scrollToTop()} color='tertiary'>
                    <IonIcon icon={arrowUp}></IonIcon>
                  </IonFabButton>
                </IonFab>
                <IonCard style={{ margin: 0, height: '100%' }}>
                  <IonCardContent style={{ height: '100%' }}>

                    <IonGrid style={{ padding: "0px", margin: "0px" }}>

                      <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12"><IonLabel><b>Lista de itens</b></IonLabel></IonCol>
                      </IonRow>

                    </IonGrid>

                    <div style={{ height: '100%', position: 'relative', overflow: 'auto' }}>
                      <div style={{
                        height: 'calc(100% - 240px)',
                        overflow: 'auto'
                      }}>
                        {loadItemProdutos()}
                      </div>
                    </div>
                    <div style={{ position: 'absolute', bottom: "10px", left: 0, right: 0, padding: '0 10px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                        {/* Resumo subtotal, desconto, taxa de serviço, total geral */}
                        <IonGrid style={{ padding: "4px", margin: "4px" }}>

                          <IonRow style={{ fontSize: '16px' }}>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="5"><IonLabel><b>Subtotal</b></IonLabel></IonCol>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="7"><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotalProdutos ? "R$ " + valorTotalProdutos : "R$ 0,00"}</b></IonLabel></IonCol>

                          </IonRow>

                          <IonRow style={{ fontSize: '16px' }}>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="7"><IonLabel>Desconto</IonLabel></IonCol>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="5"><IonLabel style={{ display: 'block', textAlign: "right" }}>{dadosCaixa.desconto ? "R$ " + dadosCaixa.desconto : "R$ 0,00"}</IonLabel></IonCol>

                          </IonRow>

                          {/* <IonRow style={{ fontSize: '16px' }}>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="7"><IonLabel>Taxa de serviço</IonLabel></IonCol>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="5"><IonLabel style={{ display: 'block', textAlign: "right" }}>{(dadosCaixa.taxaServico || 0) + "%"}</IonLabel></IonCol>

                          </IonRow> */}

                          <IonRow style={{ fontSize: '20px' }}>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="5"><IonLabel><b>Total</b></IonLabel></IonCol>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="7"><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotal ? "R$ " + valorTotal : "R$ 0,00"}</b></IonLabel></IonCol>

                          </IonRow>

                        </IonGrid>

                        <IonGrid style={{ padding: "0px", margin: "0px" }} hidden={showHabilitarEdicao}>
                          <IonRow style={{ fontSize: '20px' }}>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="6">
                              <IonButton color='success'
                                style={{ height: "46px", fontSize: "18px" }}
                                onClick={async () => {
                                  setShowLoading(true);

                                  salvar()
                                    .then((response) => {
                                      console.log("Alteração bem-sucedida", response);
                                      return imprimir(response); // Aguarda a execução de imprimir após alterar
                                    })
                                    .then(() => {
                                      console.log("Impressão bem-sucedida");
                                    })
                                    .catch((error) => {
                                      console.log("Erro:", error.message);
                                      setTextToast(error.message);
                                      setShowToast(true);
                                    })
                                    .finally(() => {
                                      setShowLoading(false);
                                    });
                                }}
                                expand="block"
                                type="submit"
                              >
                                <IonIcon icon={printOutline}></IonIcon>
                              </IonButton>
                            </IonCol>

                            <IonCol style={{ padding: "0px", margin: "0px" }} size="6">
                              <IonButton color='primary'
                                style={{ height: "46px", fontSize: "18px", fontWeight: 'bold' }}
                                onClick={async () => {
                                  setShowLoading(true);

                                  salvar()
                                    .then((response) => {
                                      console.log("Alteração bem-sucedida", response);
                                      setShowModalDesconto(true);
                                    })
                                    .catch((error) => {
                                      console.log("Erro:", error.message);
                                      setTextToast(error.message);
                                      setShowToast(true);
                                    })
                                    .finally(() => {
                                      setShowLoading(false);
                                    });
                                }}
                                expand="block"
                                type="submit"
                              >
                                <IonIcon icon={pricetagOutline}></IonIcon>
                              </IonButton>
                            </IonCol>

                          </IonRow>
                        </IonGrid>

                        <IonButton hidden={!showHabilitarEdicao}
                          style={{ height: "46px", fontSize: "18px" }}
                          onClick={async () => {

                            await salvar();
                            setShowHabilitarEdicao(false);
                          }}
                          expand="block"
                          type="submit"
                        >
                          Salvar
                        </IonButton>

                        <IonButton hidden={showHabilitarEdicao}
                          style={{ height: "46px", fontSize: "18px" }}
                          onClick={async () => {
                            setShowLoading(true);

                            salvar()
                              .then((response) => {
                                console.log("Alteração bem-sucedida", response);
                                setShowCaixaFechar(true);
                              })
                              .catch((error) => {
                                console.log("Erro:", error.message);
                                setTextToast(error.message);
                                setShowToast(true);
                              })
                              .finally(() => {
                                setShowLoading(false);
                              });



                          }}
                          expand="block"
                          type="submit"
                        >
                          Fechar {(window.localStorage.getItem("nomeInternonomeInternoBalcaoOuCaixa") || "Balcão")}
                        </IonButton>
                      </div>
                    </div>


                  </IonCardContent>
                </IonCard>
              </div>
            </IonCol>

          </IonRow>
        </IonGrid>

        <IonModal isOpen={showCaixaFechar} onDidDismiss={() => { setShowCaixaFechar(false); }}>
          <CaixaFechar projetoid={params.id} imprimir={imprimir} closeAction={closeModalCaixaFecharSucess} closeActionSimple={closeModalCaixaFechar}></CaixaFechar>
        </IonModal>

        <IonModal isOpen={showModalDesconto} onDidDismiss={() => { closeModalDesconto(); }}>
          <Desconto projetoid={params.id} closeAction={closeModalDescontoSucess} closeActionSimple={closeModalDesconto}></Desconto>
        </IonModal>


        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
    </IonPage >
  );


};

export default CaixaProdutosMemory;