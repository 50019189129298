import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonRadio, IonRadioGroup, IonRow, IonSegment, IonSegmentButton, IonTitle, IonToast, IonToolbar, useIonAlert } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { alterarProjetosItemProdutosEmpresa, inserirProjetosItemProdutosEmpresa, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import "./ProjetosProdutosComporNovo.css";


type MyModalProps = {
  idprojeto: any;
  value: any;
  empresa: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const ProjetosProdutosComporNovo: React.FC<MyModalProps> = (props: MyModalProps) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [valor, setValor] = useState<any>();
  const [subtotal, setSubtotal] = useState<any>();
  const [itemSelecionadoMassa, setItemSelecionadoMassa] = useState<any>();
  const [itemSelecionadoBorda, setItemSelecionadoBorda] = useState<any>();
  const [updatedItensSelecionadosComplementos, setUpdatedItensSelecionadosComplementos] = useState<any>([]);
  const [grupoComplementos, setGrupoComplementos] = useState<any>();
  // const [props.empresa, setDadosEmpresa] = useState<any>();
  const [observacao, setObservacao] = useState("");
  const [selectedCategoria, setSelectedCategoria] = useState<any>();
  const [selectedSize, setSelectedSize] = useState<any>();
  const [selectedCount, setSelectedCount] = useState<any>();
  const [selectedMassa, setSelectedMassa] = useState<any>();
  const [selectedBorda, setSelectedBorda] = useState<any>();
  const [selectedCategoriaObject, setSelectedCategoriaObject] = useState<any>();
  const [selectedSizeObject, setSelectedSizeObject] = useState<any>();
  const [etapa, setEtapa] = useState("1");
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [isItemValid, setIsItemValid] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const [mostartAlerta] = useIonAlert();
  const [buscarCompor, setBuscarCompor] = useState("");
  const [listaItemCatalogoProdutosCompor, setListaItemCatalogoProdutosCompor] = useState<any>([]);
  const [filteredItemsCompor, setFilteredItemsCompor] = useState<any[]>([]);
  const [listaComporItemsSelecionados, setListaComporItemsSelecionados] = useState<any>([]);
  // const inputRefSearchCompor2 = useRef<any>(null);
  const inputRefbottom = useRef<any>(null);
  const [selectedProdutos, setSelectedProdutos] = useState<string[]>([]);
  const [showModalSelecionarProduto, setShowModalSelecionarProduto] = useState(false);


  // useEffect(() => {
  //   console.log("entrou no useEffect");
  //   if (!props.empresa) {
  //     setDadosEmpresa(props.empresa);
  //   }
  // }, [props.empresa]);

  // useEffect(() => {
  //   console.log("entrou no useEffect");
  //   if (!props.value) {
  //     carregarEmpresa();
  //   } else {
  //     console.log("*********props.value", props.value);
  //     carregarEmpresaAlterar();
  //   }
  // }, [props.value]);

  // function carregarEmpresa() {

  //   const consultar = async () => {

  //     requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
  //       method: empresaCarregar.method,
  //       headers: [
  //         ["token", window.localStorage.getItem("token")]
  //       ],
  //       credentials: 'include'
  //     },
  //       history,
  //       (response: any) => {
  //         setDadosEmpresa(response.Item);
  //       },
  //       (error: any) => {
  //         console.log(error);
  //         history.replace("/login/");
  //       });

  //   }

  //   consultar();
  // }


  // function carregarEmpresaAlterar() {

  //   const consultar = async () => {

  //     requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
  //       method: empresaCarregar.method,
  //       headers: [
  //         ["token", window.localStorage.getItem("token")]
  //       ],
  //       credentials: 'include'
  //     },
  //       history,
  //       (response: any) => {
  //         setDadosEmpresa(response.Item);
  //         setSelectedCategoria(props.value.categoria);

  //         const categoriaObject = response.Item.categoriaProdutos.find((valor: any) => valor.nome === props.value.categoria);
  //         setSelectedCategoriaObject(categoriaObject);

  //         setSelectedSize(props.value.tamanho);
  //         setSelectedSizeObject(categoriaObject.tamanhos.find((valor: any) => valor.name === props.value.tamanho));

  //         setSelectedCount(props.value.quantidadeSabores);

  //         let itensFiltrados: any[] = [];
  //         props.value.descricao.map((item: any, index: any) => {
  //           if (item.categoria == "Massa") {
  //             setSelectedMassa(item.descricao);
  //           }
  //           else if (item.categoria == "Borda") {
  //             setSelectedBorda(item.descricao);
  //           }
  //           else if (item.categoria == "Observação") {
  //             setObservacao(item.descricao);
  //           }
  //           else {
  //             itensFiltrados.push(item);
  //           }

  //         })

  //         setListaComporItemsSelecionados(itensFiltrados);

  //         setValor(props.value.valor.toLocaleString('pt-br', {
  //           minimumFractionDigits: 2,
  //           maximumFractionDigits: 2
  //         }));

  //         setSubtotal(props.value.subtotal);




  //       },
  //       (error: any) => {
  //         console.log(error);
  //         history.replace("/login/");
  //       });

  //   }

  //   consultar();
  // }

  // const loadItemProdutosCompor = () => {

  //   if (listaComporItemsSelecionados == undefined) return;

  //   if (listaComporItemsSelecionados.length === 0) {
  //     return (
  //       <>
  //         <IonItem lines="none">

  //           <IonLabel>
  //             <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
  //           </IonLabel>

  //         </IonItem>
  //       </>

  //     );
  //   }


  //   return (
  //     <>
  //       {listaComporItemsSelecionados.map((value: any, index: any) => (
  //         <IonItem lines="none" key={"itemlista" + value.descricao + index.toString()}>

  //           <div style={{ flex: 1, width: "100px" }} onClick={() => {
  //             setTextToast(value.categoria + ' - ' + value.descricao);
  //             setShowToast(true);
  //           }}>
  //             <IonLabel style={{ fontSize: "12px" }}>
  //               {value.categoria}
  //             </IonLabel>

  //             <IonLabel style={{ fontSize: "12px" }}>
  //               {value.descricao}
  //             </IonLabel>
  //           </div>
  //           <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "right", fontSize: "12px" }}>{value.valor.toLocaleString('pt-br', {
  //             minimumFractionDigits: 2,
  //             maximumFractionDigits: 2
  //           })}</IonLabel>
  //           <IonLabel >  </IonLabel>

  //           <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
  //             <IonButtons >

  //               <IonButton hidden={acessos?.projetos != 'edicao'}
  //                 onClick={() => {
  //                   mostartAlerta({
  //                     backdropDismiss: false,
  //                     header: 'Remover item',
  //                     message: 'Deseja <strong>remover</strong>?',
  //                     buttons: [
  //                       {
  //                         text: 'Cancelar',
  //                         role: 'cancel',
  //                       },
  //                       {
  //                         text: 'Confirmar',
  //                         cssClass: 'primary',
  //                         handler: () => {
  //                           deletarItemCompor(index);
  //                         }
  //                       }
  //                     ],
  //                     onDidDismiss: (e) => console.log('did dismiss'),
  //                   })
  //                 }}>
  //                 <IonIcon color="primary" icon={trash} />
  //               </IonButton>

  //             </IonButtons>

  //           </div>
  //         </IonItem>

  //       ))}
  //     </>
  //   );
  // }

  async function deletarItemCompor(index: any) {
    const updatedList = listaComporItemsSelecionados.filter((_: any, i: any) => i !== index);
    setListaComporItemsSelecionados(updatedList);

    const valorFinal = calcularValorTotal(
      updatedItensSelecionadosComplementos,
      updatedList,
      itemSelecionadoMassa,
      itemSelecionadoBorda
    );

    setSubtotal(valorFinal);
    setValor(valorFinal);
  }

  function cadastrarComporProduto() {

    if (selectedCategoriaObject.tipo == "pizzas") {
      if (selectedCount == 1 && listaComporItemsSelecionados.length != 1) {
        setTextToast("Selecione uma pizza inteira.");
        setShowToast(true);
        return false;
      }
      else if (selectedCount == 2 && listaComporItemsSelecionados.length != 2) {
        setTextToast("Selecione duas partes para compor a pizza.");
        setShowToast(true);
        return false;
      }
      else if (selectedCount == 3 && listaComporItemsSelecionados.length != 3) {
        setTextToast("Selecione três partes para compor a pizza.");
        setShowToast(true);
        return false;
      }
    }
    else {
      if (listaComporItemsSelecionados.length != 1) {
        setTextToast("Selecione somente um item para avançar.");
        setShowToast(true);
        return false;
      }
    }

    let isValid = true;
    let errorMessage = '';

    if (grupoComplementos) {
      grupoComplementos.forEach((grupo: any) => {
        if (grupo.obrigatoriedade === "obrigatorio" && !grupo.selectedItem) {
          isValid = false;
          errorMessage += `Selecione um item para o complemento ${grupo.name}.<br>`;
        }
      });


      if (!isValid) {
        setTextToast(errorMessage);
        setShowToast(true);
        return false;
      }
    }

    cadastrarDireto();
  }

  function cadastrarDireto() {

    const listaComporItemsSelecionadosComComplementos = [
      ...listaComporItemsSelecionados,
      ...updatedItensSelecionadosComplementos
    ];

    if (itemSelecionadoBorda) {
      listaComporItemsSelecionadosComComplementos.push(itemSelecionadoBorda);
    }

    if (itemSelecionadoMassa) {
      listaComporItemsSelecionadosComComplementos.push(itemSelecionadoMassa);
    }

    if (observacao) {
      const novoItem = {
        descricao: observacao,
        valor: "0,00",
        categoria: "Observação",
      };
      listaComporItemsSelecionadosComComplementos.push(novoItem);
    }

    console.log("indice", props.value.indice);

    if (props.value.indice) {

      const objectRequest = {
        tipo: selectedCategoriaObject?.tipo,
        descricao: listaComporItemsSelecionadosComComplementos,
        categoria: selectedCategoria,
        tamanho: selectedSize,
        quantidadeSabores: selectedCount,
        quantidade: "1",
        largura: "",
        comprimento: "",
        unidadeMedida: "unid",
        valor: valor,
        desconto: "0",
        subtotal: subtotal,
        indice: props.value.indice
      }
      // console.log("objectRequest", objectRequest);

      setShowLoading(true);
      requestService(
        alterarProjetosItemProdutosEmpresa.url.replace("{id}", props.idprojeto.replaceAll("#", "%23")),
        {
          method: alterarProjetosItemProdutosEmpresa.method,
          body: JSON.stringify(objectRequest),
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
        history,
        (response: any) => {
          // console.log(response);
          if (response && response.message) {
            props.closeAction(response.message);

            setTextToast("Cadastro realizado com sucesso.");
            setShowToast(true);
          } else if (response.status === 400) {
            console.log(response);
            setTextToast("Ocorreu um erro ao efetuar o cadastro.");
            setShowToast(true);

          }
        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);
        })
        .finally(() => {
          setShowLoading(false);

        });
    }
    else {
      const objectRequest = {
        tipo: selectedCategoriaObject?.tipo,
        descricao: listaComporItemsSelecionadosComComplementos,
        categoria: selectedCategoria,
        tamanho: selectedSize,
        quantidadeSabores: selectedCount,
        quantidade: "1",
        largura: "",
        comprimento: "",
        unidadeMedida: "unid",
        valor: valor,
        desconto: "0",
        subtotal: subtotal,
      }
      // console.log("objectRequest", objectRequest);

      setShowLoading(true);
      requestService(
        inserirProjetosItemProdutosEmpresa.url.replace("{id}", props.idprojeto.replaceAll("#", "%23")),
        {
          method: inserirProjetosItemProdutosEmpresa.method,
          body: JSON.stringify(objectRequest),
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
        history,
        (response: any) => {
          // console.log(response);
          if (response && response.message) {
            props.closeAction(response.message);

            setTextToast("Cadastro realizado com sucesso.");
            setShowToast(true);
          } else if (response.status === 400) {
            console.log(response);
            setTextToast("Ocorreu um erro ao efetuar o cadastro.");
            setShowToast(true);

          }
        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);
        })
        .finally(() => {
          setShowLoading(false);

        });
    }



  }

  const addItemsPizzas = (items: any[]) => {
    console.log("selectedSize", selectedSize);

    return items
      .filter(item =>
        item.categoria === selectedCategoria && item.valores && item.valores.some((valor: any) => valor.name === selectedSize)
      )
      .map(item => {
        const selectedValor = item.valores.find((valor: any) => valor.name === selectedSize);
        return {
          ...item,
          nome: (selectedCount == 1 ? `${item.nome}` : `1/${selectedCount} ${item.nome}`),
          // tipo: item.tipo,
          valor: (parseFloat(selectedValor.valor.replace(',', '.')) / selectedCount).toFixed(2).replace('.', ','),
        };
      });
  };

  const addItems = (items: any[]) => {
    console.log("selectedSize", selectedSize);

    return items
      .filter(item =>
        item.categoria === selectedCategoria
      );

  };

  // const handleSearchCompor = (e: any) => {
  //   setBuscarCompor(e.target.value);
  //   const searchTerm = e.target.value?.toLowerCase();
  //   if (searchTerm === "") {
  //     setFilteredItemsCompor([]);
  //     return;
  //   }

  //   const isInteger = /^\d+$/.test(searchTerm);

  //   const filtered = listaItemCatalogoProdutosCompor.filter((item: any) =>
  //     isInteger ? item.codigo === searchTerm : item.codigo.includes(searchTerm) || item.nome.toLowerCase().includes(searchTerm)
  //   );
  //   setFilteredItemsCompor(filtered);
  // };

  const handleSelectItemCompor = (item: any) => {


    if (selectedCategoriaObject.tipo == "pizzas") {
      if (selectedCount == 1 && listaComporItemsSelecionados.length >= 1) {
        setTextToast("Selecione uma pizza inteira.");
        setShowToast(true);
        return false;
      }
      else if (selectedCount == 2 && listaComporItemsSelecionados.length >= 2) {
        setTextToast("Selecione duas partes para compor a pizza.");
        setShowToast(true);
        return false;
      }
      else if (selectedCount == 3 && listaComporItemsSelecionados.length >= 3) {
        setTextToast("Selecione três partes para compor a pizza.");
        setShowToast(true);
        return false;
      }
    }
    else {
      if (listaComporItemsSelecionados.length >= 1) {
        setTextToast("Selecione somente um item para avançar.");
        setShowToast(true);
        return false;
      }
    }


    setBuscarCompor("");
    setFilteredItemsCompor([]);

    setGrupoComplementos(item.grupoComplementos);

    const novoItem = {
      descricao: item.nome,
      valor: item.valor.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      categoria: item.categoria,
    };

    let updatedList = [...listaComporItemsSelecionados, novoItem];
    setListaComporItemsSelecionados(updatedList);


    const valorFinal = calcularValorTotal(
      updatedItensSelecionadosComplementos,
      updatedList,
      itemSelecionadoMassa,
      itemSelecionadoBorda
    );

    setSubtotal(valorFinal);
    setValor(valorFinal);

  };

  const handleCategoriaClick = (categoria: any) => {


    setSelectedSize("");
    setSelectedSizeObject([]);

    setSelectedCategoria(categoria.nome);

    console.log("**********categoria", categoria);
    setSelectedCategoriaObject(categoria);

  };




  const handleSizeClick = (size: any) => {

    setSelectedCount("");

    setSelectedSize(size.name);
    setSelectedSizeObject(size);


  };


  const handleCountClick = (count: any) => {

    setSelectedCount(count);


  };


  function avancarEtapa2Pizzas() {

    if (selectedCount && selectedSize && selectedCategoria) {
      setEtapa("2");
      let todosItems: any[];
      todosItems = addItemsPizzas(props.empresa.catalogoProdutos || []);
      // console.log("todosItems Pizzas", todosItems);
      setListaItemCatalogoProdutosCompor(todosItems || []);
    }
    else {
      setTextToast("Selecione as opções para avançar.");
      setShowToast(true);
      return false;
    }
    return true;
  }

  function avancarEtapa2() {

    if (selectedCategoria) {
      setEtapa("2");
      let todosItems: any[];
      todosItems = addItems(props.empresa.catalogoProdutos || []);
      setListaItemCatalogoProdutosCompor(todosItems || []);

    }
    else {
      setTextToast("Selecione a categoria.");
      setShowToast(true);
      return false;
    }
    return true;
  }


  const renderCategoriaButtons = () => {
    if (!props.empresa || !props.empresa.catalogoProdutos) return null;

    const categorias = props.empresa.categoriaProdutos;
    //.filter((val: any) => val.tipo == "pizzas");

    return categorias.map((val: any, index: any) => (
      <IonCol size="auto" key={index}>
        <IonButton disabled={props.value.categoria}
          onClick={() => handleCategoriaClick(val)}
          color={selectedCategoria === val.nome ? 'danger' : 'light'}
          className="custom-flat-button-size"
        >
          <div style={{ textAlign: 'center' }}>
            {/* <IonIcon icon={pizzaOutline} style={{ fontSize: '80px' }} /> */}
            <div style={{ marginTop: '6px', textAlign: 'center' }}>
              <div style={{ fontWeight: 'bold' }}>{val.nome}</div>
            </div>
          </div>
        </IonButton>
      </IonCol>
    ));
  };

  // Função para renderizar os botões de tamanho dinamicamente
  const renderSizeButtons = () => {
    if (!props.empresa || !props.empresa.catalogoProdutos) return null;


    return selectedCategoriaObject?.tamanhos
      .map((val: any, index: any) => (
        <IonCol size="auto" key={index}>
          <IonButton disabled={props.value.tamanho}
            onClick={() => handleSizeClick(val)}
            color={selectedSize === val.name ? 'danger' : 'light'}
            className="custom-flat-button-size"
          >
            <div style={{ textAlign: 'center' }}>
              <div style={{ marginTop: '6px', textAlign: 'center' }}>
                <div style={{ fontWeight: 'bold' }}>{val.name}</div>
              </div>
            </div>
          </IonButton>
        </IonCol>
      ));
  };

  // Função para renderizar os botões de quantidade dinamicamente
  const renderCountButtons = () => {
    if (!props.empresa || !props.empresa.catalogoProdutos) return null;

    let counts: string[] = [];
    if (selectedSizeObject && selectedSizeObject.sabores) {
      counts = Array.from({ length: selectedSizeObject.sabores }, (_, i) => (i + 1).toString());
    }
    return counts
      .map((count: any, index: any) => (
        <IonCol size="auto" key={index}>
          <IonButton disabled={props.value.quantidadeSabores}
            onClick={() => handleCountClick(count)}
            color={selectedCount === count ? 'danger' : 'light'}
            shape="round"
            className="custom-flat-button"
          >
            <IonLabel>{count}</IonLabel>
          </IonButton>
        </IonCol>
      ));
  };

  const handleSelectItemComporMassa = (item: any) => {
    const novoItem = {
      descricao: item.name,  // Corrigido de item.nome para item.name
      valor: item.price.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      categoria: "Massa",
    };

    setItemSelecionadoMassa(novoItem);

    const valorFinal = calcularValorTotal(
      updatedItensSelecionadosComplementos,
      listaComporItemsSelecionados,
      novoItem,
      itemSelecionadoBorda
    );

    setSubtotal(valorFinal);
    setValor(valorFinal);
  };

  const handleSelectItemComporBorda = (item: any) => {
    const novoItem = {
      descricao: item.name,  // Corrigido de item.nome para item.name
      valor: item.price.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      categoria: "Borda",
    };

    setItemSelecionadoBorda(novoItem);

    const valorFinal = calcularValorTotal(
      updatedItensSelecionadosComplementos,
      listaComporItemsSelecionados,
      itemSelecionadoMassa,
      novoItem
    );

    setSubtotal(valorFinal);
    setValor(valorFinal);
  };

  const updateGrupoComplementos = (groupIndex: number, field: string, value: any) => {
    const updatedGrupos = [...grupoComplementos];
    updatedGrupos[groupIndex][field] = value;

    // Atualiza o grupo de complementos no estado
    setGrupoComplementos(updatedGrupos);

    // Identificar o item selecionado dentro do grupo específico
    const selectedItem = updatedGrupos[groupIndex].itens.find((item: any) => item.name === value);

    if (selectedItem) {
      const novoItem = {
        descricao: selectedItem.name,
        valor: selectedItem.price.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        categoria: grupoComplementos[groupIndex].name,  // Nome do grupo como categoria
      };

      // Crie uma cópia do array de itens selecionados
      let updatedItensSelecionados = [...updatedItensSelecionadosComplementos];

      // Verificar se o grupo já tem um item selecionado no array
      const existingItemIndex = updatedItensSelecionados.findIndex(
        (item) => item.categoria === grupoComplementos[groupIndex].name
      );

      if (existingItemIndex !== -1) {
        // Se o item já existe, atualize-o
        updatedItensSelecionados[existingItemIndex] = novoItem;
      } else {
        // Se o item não existe, adicione-o
        updatedItensSelecionados.push(novoItem);
      }

      // Atualiza o estado com os itens selecionados
      setUpdatedItensSelecionadosComplementos(updatedItensSelecionados);

      const valorFinal = calcularValorTotal(
        updatedItensSelecionados,
        listaComporItemsSelecionados,
        itemSelecionadoMassa,
        itemSelecionadoBorda
      );

      setSubtotal(valorFinal);
      setValor(valorFinal);
    }
  };


  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  const validateItemForm = async () => {
    // Faça a validação aqui, por exemplo, verificar se todos os campos obrigatórios foram preenchidos
    let isValid = true;

    if (selectedCategoriaObject && selectedCategoriaObject.tipo == "pizzas") {
      isValid = avancarEtapa2Pizzas();
    }
    else {
      isValid = avancarEtapa2();
    }
    console.log("isValid", isValid);

    setIsItemValid(isValid);
    return isValid;
  };

  const limparEtapa2 = () => {
    setSelectedProdutos([]);
    setListaComporItemsSelecionados([]);

    const updatedGrupoComplementos = grupoComplementos?.map((grupo: any) => ({
      ...grupo,
      selectedItem: null, // Reseta a seleção
    }));
    setGrupoComplementos(updatedGrupoComplementos);
    setGrupoComplementos([]);

    setUpdatedItensSelecionadosComplementos([]);

    setSelectedBorda("");
    setSelectedMassa("");
    setItemSelecionadoBorda("");
    setItemSelecionadoMassa("");
    setObservacao("");

    const valorFinal = calcularValorTotal(
      [],
      [],
      "",
      ""
    );

    setSubtotal(valorFinal);
    setValor(valorFinal);
  }
  const handleEtapaChange = (value: any) => {
    if (value === '2' && !(validateItemForm())) {
      return; // Impede a mudança para a segunda etapa
    }
    if (value === '1') {
      limparEtapa2();
      setIsItemValid(false);
    }
    setEtapa(value);
    scrollToTop(); // Função para rolar a tela para o topo
  };

  const renderProdutosButtons = () => {
    return (
      <IonList style={{ width: "100%" }}>
        {listaItemCatalogoProdutosCompor.map((item: any, index: any) => (
          <IonItem lines="none"
            key={index}
            button
            detail={true}
            onClick={async () => {
              const alreadySelected = selectedProdutos.includes(item.nome);

              if (alreadySelected) {
                // Se o item já estiver selecionado, encontrar o índice na listaComporItemsSelecionados
                const indexToRemove = listaComporItemsSelecionados.findIndex(
                  (produtoSelecionado: any) => produtoSelecionado.descricao === item.nome
                );

                if (indexToRemove !== -1) {
                  const updatedProdutos = selectedProdutos.filter(
                    (produto) => produto !== item.nome
                  );
                  setSelectedProdutos(updatedProdutos);

                  // Chamar a função para deletar o item em listaComporItemsSelecionados
                  await deletarItemCompor(indexToRemove);

                  if (selectedProdutos.length == 1) {
                    limparEtapa2();
                  }
                }
              } else {
                console.log("selectedProdutos.length", selectedProdutos.length);
                console.log("selectedCount", selectedCount);
                if (selectedCategoriaObject.tipo === "pizzas") {
                  if (selectedProdutos.length >= selectedCount) {
                    return;
                  }
                  if (selectedProdutos.length >= selectedCount - 1) {
                    setTimeout(function () {
                      // Fecha a aba/janela aberta
                      scrollToBottom();
                    }, 100);
                  }
                } else {
                  if (selectedProdutos.length === 1) {
                    return;
                  }
                  if (selectedProdutos.length === 0) {
                    if (item.grupoComplementos && Array.isArray(item.grupoComplementos) && item.grupoComplementos.length > 0) {
                      setTimeout(function () {
                        // Fecha a aba/janela aberta
                        scrollToBottom();
                      }, 100);
                    }
                  }
                }

                const itensProdutos = [...selectedProdutos, item.nome];
                setSelectedProdutos(itensProdutos);
                handleSelectItemCompor(item);
              }
            }}
            color={selectedProdutos.includes(item.nome) ? 'danger' : 'light'}
          >
            {/* <IonLabel style={{ fontWeight: 'bold' }}>{item.codigo} - {item.nome}</IonLabel> */}
            <IonLabel>{item.codigo && item.codigo + " - "} {item.nome}</IonLabel>
          </IonItem>
        ))}
      </IonList>
    );
  };


  function calcularValorTotal(
    updatedItensSelecionadosComplementos: any[],
    listaComporItemsSelecionados: any[],
    itemSelecionadoMassa: any,
    itemSelecionadoBorda: any
  ): string {

    let totalValor = updatedItensSelecionadosComplementos.reduce((total: any, itemCompor: any) => {
      const itemValorNumerico = parseFloat(
        itemCompor.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
      return total + itemValorNumerico;
    }, 0);

    // Calcula o total de listaComporItemsSelecionados
    let totalValorListaSelecionados = listaComporItemsSelecionados.reduce((total: number, itemCompor: any) => {
      const itemValorNumerico = parseFloat(itemCompor.valor.toString().replace(/\./g, '').replace(/\,/g, '.'));
      return total + itemValorNumerico;
    }, 0);

    // Soma os totais calculados
    totalValor += totalValorListaSelecionados;

    // Adiciona o valor de itemSelecionadoMassa se existir
    if (itemSelecionadoMassa && itemSelecionadoMassa.valor) {
      totalValor += parseFloat(
        itemSelecionadoMassa.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
    }

    // Adiciona o valor de itemSelecionadoBorda se existir
    if (itemSelecionadoBorda && itemSelecionadoBorda.valor) {
      totalValor += parseFloat(
        itemSelecionadoBorda.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
    }

    // Formata o valor final
    const valorFinal = totalValor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return valorFinal;
  }

  const scrollToBottom = () => {
    inputRefbottom.current && inputRefbottom.current.scrollIntoView();
  };

  return (

    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
              props.closeActionSimple();
            }} />
          </IonButtons>
          <IonTitle>{selectedSize ? selectedSize + " - " + selectedCount + " sabor(es)" : "Compor produto"}</IonTitle>


        </IonToolbar>
        <IonToolbar style={{ height: "48px" }}>
          <IonSegment value={etapa} onIonChange={(e) => handleEtapaChange(e.detail.value)}>
            <IonSegmentButton style={{ height: "30px" }} value="1">
              <IonLabel style={{ fontSize: "14px" }}>Categoria</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              style={{ height: "30px" }}
              value="2"
              className={!isItemValid ? "segment-button-disabled" : ""}
            >
              <IonLabel style={{ fontSize: "14px" }}>Item</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>


        <IonGrid hidden={etapa != "1"}>

          {/* <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <div style={{ padding: '6px', textAlign: 'center' }}>
                <p>Categorias</p>
              </div>
            </IonCol>
          </IonRow> */}

          <IonRow className="ion-justify-content-center">
            {renderCategoriaButtons()}
          </IonRow>

          <IonRow className="ion-justify-content-center" hidden={selectedCategoriaObject?.tipo != "pizzas"}>
            <IonCol size="auto">
              <div style={{ padding: '6px', textAlign: 'center' }}>
                <p>Tamanho</p>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-center">
            {renderSizeButtons()}
          </IonRow>

          <IonRow className="ion-justify-content-center" hidden={!selectedSize}>
            <IonCol size="auto">
              <div style={{ padding: '6px', textAlign: 'center' }}>
                <p>Quantidade de sabores</p>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-center">
            {renderCountButtons()}
          </IonRow>



        </IonGrid>


        <div hidden={etapa != "2"}>

          {/* <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonSearchbar
              onIonInput={handleSearchCompor}
              placeholder="Digite o código ou nome"
              value={buscarCompor} ref={inputRefSearchCompor2}
            />

            <IonButtons slot="start" >
              <IonButton onClick={() => {
                setSelectedProdutos([]);
                setListaComporItemsSelecionados([]);

                const valorFinal = calcularValorTotal(
                  updatedItensSelecionadosComplementos,
                  [],
                  itemSelecionadoMassa,
                  itemSelecionadoBorda
                );

                setSubtotal(valorFinal);
                setValor(valorFinal);

                setShowModalSelecionarProduto(true);
              }}>
                <IonIcon slot="icon-only" icon={addCircleOutline}></IonIcon>
              </IonButton>

            </IonButtons>
          </div> */}


          {/* <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: filteredItemsCompor.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
            {filteredItemsCompor.map((item: any, index: any) => (
              <IonItem lines="none" key={"filtro" + index} button onClick={() => {
                handleSelectItemCompor(item);
                if (!((selectedCount == 1 && listaComporItemsSelecionados.length >= 0) || (selectedCount == 2 && listaComporItemsSelecionados.length >= 1) || (selectedCount == 3 && listaComporItemsSelecionados.length >= 2))) {

                  if (inputRefSearchCompor2.current) {
                    inputRefSearchCompor2.current.setFocus();
                  }
                }
              }}>
                <IonLabel>{item.nome} (R$ {item.valor})</IonLabel>
              </IonItem>
            ))}
          </IonList> */}



          {/* <IonGrid>
            <IonRow>
              <IonCol>

                <IonList style={{ margin: "0px", padding: "0px", minHeight: "146px" }}>
                  <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>

                    <div style={{ flex: 1, width: "100px" }}>
                      <IonLabel style={{ fontSize: "12px" }}>Descrição</IonLabel>
                    </div>
                    <IonLabel hidden={!isPlatform('mobileweb') && isPlatform('mobile')} style={{ textAlign: "right", fontSize: "12px" }}>Valor</IonLabel>
                    <IonLabel style={{ textAlign: "right" }}></IonLabel>
                  </IonItem>

                  {loadItemProdutosCompor()}

                </IonList>
              </IonCol>
            </IonRow>

          </IonGrid> */}


          <IonGrid>
            <IonRow className="ion-justify-content-center">
              {renderProdutosButtons()}
            </IonRow>
          </IonGrid>

          <div ref={inputRefbottom}></div>

          <IonGrid style={{ padding: "8px" }} hidden={selectedCategoriaObject?.tipo != "pizzas"} >

            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonLabel style={{ fontWeight: 'bold' }}>Massa</IonLabel>
                <IonRadioGroup
                  value={selectedMassa}
                  onIonChange={(e: any) => {
                    setSelectedMassa(e.detail.value);
                    const selectedMassaItem = selectedCategoriaObject?.massas.find(
                      (massa: any) => massa.name === e.detail.value
                    );
                    if (selectedMassaItem) {
                      handleSelectItemComporMassa(selectedMassaItem);
                    }
                  }}
                >
                  {selectedCategoriaObject?.massas?.map((massa: any, index: any) => (
                    <IonItem key={index} lines="none" style={{ fontSize: "12px" }}>
                      <IonRadio slot="start" value={massa.name} />
                      <IonLabel>{massa.name} - {massa.price}</IonLabel>
                    </IonItem>
                  ))}
                </IonRadioGroup>
              </IonCol>

              <IonCol size="12" sizeMd="6">
                <IonLabel style={{ fontWeight: 'bold' }}>Borda</IonLabel>
                <IonRadioGroup
                  value={selectedBorda}
                  onIonChange={(e: any) => {
                    setSelectedBorda(e.detail.value);
                    const selectedBordaItem = selectedCategoriaObject?.bordas.find(
                      (borda: any) => borda.name === e.detail.value
                    );
                    if (selectedBordaItem) {
                      handleSelectItemComporBorda(selectedBordaItem);
                    }
                  }}
                >
                  {selectedCategoriaObject?.bordas?.map((borda: any, index: any) => (
                    <IonItem key={index} lines="none" style={{ fontSize: "12px" }}>
                      <IonRadio slot="start" value={borda.name} />
                      <IonLabel>{borda.name} - {borda.price}</IonLabel>
                    </IonItem>
                  ))}
                </IonRadioGroup>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid style={{ padding: "8px" }}>

            <IonRow>
              {grupoComplementos?.map((grupo: any, groupIndex: number) => (

                <IonCol key={groupIndex} size="12" sizeMd="6">
                  <IonLabel style={{ fontWeight: 'bold' }}>{grupo.name} {grupo.obrigatoriedade == "obrigatorio" && "(Obrigatório)"}</IonLabel>
                  <IonRadioGroup
                    value={grupo.selectedItem}  // Supondo que você tenha uma chave para armazenar o item selecionado
                    onIonChange={(e: any) => {
                      const selectedValue = e.detail.value;
                      updateGrupoComplementos(groupIndex, 'selectedItem', selectedValue); // Atualiza o item selecionado no grupo
                    }}
                  >
                    {grupo.itens?.map((item: any, itemIndex: number) => (
                      <IonItem key={itemIndex} lines="none" style={{ fontSize: "12px" }}>
                        <IonRadio slot="start" value={item.name} />
                        <IonLabel>{item.name} - {item.price}</IonLabel>
                      </IonItem>
                    ))}
                  </IonRadioGroup>
                </IonCol>

              ))}
            </IonRow>

          </IonGrid>

          {/* <IonGrid style={{ padding: "8px" }}>
            <IonRow>
              <IonCol size="12">
                <IonLabel style={{ fontWeight: 'bold' }}>Observação</IonLabel>
                <IonInput placeholder="Alguma observação?" required value={observacao} onIonInput={(e: any) => setObservacao(e.target.value)} />
              </IonCol>
            </IonRow>
          </IonGrid> */}

        </div>


        {/* <IonModal className="fullscreen-modal" isOpen={showModalSelecionarProduto} onDidDismiss={() => setShowModalSelecionarProduto(false)} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Selecione um produto</IonTitle>
              <IonButtons slot="start" onClick={() => setShowModalSelecionarProduto(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonGrid>

              <IonRow className="ion-justify-content-center">
                {renderProdutosButtons()}
              </IonRow>
            </IonGrid>
          </IonContent>

        </IonModal> */}



        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />


      </IonContent>
      <IonFooter hidden={etapa != "1"} className="ion-no-border">

        <IonToolbar>
          <IonButton
            onClick={async () => {

              if (!(await validateItemForm())) {
                return;
              }

              setEtapa("2");
              scrollToTop();

              // if (inputRefSearchCompor2.current) {
              //   inputRefSearchCompor2.current.setFocus();
              // }
            }}
            expand="block"
            type="submit"
            style={{ margin: "0px", textAlign: "right" }}
          >
            Avançar
          </IonButton>
        </IonToolbar>
      </IonFooter>

      <IonFooter hidden={etapa != "2"} className="ion-no-border" style={{ borderTop: '1px solid #ccc' }}>



        <IonList>

          <IonItem lines="none">
            <IonLabel style={{ fontWeight: 'bold' }}>Observação:</IonLabel>
            <IonInput
              autoCorrect='true' autoCapitalize='true'
              placeholder="Alguma observação?"
              required value={observacao}
              onIonInput={(e: any) => setObservacao(e.target.value)} />
          </IonItem>


          <IonItem lines="none">
            <IonLabel slot="start" style={{ fontWeight: 'bold' }}>Valor Total:</IonLabel>
            <IonLabel slot="end">{subtotal || "0"}</IonLabel>
          </IonItem>
        </IonList>

        <IonToolbar>
          <IonButton onClick={async () => {
            cadastrarComporProduto();
          }
          } expand="block" type="submit">Avançar</IonButton>
        </IonToolbar>

      </IonFooter>


      {/* <IonFooter hidden={etapa != 3} className="ion-no-border" style={{ borderTop: '1px solid #ccc' }}>



        <IonList>



          <IonItem lines="none">
            <IonLabel slot="start" style={{ fontWeight: 'bold' }}>Valor Total:</IonLabel>
            <IonLabel slot="end">{subtotal || "0"}</IonLabel>
          </IonItem>
        </IonList>

        <IonToolbar>
          <IonButton hidden={etapa != 3} onClick={() => cadastrarComporProduto()} expand="block" type="submit">Cadastrar</IonButton>
        </IonToolbar>

      </IonFooter> */}

    </>
  );


};

export default ({ idprojeto, value, empresa, closeAction, closeActionSimple }: { idprojeto: any, value: any, empresa: any, closeAction: Function, closeActionSimple: Function }) => (
  <ProjetosProdutosComporNovo idprojeto={idprojeto} value={value} empresa={empresa} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </ProjetosProdutosComporNovo>
)