import { IonActionSheet, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps, useHistory } from 'react-router-dom';
import './Home.css';
import ContaAlt from './pages/ContaAlt/ContaAlt';
import TermosPolitica from './pages/TermosPolitica/TermosPolitica';

import { apps, appsOutline, calendar, calendarOutline, clipboardOutline, close, desktopOutline, flag, flagOutline, homeOutline, logoUsd, trendingUp, trendingUpOutline } from 'ionicons/icons';
import "./Home.css";
import Acompanhar from './pages/Acompanhar/Acompanhar';
import Operacoes from './pages/Operacoes/Operacoes';
import ProjetosDetalhes from './pages/ProjetosDetalhes/ProjetosDetalhes';
import ProjetosWorkflow from './pages/ProjetosWorkflow/ProjetosWorkflow';

import AgendamentosDetalhes from './pages/AgendamentosDetalhes/AgendamentosDetalhes';
import AgendamentosEventos from './pages/AgendamentosEventos/AgendamentosEventos';
import Equipe from './pages/Equipe/Equipe';


import { MessageContext } from '../../../contexts/MessageContext';
import Chat from './pages/Chat/Chat';
import Clientes from './pages/Clientes/Clientes';
import Convidados from './pages/Convidados/Convidados';
import Prestadores from './pages/Prestadores/Prestadores';
import Projetos from './pages/Projetos/Projetos';

import Prospects from './pages/Prospects/Prospects';

import { Toast } from '@capacitor/toast';
import { Buffer } from 'buffer';
import { consultaPagamentoMensalidade, empresaCarregar, requestService } from '../../../Utils/Services';
import AgendamentosEventosComMenu from './pages/AgendamentosEventosComMenu/AgendamentosEventosComMenu';
import Caixa from './pages/Caixa/Caixa';
import CaixaHistorico from './pages/CaixaHistorico/CaixaHistorico';
import CaixaProdutosMemory from './pages/CaixaProdutosMemory/CaixaProdutosMemory';
import Comandas from './pages/Comandas/Comandas';
import ComandasHistorico from './pages/ComandasHistorico/ComandasHistorico';
import ComandasProdutosMemory from './pages/ComandasProdutosMemory/ComandasProdutosMemory';
import EmpresaWorkflowCadastro from './pages/EmpresaWorkflowCadastro/EmpresaWorkflowCadastro';
import EmpresaWorkflowVendaCadastro from './pages/EmpresaWorkflowVendaCadastro/EmpresaWorkflowVendaCadastro';
import Financeiro from './pages/Financeiro/Financeiro';
import FinanceiroCategorias from './pages/FinanceiroCategorias/FinanceiroCategorias';
import Formulario from './pages/Formulario/Formulario';
import FormularioCadastro from './pages/FormularioCadastro/FormularioCadastro';
import FormularioCategorias from './pages/FormularioCategorias/FormularioCategorias';
import Inicio from './pages/Inicio/Inicio';
// import Lembretes from './pages/Lembretes/Lembretes';
import CaixaGeral from './pages/CaixaGeral/CaixaGeral';
import Desempenho from './pages/Desempenho/Desempenho';
import Mensagem from './pages/Mensagem/Mensagem';
import MenuCadastros from './pages/MenuCadastros/MenuCadastros';
import MenuConfiguracoes from './pages/MenuConfiguracoes/MenuConfiguracoes';
import MenuRelatorios from './pages/MenuRelatorios/MenuRelatorios';
import Notificacoes from './pages/Notificacoes/Notificacoes';
import OperacoesAlt from './pages/OperacoesAlt/OperacoesAlt';
import Painel from './pages/Painel/Painel';
import ProdutosCatalogo from './pages/ProdutosCatalogo/ProdutosCatalogo';
import ProdutosCategorias from './pages/ProdutosCategorias/ProdutosCategorias';
import ProdutosCategoriasNovo from './pages/ProdutosCategoriasNovo/ProdutosCategoriasNovo';
import ProjetosCupomHtml from './pages/ProjetosCupomHtml/ProjetosCupomHtml';
import ProjetosHistorico from './pages/ProjetosHistorico/ProjetosHistorico';
import ProjetosNovoPedidoVenda from './pages/ProjetosNovoPedidoVenda/ProjetosNovoPedidoVenda';
import ProjetosPainel from './pages/ProjetosPainel/ProjetosPainel';
import ProjetosProdutosMemory from './pages/ProjetosProdutosMemory/ProjetosProdutosMemory';
import ProjetosServicos from './pages/ProjetosServicos/ProjetosServicos';
import RelatorioAgendamentos from './pages/RelatorioAgendamentos/RelatorioAgendamentos';
import RelatorioCaixa from './pages/RelatorioCaixa/RelatorioCaixa';
import RelatorioComandas from './pages/RelatorioComandas/RelatorioComandas';
import RelatorioFinanceiro from './pages/RelatorioFinanceiro/RelatorioFinanceiro';
import RelatorioOperacoes from './pages/RelatorioOperacoes/RelatorioOperacoes';
import RelatorioProjetos from './pages/RelatorioProjetos/RelatorioProjetos';
import RelatorioUsuarios from './pages/RelatorioUsuarios/RelatorioUsuarios';
import RelatorioVendas from './pages/RelatorioVendas/RelatorioVendas';
import ServicosCatalogo from './pages/ServicosCatalogo/ServicosCatalogo';
import ServicosCategorias from './pages/ServicosCategorias/ServicosCategorias';
import UsuariosCaixa from './pages/UsuariosCaixa/UsuariosCaixa';
import UsuariosComandas from './pages/UsuariosComandas/UsuariosComandas';
import Vendas from './pages/Vendas/Vendas';
import VendasCad from './pages/VendasCad/VendasCad';
import VendasDetalhes from './pages/VendasDetalhes/VendasDetalhes';
import VendasPainel from './pages/VendasPainel/VendasPainel';
import VendasProdutos from './pages/VendasProdutos/VendasProdutos';
import VendasServicos from './pages/VendasServicos/VendasServicos';

const Home: React.FC<RouteComponentProps> = ({ match }) => {
  const history = useHistory();
  // const { mensagens, setMensagens } = useContext(MessageContext);
  const { loja, setLoja } = useContext(MessageContext);
  const { pkUsuario, setPkUsuario } = useContext(MessageContext);

  const acessos = JSON.parse(window.localStorage.getItem("acessos") || '{}');
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);

  const [showActionSheet, setShowActionSheet] = useState(false);

  const { idPagamento, setIdPagamento } = useContext(MessageContext);
  const { isPaymentCompleted, setIsPaymentCompleted } = useContext(MessageContext);

  const menuController = React.useRef<any>(null);

  const openMenu = () => {
    menuController.current?.open('financeiroMenu');
  };


  useEffect(
    () => {

      // verifica se tem acesso somente a uma determinada loja que não seja a principal.
      let lojasUsuario = JSON.parse(window.localStorage.getItem("lojas") || "[]");

      let lojaSelecionada = window.localStorage.getItem("empresa");

      if (lojasUsuario.length == 1) {
        lojaSelecionada = lojasUsuario[0].empresaLojaId;
      }
      else if (lojasUsuario.length > 1) {
        lojaSelecionada = window.localStorage.getItem("loja") || lojasUsuario[0].empresaLojaId || "";
      }
      else {
        lojaSelecionada = window.localStorage.getItem("loja") || window.localStorage.getItem("empresa") || "";
      }

      console.log("lojaSelecionada", lojaSelecionada);
      setLoja(lojaSelecionada);
      carregarEmpresa(lojaSelecionada);
    },
    []
  );


  useEffect(() => {
    let intervalId: any;
    let timeoutId: any;

    // Função para verificar o status do pagamento
    const verificarPagamento = async () => {
      try {
        console.log("entrou verificaPagamento", idPagamento);

        requestService(consultaPagamentoMensalidade.url.replace("{id}", idPagamento), {
          method: consultaPagamentoMensalidade.method,
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
          history,
          (response: any) => {
            console.log("response.message ", response.message);

            if (response.message === "pagou") {
              setIsPaymentCompleted(true);  // Define o pagamento como completo
              clearInterval(intervalId);    // Para o polling quando o pagamento for efetuado
              clearTimeout(timeoutId);      // Cancela o timeout de 5 minutos
              carregarEmpresa(loja);        // Chama o endpoint carregarEmpresa

              Toast.show({
                text: "Pagamento realizado com sucesso.",
                position: "center",
                duration: "long"
              });
            }
          },
          (error: any) => {
            console.log(error);
          });

      } catch (error) {
        console.error('Erro ao verificar pagamento:', error);
      }
    };

    console.log("polling");

    if (idPagamento && !isPaymentCompleted) {
      console.log("polling iniciado");
      // Inicia o polling a cada 10 segundos (10000ms)
      intervalId = setInterval(verificarPagamento, 10000);

      // Define o timeout para parar o polling após 6 minutos (360000ms)
      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        console.log("Polling parou após 6 minutos");
      }, 360000); // 360000ms = 6 minutos
    }

    // Limpa o intervalo e o timeout quando o componente é desmontado ou quando o pagamento é completo
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };

  }, [idPagamento, isPaymentCompleted]);



  useEffect(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = esconderMenu ? "none" : "flex";
  }, [esconderMenu]);

  useEffect(() => {
    let pk = "usuarios#" + (window.localStorage.getItem("cpf") || "");
    setPkUsuario(pk);
  }, []);


  function carregarEmpresa(loja: any) {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(empresaCarregar.url.replace("{id}", id.replace(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          setDadosEmpresa(response.Item);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function carregarConfigWorkflowEmpresa(loja?: any) {
    console.log("entrou configs")
    const consultar = async () => {

      let id = loja;

      requestService(empresaCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          if (response.Item.configWorkflow) {
            window.localStorage.setItem("configWorkflow", JSON.stringify(response.Item.configWorkflow));
          } else {
            window.localStorage.removeItem("configWorkflow");
          }
          if (response.Item.servicoPrincipal) { window.localStorage.setItem("servicoPrincipal", response.Item.servicoPrincipal); } else { window.localStorage.removeItem("servicoPrincipal"); }
          if (response.Item.identificacaoPorNumero) { window.localStorage.setItem("identificacaoPorNumero", response.Item.identificacaoPorNumero); } else { window.localStorage.removeItem("identificacaoPorNumero"); }
          if (response.Item.servicoTipo) { window.localStorage.setItem("servicoTipo", response.Item.servicoTipo); } else { window.localStorage.removeItem("servicoTipo"); }
          if (response.Item.servicoTipoPlural) { window.localStorage.setItem("servicoTipoPlural", response.Item.servicoTipoPlural); } else { window.localStorage.removeItem("servicoTipoPlural"); }
          if (response.Item.nomeLinhaDoTempo) { window.localStorage.setItem("nomeLinhaDoTempo", response.Item.nomeLinhaDoTempo); } else { window.localStorage.removeItem("nomeLinhaDoTempo"); }
          if (response.Item.polos) { window.localStorage.setItem("polos", response.Item.polos); } else { window.localStorage.removeItem("polos"); }
          if (response.Item.naoAgrupar) { window.localStorage.setItem("naoAgrupar", response.Item.naoAgrupar); } else { window.localStorage.removeItem("naoAgrupar"); }
          if (response.Item.naoOperacao) { window.localStorage.setItem("naoOperacao", response.Item.naoOperacao); } else { window.localStorage.removeItem("naoOperacao"); }
          if (response.Item.esconderValoresProjeto) { window.localStorage.setItem("esconderValoresProjeto", response.Item.esconderValoresProjeto); } else { window.localStorage.removeItem("esconderValoresProjeto"); }

          if (response.Item.configWorkflowVenda) {
            window.localStorage.setItem("configWorkflowVenda", JSON.stringify(response.Item.configWorkflowVenda));
          } else {
            window.localStorage.removeItem("configWorkflowVenda");
          }

          if (response.Item.empresaMarca) { window.localStorage.setItem("empresaMarca", response.Item.empresaMarca); } else { window.localStorage.removeItem("empresaMarca"); }
          if (response.Item.parceria) { window.localStorage.setItem("parceria", response.Item.parceria); } else { window.localStorage.removeItem("parceria"); }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });
    }
    consultar();
  }

  function getButtons() {
    let resultado: any = [
      {
        text: 'Cancelar',
        icon: close,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      },

    ];





    if (acessos?.vendas == 'leitura' || acessos?.vendas == 'edicao') {

      if (nomeMenu != "Vendas") {
        resultado.push(
          {
            text: "Vendas",
            icon: trendingUp,
            handler: () => {
              history.push("./vendas");
            }
          }
        )
      }
      else {
        resultado.push(
          {
            text: "Vendas",
            icon: trendingUp,
            cssClass: 'disable-action-sheet-btns'
          }
        )
      }
    }


    if (acessos?.projetos == 'leitura' || acessos?.projetos == 'edicao') {

      if (nomeMenu != (window.localStorage.getItem("servicoTipoPlural") || "Projetos")) {
        resultado.push(
          {
            text: window.localStorage.getItem("servicoTipoPlural") || "Projetos",
            icon: apps,
            handler: () => {
              history.push("./projetos");
            }
          }
        )
      }
      else {
        resultado.push(
          {
            text: window.localStorage.getItem("servicoTipoPlural") || "Projetos",
            icon: apps,
            cssClass: 'disable-action-sheet-btns'
          }
        )
      }

    }


    if (acessos?.agenda == 'leitura' || acessos?.agenda == 'edicao') {

      if (nomeMenu != "Agenda") {

        resultado.push(
          {
            text: "Agenda",
            icon: calendar,
            handler: () => {
              history.push("./agendamentoseventoscommenu");
            }
          }
        )
      }
      else {
        resultado.push(
          {
            text: "Agenda",
            icon: calendar,
            cssClass: 'disable-action-sheet-btns'
          }
        )
      }
    }

    if (acessos?.operacoes == 'leitura' || acessos?.operacoes == 'edicao') {

      if (nomeMenu != "Operações") {
        resultado.push(
          {
            text: "Operações",
            icon: flag,
            handler: () => {
              history.push("./operacoes");
            }
          }
        )
      }
      else {
        resultado.push(
          {
            text: "Operações",
            icon: flag,
            cssClass: 'disable-action-sheet-btns'
          }
        )
      }
    }

    if (acessos?.financeiro == 'leitura' || acessos?.financeiro == 'edicao') {

      if (nomeMenu != "Financeiro") {
        resultado.push(
          {
            text: "Financeiro",
            icon: logoUsd,
            handler: () => {
              history.push("./financeiro");
            }
          }
        )
      }
      else {
        resultado.push(
          {
            text: "Financeiro",
            icon: logoUsd,
            cssClass: 'disable-action-sheet-btns'
          }
        )
      }
    }




    return resultado;
  }

  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Route path={`${match.url}/inicio`} component={Inicio} exact />
          <Route path={`${match.url}/painel`} component={Painel} exact />
          <Route path={`${match.url}/desempenho`} component={Desempenho} exact />

          <Route path={`${match.url}/caixageral`} component={CaixaGeral} exact />
          <Route path={`${match.url}/financeiro`} component={Financeiro} exact />

          <Route path={`${match.url}/vendas`} component={Vendas} exact />
          <Route path={`${match.url}/vendas/vendasdetalhes`} component={VendasDetalhes} exact />
          <Route path={`${match.url}/vendas/vendaspainel`} component={VendasPainel} exact />
          <Route path={`${match.url}/vendas/vendascad`} component={VendasCad} exact />
          <Route path={`${match.url}/vendas/vendasservicos`} component={VendasServicos} exact />
          <Route path={`${match.url}/vendas/vendasprodutos`} component={VendasProdutos} exact />

          <Route path={`${match.url}/projetos`} component={Projetos} exact />
          <Route path={`${match.url}/projetoshistorico`} component={ProjetosHistorico} exact />
          <Route path={`${match.url}/chat`} component={Chat} exact />
          <Route path={`${match.url}/notificacoes`} component={Notificacoes} exact />
          <Route path={`${match.url}/projetos/mensagem`} component={Mensagem} exact />

          <Route path={`${match.url}/projetos/projetosdetalhes`} component={ProjetosDetalhes} exact />
          <Route path={`${match.url}/projetos/projetospainel`} component={ProjetosPainel} exact />
          <Route path={`${match.url}/projetos/projetosworkflow`} component={ProjetosWorkflow} exact />
          <Route path={`${match.url}/projetos/projetosservicos`} component={ProjetosServicos} exact />
          <Route path={`${match.url}/projetos/projetosprodutos`} component={ProjetosProdutosMemory} exact />
          <Route path={`${match.url}/projetos/projetosnovopedidovenda`} component={ProjetosNovoPedidoVenda} exact />

          <Route path={`${match.url}/projetos/projetoscupomhtml`} component={ProjetosCupomHtml} exact />

          {/* <Route path={`${match.url}/comandas/comandashistorico`} component={ComandasHistorico} exact /> */}

          <Route path={`${match.url}/caixa`} component={Caixa} exact />
          <Route path={`${match.url}/caixa/caixahistorico`} component={CaixaHistorico} exact />


          <Route path={`${match.url}/comandas`} component={Comandas} exact />
          <Route path={`${match.url}/comandas/comandashistorico`} component={ComandasHistorico} exact />

          {/* <Route path={`${match.url}/caixa`} component={Caixa} exact /> */}
          {/* <Route path={`${match.url}/caixa/caixafechar`} component={CaixaFechar} exact /> */}
          {/* <Route path={`${match.url}/caixa/caixafechar`} component={CaixaFechar} exact /> */}


          <Route path={`${match.url}/comandas/produtos`} component={ComandasProdutosMemory} exact />
          <Route path={`${match.url}/caixa/produtos`} component={CaixaProdutosMemory} exact />

          <Route path={`${match.url}/agendamentos/agendamentosdetalhes`} component={AgendamentosDetalhes} exact />
          <Route path={`${match.url}/agendamentoseventos`} component={AgendamentosEventos} exact />
          <Route path={`${match.url}/agendamentoseventoscommenu`} component={AgendamentosEventosComMenu} exact />



          <Route path={`${match.url}/operacoes`} component={Operacoes} exact />
          <Route path={`${match.url}/operacoes/acompanhar`} component={Acompanhar} exact />
          <Route path={`${match.url}/operacoesalt`} component={OperacoesAlt} exact />

          <Route path={`${match.url}/equipe`} component={Equipe} exact />

          <Route path={`${match.url}/clientes`} component={Clientes} exact />
          <Route path={`${match.url}/usuarioscomandas`} component={UsuariosComandas} exact />
          <Route path={`${match.url}/usuarioscaixa`} component={UsuariosCaixa} exact />

          {/* <Route path={`${match.url}/arquitetos`} component={Arquitetos} exact /> */}

          <Route path={`${match.url}/prestadores`} component={Prestadores} exact />

          <Route path={`${match.url}/prospects`} component={Prospects} exact />


          <Route path={`${match.url}/convidados`} component={Convidados} exact />

          <Route path={`${match.url}/relatoriocaixa`} component={RelatorioCaixa} exact />
          <Route path={`${match.url}/relatoriocomandas`} component={RelatorioComandas} exact />

          <Route path={`${match.url}/relatorioprojetos`} component={RelatorioProjetos} exact />
          <Route path={`${match.url}/relatoriovendas`} component={RelatorioVendas} exact />
          {/* <Route path={`${match.url}/relatorioprojetosarquiteto`} component={RelatorioProjetosArquiteto} exact /> */}
          <Route path={`${match.url}/relatorioagendamentos`} component={RelatorioAgendamentos} exact />
          <Route path={`${match.url}/relatoriooperacoes`} component={RelatorioOperacoes} exact />
          <Route path={`${match.url}/relatoriousuarios`} component={RelatorioUsuarios} exact />
          <Route path={`${match.url}/relatoriofinanceiro`} component={RelatorioFinanceiro} exact />

          <Route path={`${match.url}/contaalt`} component={ContaAlt} exact />
          {/* <Route path={`${match.url}/empresaworkflow`} component={EmpresaWorkflow} exact /> */}
          <Route path={`${match.url}/empresaworkflowcadastro`} component={EmpresaWorkflowCadastro} exact />
          <Route path={`${match.url}/empresaworkflowvendacadastro`} component={EmpresaWorkflowVendaCadastro} exact />
          <Route path={`${match.url}/termospolitica`} component={TermosPolitica} exact />

          {/* <Route path={`${match.url}/portfolio`} component={Portfolio} exact />
          <Route path={`${match.url}/galeria`} component={Galeria} exact />
          <Route path={`${match.url}/galeriafoco`} component={GaleriaFoco} exact /> */}

          {/* <Route path={`${match.url}/enviarnotificacaoclientes`} component={EnviarNotificacaoClientes} exact />
          <Route path={`${match.url}/enviarnotificacaoarquitetos`} component={EnviarNotificacaoArquitetos} exact /> */}


          {/* <Route path={`${match.url}/portfoliovisualizar`} component={PortfolioVisualizar} exact /> */}

          <Route path={`${match.url}/servicoscatalogo`} component={ServicosCatalogo} exact />
          <Route path={`${match.url}/servicoscategorias`} component={ServicosCategorias} exact />

          <Route path={`${match.url}/produtoscatalogo`} component={ProdutosCatalogo} exact />
          <Route path={`${match.url}/produtoscategorias`} component={ProdutosCategorias} exact />
          <Route path={`${match.url}/produtoscategoriasnovo`} component={ProdutosCategoriasNovo} exact />

          <Route path={`${match.url}/formulario`} component={Formulario} exact />
          <Route path={`${match.url}/formulariocadastro`} component={FormularioCadastro} exact />
          <Route path={`${match.url}/formulariocategorias`} component={FormularioCategorias} exact />

          <Route path={`${match.url}/financeirocategorias`} component={FinanceiroCategorias} exact />

          <Route path={`${match.url}/menucadastros`} component={MenuCadastros} exact />
          {/* <Route path={`${match.url}/menucampanha`} component={MenuCampanha} exact /> */}
          <Route path={`${match.url}/menurelatorios`} component={MenuRelatorios} exact />
          <Route path={`${match.url}/menuconfiguracoes`} component={MenuConfiguracoes} exact />

          {/* <Route path={`${match.url}/lembretes`} component={Lembretes} exact /> */}

          <Route exact path={match.url} render={() => <Redirect to={`${match.url}/inicio`} />} />
        </IonRouterOutlet>


        {/* {(!isPlatform('mobileweb') && isPlatform('mobile')) ? (
          <IonTabBar slot="bottom" style={{ shadowBox: "0px 0px 5px black" }}>
            <IonTabButton href={`${match.url}/inicio`} tab="inicio">
              <IonIcon icon={homeOutline}></IonIcon>
              <IonLabel style={{ fontSize: "12px", fontWeight: 'bold' }}>Início</IonLabel>
            </IonTabButton>
            <IonTabButton disabled={true}>
            </IonTabButton>
            <IonTabButton href={`${match.url}/painel`} tab="painel">
              <IonIcon icon={gridOutline}></IonIcon>
              <IonLabel style={{ fontSize: "12px", fontWeight: 'bold' }}>Painel</IonLabel>
            </IonTabButton>
          </IonTabBar>

        ) : ( */}





        <IonTabBar slot="bottom" style={{ shadowBox: "0px 0px 5px black" }}>
          <IonTabButton href={`${match.url}/inicio`} tab="inicio">
            <IonIcon icon={homeOutline}></IonIcon>
            <IonLabel style={{ fontSize: "10px" }}>Início</IonLabel>
          </IonTabButton>
          {/* <IonTabButton href={`${match.url}/painel`} tab="painel">
              <IonIcon icon={gridOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>Painel</IonLabel>
            </IonTabButton> */}
          {(acessos?.vendas && acessos?.vendas != 'sem acesso') && (window.localStorage.getItem("configWorkflowVenda")) ? (
            <IonTabButton href={`${match.url}/vendas`} tab="vendas" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={trendingUpOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>Vendas</IonLabel>
            </IonTabButton>) : <></>}
          {(acessos?.balcao && acessos?.balcao != 'sem acesso') ? (
            <IonTabButton href={`${match.url}/caixa`} tab="caixa" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={desktopOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>{(window.localStorage.getItem("nomeInternonomeInternoBalcaoOuCaixa") || "Balcão")}</IonLabel>
            </IonTabButton>) : <></>}
          {(acessos?.comandas && acessos?.comandas != 'sem acesso') ? (
            <IonTabButton href={`${match.url}/comandas`} tab="comandas" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={clipboardOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>{(window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas")}</IonLabel>
            </IonTabButton>) : <></>}
          {(acessos?.projetos && acessos?.projetos != 'sem acesso') && (window.localStorage.getItem("configWorkflow")) ? (
            <IonTabButton href={`${match.url}/projetos`} tab="projetos" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={appsOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>{window.localStorage.getItem("servicoTipoPlural") || "Projetos"}</IonLabel>
            </IonTabButton>) : <></>}

          {(acessos?.agenda && acessos?.agenda != 'sem acesso') ? (
            <IonTabButton href={`${match.url}/agendamentoseventoscommenu`} tab="agenda" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={calendarOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>Agenda</IonLabel>
            </IonTabButton>) : <></>}
          {(acessos?.operacoes && acessos?.operacoes != 'sem acesso') ? (
            <IonTabButton href={`${match.url}/operacoes`} tab="operacoes" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={flagOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>Operações</IonLabel>
            </IonTabButton>) : <></>}
        </IonTabBar>




        {/* )} */}



      </IonTabs>

      {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} hidden={!(!isPlatform('mobileweb') && isPlatform('mobile')) || esconderMenu}>
        <IonFab vertical="bottom" horizontal="center">
          <IonFabButton data-desc={nomeMenu} color="tertiary" onClick={() => { setShowActionSheet(true); }}>
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>          
        </IonFab>
      </div> */}

      {/* {
        <Menu id="main-content-inicio"></Menu>
      } */}


      <IonActionSheet
        backdropDismiss={true}
        translucent={true}
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass='my-custom-class'
        buttons={getButtons()}

      />

    </>
  );

};

export default Home;
