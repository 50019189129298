import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonReorder, IonReorderGroup, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, ItemReorderEventDetail, isPlatform, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { add, addCircle, addCircleOutline, arrowBack, bookOutline, checkmarkCircle, closeOutline, ellipsisVertical, listOutline, logoUsd, pencil, reorderTwo, trash } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { alterarProjetosItemServicosEmpresa, empresaCarregar, inserirProjetosItemServicosEmpresa, listaEquipePorPerfil, ordernarProjetosItemServicosEmpresa, removerProjetosItemServicosEmpresa, requestService, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import FinanceiroLancamentos from '../FinanceiroLancamentos/FinanceiroLancamentos';
import PrestadoresCad from '../PrestadoresCad/PrestadoresCad';
import "./ProjetosServicos.css";

type Props = {
  id: any;
  nome: any;
};

export interface Liberacao {
  observacao: string;
  quantidade: string;
  data: string;
  idPrestador: string;
  nomePrestador: string;
  liberado: any;
}

const ProjetosServicos: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();
  const [id, setId] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [listaItemServicos, setListaItemServicos] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const [indice, setIndice] = useState("");
  const [descricao, setDescricao] = useState("");
  const [categoria, setCategoria] = useState("");
  const [quantidade, setQuantidade] = useState<any>();
  const [largura, setLargura] = useState<any>();
  const [comprimento, setComprimento] = useState<any>();
  const [quantidadeLiberado, setQuantidadeLiberado] = useState<any>();
  const [unidadeMedida, setUnidadeMedida] = useState("");
  const [valor, setValor] = useState<any>();
  const [desconto, setDesconto] = useState<any>();
  const [subtotal, setSubtotal] = useState<any>();
  const [percentual, setPercentual] = useState<any>();

  const [valorTotal, setValorTotal] = useState<any>();
  const [valorTotalServicos, setValorTotalServicos] = useState<any>();
  const [percentualTotalServicos, setPercentualTotalServicos] = useState<any>();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [listaCategoria, setListaCategoria] = useState<any>([]);

  const [isDisabled, setIsDisabled] = useState(true);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);
  const { recarregarCatalogo, setRecarregarCatalogo } = useContext(MessageContext);

  const [teveAlteracao, setTeveAlteracao] = useState(false);
  const [mostartAlerta] = useIonAlert();

  const [listaLiberacoes, setListaLiberacoes] = useState<Liberacao[]>([]);

  // const [checkedLiberar, setCheckedLiberar] = useState(false);
  const [observacaoLiberacao, setObservacaoLiberacao] = useState("");
  const [quantidadeLiberacao, setQuantidadeLiberacao] = useState<any>();
  const [larguraLiberacao, setLarguraLiberacao] = useState<any>();
  const [comprimentoLiberacao, setComprimentoLiberacao] = useState<any>();
  const [prestador, setPrestador] = useState<any>();
  const [listaPrestador, setListaPrestador] = useState<any>([]);
  const [showDetalhesPrestador, setShowDetalhesPrestador] = useState(false);

  const [editar, setEditar] = useState(false);

  const [showLiberar, setShowLiberar] = useState(false);

  const { recarregarFinanceiro, setRecarregarFinanceiro } = useContext(MessageContext);
  const [showDetalhesFinanceiro, setShowDetalhesFinanceiro] = useState(false);

  const [observacao, setObservacao] = useState<any>();


  const [indiceLiberar, setIndiceLiberar] = useState<any>();

  const buscarInputRef = useRef<any>(null);

  const quantidadeLiberacaoInputRef = useRef<any>(null);

  const larguraLiberacaoInputRef = useRef<any>(null);

  const inputPrestadoresCadRef = useRef<any>(null);

  const [showDetalhesCompor, setShowDetalhesCompor] = useState(false);
  const [area, setArea] = useState("");
  const [buscar, setBuscar] = useState("");
  const [listaItemCatalogoServicos, setListaItemCatalogoServicos] = useState<any>([]);

  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [listaCompor, setListaCompor] = useState<any>([]);
  const [showActionSheet, setShowActionSheet] = useState(false);

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);

  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };

  let params: any = {
    id: "",
    abrirInserirAgendamento: false
  }
  params = useParams();

  if (location && location.state && location.state.id) {
    params.id = location.state.id;
  }


  if (props.id) {
    params = {
      id: props.id
    }
  }

  useEffect(() => {
    if (id != "") {
      console.log("entrou no useEffect");
      carregarProjeto();
      carregarEmpresa();
      consultarPrestadorUsuarios();

    }
  }, [id]);

  useEffect(() => {
    console.log("recarregarCatalogo", recarregarCatalogo);
    if (recarregarCatalogo) {
      carregarEmpresa();
      setRecarregarCatalogo(false);
    }
  }, [recarregarCatalogo]
  );


  useEffect(() => {

    try {
      const valorNumerico = parseFloat(valor?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o valor para número
      let quantidadeNumerica = parseFloat(quantidade?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a quantidade para número
      if (unidadeMedida == "m2") {
        let larg = parseFloat(largura?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a quantidade para número
        let comp = parseFloat(comprimento?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a quantidade para número
        quantidadeNumerica = larg * comp;
        if (largura && comprimento) {
          setQuantidade((larg * comp).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
      }
      let descontoNumerico = 0;
      if (desconto) {
        descontoNumerico = descontoNumerico = parseFloat(desconto.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte o desconto para número
      }


      console.log("valorNumerico", valorNumerico);
      console.log("quantidadeNumerica", quantidadeNumerica);
      console.log("descontoNumerico", descontoNumerico);

      if (!valorNumerico || !quantidadeNumerica) {
        setSubtotal("0,00");
        return;
      }

      // Calcula o valor total sem desconto
      let valorTotal = valorNumerico * quantidadeNumerica;
      // Aplica o desconto
      valorTotal -= (valorTotal * descontoNumerico / 100);

      setSubtotal(valorTotal.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [largura, comprimento, quantidade, valor, desconto]);


  useEffect(() => {

    try {
      if (unidadeMedida == "m2") {

        if (larguraLiberacao && comprimentoLiberacao) {
          let larg = parseFloat(larguraLiberacao?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a quantidade para número
          let comp = parseFloat(comprimentoLiberacao?.toString().replace(/\./g, '').replace(/\,/g, '.')); // Converte a quantidade para número


          setQuantidadeLiberacao((larg * comp).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
        else {
          setQuantidadeLiberacao("");
        }

      }
    } catch (error) {
      console.log("erro ao calcular Quantidade liberacao", error);
    }
  }, [larguraLiberacao, comprimentoLiberacao]);

  useEffect(() => {
    try {
      console.log(listaLiberacoes);
      if (listaLiberacoes.length > 0) {
        if (quantidade == 0) {
          return;
        }
        let somaQuantidadeLiberado = listaLiberacoes.reduce((acc, item: any) => acc + parseFloat(item.quantidade?.toString().replace(/\./g, '').replace(/\,/g, '.') || '0'), 0);
        setQuantidadeLiberado(somaQuantidadeLiberado.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
        console.log("somaQuantidadeLiberado", somaQuantidadeLiberado);
        console.log("somaQuantidadeLiberado", quantidade);
        let percentualCalculado = (somaQuantidadeLiberado * 100) / parseFloat(quantidade?.toString().replace(/\./g, '').replace(/\,/g, '.') || '0');
        console.log("percentualCalculado", percentualCalculado);

        setPercentual(percentualCalculado.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      }
      else {
        setQuantidadeLiberado(0.00.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));

        setPercentual(0.00.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      }
    } catch (error) {
      console.log("Erro ao calcular", error);
    }
  }, [listaLiberacoes, quantidade]);

  useIonViewDidEnter(() => {

    console.log("***********************", params.id);
    setEsconderMenu(true);
    setNomeMenu("");
    if (params.id) {
      setId(params.id);
    }

  });

  function carregarEmpresa() {

    const consultar = async () => {

      requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setArea(response.Item.area);
          setListaItemCatalogoServicos(response.Item.catalogoServicos || []);
          setListaCategoria(response.Item.categoriaServicos || []);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function carregarProjeto() {

    const consultar = async () => {

      // console.log("*********************************params.id", params.id);

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }
      setShowLoading(true);
      const resp = await fetch(urlBase.url + "/projetos/" + params.id.replaceAll(/#/g, '%23'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        }).finally(() => {
          setShowLoading(false);
        });
      const response = await resp.json();

      if (response.Item !== undefined) {
        console.log("***************************", response);
        setListaItemServicos(response.Item.servicos || []);
        setValorTotal(response.Item.valorTotal?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
        setValorTotalServicos(response.Item.valorTotalServicos?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));

        setPercentualTotalServicos(response.Item.percentualTotalServicos?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      }

      if (buscarInputRef.current) {
        buscarInputRef.current.setFocus();
      }
    }

    consultar();
  }

  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setValor(v);
  }

  function onChangeQuantidade(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setQuantidade(v);
  }

  function onChangeLargura(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setLargura(v);
  }

  function onChangeComprimento(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setComprimento(v);
  }


  function onChangeQuantidadeLiberacao(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setQuantidadeLiberacao(v);
  }

  function onChangeLarguraLiberacao(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setLarguraLiberacao(v);
  }


  function onChangeComprimentoLiberacao(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setComprimentoLiberacao(v);
  }



  function onChangeDesconto(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    // Remove qualquer coisa que não seja um dígito
    v = v.replace(/\D/g, '');

    // Limita o desconto a 100%
    if (parseInt(v) > 100) {
      v = '100';
    }

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setDesconto(v);
  }


  function deletarItemServicos(indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delItemServicos = async () => {
      const resp = await fetch(removerProjetosItemServicosEmpresa.url.replace("{id}", id.replace(/#/g, "%23")),
        {
          method: removerProjetosItemServicosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno", retorno);

      if (retorno && retorno.Item?.Attributes) {
        console.log("retorno.Item?.Attributes", retorno.Item?.Attributes);
        setTextToast("Servico removido com sucesso.");
        setShowToast(true);
        setListaItemServicos(retorno.Item?.Attributes?.servicos || []);
        setTeveAlteracao(true);

        setValorTotal(retorno.Item?.Attributes?.valorTotal.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
        setValorTotalServicos(retorno.Item?.Attributes?.valorTotalServicos.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
        setPercentualTotalServicos(retorno.Item?.Attributes?.percentualTotalServicos?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      }
      else {
        setTextToast("Ocorreu um erro ao efetuar a exclusão.");
        setShowToast(true);
      }
    }

    delItemServicos();

  }


  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    // console.log('Before complete', listaItemServicos);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    setListaItemServicos(event.detail.complete(listaItemServicos));

    // After complete is called the items will be in the new order
    // console.log('After complete', listaItemServicos);


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const ordItemServicos = async () => {
      const resp = await fetch(ordernarProjetosItemServicosEmpresa.url.replace("{id}", id.replace(/#/g, "%23")),
        {
          method: ordernarProjetosItemServicosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'servicos': listaItemServicos }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno ordenar", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Ordenação alterada com sucesso.");
        setShowToast(true);
        setListaItemServicos(retorno.Item?.Attributes?.servicos || []);
      }
      else {
        setTextToast("Ocorreu um erro ao ordernar.");
        setShowToast(true);
      }
    }

    ordItemServicos();

  }



  const getColor = (percentual: any) => {
    const percent = parseFloat(percentual?.toString().replace(/\./g, '').replace(/\,/g, '.') || 0);
    if (percent <= 33) {
      return "rgba(212, 37, 28, 0.5)"; // Vermelho com 70% de opacidade
    } else if (percent <= 66) {
      return "rgba(29, 52, 96, 0.5)"; // Azul com 70% de opacidade
    } else {
      return "rgba(51, 172, 27, 0.5)"; // Verde com 70% de opacidade
    }
  };


  const loadItemServicos = () => {

    if (listaItemServicos == undefined) return;

    if (listaItemServicos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }


    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonReorderGroup disabled={isDisabled} onIonItemReorder={handleReorder}>
          {listaItemServicos.map((value: any, index: any) => (
            <IonItem lines="none" key={"itemlista" + value.descricao + index.toString()}>

              <div style={{ flex: 1, width: "100px" }} onClick={() => {
                setTextToast(value.categoria + ' - ' + value.descricao);
                setShowToast(true);
              }}>
                <IonLabel style={{ fontSize: "12px" }}>
                  {value.categoria}
                </IonLabel>

                <IonLabel style={{ fontSize: "12px" }}>
                  {value.descricao}
                </IonLabel>
              </div>
              <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile')) || window.localStorage.getItem("esconderValoresProjeto") == "true"} style={{ textAlign: "right", fontSize: "12px" }}>{value.valor.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}</IonLabel>
              <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} style={{ textAlign: "right", fontSize: "12px" }}>{value.quantidade.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}</IonLabel>
              <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} style={{ textAlign: "center", fontSize: "12px" }}>{value.unidadeMedida}</IonLabel>
              {/* <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile')) || window.localStorage.getItem("esconderValoresProjeto") == "true"} style={{ textAlign: "right", fontSize: "12px" }}>{value.desconto}%</IonLabel> */}
              <IonLabel hidden={window.localStorage.getItem("esconderValoresProjeto") == "true"} style={{ textAlign: "right", fontSize: "12px" }}>{value.subtotal}</IonLabel>
              <IonLabel style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", fontSize: "12px" }}>
                <div style={{
                  position: "relative", height: "20px", width: "100%", backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  marginLeft: "10px"
                }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      height: "100%",
                      width: `${parseFloat(value.percentual?.toString().replace(/\./g, '').replace(/\,/g, '.') || 0)}%`,
                      backgroundColor: getColor(value.percentual),
                      zIndex: "1",
                      borderRadius: "10px"
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: "2"
                    }}
                  >
                    {value.percentual || "0"}%
                  </div>
                </div>
              </IonLabel>
              <IonLabel >  </IonLabel>


              <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonReorder slot="end"></IonReorder>
              </div>
              <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonButtons >


                  {/* <IonButton hidden={!isDisabled} onClick={() => {
                    if (value.obrigatorio == "sim") {
                      setTextToast("Campo Obrigatório.");
                    }
                    else {
                      setTextToast("Campo não Obrigatório.");
                    }
                    setShowToast(true);
                  }}>
                    <IonIcon color="primary" icon={value.obrigatorio == "sim" ? checkmarkCircle : checkmarkCircleOutline}></IonIcon>
                  </IonButton> */}


                  <IonButton hidden={!isDisabled || acessos?.projetos != 'edicao'}
                    onClick={() => {
                      setIndice(value.indice);
                      setDescricao(value.descricao);
                      setCategoria(value.categoria);


                      setQuantidade(value.quantidade.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }));

                      if (value.unidadeMedida == "m2") {
                        setLargura(value.largura.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));


                        setComprimento(value.comprimento.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));
                      }

                      setUnidadeMedida(value.unidadeMedida);

                      setValor(value.valor.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }));

                      setDesconto(value.desconto.toString());
                      setSubtotal(value.subtotal);

                      setPercentual(value.percentual?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }));

                      setQuantidadeLiberacao("");
                      setLarguraLiberacao("");
                      setComprimentoLiberacao("");
                      setPrestador("");
                      setObservacaoLiberacao("");


                      let lib: Liberacao[] = [];
                      value.liberacoes?.forEach((value: any) => {
                        lib.push({ data: value.data, quantidade: value.quantidade, idPrestador: value.idPrestador, nomePrestador: value.nomePrestador, observacao: value.observacao, liberado: value.liberado });
                      });
                      setListaLiberacoes(lib);

                      setEditar(true);
                      setShowLiberar(true);

                    }}>
                    <IonIcon color="primary" icon={checkmarkCircle} />
                  </IonButton>


                  <IonButton hidden={!isDisabled || acessos?.projetos != 'edicao'}
                    onClick={() => {
                      setIndice(value.indice);
                      setDescricao(value.descricao);
                      setCategoria(value.categoria);

                      setQuantidade(value.quantidade.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }));

                      if (value.unidadeMedida == "m2") {
                        setLargura(value.largura.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));


                        setComprimento(value.comprimento.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));
                      }

                      setUnidadeMedida(value.unidadeMedida);

                      setValor(value.valor.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }));

                      setDesconto(value.desconto.toString());
                      setSubtotal(value.subtotal);

                      setPercentual(value.percentual?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }));

                      // setCheckedLiberar(false);
                      // if (value.liberacoes && value.liberacoes.length > 0) {
                      //   setCheckedLiberar(true);
                      // }
                      setQuantidadeLiberacao("");
                      setLarguraLiberacao("");
                      setComprimentoLiberacao("");
                      setPrestador("");
                      setObservacaoLiberacao("");


                      let lib: Liberacao[] = [];
                      value.liberacoes?.forEach((value: any) => {
                        lib.push({ data: value.data, quantidade: value.quantidade, idPrestador: value.idPrestador, nomePrestador: value.nomePrestador, observacao: value.observacao, liberado: value.liberado });
                      });
                      setListaLiberacoes(lib);

                      setEditar(true);
                      setShowDetalhes(true);

                    }}>
                    <IonIcon color="primary" icon={pencil} />
                  </IonButton>

                  <IonButton hidden={!isDisabled || acessos?.projetos != 'edicao'}
                    onClick={() => {
                      mostartAlerta({
                        backdropDismiss: false,
                        header: 'Remover item',
                        message: 'Deseja <strong>remover</strong>?',
                        buttons: [
                          {
                            text: 'Cancelar',
                            role: 'cancel',
                          },
                          {
                            text: 'Confirmar',
                            cssClass: 'primary',
                            handler: () => {
                              deletarItemServicos(value.indice);
                            }
                          }
                        ],
                        onDidDismiss: (e) => console.log('did dismiss'),
                      })
                    }}>
                    <IonIcon color="primary" icon={trash} />
                  </IonButton>

                </IonButtons>

              </div>
            </IonItem>

          ))}
        </IonReorderGroup >
      </>
    );
  }

  function validarCampo(valor: any, mensagem: any) {
    if (!valor || valor === 0 || valor.trim() === "") {
      setTextToast(mensagem);
      setShowToast(true);
      return false;
    }
    return true;
  }

  function cadastrar(lstLiberacoes: any = null) {

    console.log("descricao", descricao);
    console.log("categoria", categoria);
    console.log("quantidade", quantidade);
    console.log("unidadeMedida", unidadeMedida);
    console.log("valor", valor);



    const esconderValoresProjeto = window.localStorage.getItem("esconderValoresProjeto") === "true";

    if (esconderValoresProjeto) {
      if (
        !validarCampo(descricao, "O campo descrição é obrigatório.") ||
        !validarCampo(categoria, "O campo categoria é obrigatório.") ||
        !validarCampo(unidadeMedida, "O campo unidade de medida é obrigatório.")
      ) {
        console.log("Entrou 1");
        return false;
      }
    } else {
      if (
        !validarCampo(descricao, "O campo descrição é obrigatório.") ||
        !validarCampo(categoria, "O campo categoria é obrigatório.") ||
        !validarCampo(unidadeMedida, "O campo unidade de medida é obrigatório.") ||
        !validarCampo(valor, "O campo valor é obrigatório.") ||
        // !validarCampo(desconto, "O campo desconto é obrigatório.") ||
        !validarCampo(subtotal, "O campo subtotal é obrigatório.")
      ) {
        console.log("Entrou 2");
        return false;
      }
    }


    if (unidadeMedida === "m2") {
      if (
        !validarCampo(largura, "O campo largura é obrigatório.") ||
        !validarCampo(comprimento, "O campo comprimento é obrigatório.")
      ) {
        return false;
      }
    } else {
      if (!validarCampo(quantidade, "O campo quantidade é obrigatório.")) {
        return false;
      }
    }

    let somaQuantidade: number = 0;
    listaLiberacoes.forEach(element => {
      somaQuantidade += parseFloat(element.quantidade?.toString().replace(/\./g, '').replace(/\,/g, '.'));
    });

    console.log("********************somaQuantidade", somaQuantidade);

    if (somaQuantidade > parseFloat(quantidade?.toString().replace(/\./g, '').replace(/\,/g, '.'))) {
      setTextToast("Para diminuir a quantidade é necessário excluir os itens liberados.");
      setShowToast(true);
      return false;
    }


    console.log("********************", listaLiberacoes);

    console.log("indice", indice);
    if (indice != "") {
      const objectRequest = {
        descricao: descricao,
        categoria: categoria,
        quantidade: quantidade,
        largura: largura,
        comprimento: comprimento,
        unidadeMedida: unidadeMedida,
        valor: valor,
        desconto: desconto || 0,
        subtotal: subtotal,
        liberacoes: (lstLiberacoes != null ? lstLiberacoes : listaLiberacoes),
        indice: indice
      }
      console.log("objectRequest", objectRequest);

      setShowLoading(true);
      requestService(
        alterarProjetosItemServicosEmpresa.url.replace("{id}", id.replaceAll("#", "%23")),
        {
          method: alterarProjetosItemServicosEmpresa.method,
          body: JSON.stringify(objectRequest),
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
        history,
        (response: any) => {
          console.log(response);
          if (response) {


            setListaItemServicos(response.message.Attributes.servicos || []);
            setValorTotal(response.message.Attributes.valorTotal.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setValorTotalServicos(response.message.Attributes.valorTotalServicos.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setPercentualTotalServicos(response.message.Attributes.percentualTotalServicos?.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setPercentual(response.message.Attributes.percentual?.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setTeveAlteracao(true);
            if (lstLiberacoes == null) {
              setTextToast("Alteração realizada com sucesso.");
              setShowToast(true);
              setShowDetalhes(false);
              setShowLiberar(false);
            }

          } else if (response.status === 400) {
            console.log(response);
            setTextToast("Ocorreu um erro ao efetuar a alteração.");
            setShowToast(true);

          }

        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao efetuar a alteração.");
          setShowToast(true);
        }).finally(() => {
          setShowLoading(false);
          if (buscarInputRef.current) {
            buscarInputRef.current.setFocus();
          }
        });

    } else {
      const objectRequest = {
        descricao: descricao,
        categoria: categoria,
        quantidade: quantidade,
        largura: largura,
        comprimento: comprimento,
        unidadeMedida: unidadeMedida,
        valor: valor,
        desconto: desconto,
        subtotal: subtotal,
        liberacoes: listaLiberacoes,
      }
      console.log("objectRequest", objectRequest);

      setShowLoading(true);
      requestService(
        inserirProjetosItemServicosEmpresa.url.replace("{id}", id.replaceAll("#", "%23")),
        {
          method: inserirProjetosItemServicosEmpresa.method,
          body: JSON.stringify(objectRequest),
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
        history,
        (response: any) => {
          console.log(response);
          if (response) {
            setTextToast("Cadastro realizado com sucesso.");
            setShowToast(true);

            setListaItemServicos(response.message.Attributes.servicos || []);
            setValorTotal(response.message.Attributes.valorTotal.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setValorTotalServicos(response.message.Attributes.valorTotalServicos.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setPercentualTotalServicos(response.message.Attributes.percentualTotalServicos?.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setPercentual(response.message.Attributes.percentual?.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));
            setDescricao("");
            setValor("");
            setQuantidade("");
            setLargura("");
            setComprimento("");

            if (window.localStorage.getItem("esconderValoresProjeto") == "true") {
              setValor("0,00");
            }
            //setUnidadeMedida("");
            setTeveAlteracao(true);
            // setShowDetalhes(false);

          } else if (response.status === 400) {
            console.log(response);
            setTextToast("Ocorreu um erro ao efetuar o cadastro.");
            setShowToast(true);

          }
        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);
        })
        .finally(() => {
          setShowLoading(false);
          if (buscarInputRef.current) {
            buscarInputRef.current.setFocus();
          }
        });
    }


  }

  function readCategoria() {
    return listaCategoria.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={value} value={value}>{value}</IonSelectOption>
    )
    )
  };


  function salvarLiberacao() {

    if (!quantidadeLiberacao || quantidadeLiberacao.trim() == "") {
      setTextToast("Campo quantidade da liberação é obrigatório.");
      setShowToast(true);
      return false;
    }
    else {


      let somaQuantidade: number = 0;
      listaLiberacoes.forEach(element => {
        somaQuantidade += parseFloat(element.quantidade?.toString().replace(/\./g, '').replace(/\,/g, '.'));
      });

      if (somaQuantidade + parseFloat(quantidadeLiberacao?.toString().replace(/\./g, '').replace(/\,/g, '.')) > parseFloat(quantidade?.toString().replace(/\./g, '').replace(/\,/g, '.'))) {
        setTextToast("Soma da quantidade da liberação não pode ser maior que a quantidade do serviço: " + quantidade);
        setShowToast(true);
        return false;
      }

      let found;
      found = listaPrestador.find((obj: any) => {
        return obj.pk === prestador;
      });

      let nomePrestador = "";

      if (found) {
        nomePrestador = found.nome;
      }

      let novaLiberacao = { data: new Date().toLocaleDateString(), quantidade: quantidadeLiberacao, idPrestador: prestador, nomePrestador: nomePrestador, observacao: observacaoLiberacao, liberado: false };
      setListaLiberacoes([...listaLiberacoes, novaLiberacao]);

      setQuantidadeLiberacao("");
      setLarguraLiberacao("");
      setComprimentoLiberacao("");
      setPrestador("");
      setObservacaoLiberacao("");
    }

  }

  function deletarLiberacao(index: Number): void {
    // Filtrar a lista de opções para criar uma nova lista excluindo o item na posição index
    const novalistaLiberacoes = listaLiberacoes.filter((_, i) => i !== index);
    // Atualizar o estado da lista de opções com a nova lista
    setListaLiberacoes(novalistaLiberacoes);
  }



  function readLiberacoes() {

    return listaLiberacoes.map((value: any, index: any, array: any) =>
    (
      <>

        <IonItem lines="none" key={index}>
          <IonCard style={{ width: "100%" }}>
            <IonCardContent style={{ padding: "8px" }}>
              <IonGrid>
                <IonRow>
                  {/* Data */}
                  <IonCol size="12" className="ion-text-wrap ion-text-start">
                    <strong>Data:</strong> {value.data}
                  </IonCol>
                </IonRow>
                <IonRow>
                  {/* Quantidade */}
                  <IonCol size="12" className="ion-text-wrap ion-text-start">
                    <strong>Quantidade:</strong> {value.quantidade}
                  </IonCol>
                </IonRow>
                <IonRow>
                  {/* Nome do Entregador */}
                  <IonCol size="12" className="ion-text-wrap">
                    <strong>Entregador:</strong> {value.nomePrestador}
                  </IonCol>
                </IonRow>
                <IonRow>
                  {/* Observação */}
                  <IonCol size="12" className="ion-text-wrap">
                    <strong>Observação:</strong> {value.observacao}
                  </IonCol>
                </IonRow>
              </IonGrid>

              <div className="ion-text-end" style={{ marginTop: '10px' }}>
                <IonButton fill="clear" hidden={true}
                  disabled={value.liberado || false}
                  size="small"
                  onClick={() => {
                    mostartAlerta({
                      backdropDismiss: false,
                      header: 'Nova Despesa',
                      message: 'Deseja lançar uma nova <strong>Despesa</strong>?',
                      buttons: [
                        {
                          text: 'Cancelar',
                          role: 'cancel',
                        },
                        {
                          text: 'Confirmar',
                          cssClass: 'primary',
                          handler: () => {
                            setObservacao("Pagamento referente a " + listaLiberacoes[index].quantidade + " (" + unidadeMedida + ")" + (listaLiberacoes[index].nomePrestador ? (" para " + listaLiberacoes[index].nomePrestador) : ""));
                            setIndiceLiberar(index);
                            setShowDetalhesFinanceiro(true);
                          }
                        }
                      ],
                      onDidDismiss: (e) => console.log('did dismiss'),
                    })
                  }}>
                  <IonIcon color="primary" icon={logoUsd} />
                </IonButton>
                <IonButton fill="clear"
                  size="small"
                  onClick={() => {
                    mostartAlerta({
                      backdropDismiss: false,
                      header: 'Remover item',
                      message: 'Deseja <strong>remover</strong>?',
                      buttons: [
                        {
                          text: 'Cancelar',
                          role: 'cancel',
                        },
                        {
                          text: 'Confirmar',
                          cssClass: 'primary',
                          handler: () => {
                            deletarLiberacao(index);
                          }
                        }
                      ],
                      onDidDismiss: (e) => console.log('did dismiss'),
                    })
                  }}>
                  <IonIcon color="danger" icon={trash} />
                </IonButton>
              </div>
            </IonCardContent>
          </IonCard>
        </IonItem>




      </>
    )
    )
  };

  function readPrestador() {
    return listaPrestador.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"prestador" + index} value={value.pk}>{value.nome}</IonSelectOption>
    )
    )
  };



  const consultarUsuario = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo");

    const resp = await fetch(montarUrl.replace("{perfil}", "usuario"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        return equipe.Items;
      }
    }
  }

  const consultarPrestadorUsuarios = async () => {

    let ret1 = await consultarPrestador();
    let ret2 = await consultarUsuario();

    if (ret1 && ret2) {
      let merged = [...ret1, ...ret2]

      console.log("merged", merged);

      let lista = (merged.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1))
      setListaPrestador(lista);
    }
  }

  const consultarPrestador = async () => {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    let montarUrl = listaEquipePorPerfil.url.replace("{loja}", loja.replaceAll(/#/g, "%23")).concat("?status=ativo");

    const resp = await fetch(montarUrl.replace("{perfil}", "prestador"),
      {
        method: "GET",
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    let equipe = await resp.json();
    if (resp.status === 400 || resp.status === 401) {
      history.replace("/login/");
    }
    else {
      if (equipe.Items) {
        return equipe.Items;
      }
    }
  }

  async function closeModalPrestador(pk: any) {
    setPrestador(pk);
    setShowDetalhesPrestador(false);
    await consultarPrestadorUsuarios();
  }

  async function closeModalSimplePrestador() {
    setShowDetalhesPrestador(false);
  }



  async function closeModalFinanceiro() {
    setRecarregarFinanceiro(true);
    setShowDetalhesFinanceiro(false);


    let obj = [...listaLiberacoes];
    obj[indiceLiberar] = { ...obj[indiceLiberar], liberado: true };
    setListaLiberacoes(obj);


    cadastrar(obj);
  }

  async function closeModalSimpleFinanceiro() {
    setShowDetalhesFinanceiro(false);
  }

  // Função para buscar itens
  const handleSearch = (e: any) => {
    setBuscar(e.target.value);
    const searchTerm = e.target.value?.toLowerCase();
    if (searchTerm === "") {
      setFilteredItems([]);
      return;
    }

    const isInteger = /^\d+$/.test(searchTerm);

    const filtered = listaItemCatalogoServicos.filter((item: any) =>
      isInteger ? item.codigo === searchTerm : item.codigo.includes(searchTerm) || item.nome.toLowerCase().includes(searchTerm)
    );
    setFilteredItems(filtered);
  };

  const handleSelectItem = (item: any) => {
    setDescricao(item.nome);
    setCategoria(item.categoria);
    setQuantidade("1");
    setUnidadeMedida(item.unidadeMedida);
    setValor(item.valor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
    setDesconto("0");
    setBuscar("");
    setFilteredItems([]);
  };

  function avancarAdicionar() {
    setIndice("");
    setDescricao("");
    setCategoria("");
    setQuantidade("");
    setLargura("");
    setComprimento("");
    setUnidadeMedida("");
    setValor("");
    setDesconto("");
    setSubtotal("0,00");

    if (window.localStorage.getItem("esconderValoresProjeto") == "true") {
      setDesconto("0,00");
      setValor("0,00");
    }

    // setCheckedLiberar(false);
    setQuantidadeLiberacao("");
    setLarguraLiberacao("");
    setComprimentoLiberacao("");
    setPrestador("");
    setObservacaoLiberacao("");
    setPercentual("0");

    setListaLiberacoes([]);

    setEditar(false);
    setShowDetalhes(true);
  }

  return (

    <IonPage className="ProjetosServicos">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => {
              if (teveAlteracao) {
                setRecarregarProjeto(true);
              }
              history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */
            }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Itens</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>

          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>
              <IonItem lines="none" button
                hidden={acessos?.configuracoes != 'edicao'}
                onClick={() => {
                  avancarAdicionar();
                  closePopover();

                }}>
                <IonIcon icon={addCircleOutline} slot="start" />
                <IonLabel>Adicionar</IonLabel>
              </IonItem>

              <IonItem lines="none" button hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}
                onClick={() => {
                  history.push("../servicoscatalogo", { recarregar: true });
                  closePopover();
                }}>
                <IonIcon icon={bookOutline} slot="start" />
                <IonLabel>Catálogo de serviços</IonLabel>
              </IonItem>
              <IonItem lines="none" button hidden={acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'}
                onClick={() => { history.push("../servicoscategorias"); closePopover(); }}>
                <IonIcon icon={listOutline} slot="start" />
                <IonLabel>Categorias dos serviços</IonLabel>
              </IonItem>

              <IonItem lines="none" button onClick={closePopover}>
                <IonIcon icon={closeOutline} slot="start" />
                <IonLabel>Cancelar</IonLabel>
              </IonItem>
            </IonList>
          </IonPopover>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.projetos != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            avancarAdicionar();
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>



        <IonList style={{ margin: "0px", padding: "0px" }}>
          <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>

            <div style={{ flex: 1, width: "100px" }}>
              <IonLabel style={{ fontSize: "12px" }}>Descrição</IonLabel>
            </div>
            <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile')) || window.localStorage.getItem("esconderValoresProjeto") == "true"} style={{ textAlign: "right", fontSize: "12px" }}>Valor</IonLabel>
            <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} style={{ textAlign: "right", fontSize: "12px" }}>Quantidade</IonLabel>
            <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} style={{ textAlign: "center", fontSize: "12px" }}>Unidade de Medida</IonLabel>
            {/* <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile')) || window.localStorage.getItem("esconderValoresProjeto") == "true"} style={{ textAlign: "right", fontSize: "12px" }}>Desconto</IonLabel> */}
            <IonLabel hidden={window.localStorage.getItem("esconderValoresProjeto") == "true"} style={{ textAlign: "right", fontSize: "12px" }}>Total</IonLabel>
            <IonLabel style={{ textAlign: "center", fontSize: "12px" }}>Realizado</IonLabel>
            <IonLabel style={{ textAlign: "right" }}>
              <IonBadge hidden={acessos?.projetos != 'edicao'}
                onClick={() => setIsDisabled((current) => !current)}>
                <IonIcon color="medium" icon={reorderTwo}></IonIcon>
              </IonBadge>
            </IonLabel>
          </IonItem>

          {loadItemServicos()}

          <IonItem lines="none" style={{ fontWeight: 'bold', borderTop: '1px solid #dddddd' }} hidden={listaItemServicos.length === 0}>

            <div style={{ flex: 1, width: "100px" }}>
              <IonLabel style={{ fontSize: "12px" }}></IonLabel>
            </div>
            <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile')) || window.localStorage.getItem("esconderValoresProjeto") == "true"} style={{ fontSize: "12px" }}></IonLabel>
            <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} style={{ fontSize: "12px" }}></IonLabel>
            {/* <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} style={{ fontSize: "12px" }}></IonLabel> */}
            <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile')) || window.localStorage.getItem("esconderValoresProjeto") == "true"} style={{ fontSize: "12px" }}></IonLabel>
            <IonLabel hidden={window.localStorage.getItem("esconderValoresProjeto") == "true"} style={{ textAlign: "right", fontSize: "12px" }}>{valorTotalServicos}</IonLabel>
            <IonLabel style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", fontSize: "12px" }}>



              <div style={{
                position: "relative", height: "20px", width: "100%", backgroundColor: "#f5f5f5",
                borderRadius: "10px",
                marginLeft: "10px"
              }}>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    height: "100%",
                    width: `${parseFloat(percentualTotalServicos?.toString().replace(/\./g, '').replace(/\,/g, '.') || 0)}%`,
                    backgroundColor: getColor(percentualTotalServicos),
                    zIndex: "1",
                    borderRadius: "10px"
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: "2"
                  }}
                >
                  {percentualTotalServicos || "0"}%
                </div>
              </div>

            </IonLabel>

            <IonLabel style={{ textAlign: "right" }}></IonLabel>
          </IonItem>


          {/* <IonFab horizontal="end" edge style={{ "top": "0px", "right": "-4px" }} hidden={acessos?.projetos != 'edicao'}>
            <IonFabButton size="small" color="tertiary" 
              <IonIcon color="light" icon={menu}></IonIcon>
            </IonFabButton>
          </IonFab> */}
        </IonList>


        {/* <IonButton slot="end" >Ordenar</IonButton> */}



        <IonModal onDidPresent={() => {
          if (buscarInputRef.current) {
            buscarInputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
                    setShowDetalhes(false);
                  }} />
                </IonButtons>
                <IonTitle>{editar ? "Editar" : "Novo"} serviço</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>


              <IonSearchbar
                onIonInput={handleSearch}
                placeholder="Digite o código ou nome"
                value={buscar} ref={buscarInputRef}
              />

              <IonList style={{ position: 'absolute', zIndex: 999, backgroundColor: 'white', width: '100%', height: "auto", borderBottom: filteredItems.length > 0 ? '1px solid #f1f1f1' : 'none' }}>
                {filteredItems.map((item: any, index: any) => (
                  <IonItem lines="none" key={"filtro" + index} button onClick={() => handleSelectItem(item)}>
                    <IonLabel>{item.nome}</IonLabel>
                  </IonItem>
                ))}
              </IonList>

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonList lines="full">
                      <IonItem>
                        <IonLabel position="stacked">Descrição*</IonLabel>
                        <IonInput
                          placeholder="Digite a descrição"
                          autoCorrect='true' autoCapitalize='true'
                          value={descricao}
                          required
                          onIonInput={(e: any) => setDescricao(e.target.value)}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel position="stacked">Categoria*</IonLabel>
                        <IonSelect
                          value={categoria}
                          placeholder="Selecione"
                          onIonChange={e => { setCategoria(e.detail.value); }}
                        >
                          {readCategoria()}
                        </IonSelect>
                      </IonItem>
                      <IonItem hidden={window.localStorage.getItem("esconderValoresProjeto") == "true"} >
                        <IonLabel position="stacked">Valor*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite o valor"
                          value={valor}
                          onKeyUp={onChangeValor}
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Unidade de medida*</IonLabel>
                        <IonSelect
                          value={unidadeMedida}
                          placeholder="Selecione"
                          onIonChange={e => { setUnidadeMedida(e.detail.value); }}
                        >
                          <IonSelectOption key="kg" value="kg">Quilograma (kg)</IonSelectOption>
                          <IonSelectOption key="m" value="m">Metro (m)</IonSelectOption>
                          <IonSelectOption key="m2" value="m2">Metro (m²)</IonSelectOption>
                          <IonSelectOption key="unid" value="unid">Unidade (unid)</IonSelectOption>
                        </IonSelect>
                      </IonItem>

                      <IonItem hidden={unidadeMedida != "m2"}>
                        <IonLabel position="stacked">Largura*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite a largura"
                          value={largura}
                          onKeyUp={onChangeLargura}
                        />
                      </IonItem>
                      <IonItem hidden={unidadeMedida != "m2"}>
                        <IonLabel position="stacked">Comprimento*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite o comprimento"
                          value={comprimento}
                          onKeyUp={onChangeComprimento}
                        />
                      </IonItem>

                      <IonItem disabled={unidadeMedida == "m2"}>
                        <IonLabel position="stacked">Quantidade*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite a quantidade"
                          value={quantidade}
                          onKeyUp={onChangeQuantidade}
                        />
                      </IonItem>

                      {/* <IonItem hidden={window.localStorage.getItem("esconderValoresProjeto") == "true"} >
                        <IonLabel position="stacked">Desconto(%)*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={3}
                          placeholder="Digite o desconto"
                          value={desconto}
                          onKeyUp={onChangeDesconto}
                        />
                      </IonItem> */}
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonContent>

            <IonFooter className="ion-no-border" style={{ borderTop: '1px solid #ccc' }}>


              <IonList hidden={window.localStorage.getItem("esconderValoresProjeto") == "true"} >
                <IonItem lines="none">
                  <IonLabel slot="start" style={{ fontWeight: 'bold' }}>Valor Total:</IonLabel>
                  <IonLabel slot="end">{subtotal || "0"}</IonLabel>
                </IonItem>


              </IonList>

              <IonToolbar>
                <IonButton onClick={() => {
                  cadastrar();
                }} expand="block" type="submit">Cadastrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonModal
          onDidPresent={() => {
            if (unidadeMedida != "m2") {
              if (quantidadeLiberacaoInputRef.current) {
                quantidadeLiberacaoInputRef.current.setFocus();
              }
            }
            else {
              if (larguraLiberacaoInputRef.current) {
                larguraLiberacaoInputRef.current.setFocus();
              }
            }
          }}
          isOpen={showLiberar} onDidDismiss={() => {
            setShowLiberar(false);
          }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
                    setShowLiberar(false);
                  }} />
                </IonButtons>
                <IonTitle>{categoria} - {descricao}</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonList lines="full">

                      <IonItem hidden={unidadeMedida != "m2"}>
                        <IonLabel position="stacked">Largura*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite a largura"
                          ref={(ref) => larguraLiberacaoInputRef.current = ref}
                          value={larguraLiberacao}
                          onKeyUp={onChangeLarguraLiberacao}
                        />
                      </IonItem>
                      <IonItem hidden={unidadeMedida != "m2"}>
                        <IonLabel position="stacked">Comprimento*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite o comprimento"
                          value={comprimentoLiberacao}
                          onKeyUp={onChangeComprimentoLiberacao}
                        />
                      </IonItem>
                      <IonItem disabled={unidadeMedida == "m2"}>
                        <IonLabel position="stacked">Quantidade*</IonLabel>
                        <IonInput
                          type="tel"
                          maxlength={14}
                          placeholder="Digite a quantidade"
                          ref={(ref) => quantidadeLiberacaoInputRef.current = ref}
                          value={quantidadeLiberacao}
                          onKeyUp={onChangeQuantidadeLiberacao}
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Entregador</IonLabel>
                        <IonButtons slot="end" onClick={() => { setShowDetalhesPrestador(true) }}>
                          <IonIcon color="tertiary" size='large' slot="icon-only" icon={addCircle}></IonIcon>
                        </IonButtons>

                        <IonSelect
                          value={prestador}
                          placeholder="Selecione"
                          onIonChange={e => { setPrestador(e.detail.value); }}
                        >
                          {readPrestador()}
                        </IonSelect>
                      </IonItem>

                      <IonItem>
                        <IonLabel position="stacked">Observação</IonLabel>
                        <IonInput
                          placeholder="Digite a observação"
                          value={observacaoLiberacao}
                          autoCorrect='true' autoCapitalize='true'
                          onIonInput={(e: any) => setObservacaoLiberacao(e.target.value)}
                        />
                      </IonItem>

                      <div style={{ textAlign: 'right', paddingRight: "8px" }}>
                        <IonButton onClick={() => salvarLiberacao()}>Adicionar</IonButton>
                      </div>
                      <IonItem lines="none" >

                        <IonList style={{ width: "100%", padding: "0px", margin: "0px" }}>
                          {readLiberacoes()}
                        </IonList>

                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonContent>
            <IonFooter className="ion-no-border" style={{ borderTop: '1px solid #ccc' }}>


              <IonList style={{ margin: 0, padding: 0 }}>
                <IonItem lines="none" style={{ margin: 0, padding: 0 }}>
                  <IonLabel slot="start" style={{ fontWeight: 'bold', margin: 0, padding: 0 }}>Quantidade Total:</IonLabel>
                  <IonLabel slot="end" style={{ margin: 0, padding: 0 }}>{(quantidadeLiberado || "0,00") + "/" + (quantidade || "0,00")}</IonLabel>
                </IonItem>
                <IonItem lines="none" style={{ margin: 0, padding: 0 }}>
                  <IonLabel slot="start" style={{ fontWeight: 'bold', margin: 0, padding: 0 }}>Realizado:</IonLabel>
                  <IonLabel slot="end" style={{ margin: 0, padding: 0 }}>{percentual || "0"}%</IonLabel>
                </IonItem>
              </IonList>

              <IonToolbar>
                <IonButton onClick={() => {
                  let temNovoItem = false;
                  listaLiberacoes.forEach(element => {
                    if (element.liberado == false) {
                      temNovoItem = true;
                    }
                  });

                  if (temNovoItem || listaLiberacoes.length == 0) {
                    cadastrar();
                  }
                  else {
                    setTextToast("Não existem atualizações para serem enviadas.");
                    setShowToast(true);
                  }
                }}
                  expand="block" type="submit">Salvar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonModal onDidPresent={() => {
          if (inputPrestadoresCadRef.current) {
            inputPrestadoresCadRef.current.setFocus();
          }
        }} isOpen={showDetalhesPrestador} onDidDismiss={() => { setShowDetalhesPrestador(false); }}>
          <PrestadoresCad inputRef={inputPrestadoresCadRef} usuario={null} closeAction={closeModalPrestador} closeActionSimple={closeModalSimplePrestador}></PrestadoresCad>
        </IonModal>

        <IonModal isOpen={showDetalhesFinanceiro} onDidDismiss={() => { setShowDetalhesFinanceiro(false); }}>
          <FinanceiroLancamentos projetoid={btoa(params.id)} tipo="despesa" observacao={observacao} closeAction={closeModalFinanceiro} closeActionSimple={closeModalSimpleFinanceiro}></FinanceiroLancamentos>
        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default ProjetosServicos;