import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonRow, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { alterarStatusProjeto, requestService, urlBase } from '../../../../../Utils/Services';
import "./ProjetosLiberar.css";


type Props = {
  projetoid: string;
  closeAction: Function;
  closeActionSimple: Function;
};

const ProjetosLiberar: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [dadosProjeto, setDadosProjeto] = useState<any>([]);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const buscarInputRef = useRef<any>(null);
  const [interno, setInterno] = useState(false);
  const [troco, setTroco] = useState<any>();
  const [formaPagamento, setFormaPagamento] = useState('');

  const opcoesPagamento = [
    'Dinheiro',
    'Cartão de Crédito',
    'Cartão de Débito',
    'Pix',
    'Alelo',
    'Sodexo',
    'Vale Alimentação',
    'Boleto'
  ];

  const [taxaEntrega, setTaxaEntrega] = useState('');

  const opcoesTaxaEntrega = [
    { label: 'Grátis', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 6,00', value: '6,00' },
    { label: 'R$ 7,00', value: '7,00' },
    { label: 'R$ 8,00', value: '8,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 12,00', value: '12,00' },
    { label: 'R$ 15,00', value: '15,00' },
  ];

  const [desconto, setDesconto] = useState('');

  const opcoesDesconto = [
    { label: 'R$ 0,00', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 15,00', value: '15,00' },
    { label: 'R$ 20,00', value: '20,00' },
    { label: 'R$ 25,00', value: '25,00' }
  ];

  const [taxaServico, setTaxaServico] = useState('');

  const opcoesTaxaServico = [
    { label: '0%', value: '0' },
    { label: '10%', value: '10' },
    { label: '12%', value: '12' },
    { label: '15%', value: '15' }
  ];


  useEffect(() => {
    if (props.projetoid != "") {
      // console.log("entrou no useEffect");
      carregarProjeto();
    }
  }, [props.projetoid]);

  function carregarProjeto() {

    const consultar = async () => {

      // console.log("*********************************params.projetoid", props.projetoid);

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }
      setShowLoading(true);
      const resp = await fetch(urlBase.url + "/projetos/" + props.projetoid.replaceAll(/#/g, '%23'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        }).finally(() => {
          setShowLoading(false);
        });
      const response = await resp.json();

      if (response.Item !== undefined) {
        // console.log("***************************", response);
        setDadosProjeto(response.Item || []);

        setFormaPagamento(response.Item.formaPagamento);
        setTaxaEntrega(response.Item.taxaEntrega);
        setInterno(response.Item.interno);
        setDesconto(response.Item.desconto);
        setTaxaServico(response.Item.taxaServico);
      }

      if (buscarInputRef.current) {
        buscarInputRef.current.setFocus();
      }
    }

    consultar();
  }

  async function salvar() {
    const objectRequest = {
      formaPagamento: formaPagamento,
      troco: troco ? troco.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) : "",
      taxaEntrega: taxaEntrega,
      desconto: desconto,
      taxaServico: taxaServico
    }
    requestService(alterarStatusProjeto.url.replaceAll("{id}", props.projetoid.replaceAll(/#/g, "%23")) + "?alteracao=true", {
      method: alterarStatusProjeto.method,
      headers: [["token", localStorage.getItem("token") || ""]],
      credentials: 'include',
      body: JSON.stringify(objectRequest)
    },
      history,
      (response: any) => {

        // console.log("response retorno liberar", response);
        props.closeAction(response);

      },
      (error: any) => {
        setTextToast(error.message);
        setShowToast(true);
      });
  }

  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    // Define o desconto como zero se o campo estiver vazio
    if (v === '') {
      v = '';
    }

    setTroco(v);
  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">

          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
              props.closeActionSimple();
            }} />
          </IonButtons>
          <IonTitle>Liberar</IonTitle>

        </IonToolbar>


      </IonHeader>

      <IonContent fullscreen={false}>

        <IonGrid hidden={interno || dadosProjeto.dataCupom}>
          <IonRow>
            <IonCol>
              <IonList>
                <IonItem lines="none">
                  <IonLabel>Forma de pagamento</IonLabel>
                  <IonSelect
                    placeholder="Selecione"
                    value={formaPagamento}
                    onIonChange={(e) => {
                      setFormaPagamento(e.detail.value);

                    }}
                  >
                    {opcoesPagamento.map((opcao, index) => (
                      <IonSelectOption key={index} value={opcao}>
                        {opcao}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>

                <IonItem lines="none" hidden={formaPagamento != "Dinheiro"}>
                  <IonLabel>Troco</IonLabel>
                  <IonInput
                    type="tel"
                    maxlength={14}
                    placeholder="Digite o valor"
                    value={troco}
                    onKeyUp={onChangeValor}
                  />
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Taxa de entrega</IonLabel>
                  <IonSelect
                    placeholder="Selecione"
                    value={taxaEntrega}
                    onIonChange={(e) => setTaxaEntrega(e.detail.value)}
                  >
                    {opcoesTaxaEntrega.map((opcao, index) => (
                      <IonSelectOption key={index} value={opcao.value}>
                        {opcao.label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>Desconto</IonLabel>
                  <IonSelect
                    placeholder="Selecione"
                    value={desconto}
                    onIonChange={(e) => setDesconto(e.detail.value)}
                  >
                    {opcoesDesconto.map((opcao, index) => (
                      <IonSelectOption key={index} value={opcao.value}>
                        {opcao.label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>


              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginTop: '20px' }} hidden={dadosProjeto.dataCupom}>
          <IonButton
            onClick={async () => {
              await salvar();
            }}
            type="submit"
            style={{ margin: "0px", textAlign: "right" }}
          >
            Liberar
          </IonButton>
        </div>

        <IonToast
          isOpen={showToast}
          position="middle"
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />
      </IonContent>
    </>


  );
};

export default ({ projetoid, closeAction, closeActionSimple }: { projetoid: any, closeAction: Function, closeActionSimple: Function }) => (
  <ProjetosLiberar projetoid={projetoid} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </ProjetosLiberar>
)